import { FC } from "react";
import styled from "styled-components";

interface LogoProps {
  width?: string;
  height?: string;
  url?: string;
}
export const Logo: FC<LogoProps> = props => {
  const { width, height, url } = props;

  return (
    <LogoImg
      alt="logo digital roadbook"
      src={url ?? "/logo.svg"}
      width={width}
      height={height}
    />
  );
};

const LogoImg = styled.img<LogoProps>`
  width: ${({ width }) => width ?? "154px"};
  height: ${({ height }) => height ?? "38px"};
  user-select: none;
  object-fit: contain;
`;
