import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { add, addHours, compareAsc, format, parse } from "date-fns";
import { useFormik } from "formik";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { Card } from "src/components/Card";
import { UpdateStepButtons } from "../../UpdateStepButtons";
import { AirportForm } from "./AirportForm";
import { StepsForm } from "./StepsForm";

import { useRequestStatus, RequestStatus } from "src/hooks/useRequestStatus";
import { updateOrder } from "src/services/order";
import { validateDate } from "src/utils/validations";
import {
  getDestinationLocalDate,
  getLocalDateWithDestinationUTC,
} from "src/utils/date";
import { computeStepsNewDate } from "src/utils/order-steps";

import { colors } from "src/theme/colors";

import type { Location } from "src/models/location";
import type { CommonStepProps } from "../../EditContent";

import { ReactComponent as PinPlaneIcon } from "src/assets/svg-icons/transports/plane.svg";
import { ReactComponent as FootstepsIcon } from "src/assets/svg-icons/footsteps.svg";

export interface StepDraft {
  id?: string; // only available for existing steps already registered in DB
  date: string; // ISO-8601
  rank: number;
  nightsCount: number;
  location: Location;
}

interface OrderStepsProps extends CommonStepProps {}

export const OrderSteps: FC<OrderStepsProps> = props => {
  const {
    order,
    currentStep,
    previousStepHandler,
    nextStepHandler,
    updateOrderState,
    goToRecapHandler,
  } = props;
  const { t } = useTranslation();
  const [status, setStatus] = useRequestStatus();
  const [startDelay, setStartDelay] = useState(order.startDelay ?? 0);
  const [endDelay, setEndDelay] = useState(order.endDelay ?? 0);
  const [startLocationSelected, setStartLocationSelected] =
    useState<Location | null>(order.startLocation ?? null);
  const [endLocationSelected, setEndLocationSelected] =
    useState<Location | null>(order.endLocation ?? null);

  const compareTwoSteps = (firstStep: StepDraft, secondStep: StepDraft) => {
    return compareAsc(new Date(firstStep.date), new Date(secondStep.date));
  };

  const sortedSteps = order.steps ? order.steps.sort(compareTwoSteps) : [];
  const [steps, setSteps] = useState<StepDraft[]>(sortedSteps);

  const localStartDate = order.startDate
    ? getLocalDateWithDestinationUTC(
        order.startDate,
        order.startLocation.utcOffsetMinutes ?? 0,
      )
    : null;

  const localEndDate = order.endDate
    ? getLocalDateWithDestinationUTC(
        order.endDate,
        order.endLocation.utcOffsetMinutes ?? 0,
      )
    : null;

  const formik = useFormik({
    initialValues: {
      startDate: localStartDate
        ? format(new Date(localStartDate), "yyyy-MM-dd")
        : "",
      startHour: localStartDate
        ? format(new Date(localStartDate), "HH:mm:ss")
        : "",
      startLocation: order.startLocation?.displayName || "",
      endDate: localEndDate ? format(new Date(localEndDate), "yyyy-MM-dd") : "",
      endHour: localEndDate ? format(new Date(localEndDate), "HH:mm:ss") : "",
      endLocation: order.endLocation?.displayName || "",
    },
    validateOnChange: false,
    validate: values => {
      const errors: Record<string, string> = {};
      ["startDate", "endDate"].forEach(date => {
        if (!validateDate(values[date as "startDate" | "endDate"])) {
          errors[date] = "This date is not valid.";
        }
      });

      if (!values.startLocation || !startLocationSelected) {
        errors["startLocation"] = "The start location is not valid.";
      }

      if (!values.endLocation || !endLocationSelected) {
        errors["endLocation"] = "The end location is not valid.";
      }

      if (Object.keys(errors).length > 0) {
        setStatus(RequestStatus.VALIDATION_ERROR);
      }
      return errors;
    },
    onSubmit: async values => {
      if (status === RequestStatus.LOADING) {
        return {
          shouldExecuteCallback: false,
        };
      }

      setStatus(RequestStatus.LOADING);

      try {
        const newSteps = steps.map(step => {
          let newStep = step;

          step.date = addHours(new Date(step.date), 12).toISOString();
          return newStep;
        });

        const startHour = !values["startHour"] ? "11:59:42" : values.startHour;
        const endHour = !values["endHour"] ? "11:59:42" : values.endHour;

        const res = await updateOrder(order.id, {
          startDelay,
          endDelay,
          startDate: getDestinationLocalDate(
            values.startDate,
            startHour,
            startLocationSelected?.utcOffsetMinutes ?? 0,
          ).toISOString(),
          endDate: getDestinationLocalDate(
            values.endDate,
            endHour,
            endLocationSelected?.utcOffsetMinutes ?? 0,
          ).toISOString(),
          startLocation: startLocationSelected,
          endLocation: endLocationSelected,
          steps: newSteps,
        });

        if (!res.data.success) {
          throw new Error();
        }
        updateOrderState(res.data.order);
        return {
          shouldExecuteCallback: true,
        };
      } catch {
        setStatus(RequestStatus.SERVER_ERROR);
        return {
          shouldExecuteCallback: false,
        };
      }
    },
  });

  const submitFormHandler = async (successCallback: () => void) => {
    const res = (await formik.submitForm()) as {
      shouldExecuteCallback: boolean;
    };
    if (
      typeof res === "object" &&
      "shouldExecuteCallback" in res &&
      res.shouldExecuteCallback
    ) {
      successCallback();
    }
  };

  /**
   * Function updating the steps' state by computing every date depending on a initial yyyy-MM-dd date.
   */
  const refreshStepsDatesHandler = useCallback((initialStartDate: string) => {
    setSteps(prevSteps =>
      prevSteps.map((step, index) => ({
        ...step,
        date: computeStepsNewDate(initialStartDate, prevSteps.slice(0, index)),
      })),
    );
  }, []);

  const handleLocationSelect = (
    key: "startLocation" | "endLocation",
    location: Location,
  ) => {
    if (key === "startLocation") {
      setStartLocationSelected(location);
    } else {
      setEndLocationSelected(location);
    }
    formik.setValues(values => ({
      ...values,
      [key]: location.displayName,
    }));
  };

  const canGoNext =
    steps.length > 0 &&
    status !== RequestStatus.VALIDATION_ERROR &&
    !!formik.values["startDate"] &&
    !!formik.values["endDate"] &&
    !!formik.values["startLocation"] &&
    !!formik.values["endLocation"];

  const formStartDate = formik.values["startDate"] || null;
  const initialStartJsDate =
    formStartDate &&
    parse(formik.values["startDate"], "yyyy-MM-dd", new Date());

  const initialStartDate = initialStartJsDate
    ? format(add(initialStartJsDate, { days: startDelay }), "yyyy-MM-dd")
    : "";

  // memoized dates to prevent possible human errors
  const minStartDate = useMemo(() => {
    return format(new Date(), "yyyy-MM-dd");
  }, []);

  const initialEndDate = initialStartDate
    ? computeStepsNewDate(initialStartDate, steps)
    : "";

  // effect updating every step's date if the initialStartDate changes
  useEffect(() => {
    if (!!initialStartDate) {
      refreshStepsDatesHandler(initialStartDate);
    }
  }, [initialStartDate, refreshStepsDatesHandler]);

  return (
    <Wrapper>
      <CardWrapper>
        <Section>
          <SectionTitle>
            <PinPlaneIcon />
            <span>{t("pages.orders.edit.order_steps.arrival_airport")}</span>
          </SectionTitle>
          <AirportForm
            status={status}
            labels={{
              dateLabel: t("pages.orders.edit.order_steps.arrival_date"),
              locationLabel: t("pages.orders.edit.order_steps.arrival_airport"),
              hourLabel: t("pages.orders.edit.order_steps.arrival_hour"),
            }}
            dateKey="start"
            locationKey="startLocation"
            minDate={minStartDate}
            values={formik.values}
            errors={formik.errors}
            delay={startDelay}
            updateDelay={newDelay => setStartDelay(newDelay)}
            handleChange={(e: any) => {
              formik.handleChange(e);
            }}
            handleLocationSelected={location => {
              handleLocationSelect("startLocation", location);
            }}
          />
        </Section>
        {/* Remaining sections are only displayed if a start date is selected */}
        {!!initialStartDate && (
          <>
            <Section>
              <SectionTitle>
                <FootstepsIcon />
                <span>{t("pages.orders.edit.order_steps.step_and_hotel")}</span>
              </SectionTitle>
              <StepsForm
                initialStartDate={initialStartDate}
                steps={steps}
                addStep={step => setSteps(prevSteps => [...prevSteps, step])}
                deleteStep={step => {
                  setSteps(prevSteps =>
                    prevSteps.filter(_step => _step !== step),
                  );
                  refreshStepsDatesHandler(initialStartDate);
                }}
                moveStep={step => {
                  setSteps(step);
                  refreshStepsDatesHandler(initialStartDate);
                }}
              />
            </Section>
            <Section>
              <SectionTitle>
                <PinPlaneIcon />
                <span>
                  {t("pages.orders.edit.order_steps.departure_airport")}
                </span>
              </SectionTitle>
              <AirportForm
                status={status}
                initialDate={initialEndDate}
                labels={{
                  dateLabel: t("pages.orders.edit.order_steps.departure_date"),
                  hourLabel: t("pages.orders.edit.order_steps.departure_hour"),
                  locationLabel: t(
                    "pages.orders.edit.order_steps.departure_airport",
                  ),
                }}
                dateKey="end"
                locationKey="endLocation"
                values={formik.values}
                errors={formik.errors}
                delay={endDelay}
                updateDelay={newDelay => setEndDelay(newDelay)}
                handleChange={formik.handleChange}
                handleLocationSelected={location =>
                  handleLocationSelect("endLocation", location)
                }
                disabled={true}
              />
            </Section>
          </>
        )}
      </CardWrapper>
      <UpdateStepButtons
        order={order}
        disableNext={!canGoNext}
        loadingNext={status === RequestStatus.LOADING}
        currentStep={currentStep}
        previousHandler={previousStepHandler}
        nextHandler={() => submitFormHandler(nextStepHandler)}
        recapHandler={() => submitFormHandler(goToRecapHandler)}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  & > *:not(:last-child) {
    margin-bottom: 16px;
  }
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
`;

const SectionTitle = styled.div`
  display: flex;
  align-items: center;
  color: ${colors.turquoise[800]};
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: -0.015em;
  text-transform: uppercase;
  margin-bottom: 12px;

  & > svg {
    width: 16px;
    height: 16px;
    margin-right: 8px;
  }
`;

const CardWrapper = styled(Card)`
  padding: 16px;

  & > ${Section}:not(:first-child) {
    padding-top: 32px;
  }
  & > ${Section}:not(:last-child) {
    padding-bottom: 32px;
    border-bottom: 1px solid ${colors.background[100]};
  }
`;
