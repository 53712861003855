import { FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { BasicPageWithHeader } from "src/components/layout/BasicPageWithHeader";
import { Card } from "src/components/Card";
import { Button } from "src/components/form/Button";
import { Alert } from "src/components/Alert";
import { CreateDraftOrderModal } from "src/components/pages/new-order/CreateDraftOrderModal";

import { api } from "src/services/api";

import { EmphasizedTitle } from "src/styles/styled-components-library";

import type { Order } from "src/models/order";

import { ReactComponent as GoBackIcon } from "src/assets/svg-icons/navigation-left.svg";
import { ReactComponent as NewIcon } from "src/assets/svg-icons/tag-new.svg";

enum Status {
  LOADING = "LOADING",
  DONE = "DONE",
}

export const NewOrder: FC = () => {
  const { t } = useTranslation();

  const [showModal, setShowModal] = useState(false);
  const [orders, setOrders] = useState<Order[]>([]);
  const [status, setStatus] = useState(Status.LOADING);

  useEffect(() => {
    async function fetchOrders() {
      try {
        const res = await api.get<Order[]>("/orders");
        if (!res.data) {
          throw new Error();
        }
        setOrders(res.data);
      } catch {
        setOrders([]);
      } finally {
        setStatus(Status.DONE);
      }
    }

    fetchOrders();
  }, []);

  return (
    <BasicPageWithHeader>
      {status !== Status.LOADING && (
        <>
          <ContentWrapper>
            <div>
              <Link to="/orders">
                <GoBackIcon />
              </Link>
            </div>
            <CenterWrapper>
              <FirstOrderCard>
                <CardHeader>
                  <NewIcon />
                  <EmphasizedTitle>
                    {orders.length === 0 ? (
                      <>
                        {t("pages.orders.order.new.first")}{" "}
                        <span className="emphasized">Roadbook</span>
                      </>
                    ) : (
                      <>
                        {t("pages.orders.order.new.new")}{" "}
                        <span className="emphasized">Roadbook</span>
                      </>
                    )}
                  </EmphasizedTitle>
                </CardHeader>
                <SubmitWrapper>
                  <Button onClick={() => setShowModal(true)}>
                    {t("pages.orders.order.order")}
                  </Button>
                  {orders.length === 0 && (
                    <Alert variant="info" transparent={false}>
                      {t("pages.orders.order.new.alert")}
                    </Alert>
                  )}
                </SubmitWrapper>
              </FirstOrderCard>
            </CenterWrapper>
          </ContentWrapper>
          <CreateDraftOrderModal
            showModal={showModal}
            closeModal={() => setShowModal(false)}
          />
        </>
      )}
    </BasicPageWithHeader>
  );
};

const ContentWrapper = styled.div`
  flex: 1;
  padding: 16px;
  display: flex;
  flex-direction: column;
`;

const CenterWrapper = styled.div`
  flex: 1;
  display: grid;
  place-items: center;
`;

const FirstOrderCard = styled(Card)`
  width: 650px;
  padding: 80px 120px;

  & > *:not(:last-child) {
    margin-bottom: 40px;
  }
`;

const CardHeader = styled.div`
  text-align: center;

  & > svg {
    margin-bottom: 16px;
  }
`;

const SubmitWrapper = styled.div`
  & > *:not(:last-child) {
    margin-bottom: 12px;
  }
`;
