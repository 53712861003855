import { ChangeEvent, FC } from "react";
import styled from "styled-components";
import { FormikErrors, useFormik } from "formik";
import { useTranslation } from "react-i18next";

import { Card } from "src/components/Card";
import { Button } from "src/components/form/Button";
import { LabeledSelect } from "src/components/form/LabeledSelect";
import { LabeledTextInput } from "src/components/form/LabeledTextInput";
import { Alert } from "src/components/Alert";

import { useRequestStatus, RequestStatus } from "src/hooks/useRequestStatus";
import { validateEmail, validateNonEmptyFields } from "src/utils/validations";
import { api } from "src/services/api";
import { countryOptions } from "src/constants/countries";

import { Title } from "src/styles/styled-components-library";

import { Display as DisplayEnum } from "src/pages/drb/TourOperators";
import type { SelectOption } from "src/components/form/Select";

import { ReactComponent as GoBackIcon } from "src/assets/svg-icons/navigation-left.svg";

type Key =
  | "email"
  | "companyName"
  | "phone"
  | "lastName"
  | "firstName"
  | "addressStreet"
  | "addressZipCode"
  | "addressCity"
  | "addressCountry"
  | "vatNumber";

type FormInput = {
  key: Key;
  label: string;
  placeholder: string;
  type: "text" | "select";
  options?: SelectOption[];
};

interface DisplayNewToProps {
  updateDisplay: (display: DisplayEnum) => void;
}
export const DisplayNewTo: FC<DisplayNewToProps> = props => {
  const { updateDisplay } = props;
  const { t } = useTranslation();

  const inputs: FormInput[] = [
    {
      key: "email",
      label: t("forms.email"),
      placeholder: "mail@mail.com",
      type: "text",
    },
    {
      key: "companyName",
      label: t("forms.company_name"),
      placeholder: "Martel",
      type: "text",
    },
    {
      key: "phone",
      label: t("forms.phone"),
      placeholder: "+ 33 07 83 32 42 42",
      type: "text",
    },
    {
      key: "lastName",
      label: t("forms.admin_last_name"),
      placeholder: "Nom",
      type: "text",
    },
    {
      key: "firstName",
      label: t("forms.admin_first_name"),
      placeholder: "Prénom",
      type: "text",
    },
    {
      key: "addressStreet",
      label: t("forms.address"),
      placeholder: "-",
      type: "text",
    },
    {
      key: "addressZipCode",
      label: t("forms.postal_code"),
      placeholder: "-",
      type: "text",
    },
    {
      key: "addressCity",
      label: t("forms.city"),
      placeholder: "-",
      type: "text",
    },
    {
      key: "addressCountry",
      label: t("forms.country"),
      placeholder: "France",
      type: "select",
      options: countryOptions,
    },
    {
      key: "vatNumber",
      label: t("forms.tva"),
      placeholder: "-",
      type: "text",
    },
  ];

  const [status, setStatus] = useRequestStatus();
  const formInitialValues = {
    email: "",
    companyName: "",
    phone: "",
    lastName: "",
    firstName: "",
    addressStreet: "",
    addressZipCode: "",
    addressCity: "",
    addressCountry: "FRA",
    vatNumber: "",
  };

  const formik = useFormik({
    initialValues: formInitialValues,
    validate: values => {
      const errors: FormikErrors<typeof formInitialValues> = {};
      if (!validateEmail(values.email)) {
        errors.email = "The email provided is not valid.";
      }
      const emptyFields = validateNonEmptyFields(
        values,
        "addressStreet",
        "addressCity",
        "addressCountry",
        "addressZipCode",
        "companyName",
        "firstName",
        "lastName",
        "phone",
        "vatNumber",
      );
      if (emptyFields.length > 0) {
        for (const key of emptyFields) {
          errors[key] = "This field is empty.";
        }
      }
      if (Object.keys(errors).length > 0) {
        setStatus(RequestStatus.VALIDATION_ERROR);
      }
      return errors;
    },
    validateOnChange: false,
    onSubmit: async values => {
      if (
        status === RequestStatus.LOADING ||
        status === RequestStatus.SUCCESS
      ) {
        return;
      }

      // !! TODO : déterminer le "locale" via la langue actuelle de l'app React
      // (quand on aura mis en place i18n)
      const body = {
        ...values,
        locale: "fr",
      };

      setStatus(RequestStatus.LOADING);
      try {
        const res = await api.post<{ success: boolean }>(
          "/tour-operators",
          body,
        );
        if (!res.data.success) {
          throw new Error();
        }
        setStatus(RequestStatus.SUCCESS);
      } catch {
        setStatus(RequestStatus.SERVER_ERROR);
      }
    },
  });

  const updateTextInputHandler = (
    e: ChangeEvent<HTMLInputElement>,
    key: Key,
  ) => {
    formik.setErrors({
      ...formik.errors,
      [key]: false,
    });
    formik.handleChange(e);
  };

  return (
    <>
      <NavigationWrapper>
        <StyledGoBackIcon onClick={() => updateDisplay(DisplayEnum.ALL)} />
      </NavigationWrapper>
      <CardWrapper>
        <FormWrapper onSubmit={formik.handleSubmit}>
          <Title>{t("pages.to.new.title")}</Title>
          <InputsWrapper>
            {inputs.map(input => {
              return input.type === "select" ? (
                <LabeledSelect
                  key={input.key}
                  name={input.key}
                  label={input.label}
                  placeholder={input.placeholder}
                  value={formik.values[input.key]}
                  hasError={!!formik.errors[input.key]}
                  onChange={formik.handleChange}
                  options={input.options || []}
                />
              ) : (
                <LabeledTextInput
                  key={input.key}
                  name={input.key}
                  type={input.type}
                  label={input.label}
                  placeholder={input.placeholder}
                  value={formik.values[input.key]}
                  hasError={!!formik.errors[input.key]}
                  onChange={e => updateTextInputHandler(e, input.key)}
                />
              );
            })}
          </InputsWrapper>
          {status === RequestStatus.VALIDATION_ERROR && (
            <Alert>{t("forms.invalid_form")}</Alert>
          )}
          {status === RequestStatus.SERVER_ERROR && (
            <Alert>{t("pages.to.new.server_error")}</Alert>
          )}
          {status === RequestStatus.SUCCESS && (
            <Alert variant="success">{t("pages.to.new.success")}</Alert>
          )}
          <Button type="submit">{t("pages.to.new.button")}</Button>
        </FormWrapper>
      </CardWrapper>
    </>
  );
};

const NavigationWrapper = styled.div`
  width: 750px;
  margin: 0 auto;
`;

const StyledGoBackIcon = styled(GoBackIcon)`
  cursor: pointer;
`;

const CardWrapper = styled(Card)`
  margin: 0 auto;
  padding: 24px;
  width: 750px;
`;

const FormWrapper = styled.form`
  width: 100%;

  & > a:first-child {
    display: inline-block;
    margin-bottom: 12px;
  }

  & > *:not(:last-child) {
    margin-bottom: 24px;
  }
`;

const InputsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  & > * {
    width: 48%;
  }

  @supports (gap: 24px 28px) {
    gap: 24px 28px;
  }
  @supports not (gap: 24px 28px) {
    & > * {
      margin-bottom: 24px;
    }
  }
`;
