import { FC, ChangeEventHandler, CSSProperties } from "react";
import styled from "styled-components";

import { colors } from "src/theme/colors";

type TextAreaState = "default" | "error";
type TextAreaStyles = {
  color: NonNullable<CSSProperties["color"]>;
  borderColor: NonNullable<CSSProperties["borderColor"]>;
};
const mapStateToStyles: Record<TextAreaState, TextAreaStyles> = {
  default: {
    color: colors.main.black,
    borderColor: colors.grey[100],
  },
  error: {
    color: colors.error[300],
    borderColor: colors.error[100],
  },
};

export interface TextAreaProps {
  value?: string | number;
  placeholder?: string;
  name?: string;
  onChange?: ChangeEventHandler<HTMLTextAreaElement>;
  hasError?: boolean;
  autoComplete?: "on" | "off";
  rows?: number;
}

export const TextArea: FC<TextAreaProps> = props => {
  const {
    value,
    placeholder,
    name,
    autoComplete = "off",
    hasError = false,
    rows = 3,
    onChange,
  } = props;

  const state: TextAreaState = hasError ? "error" : "default";
  const textAreaStyles: TextAreaStyles = mapStateToStyles[state];
  return (
    <StyledTextArea
      {...textAreaStyles}
      autoComplete={autoComplete}
      value={value}
      hasError={hasError}
      name={name}
      placeholder={placeholder}
      rows={rows}
      onChange={onChange}
    />
  );
};

type StyleTextAreaProps = TextAreaStyles & { hasError: boolean };
const StyledTextArea = styled.textarea<StyleTextAreaProps>`
  resize: none;
  height: 200px;
  width: 100%;
  padding: 16px;
  border-radius: 4px;
  color: ${({ color }) => color};
  background-color: ${colors.background[100]};
  border: 1px solid ${({ borderColor }) => borderColor};
  outline: none;
  transition: all 200ms ease;

  font-weight: 500;
  font-size: 14px;
  line-height: 18px;

  &::placeholder {
    color: ${({ hasError }) =>
      hasError ? colors.error[100] : colors.grey[200]};
  }

  /* we don't display the CTA green color if the textarea has an error */
  &:focus {
    color: ${({ color, hasError }) => (hasError ? color : colors.cta.green)};
    border: 1px solid
      ${({ borderColor, hasError }) =>
        hasError ? borderColor : colors.cta.green};
  }
`;
