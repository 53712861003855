export const colors = {
  main: {
    white: "#FFFFFF",
    black: "#222222",
  },
  grey: {
    100: "#E1E4E5",
    200: "#BBC9CA",
    600: "#919B9B",
  },
  green: {
    100: "#C7FFB9",
    200: "#ABFF96",
    300: "#8FFF74",
    400: "#73FF51",
    500: "#57FF2E",
    600: "#2EE500",
  },
  background: {
    100: "#F9F9F9",
  },
  turquoise: {
    100: "#CCF9EB",
    200: "#99F3D8",
    300: "#66EDC4",
    400: "#33E6B1",
    500: "#00E09D",
    600: "#00B37E",
    700: "#00875E",
    800: "#005A3F",
    900: "#002D1F",
  },
  error: {
    100: "#FFC1B9",
    200: "#FF8676",
    300: "#F34C36",
  },
  cta: {
    green: "#64DA40",
  },
  red: {
    500: "#851404",
  },

  // the following colors are not part of the project's design system
  custom: {
    error: {
      50: "#FFE4E1",
    },
  },
};
