export const TRANSLATIONS_EN = {
  pages: {
    sign_up: {
      title: "Create an account",
      description:
        "Create an account. If your company already has an account, ask your administrator to create your password.",
      sign_up: "Sign in.",
      error: "Registration could not be completed. please try again later",
    },
    sign_in: {
      sign_in: "Sign in",
      create_society_account: "Create company account",
      reset: "Reset",
      pro: "Your portal",
      forgot_password: "Forgotten password?",
      email_for_reset: "Please enter the email address linked to your account",
      error: "Enter valid address or password",
      success:
        "Reset request successfully completed. If you don’t see an email soon, please check your Spam folder.",
    },
    reset_password: {
      title: "Change password",
      description: "Enter new password",
      connect: "Log In to your portal",
      modify: "Change password",
      valid_password: "Enter password.",
      error: "Changing your password unsuccessful. Try again later.",
      success: "Password sucessfully entered.",
    },
    settings: {
      general_information: {
        admin: "Account administrator",
        society: "Company information",
        logo: "Logo (optional)",
        no_logo: "No logo to upload",
        delete_logo: "Delete logo",
        logo_line_1: "Upload company logo for digital roadbook.",
        logo_line_2:
          "If you do not upload a logo, the Digital Roadbook logo will appear.",
        logo_line_3: "(png, jpg ou jpeg)",
        form_error:
          "The specified fields are not valid. The change could not be made.",
        server_error: "An error has occurred while changing your information.",
        success: "Information successfully entered.",
      },
      users: {
        per_pages: "Users per page",
        search: "Find a user",
        create: "Add user",
        delete: "Delete user",
        create_new: "Add user",
        server_error:
          "Your user could not be created. The specified email may already exist. Try again later.",
        success: "User successfully entered. An email has been sent. ",
        create_this_user: "Add user",
      },
    },
    orders: {
      simple: "Single",
      multiple: "Multiple",
      order: {
        validation_error: "Your order is invalid. Enter valid information.",
        search:
          "Search for an order, reference, customer, departure date, roadbook code",
        paid: "Submit payment",
        order: "Place order",
        order_per_pages: "Orders per page",
        delete_title: "Delete order",
        delete_description: "Confirm deletion?.",
        cancel: "Cancel",
        confirm: "Confirm",
        name: "order #",
        validate: "Confirm",
        delete: "Delete order",
        pay_order: "Pay the order",
        modify: "Update  order",
        archive_order: "Archive",
        draft: {
          title: "Number of roadbook codes for one order",
          one: "Only one",
          one_description: "Place an order for a single roadbook code",
          many: "Several",
          many_description:
            "Place one order with several codes (groups, buses/coaches, etc.)",
          server_error: "Order unsuccessful. Try again later.",
        },
        new: {
          first: "My first order of",
          new: "New order of",
          alert: "Add a client for this trip",
        },
        archive: {
          title: "Archive an order",
          description:
            "Are you sure you want to archive this order? You will not longer be able to change it if it is archived.",
          confirm: "Confirm",
          cancel: "Cancel",
        },
        step_button: {
          next: "Next",
          previous: "Previous",
          recap: "Go to summary",
          complete_later: "Complete later",
        },
      },
      no_order: {
        order: "Place an order",
        description:
          "A few more steps to create a roadbook for your clients' trip",
        first_ordre: "First order",
      },
      to_paid: {
        title: "Submit payment",
        description: "Select the orders to purchase",
        paid_selected_order: "Submit payment for selected orders",
        number_error: "No more than 50 at one time.",
        payment_error:
          "Accessing the payment page unsuccessful. Try again later.",
        order_per_pages: "Orders per page",
      },
      edit: {
        steps: {
          client: "Client",
          general_informations: "General information",
          step_and_hotel: "Travel segments and hotels",
          transport_and_activity: "Transport and activities",
          recap: "Summary",
          payment: "Submit payment",
        },
        client: {
          search: "Customer search",
          create: "Add client",
          create_this: "Add client",
          email: "Email to send the roadbook code",
          server_error: "Adding client unsuccessful. Try again later.",
          button: "Confirm",
          how_many: "Number of roadbook codes",
          code_number: "Number of roadbook codes",
        },
        general_informations: {
          ref: "Add in-house reference",
          destination: "Destination Country ",
          destination_city: "1st city or region of the trip",
        },
        order_steps: {
          arrival_airport: "Arrival airport",
          arrival_date: "Arrival date",
          arrival_hour: "Arrival time (optional)",
          step_and_hotel: "Travel segments and hotels",
          departure_airport: "Departure airport",
          departure_date: "Date of departure",
          departure_hour: "Departure time (optional)",
          arrival_day_0: "Same day arrival",
          arrival_day_1: "Arrival D+1",
          arrival_day_2: "Arrival D+2",
          custom: "Other hotel",
          google: "Hotel by Google search",
          free_step: "On your own",
          date: "Date",
          hotel: "Hotel",
          city: "City/town",
          address: "Address",
          accommodation_type: "Type of accommodation",
          name: "Name and address",
          where: "Where",
          city_name: "City name",
          night_number: "Number of nights",
          add_step: "Add travel segment",
          create_hotel: "Add hotel",
          if_hotel_does_not_exist: "Add new hotel",
          hotel_name: "Hotel name",
          mandatory_address: "Address",
          create_hotel_title: "Add new hotel",
          create_hotel_db: "Add hotel",
        },
        extra_travel: {
          car_title: "CAR RENTAL (OR MOTORBIKE / MOTORHOME)",
          car_form_title: "Add car rental",
          car_from_location: "Pick-up location",
          car_to_location: "Return location",
          car_submit_button: "Confirm",
          car_name: "Renter / Type of vehicle",
          car_hour: "Pick-up date and time",

          plane_title: "DOMESTIC FLIGHT",
          plane_form_title: "Add a domestic flight",
          plane_from_location: "Departure airport",
          plane_to_location: "Arrival airport",
          plane_submit_button: "Confirm",

          train_title: "TRAIN",
          train_form_title: "Add train",
          train_from_location: "Departure station",
          train_to_location: "Arrival station",
          train_submit_button: "Confirm",

          boat_title: "FERRY OR CRUISE",
          boat_form_title: "Add ferry or cruise",
          boat_from_location: "Departure Port / Ferry Terminal",
          boat_to_location: "Arrival Port / Ferry Terminal",
          boat_submit_button: "Confirm",

          activity_title: "EXCURSIONS AND ACTIVITIES",
          activity_formTitle: "Add excursion or activity",
          activity_from_location: "Location",
          activity_to_location: "Location",
          activity_submit_button: "Confirm",
          activity_name: "Tour/activity name",
          activity_hour: "Date and time",

          next: "Next",
          pass: "Continue",
          date: "Date",
          hour: "Time",
          date_hour: "Date and time",
        },
        recap: {
          please_check:
            "Confirm.  Changes can be made up to the moment of departure.",
          client: {
            order_client: "Clients for this order",
            code: "Roadbook code (not valid until payment)",
          },
          general_informations: {
            not_provided_yet: "To be completed",
            not_provided: "Not completed",
            complete: "Complete",
          },
          validate_and_pay: "Confirm payment",
          pay: "Submit payment",
        },
        payment: {
          title: "Submit payment ",
          price: "Total including VAT {{price}}€",
          description_0: "I declare that I have read",
          description_1: "the general conditions of sale",
          description_2: "and accept them",
          pay: "Submit payment {{price}}€",
        },
      },
    },
    poi: {
      navbar: {
        regions: "Regions",
        poi: "Points of interest",
        practical_informations: "Practical information",
      },
      country: {
        search: "Select country",
        country_name: "Country",
        create: "Add Country",
        select: "Select country",
        country_per_page: "Countries per page",
        create_this_country: "Add country",
        select_this_country: "Select a country to create a point of interest",
        create_button: "Add country",
        select_button: "Select country",

        delete_country_title: "Delete country",
        delete_country_description: "Confirm deletion",

        cancel: "Cancel",
        confirm: "Confirm",
        server_error: "Country not entered. Try again later. .",
        double_region_error: "Region already exists",
        success: "Country successfully added",
        delete_error: "Country deletion unsuccessful.",
      },
      poi: {
        search: "Find a point of interest",
        create_button: "Add point of interest",
        poi_per_page: "Points of interest per page",
        form: {
          name_placeholder: "Place-name",
          tags_placeholder: "Add tag",
          logo_title: "Photo/Logo (optional)",
          no_logo: "No logo to upload",
          delete_logo: "Delete logo",
          logo_description: "Add logo for your app.",
          suggested_tags: "Suggested tags",
          error: "Error",
        },
        no_poi: {
          title: "Add point of interest",
          description: "A few more steps to add a new point of interest",
          create: "Add point of interest",
        },
        create: {
          title: "Confirm",
          server_error: "Point of interest not entered. Try again later.",
          success: "Successfully entered",
          create_this_poi: "Add point of interest",
        },
        modify: {
          title: "Update",

          server_error: "Point of interest not updated. Try again later.",
          success: "Successfully entered",
          modify_this_poi: "Update",
        },
      },
      regions: {
        search: "Region search",
        create: "Add region",
        regions_per_page: "Regions per page",
        no_logo: "Aucune image d'upload",
        logo_title: "Logo (optional)",
        delete: "Delete logo",
        logo_description: "Add logo for the app.",

        no_region: {
          title: "Add region",
          description: "A few more steps to add a new city",
          first_region: "First region",
        },
        edit: {
          title: "Update",
          success: "Region successfully updated",
          button: "Update",
          server_error: "Update of city unsuccessful. Try again later.",
        },
        new: {
          title: "Add region",
          success: "City successfully entered",
          button: "Add region",
          server_error: "Update of region unsuccessful. Try again later.",
        },
      },
      practical_informations: {
        search: "Find practical information",
        create: "Add practical infomation",
        practical_informations_per_page:
          "Number of practical information per page",
        no_logo: "No logo to upload",
        delete_logo: "Delete logo",
        logo_title: "Logo",
        server_error: "Not successfully entered. Try again later.",
        logo_description: "Add logo for the app.",
        no_practical_informations: {
          title: "Add practical information",
          description: "A few more steps to add a bit of practical information",
          button: "First practical information",
        },
        new: {
          title: "Add practical information",
          success: "Successfully added",
          create: "Add practical information",
        },
        edit: {
          title: "Update practical information",
          success: "Successfully updated",
          button: "Update practical information",
        },
        delete: {
          title: "Delete practical information",
          description: "Confirm",
          cancel: "Cancel",
          confirm: "Confirm",
        },
      },
    },
    hotel: {
      search: "Htoel search",
      finished: "End",
      not_finished: "Not finished",
      hotel_per_page: "Hotel per page",
      delete: {
        error: "Error.",
        title: "Delete hotel",
        description: "Confirm",
        cancel: "Cancel",
        confirm: "Confirm",
      },
      edit: {
        server_error: "Error.",
        success: "Successfully updated.",
      },
    },
    to: {
      search: "Tour operator search",
      create: "Add tour operator",
      to_per_page: "Tour operator per page",
      billing: {
        now: "Direct",
        end_of_the_month: "End of month",
        success: "Error.",
        error: "Error.",
      },
      new: {
        title: "Add tout operator",
        server_error: "Error.",
        success: "Tour operator and administrator successfully entered",
        button: "Add tour operator and administrator",
      },
    },
  },
  navbar: {
    to: {
      orders: "Orders",
      poi: "Manage points of interest",
    },
    drb: {
      to: "Tour operators",
      hotels: "Hotels",
      poi: "Manage points of interest",
    },
  },
  roles: {
    user: "User",
    admin: "Administrator",
  },
  forms: {
    email: "Email",
    password: "Password",
    company_name: "Company name",
    phone: "Telephone",
    last_name: "Surname",
    first_name: "First name",
    address: "Address",
    postal_code: "Postal code",
    city: "City",
    country: "Country",
    tva: "VAT number",
    status: "Status",
    actions: "Actions",
    order_number: "Order number",
    ref: "Reference",
    start_date: "Departure date",
    customer: "Client",
    customer_number: "Number of clients",
    code_number: "Number of codes",
    birthday: "Date of birth",
    language: "Language",
    longitude: "Longitude",
    latitude: "Latitude",
    description: "Description",
    tags: "Tags",
    media_credits: "Media credit",
    picture: "Photo",
    error: "Error",
    name: "Name",
    billing: "Invoice",
    admin_last_name: "Administrator name",
    admin_first_name: "Administrator first name",
    invalid_form: "Some information not successfully entered. Try again later.",
  },
  enum: {
    order_status: {
      draft: "Unfinished order",
      to_pay: "Awaiting payment",
      paid: "Paid",
      archived: "Archived",
    },
    wemap_tags: {
      activity: "Activity",
      building: "Building",
      states: "State",
      monument: "Monument",
      museum: "Museum",
      province: "Province",
      hiking: "Hiking",
      natural_site: "Natural Site",
      top: "Top !",
    },
  },
};
