import { FC } from "react";
import styled from "styled-components";

import { Card } from "src/components/Card";

import { colors } from "src/theme/colors";

import { SettingMode } from "src/types/settings";
import { useTranslation } from "react-i18next";

type MenuOption = {
  mode: SettingMode;
  label: string;
};

interface ModeMenuProps {
  mode: SettingMode;
  changeMode: (mode: SettingMode) => void;
}
export const ModeMenu: FC<ModeMenuProps> = props => {
  const { t } = useTranslation();
  const { mode, changeMode } = props;

  const options: MenuOption[] = [
    {
      mode: SettingMode.GENERAL_INFO,
      label: t("pages.orders.edit.steps.general_informations"),
    },
    {
      mode: SettingMode.USERS,
      label: t("roles.users"),
    },
  ];

  return (
    <MenuCard>
      {options.map(option => (
        <Option
          key={option.mode}
          isActive={mode === option.mode}
          onClick={() => changeMode(option.mode)}
        >
          {option.label}
        </Option>
      ))}
    </MenuCard>
  );
};

const MenuCard = styled(Card)`
  min-width: 200px;
  padding: 16px 24px;
  box-shadow: 0px 1px 0px rgba(187, 201, 202, 0.1);
`;

type OptionStyleProps = {
  isActive: boolean;
};
const Option = styled.div<OptionStyleProps>`
  color: ${({ isActive }) => (isActive ? colors.cta.green : colors.main.black)};
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  cursor: pointer;
  padding: 4px 0;
  margin: 4px 0;
  transition: opacity 200ms ease;

  &:hover {
    opacity: ${({ isActive }) => (isActive ? "1" : "0.8")};
  }
`;
