import { FC } from "react";

import { NoListRegions } from "src/components/pages/list-regions/NoListRegions";
import { AllListRegions } from "src/components/pages/list-regions/AllListRegions";
import styled from "styled-components";

type ListRegionsProps = {
  refreshListRegions: (listId: string) => Promise<void>;
  listRegions: any[];
};

export const ListRegions: FC<ListRegionsProps> = ({
  listRegions,
  refreshListRegions,
}) => {
  return (
    <>
      {listRegions && listRegions.length === 0 ? (
        <NoListRegions />
      ) : (
        <Wrapper>
          <AllListRegions
            listRegions={listRegions}
            refreshListRegions={refreshListRegions}
          />
        </Wrapper>
      )}
    </>
  );
};

export const Wrapper = styled.div`
  flex: 1;
  padding: 16px;
  display: flex;
  flex-direction: column;

  & > *:not(:last-child) {
    margin-bottom: 12px;
  }
`;
