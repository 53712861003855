import { useCallback, useEffect, useState } from "react";
import { Region } from "src/models/region";
import { getAllRegions } from "src/services/regions";

enum FetchStatus {
  PENDING = "PENDING",
  DONE = "DONE",
}

export const useRegions = () => {
  const [regions, setRegions] = useState<Region[]>([]);
  const [fetchStatus, setFetchStatus] = useState(FetchStatus.PENDING);

  const refreshRegions = useCallback(async () => {
    try {
      const res = await getAllRegions();
      if (!res.data) {
        throw new Error();
      }

      setRegions(res.data);
    } catch {
      setRegions([]);
    } finally {
      setFetchStatus(FetchStatus.DONE);
    }
  }, []);

  useEffect(() => {
    refreshRegions();
  }, [refreshRegions]);

  return {
    regions,
    fetchStatus,
    refreshRegions,
    FetchStatus,
  };
};
