import { FC } from "react";
import { format, getSeconds } from "date-fns";
import { useTranslation } from "react-i18next";

import { BasicTable, IconWrapper } from "src/styles/styled-components-library";

import type { ExtraTravelDraft } from "src/types/order";
import { TravelKind } from "src/types/order";

import { ReactComponent as DeleteIcon } from "src/assets/svg-icons/actions/delete.svg";
import type { Location } from "src/models/location";
import { getLocalDateWithDestinationUTC } from "src/utils/date";

interface DisplayExtraTravelsProps {
  kind: TravelKind; // only display one type of extra-travel
  extraTravels: ExtraTravelDraft[];
  deleteExtraTravelHandler?: (extraTravel: ExtraTravelDraft) => void;
}
export const DisplayExtraTravels: FC<DisplayExtraTravelsProps> = props => {
  const { kind, extraTravels, deleteExtraTravelHandler } = props;
  const { t } = useTranslation();

  const mapTravelKindToLabels: Record<
    TravelKind,
    { fromLocationLabel: string; toLocationLabel: string }
  > = {
    [TravelKind.CAR_RENTAL]: {
      fromLocationLabel: t("pages.orders.edit.extra_travel.car_from_location"),
      toLocationLabel: t("pages.orders.edit.extra_travel.car_to_location"),
    },
    [TravelKind.PLANE]: {
      fromLocationLabel: t(
        "pages.orders.edit.extra_travel.plane_from_location",
      ),
      toLocationLabel: t("pages.orders.edit.extra_travel.plane_to_location"),
    },
    [TravelKind.TRAIN]: {
      fromLocationLabel: t(
        "pages.orders.edit.extra_travel.train_from_location",
      ),
      toLocationLabel: t("pages.orders.edit.extra_travel.train_to_location"),
    },
    [TravelKind.BOAT]: {
      fromLocationLabel: t("pages.orders.edit.extra_travel.boat_from_location"),
      toLocationLabel: t("pages.orders.edit.extra_travel.boat_to_location"),
    },
    [TravelKind.ACTIVITY]: {
      fromLocationLabel: t(
        "pages.orders.edit.extra_travel.activity_from_location",
      ),
      toLocationLabel: t("pages.orders.edit.extra_travel.activity_to_location"),
    },
  };

  const generateDateAndHourLabel = (
    date: string,
    location: Location,
  ): string => {
    const seconds = getSeconds(new Date(date));

    const locationDate = date
      ? getLocalDateWithDestinationUTC(date, location.utcOffsetMinutes ?? 0)
      : null;

    if (seconds === 42) {
      return locationDate ? format(new Date(locationDate), "dd/MM/yyyy") : "";
    } else {
      return locationDate
        ? format(new Date(locationDate), "dd/MM/yyyy - HH:mm:ss")
        : "";
    }
  };

  const filteredExtraTravels = extraTravels.sort((a, b) => {
    if (a.fromDate < b.fromDate) {
      return -1;
    }
    if (a.fromDate > b.fromDate) {
      return 1;
    }
    return 0;
  });

  return (
    <BasicTable>
      <thead>
        <tr>
          {kind === TravelKind.CAR_RENTAL && (
            <th>{t("pages.orders.edit.extra_travel.car_name")}</th>
          )}
          {kind === TravelKind.ACTIVITY && (
            <th>{t("pages.orders.edit.extra_travel.activity_name")}</th>
          )}
          <th>{mapTravelKindToLabels[kind].fromLocationLabel}</th>
          {kind === TravelKind.CAR_RENTAL ? (
            <th>{t("pages.orders.edit.extra_travel.car_hour")}</th>
          ) : (
            <th>{t("pages.orders.edit.extra_travel.date_hour")}</th>
          )}

          {kind !== TravelKind.ACTIVITY ? (
            <>
              <th>{mapTravelKindToLabels[kind].toLocationLabel}</th>
              <th>{t("pages.orders.edit.extra_travel.activity_hour")}</th>
            </>
          ) : null}
          <th />
        </tr>
      </thead>
      <tbody>
        {filteredExtraTravels.map(extraTravel => (
          <tr key={extraTravel.id || extraTravel.tempId || ""}>
            {(kind === TravelKind.CAR_RENTAL ||
              kind === TravelKind.ACTIVITY) && <td>{extraTravel.name}</td>}

            <td>
              {extraTravel.fromLocation.displayName} (
              {extraTravel.fromLocation.street ?? ""}{" "}
              {extraTravel.fromLocation.city ??
                extraTravel.fromLocation.country}
              )
            </td>
            <td>
              {generateDateAndHourLabel(
                extraTravel.fromDate,
                extraTravel.fromLocation,
              )}
            </td>
            {kind !== TravelKind.ACTIVITY ? (
              <>
                <td>
                  {extraTravel.toLocation.displayName} (
                  {extraTravel.toLocation.street ?? ""}{" "}
                  {extraTravel.toLocation.city ?? ""}){" "}
                </td>
                <td>
                  {generateDateAndHourLabel(
                    extraTravel.toDate,
                    extraTravel.toLocation,
                  )}
                </td>
              </>
            ) : null}
            <td>
              {!!deleteExtraTravelHandler && (
                <IconWrapper>
                  <DeleteIcon
                    onClick={() => deleteExtraTravelHandler(extraTravel)}
                  />
                </IconWrapper>
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </BasicTable>
  );
};
