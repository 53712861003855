import { FC, MutableRefObject, useRef, useState } from "react";
import { useFormik } from "formik";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { Card } from "src/components/Card";
import { LabeledSaveableTextInput } from "src/components/form/LabeledSaveableTextInput";
import { LabeledSaveableSelect } from "src/components/form/LabeledSaveableSelect";
import { Alert } from "src/components/Alert";

import { useRequestStatus, RequestStatus } from "src/hooks/useRequestStatus";
import { validateEmail } from "src/utils/validations";
import { api } from "src/services/api";
import { countryOptions } from "src/constants/countries";

import type { User } from "src/models/user";
import type { SelectOption } from "src/components/form/SaveableSelect";
import { deleteImage, getS3Url, uploadFile } from "src/services/image";
import { colors } from "src/theme/colors";
import { ReactComponent as UploadIcon } from "src/assets/svg-icons/download-square.svg";
import { Button } from "src/components/form/Button";
import { useAuth } from "src/contexts/AuthContext";
import { LabeledSaveableTextArea } from "src/components/form/LabeledSaveableTextArea";

interface CompanyInformationsProps {
  user: User;
}
export const CompanyInformations: FC<CompanyInformationsProps> = props => {
  const { user } = props;
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [logoName, setLogoName] = useState<string | null | undefined>(
    user.tourOperator.logo,
  );
  const { userPayload, setUserPayload } = useAuth();
  const inputFile = useRef() as MutableRefObject<HTMLInputElement>;
  const { t } = useTranslation();

  // form init & type definitions
  const formInitialValues = {
    addressStreet: user.tourOperator.addressStreet,
    addressZipCode: user.tourOperator.addressZipCode,
    addressCity: user.tourOperator.addressCity,
    addressCountry: user.tourOperator.addressCountry,
    companyName: user.tourOperator.name,
    vatNumber: user.tourOperator.vatNumber,
    email: user.tourOperator.email,
    phone: user.tourOperator.phone,
    contactInformations: user.tourOperator.contactInformations,
  };
  type FormKey = keyof typeof formInitialValues;
  type FormInput = {
    key: FormKey;
    label: string;
    placeholder?: string;
    isValid: (input: string) => boolean;
    isSelect?: boolean;
    options?: SelectOption[];
  };
  const inputs: FormInput[] = [
    {
      key: "companyName",
      label: t("forms.company_name"),
      placeholder: "Martel",
      isValid: input => !!input,
    },
    {
      key: "email",
      label: t("forms.email"),
      placeholder: "Adresse",
      isValid: input => validateEmail(input),
    },
    {
      key: "phone",
      label: t("forms.phone"),
      placeholder: "+ 33 07 83 32 42 42",
      isValid: input => !!input,
    },
    {
      key: "addressStreet",
      label: t("forms.address"),
      placeholder: "Adresse",
      isValid: input => !!input,
    },
    {
      key: "addressZipCode",
      label: t("forms.postal_code"),
      placeholder: "80000",
      isValid: input => !!input,
    },
    {
      key: "addressCity",
      label: t("forms.city"),
      placeholder: "Amiens",
      isValid: input => !!input,
    },
    {
      key: "addressCountry",
      label: t("forms.country"),
      isValid: input => !!input,
      isSelect: true,
      options: countryOptions,
    },
    {
      key: "vatNumber",
      label: t("forms.tva"),
      placeholder: "84204424",
      isValid: input => !!input,
    },
    {
      key: "contactInformations",
      label: t("forms.contactInformations"),
      placeholder: "",
      isValid: input => !!input,
    },
  ];

  // hooks
  const formik = useFormik({
    initialValues: formInitialValues,
    onSubmit: () => {},
  });
  const latestSavedValuesRef =
    useRef<typeof formInitialValues>(formInitialValues);
  const [status, setStatus] = useRequestStatus();

  // callbacks
  const cancelInputHandler = (key: FormKey) => {
    formik.setValues({
      ...formik.values,
      [key]: latestSavedValuesRef.current[key],
    });
  };

  const saveInputHandler = async (key: FormKey) => {
    if (
      status === RequestStatus.LOADING ||
      formik.values[key] === latestSavedValuesRef.current[key]
    ) {
      return;
    }

    setStatus(RequestStatus.LOADING);
    try {
      const isCompanyNameKey = key === "companyName";
      const res = await api.patch<{ success: boolean }>(
        `/tour-operators/${user.tourOperator.id}`,
        { [isCompanyNameKey ? "name" : key]: formik.values[key] },
      );
      if (!res.data.success) {
        throw new Error();
      }

      latestSavedValuesRef.current = {
        ...latestSavedValuesRef.current,
        [key]: formik.values[key],
      };
      setStatus(RequestStatus.SUCCESS);
    } catch {
      cancelInputHandler(key);
      setStatus(RequestStatus.SERVER_ERROR);
    }
  };

  const uploadImage = async (image: File) => {
    const data = await getS3Url("to-logo");

    if (data.errorMessage) {
      setErrorMessage(data.errorMessage);
      return;
    }

    const res = await uploadFile(image, data.url, "to-logo");

    if (res.errorMessage) {
      setErrorMessage(res.errorMessage);
    } else {
      setErrorMessage(null);
      setLogoName(res.uploadedFilename);
      if (userPayload) {
        // Force le refresh du authUser
        setUserPayload(userPayload);
      }
    }
  };

  const deleteLogo = async () => {
    await deleteImage();
    inputFile.current.value = "";
    setLogoName(null);
    if (userPayload) {
      // Force le refresh du authUser
      setUserPayload(userPayload);
    }
  };

  return (
    <CardWrapper>
      <Form onSubmit={e => e.preventDefault()}>
        {inputs.map(input => {
          const isValidInput = input.isValid(formik.values[input.key]);
          return !input.isSelect ? (
            input.key !== "contactInformations" ? (
              <LabeledSaveableTextInput
                key={input.key}
                name={input.key}
                label={input.label}
                placeholder={input.placeholder}
                value={formik.values[input.key]}
                onChange={formik.handleChange}
                onCancel={() => cancelInputHandler(input.key)}
                onSave={() => saveInputHandler(input.key)}
                onSaveError={() => setStatus(RequestStatus.VALIDATION_ERROR)}
                isValidInput={isValidInput}
                hasError={
                  status === RequestStatus.VALIDATION_ERROR && !isValidInput
                }
              />
            ) : null
          ) : (
            <LabeledSaveableSelect
              key={input.key}
              options={input.options || []}
              name={input.key}
              label={input.label}
              value={formik.values[input.key]}
              onChange={formik.handleChange}
              onCancel={() => cancelInputHandler(input.key)}
              onSave={() => saveInputHandler(input.key)}
              onSaveError={() => setStatus(RequestStatus.VALIDATION_ERROR)}
              isValidInput={isValidInput}
              hasError={
                status === RequestStatus.VALIDATION_ERROR && !isValidInput
              }
            />
          );
        })}
        <FullContainer>
          <LabeledSaveableTextArea
            key={"contactInformations"}
            name={"contactInformations"}
            label={t("forms.contactInformations")}
            placeholder={""}
            value={formik.values["contactInformations"]}
            hasError={status === RequestStatus.VALIDATION_ERROR}
            onChange={formik.handleChange}
            onCancel={() => cancelInputHandler("contactInformations")}
            onSave={() => saveInputHandler("contactInformations")}
            onSaveError={() => setStatus(RequestStatus.VALIDATION_ERROR)}
            isValidInput={!!formik.values["contactInformations"]}
          />
        </FullContainer>

        <input
          ref={inputFile}
          type="file"
          style={{ display: "none" }}
          onChange={(e: any) => uploadImage(e.target.files[0])}
        />
        {logoName ? (
          <FullContainer>
            <Label>{t("pages.settings.general_information.logo")}</Label>
            <Upload>
              {logoName ? (
                <Logo
                  src={`https://d2tbchnqphq2bv.cloudfront.net/${logoName}`}
                  alt="Logo"
                />
              ) : (
                <UploadText>
                  {t("pages.settings.general_information.no_logo")}
                </UploadText>
              )}
              <ButtonContainer>
                <Button variant="secondary" onClick={deleteLogo}>
                  {t("pages.settings.general_information.delete_logo")}
                </Button>
              </ButtonContainer>
            </Upload>
          </FullContainer>
        ) : (
          <FullContainer>
            <Label>{t("pages.settings.general_information.logo")}</Label>
            <Upload onClick={() => inputFile.current.click()}>
              <UploadIcon />
              <UploadText>
                {t("pages.settings.general_information.logo_line_1")}
                <br />
                {t("pages.settings.general_information.logo_line_2")}
                <br />
                {t("pages.settings.general_information.logo_line_3")}
              </UploadText>
            </Upload>
          </FullContainer>
        )}

        {errorMessage ? <Alert>Erreur: {errorMessage}</Alert> : null}
      </Form>
      {status === RequestStatus.VALIDATION_ERROR && (
        <Alert>{t("forms.invalid_form")}</Alert>
      )}
      {status === RequestStatus.SERVER_ERROR && (
        <Alert>{t("pages.settings.general_information.server_error")}</Alert>
      )}
      {status === RequestStatus.SUCCESS && (
        <Alert variant="success">
          {t("pages.settings.general_information.success")}
        </Alert>
      )}
    </CardWrapper>
  );
};

const CardWrapper = styled(Card)`
  padding: 64px 110px;

  & > *:not(:last-child) {
    margin-bottom: 24px;
  }
`;

const Form = styled.form`
  display: flex;
  flex-wrap: wrap;
  gap: 24px 30px;

  & > * {
    width: 47.5%;
  }
`;

const FullContainer = styled.div`
  width: 100%;
  padding-right: 24px;
`;

const Upload = styled.div`
  width: 100%;
  border: 1px dotted ${colors.grey[200]};
  padding: 50px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Label = styled.p`
  margin-top: 0;
  margin-bottom: 4px;
  color: ${colors.grey[600]};
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
`;
const UploadText = styled.p`
  color: ${colors.grey[200]};
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
`;

const Logo = styled.img`
  width: 50%;
  margin-bottom: 10px;
`;

const ButtonContainer = styled.div`
  width: 50%;
`;
