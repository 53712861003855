import { FC, useEffect, useMemo, useState } from "react";
import {
  CellProps,
  Column,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { Card } from "src/components/Card";
import { PaginationMenu } from "src/components/table/PaginationMenu";
import { Table } from "src/components/table/Table";
import { Button, StyledButton } from "src/components/form/Button";

import { Pinpoint } from "src/models/pinpoint";
import { GlobalFilterSearchBar } from "src/components/table/GlobalFilterSearchBar";
import { DeletePointOfInterestModal } from "./specific/DeletePointOfInterest";
import { useHistory, useParams } from "react-router-dom";
import { IconWrapper } from "src/styles/styled-components-library";
import { ReactComponent as DeleteIcon } from "src/assets/svg-icons/actions/delete.svg";

interface AllPointsOfInterestProps {
  pinpoints: Pinpoint[];
  refreshPinpoints: (listId: string) => void;
}

export const AllPointsOfInterest: FC<AllPointsOfInterestProps> = props => {
  const { t } = useTranslation();

  const history = useHistory();
  const params = useParams<{ listId: string }>();
  const { pinpoints, refreshPinpoints } = props;

  const [pinpointIdToDelete, setPinpointIdToDelete] = useState<string | null>(
    null,
  );

  const columns: Column<Pinpoint>[] = useMemo(() => {
    return [
      {
        Header: `${t("forms.name")}`,
        accessor: "name",
      },
      {
        Header: `${t("forms.address")}`,
        accessor: "address",
      },
      {
        Header: `${t("forms.longitude")}`,
        accessor: "longitude",
      },
      {
        Header: `${t("forms.latitude")}`,
        accessor: "latitude",
      },
      {
        Header: `${t("forms.actions")}`,
        disableSortBy: true,
        Cell: ({ row }: CellProps<Pinpoint>) => {
          const { original: currentPinpoint } = row;
          return (
            <IconsWrapper>
              <IconWrapper
                onClick={e => {
                  e.stopPropagation();
                  setPinpointIdToDelete(currentPinpoint.id.toString());
                }}
              >
                <DeleteIcon />
              </IconWrapper>
            </IconsWrapper>
          );
        },
      },
    ];
  }, [t]);

  const instance = useTable(
    {
      columns,
      data: pinpoints,
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  useEffect(() => {
    refreshPinpoints(params.listId);
  }, [refreshPinpoints, params.listId]);

  return (
    <>
      <ActionsWrapper>
        <GlobalFilterSearchBar
          instance={instance}
          placeholder={t("pages.poi.poi.search")}
        />
        <Button
          onClick={() => {
            history.push(`/lists/${params.listId}/points-of-interest/new`);
          }}
        >
          {t("pages.poi.poi.create_button")}
        </Button>
      </ActionsWrapper>
      <TableCardWrapper>
        <Table
          instance={instance}
          columnsWidths={["20%", "30%", "25%", "25%"]}
          rowClickHandler={pinpoint =>
            history.push(
              `/lists/${params.listId}/points-of-interest/${pinpoint.id}/edit`,
            )
          }
        />
      </TableCardWrapper>
      <PaginationWrapper>
        <PaginationMenu
          instance={instance}
          label={t("pages.poi.poi.poi_per_page")}
        />
      </PaginationWrapper>
      {!!pinpointIdToDelete && (
        <DeletePointOfInterestModal
          pinpointId={pinpointIdToDelete}
          showModal
          closeModal={() => setPinpointIdToDelete(null)}
          postDeleteCallback={() => refreshPinpoints(params.listId)}
        />
      )}
    </>
  );
};

const TableCardWrapper = styled(Card)`
  width: 100%;
  height: min-content;
  padding: 24px;
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ActionsWrapper = styled.div`
  display: flex;

  & > *:not(:first-child) {
    margin-left: 16px;
  }

  & > ${StyledButton} {
    width: min-content;
  }
`;

const IconsWrapper = styled.div`
  display: flex;

  & > *:not(:last-child) {
    margin-right: 8px;
  }
`;
