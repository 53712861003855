import { FC } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { CompanyInformations } from "./GeneralInformations/CompanyInformations";
import { PersonalInformations } from "./PersonalInformations";

import { colors } from "src/theme/colors";

import { User } from "src/models/user";
import { Kind, Role } from "src/types/user";

interface GeneralInformationsProps {
  user: User;
}
export const GeneralInformations: FC<GeneralInformationsProps> = props => {
  const { t } = useTranslation();

  const { user } = props;
  return (
    <Wrapper>
      {!!user && (
        <>
          <Section>
            <SectionTitle>
              {t("pages.settings.general_information.admin")}
            </SectionTitle>
            <PersonalInformations user={user} />
          </Section>
          {user.role === Role.ADMIN && user.kind === Kind.TO && (
            <Section>
              <SectionTitle>
                {t("pages.settings.general_information.society")}
              </SectionTitle>
              <CompanyInformations user={user} />
            </Section>
          )}
        </>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin: 0 auto;
  padding: 8px 0;
  width: 90%;

  & > * {
    margin-bottom: 30px;
  }
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
`;

const SectionTitle = styled.h2`
  color: ${colors.main.black};
  margin-top: 0;
  margin-bottom: 12px;
  font-weight: 600;
  font-size: 18px;
  line-height: 18px;
  letter-spacing: -0.0446em;
`;
