import { FC } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { Modal } from "src/components/Modal";
import { Button } from "src/components/form/Button";

import { RequestStatus, useRequestStatus } from "src/hooks/useRequestStatus";
import { api } from "src/services/api";

import { colors } from "src/theme/colors";
import { Title } from "src/styles/styled-components-library";
import { useToastNotifications } from "src/hooks/useToastNotifications";

interface DeleteHotelModalProps {
  hotelId: string;
  showModal: boolean;
  closeModal: () => void;
  postDeleteCallback?: () => void;
}
export const DeleteHotelModal: FC<DeleteHotelModalProps> = props => {
  const { t } = useTranslation();

  const { hotelId, showModal, closeModal, postDeleteCallback } = props;
  const { sendToast } = useToastNotifications();
  const [status, setStatus] = useRequestStatus();

  const deleteHotelHandler = async () => {
    if (status === RequestStatus.LOADING) {
      return;
    }
    setStatus(RequestStatus.LOADING);

    try {
      const res = await api.delete<{ success: boolean }>(`/hotels/${hotelId}`);
      if (!res.data.success) {
        throw new Error();
      }
      closeModal();
      if (postDeleteCallback) {
        postDeleteCallback();
      }
    } catch {
      setStatus(RequestStatus.SERVER_ERROR);
      sendToast("error", t("pages.hotel.delete.error"));
    }
  };

  return (
    <Modal showModal={showModal} closeModal={closeModal} width="800px">
      <ContentWrapper>
        <Title>{t("pages.hotel.delete.title")}</Title>
        <Description>{t("pages.hotel.delete.description")}</Description>
        <ButtonsWrapper>
          <Button variant="outline" onClick={closeModal}>
            {t("pages.hotel.delete.cancel")}
          </Button>
          <Button
            onClick={deleteHotelHandler}
            loading={status === RequestStatus.LOADING}
          >
            {t("pages.hotel.delete.confirm")}
          </Button>
        </ButtonsWrapper>
      </ContentWrapper>
    </Modal>
  );
};

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  & > *:not(:last-child) {
    margin-bottom: 36px;
  }
`;

const Description = styled.p`
  margin: 0;
  color: ${colors.main.black};
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
`;

const ButtonsWrapper = styled.div`
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr;
  grid-gap: 24px;
`;
