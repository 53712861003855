import { FC, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { Card } from "src/components/Card";
import { Button } from "src/components/form/Button";
import { Checkbox } from "src/components/form/Checkbox";

import { RequestStatus, useRequestStatus } from "src/hooks/useRequestStatus";
import { useToastNotifications } from "src/hooks/useToastNotifications";
import { api } from "src/services/api";
import { PaymentIntentService } from "src/services/payment-intent";
import { getOrderIdLabel } from "src/utils/order";

import { Title } from "src/styles/styled-components-library";
import { colors } from "src/theme/colors";

import type { CommonStepProps } from "../EditContent";
import { Status } from "src/types/order";

const BASE_PRICE = 36;
const PRICE_PER_CODE = 12;

interface PaymentProps extends CommonStepProps {}
export const Payment: FC<PaymentProps> = props => {
  const { order, previousStepHandler } = props;
  const { t } = useTranslation();

  const [status, setStatus] = useRequestStatus();
  const { sendToast } = useToastNotifications();

  const [consent, setConsent] = useState(false);

  const orderPrice = useMemo(() => {
    return BASE_PRICE + (order.clients || []).length * PRICE_PER_CODE;
  }, [order]);

  const submitPayOrderHandler = async () => {
    if (status === RequestStatus.LOADING) {
      return;
    }

    setStatus(RequestStatus.LOADING);
    try {
      const res = await api.post<{ success: boolean; url: string }>(
        "/payments",
        {
          orderIds: [order.id],
        },
      );
      if (!res.data.success) {
        throw new Error();
      }

      // adding order payment metadata to visualize after checkout which codes are now valid
      PaymentIntentService.setPaymentIntentData([
        {
          orderName: getOrderIdLabel(order.id),
          codes: order.clients?.map(client => client.drbRef) || [],
        },
      ]);

      window.location.href = res.data.url;
    } catch {
      setStatus(RequestStatus.IDLE);
      sendToast(
        "error",
        "Une erreur s'est produise durant la création de la page de paiement. Veuillez réessayer ultérieurement.",
      );
    }
  };

  // making sure the user doesn't try to access this step if he isn't authorized to do so
  useEffect(() => {
    if (
      !order.clients ||
      order.clients.length === 0 ||
      order.status !== Status.TO_PAY
    ) {
      previousStepHandler();
    }
  }, [order, previousStepHandler]);

  return (
    <ContentCardWrapper>
      <Title>{t("pages.orders.edit.payment.title")}</Title>
      {order.tourOperator?.facturationKind !== "selfManaged" ? (
        <>
          <p>{t("pages.orders.edit.payment.next_month_0")}</p>
          <p>{t("pages.orders.edit.payment.next_month_1")}</p>
        </>
      ) : (
        <>
          <p>{t("pages.orders.edit.payment.price", { price: orderPrice })}</p>
          <ConsentWrapper>
            <div>
              <Checkbox
                checked={consent}
                onChange={() => setConsent(prevState => !prevState)}
              />
            </div>
            <p>
              {t("pages.orders.edit.payment.description_0")}{" "}
              <Cgv>{t("pages.orders.edit.payment.description_1")} </Cgv>
              {t("pages.orders.edit.payment.description_2")}
            </p>
          </ConsentWrapper>
          <Button
            disabled={!consent}
            loading={status === RequestStatus.LOADING}
            onClick={submitPayOrderHandler}
          >
            {t("pages.orders.edit.payment.pay", { price: orderPrice })}
          </Button>
        </>
      )}
    </ContentCardWrapper>
  );
};

const ContentCardWrapper = styled(Card)`
  width: 600px;
  margin: auto;
  padding: 48px 120px;
  text-align: center;
  color: ${colors.main.black};
  line-height: 18px;
  font-weight: 500;

  & > p {
    margin: 0;
    font-size: 14px;
  }

  & > *:not(:last-child) {
    margin-bottom: 24px;
  }
`;

const ConsentWrapper = styled.div`
  display: flex;
  font-size: 12px;
  text-align: left;

  & > div {
    margin-right: 16px;
  }
  & > p {
    margin: 0;
  }
`;

const Cgv = styled.span`
  cursor: pointer;
  text-decoration: underline;
`;
