import { CellProps } from "react-table";
import styled from "styled-components";

/**
 * Custom cell to show a "Not provided" label when the value is falsy.
 */
export const NotProvidedCell = <T extends unknown>({
  value,
}: CellProps<{}, T>) => {
  return <>{value || <UnavailableLabel>Non renseigné</UnavailableLabel>}</>;
};

const UnavailableLabel = styled.span`
  font-style: italic;
`;
