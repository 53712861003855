import { FC } from "react";
import styled from "styled-components";

import { colors } from "src/theme/colors";

interface CardProps {
  className?: string;
}
export const Card: FC<CardProps> = (props) => {
  const { className, children } = props;

  return <Wrapper className={className}>{children}</Wrapper>;
};

const Wrapper = styled.div`
  width: 100%;
  border: 1px solid ${colors.background[100]};
  background-color: ${colors.main.white};
  box-shadow: 0px 12px 24px rgba(186, 186, 186, 0.09);
  border-radius: 8px;
  padding: 8px;
`;
