import { Tokens } from "src/types/tokens";

class TokenService {
  getLocalRefreshToken() {
    try {
      const tokens: Tokens = JSON.parse(localStorage.getItem("tokens") ?? "");
      return tokens.refreshToken;
    } catch {
      return;
    }
  }

  getLocalAccessToken() {
    try {
      const tokens: Tokens = JSON.parse(localStorage.getItem("tokens") ?? "");
      return tokens.accessToken;
    } catch {
      return;
    }
  }

  updateLocalAccessToken(token: string) {
    try {
      const tokens: Tokens = JSON.parse(localStorage.getItem("tokens") || "");
      tokens.accessToken = token;
      localStorage.setItem("tokens", JSON.stringify(tokens));
    } catch {}
  }

  getTokens() {
    try {
      const tokens: Tokens | undefined = JSON.parse(
        localStorage.getItem("tokens") ?? ""
      );
      return tokens;
    } catch {
      return;
    }
  }

  setTokens(tokens: Tokens) {
    localStorage.setItem("tokens", JSON.stringify(tokens));
  }

  removeTokens() {
    localStorage.removeItem("tokens");
  }
}

export default new TokenService();
