import { FC } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { LabeledReadOnlyInput } from "src/components/form/LabeledReadOnlyInput";
import { RecapSection } from "./RecapSection";
import { NotProvided } from "./NotProvided";

import type { CommonOrderRecapSectionProps } from "./index";

interface GeneralInformationsProps extends CommonOrderRecapSectionProps {}
export const GeneralInformations: FC<GeneralInformationsProps> = props => {
  const { order, editStep } = props;
  const { t } = useTranslation();

  const regionsName = order.lists
    .map(list => {
      return list.region.name;
    })
    .join(", ");

  return (
    <RecapSection
      title={t("pages.orders.edit.steps.general_informations")}
      editClickHandler={editStep && (() => editStep(2))}
    >
      {!!order.toReference && !!order.listRegion && !!order.listRegion.list ? (
        <InputsWrapper>
          <LabeledReadOnlyInput
            label={t("pages.orders.edit.general_informations.ref")}
            value={order.toReference}
          />
          <LabeledReadOnlyInput
            label={t("pages.orders.edit.general_informations.destination")}
            value={regionsName}
          />
          <LabeledReadOnlyInput
            label={t("pages.orders.edit.general_informations.destination_city")}
            value={order.listRegion.name}
          />
        </InputsWrapper>
      ) : (
        <NotProvided completeClickHandler={editStep && (() => editStep(2))} />
      )}
    </RecapSection>
  );
};

const InputsWrapper = styled.div`
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 24px;
`;
