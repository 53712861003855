import { FC } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { PersonalInformations } from "../PersonalInformations";
import { ButtonWithIcon } from "src/components/ButtonWithIcon";

import { api } from "src/services/api";

import type { User } from "src/models/user";
import { Display as DisplayEnum } from "../ToUsers";
import { Role } from "src/types/user";

import { ReactComponent as NavigationBack } from "src/assets/svg-icons/navigation-left.svg";
import { ReactComponent as BinIcon } from "src/assets/svg-icons/bin.svg";

interface DisplayUserProps {
  user: User;
  refreshUsersData: () => void;
  updateDisplay: (display: DisplayEnum) => void;
}
export const DisplayUser: FC<DisplayUserProps> = props => {
  const { user, refreshUsersData, updateDisplay } = props;
  const { t } = useTranslation();

  const deleteUserHandler = async () => {
    try {
      const res = await api.delete<{ success: boolean }>(`/users/${user.id}`);
      if (!res.data.success) {
        throw new Error();
      }
      refreshUsersData();
      updateDisplay(DisplayEnum.ALL);
    } catch {}
  };

  return (
    <Wrapper>
      <ActionsWrapper>
        <GoBack
          onClick={() => {
            refreshUsersData();
            updateDisplay(DisplayEnum.ALL);
          }}
        >
          <NavigationBack />
          {user.firstName} {user.lastName.toLocaleUpperCase()}
        </GoBack>
        {user.role === Role.EMPLOYEE && (
          <ButtonWithIcon onClick={deleteUserHandler} icon={BinIcon}>
            {t("pages.settings.users.delete")}
          </ButtonWithIcon>
        )}
      </ActionsWrapper>
      <PersonalInformations user={user} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  & > *:not(:last-child) {
    margin-bottom: 16px;
  }
`;

const ActionsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const GoBack = styled.span`
  display: flex;
  align-items: center;
  white-space: nowrap;

  margin-top: 8px;
  width: min-content;
  cursor: pointer;
  font-weight: 600;
  font-size: 18px;
  line-height: 18px;
  letter-spacing: -0.0446em;

  & > svg {
    margin-right: 8px;
  }
`;
