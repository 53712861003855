import { FC, useCallback, useEffect, useState } from "react";

import { BasicPageWithHeader } from "src/components/layout/BasicPageWithHeader";
import { NoOrders } from "src/components/pages/orders/NoOrders";
import { TourOpOrders } from "src/components/pages/orders/TourOpOrders";

import { api } from "src/services/api";

import type { Order } from "src/models/order";

enum FetchStatus {
  PENDING = "PENDING",
  DONE = "DONE",
}

export const Orders: FC = () => {
  const [orders, setOrders] = useState<Order[]>([]);
  const [fetchStatus, setFetchStatus] = useState(FetchStatus.PENDING);

  const refreshOrders = useCallback(async () => {
    try {
      const res = await api.get<Order[]>("/orders");
      if (!res.data) {
        throw new Error();
      }
      setOrders(res.data);
    } catch {
      setOrders([]);
    } finally {
      setFetchStatus(FetchStatus.DONE);
    }
  }, []);

  useEffect(() => {
    refreshOrders();
  }, [refreshOrders]);

  return (
    <BasicPageWithHeader>
      {fetchStatus !== FetchStatus.PENDING ? (
        orders.length === 0 ? (
          <NoOrders />
        ) : (
          <TourOpOrders orders={orders} refreshOrders={refreshOrders} />
        )
      ) : null}
    </BasicPageWithHeader>
  );
};
