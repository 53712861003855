import { FC, ReactNode, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";

import { OrderStepper } from "./OrderStepper";
import { ClientSelection } from "./steps/ClientSelection";
import { MultipleClientsSelection } from "./steps/MultipleClientsSelection";
import { GeneralInfo } from "./steps/GeneralInfo";
import { OrderSteps } from "./steps/OrderSteps";
import { ExtraTravels } from "./steps/ExtraTravels";
import { Recap } from "./steps/Recap";
import { Payment } from "./steps/Payment";

import type { Order } from "src/models/order";
import { OrderType, Status } from "src/types/order";
import { FacturationKind } from "src/types/tour-operator";

export interface CommonStepProps {
  currentStep: number;
  order: Order;
  previousStepHandler: () => void;
  nextStepHandler: () => void;
  goToRecapHandler: () => void;
  updateOrderState: (order: Order) => void;
}

interface EditContentProps {
  order: Order;
  updateOrderState: (order: Order) => void;
}
export const EditContent: FC<EditContentProps> = props => {
  const { order, updateOrderState } = props;

  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const MAX_STEP =
    order.status === Status.PAID ||
    order.tourOperator?.facturationKind === FacturationKind.END_OF_MONTH
      ? 5
      : 6;

  const [currentStep, setCurrentStep] = useState(() => {
    const stepParam = Number(params.get("step") ?? 1);
    if (isNaN(stepParam) || stepParam < 1 || stepParam > MAX_STEP) {
      // default step if something goes wrong : 1
      return 1;
    }
    return stepParam;
  });

  const previousStepHandler = () => {
    if (!!params.get("step")) {
      history.replace(location.pathname);
    }
    setCurrentStep(prevState => prevState - 1);
  };

  const nextStepHandler = () => {
    if (!!params.get("step")) {
      history.replace(location.pathname);
    }
    setCurrentStep(prevState => prevState + 1);
  };

  const goToRecapHandler = () => {
    setCurrentStep(5);
  };

  const goToStepHandler = (newStep: number) => {
    if (newStep < 1 || newStep > MAX_STEP) {
      return;
    }

    if (!!params.get("step")) {
      history.replace(location.pathname);
    }
    setCurrentStep(newStep);
  };

  const renderCurrentStep = (): ReactNode => {
    const commonProps: CommonStepProps = {
      currentStep,
      order,
      previousStepHandler,
      nextStepHandler,
      goToRecapHandler,
      updateOrderState,
    };

    if (currentStep === 1) {
      // client selection
      return order.type === OrderType.SIMPLE ? (
        <ClientSelection {...commonProps} />
      ) : (
        <MultipleClientsSelection {...commonProps} />
      );
    } else if (currentStep === 2) {
      // general info
      return <GeneralInfo {...commonProps} />;
    } else if (currentStep === 3) {
      // steps & hotels
      return <OrderSteps {...commonProps} />;
    } else if (currentStep === 4) {
      // transports
      return <ExtraTravels {...commonProps} />;
    } else if (currentStep === 5) {
      // recap
      return <Recap {...commonProps} goToStepHandler={goToStepHandler} />;
    } else if (currentStep === 6) {
      // payment
      return <Payment {...commonProps} />;
    }
    // unexpected step
    return null;
  };

  return (
    <Wrapper>
      <OrderStepper order={order} currentStep={currentStep} />
      {renderCurrentStep()}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  flex: 1;
  max-width: 90%;
  margin: 0 auto;

  & > div:first-child {
    margin-bottom: 24px;
  }
`;
