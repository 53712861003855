import { useCallback, useEffect, useState } from "react";
import { getAllPinpoints } from "src/services/pinpoints";

enum FetchStatus {
  PENDING = "PENDING",
  DONE = "DONE",
}

export const usePinpoints = (listId: string) => {
  const [pinpoints, setPinpoints] = useState<any[]>([]);
  const [fetchStatus, setFetchStatus] = useState(FetchStatus.PENDING);

  const refreshPinpoints = useCallback(async (listId: string) => {
    try {
      if (!listId) {
        throw new Error();
      }
      const res = await getAllPinpoints(listId);
      if (!res.data) {
        throw new Error();
      }
      setPinpoints(res.data);
    } catch {
      setPinpoints([]);
    } finally {
      setFetchStatus(FetchStatus.DONE);
    }
  }, []);

  useEffect(() => {
    refreshPinpoints(listId);
  }, [refreshPinpoints, listId]);

  return {
    pinpoints,
    fetchStatus,
    refreshPinpoints,
    FetchStatus,
  };
};
