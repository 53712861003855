import type { Location } from "src/models/location";
import { LocationKind } from "src/types/location";

export const generateLocationFromGooglePlaceResult = (
  placeResult: google.maps.places.PlaceResult,
  kind: LocationKind = LocationKind.STEP,
): Location => {
  const components = placeResult.address_components || [];

  const findComponent = (type: string) =>
    components.find(component => component.types.includes(type));

  const streetNumberComponent = findComponent("street_number");
  const routeComponent = findComponent("route");
  const countryComponent = findComponent("country");
  const cityComponent = findComponent("locality");
  const zipCodeComponent = findComponent("postal_code");
  const utcOffsetMinutes = placeResult.utc_offset_minutes;

  const street =
    !!streetNumberComponent && !!routeComponent
      ? `${streetNumberComponent.long_name} ${routeComponent.long_name}`
      : null;
  const city = cityComponent?.long_name ?? null;

  const placeType: "building" | "location" = placeResult.types?.some(type =>
    ["lodging", "establishment"].includes(type),
  )
    ? "building"
    : "location";
  const displayName =
    placeType === "building"
      ? // building name
        placeResult.name || null
      : // location name
      !!street && !!city
      ? `${street}, ${city}`
      : city || null;

  const picture = placeResult?.photos ? placeResult?.photos[0].getUrl() : "";
  return {
    kind,
    city,
    street,
    displayName,
    lat: placeResult?.geometry?.location?.lat() ?? null,
    lng: placeResult?.geometry?.location?.lng() ?? null,
    country: countryComponent?.long_name ?? null,
    zipCode: zipCodeComponent?.long_name ?? null,
    utcOffsetMinutes: utcOffsetMinutes ?? null,
    mediaUrl: picture,
  };
};

export const generateLocationFromPartialLocation = (
  data: Partial<Location>,
): Location => {
  return {
    kind: data.kind ?? LocationKind.STEP,
    displayName: data.displayName ?? null,
    city: data.city ?? null,
    country: data.country ?? null,
    street: data.street ?? null,
    lat: data.lat ?? null,
    lng: data.lng ?? null,
    zipCode: data.zipCode ?? null,
    utcOffsetMinutes: data.utcOffsetMinutes ?? null,
    mediaUrl: data.mediaUrl ?? null,
  };
};
