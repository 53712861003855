import { useCallback, useEffect, useState } from "react";
import { ListRegion } from "src/models/listRegion";
import { getAllListRegions } from "src/services/lists";

enum FetchStatus {
  PENDING = "PENDING",
  DONE = "DONE",
}

export const useListRegions = (listId: string) => {
  const [listRegions, setListRegions] = useState<ListRegion[]>([]);
  const [fetchStatus, setFetchStatus] = useState(FetchStatus.PENDING);

  const refreshListRegions = useCallback(async listId => {
    try {
      const res = await getAllListRegions(listId);
      if (!res.data) {
        throw new Error();
      }

      setListRegions(res.data);
    } catch {
      setListRegions([]);
    } finally {
      setFetchStatus(FetchStatus.DONE);
    }
  }, []);

  useEffect(() => {
    refreshListRegions(listId);
  }, [refreshListRegions, listId]);

  return {
    listRegions,
    fetchStatus,
    refreshListRegions,
    FetchStatus,
  };
};
