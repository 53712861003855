import { FC, useEffect, useMemo, useState } from "react";
import {
  CellProps,
  Column,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { Card } from "src/components/Card";
import { PaginationMenu } from "src/components/table/PaginationMenu";
import { Table } from "src/components/table/Table";
import { Button, StyledButton } from "src/components/form/Button";

import { GlobalFilterSearchBar } from "src/components/table/GlobalFilterSearchBar";
import { DeletePracticalInformationModal } from "./specific/DeletePracticalInformation";
import { useHistory, useParams } from "react-router-dom";
import { IconWrapper } from "src/styles/styled-components-library";
import { ReactComponent as DeleteIcon } from "src/assets/svg-icons/actions/delete.svg";
import { PracticalInformation } from "src/models/practicalInformation";

interface AllPracticalInformationsProps {
  practicalInformations: PracticalInformation[];
  refreshPracticalInformations: (listId: string) => void;
}

export const AllPracticalInformations: FC<AllPracticalInformationsProps> = ({
  practicalInformations,
  refreshPracticalInformations,
}) => {
  const { t } = useTranslation();

  const history = useHistory();
  const params = useParams<{ listId: string }>();

  const [practicalInformationIdToDelete, setPracticalInformationIdToDelete] =
    useState<string | null>(null);

  const columns: Column<PracticalInformation>[] = useMemo(() => {
    return [
      {
        Header: `${t("forms.name")}`,
        accessor: "name",
      },
      {
        Header: `${t("forms.language")}`,
        accessor: "language",
      },
      {
        Header: `${t("forms.actions")}`,
        disableSortBy: true,
        Cell: ({ row }: CellProps<PracticalInformation>) => {
          const { original: currentPracticalInformation } = row;
          return (
            <IconsWrapper>
              <IconWrapper
                onClick={e => {
                  e.stopPropagation();
                  setPracticalInformationIdToDelete(
                    currentPracticalInformation.id.toString(),
                  );
                }}
              >
                <DeleteIcon />
              </IconWrapper>
            </IconsWrapper>
          );
        },
      },
    ];
  }, [t]);

  const instance = useTable(
    {
      columns,
      data: practicalInformations,
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  useEffect(() => {
    refreshPracticalInformations(params.listId);
  }, [refreshPracticalInformations, params.listId]);

  return (
    <>
      <ActionsWrapper>
        <GlobalFilterSearchBar
          instance={instance}
          placeholder={t("pages.poi.practical_informations.search")}
        />
        <Button
          onClick={() => {
            history.push(`/lists/${params.listId}/practical-informations/new`);
          }}
        >
          {t("pages.poi.practical_informations.create")}
        </Button>
      </ActionsWrapper>
      <TableCardWrapper>
        <Table
          instance={instance}
          columnsWidths={["100%"]}
          rowClickHandler={practicalInformation =>
            history.push(
              `/lists/${params.listId}/practical-informations/${practicalInformation.id}/edit`,
            )
          }
        />
      </TableCardWrapper>
      <PaginationWrapper>
        <PaginationMenu
          instance={instance}
          label={t(
            "pages.poi.practical_informations.practical_informations_per_page",
          )}
        />
      </PaginationWrapper>
      {!!practicalInformationIdToDelete && (
        <DeletePracticalInformationModal
          practicalInformationId={practicalInformationIdToDelete}
          showModal
          closeModal={() => setPracticalInformationIdToDelete(null)}
          postDeleteCallback={() => refreshPracticalInformations(params.listId)}
        />
      )}
    </>
  );
};

const TableCardWrapper = styled(Card)`
  width: 100%;
  height: min-content;
  padding: 24px;
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ActionsWrapper = styled.div`
  display: flex;

  & > *:not(:first-child) {
    margin-left: 16px;
  }

  & > ${StyledButton} {
    width: min-content;
  }
`;

const IconsWrapper = styled.div`
  display: flex;

  & > *:not(:last-child) {
    margin-right: 8px;
  }
`;
