import { FC } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { Card } from "src/components/Card";
import { Button } from "src/components/form/Button";

import { EmphasizedTitle } from "src/styles/styled-components-library";
import { colors } from "src/theme/colors";
import { Link } from "react-router-dom";

export const NoOrders: FC = () => {
  const { t } = useTranslation();

  return (
    <NoOrdersWrapper>
      <CardWrapper>
        <EmphasizedTitle>
          {t("pages.orders.no_order.order")} Digital{" "}
          <span className="emphasized">Roadbook</span>
        </EmphasizedTitle>
        <Description>{t("pages.orders.no_order.description")}</Description>
        <Link to="/orders/new">
          <CtaButton>{t("pages.orders.no_order.first_ordre")}</CtaButton>
        </Link>
      </CardWrapper>
    </NoOrdersWrapper>
  );
};

const NoOrdersWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CardWrapper = styled(Card)`
  width: 680px;
  padding: 100px 80px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  & > *:not(:last-child) {
    margin-bottom: 16px;
  }
`;

const Description = styled.p`
  margin: 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: ${colors.grey[600]};
`;

const CtaButton = styled(Button)`
  width: min-content;
`;
