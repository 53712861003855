import { FC, ChangeEventHandler, CSSProperties } from "react";
import styled from "styled-components";

import { colors } from "src/theme/colors";

type InputState = "default" | "error" | "disabled";
type InputStyles = {
  color: NonNullable<CSSProperties["color"]>;
  borderColor: NonNullable<CSSProperties["borderColor"]>;
  backgroundColor: NonNullable<CSSProperties["backgroundColor"]>;
};
const mapStateToStyles: Record<InputState, InputStyles> = {
  default: {
    color: colors.main.black,
    borderColor: colors.grey[100],
    backgroundColor: colors.background[100],
  },
  error: {
    color: colors.error[300],
    borderColor: colors.error[100],
    backgroundColor: colors.background[100],
  },
  disabled: {
    color: colors.grey[600],
    borderColor: "rgba(34, 34, 34, 0.05)",
    backgroundColor: colors.grey[100],
  },
};

export interface DateInputProps {
  value?: string | number;
  name?: string;
  min?: string;
  max?: string;
  placeholder?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  hasError?: boolean;
  disabled?: boolean;
}

export const DateInput: FC<DateInputProps> = props => {
  const {
    value,
    name,
    placeholder,
    min,
    max,
    hasError = false,
    disabled = false,
    onChange,
  } = props;

  const state: InputState = hasError
    ? "error"
    : disabled
    ? "disabled"
    : "default";
  const inputStyles: InputStyles = mapStateToStyles[state];
  return (
    <StyledInput
      {...inputStyles}
      type="date"
      name={name}
      value={value}
      hasError={hasError}
      disabled={disabled}
      placeholder={placeholder}
      min={min}
      max={max ?? "9999-12-31"}
      onChange={onChange}
    />
  );
};

type StyledInputStyleProps = InputStyles & {
  hasError: boolean;
  disabled: boolean;
};
const StyledInput = styled.input<StyledInputStyleProps>`
  width: 100%;
  height: 52px;
  padding: 16px;
  border-radius: 4px;
  color: ${({ color }) => color};
  background-color: ${({ backgroundColor }) => backgroundColor};
  border: 1px solid ${({ borderColor }) => borderColor};
  outline: none;
  transition: all 200ms ease;
  cursor: ${({ disabled }) => (disabled ? "default" : "text")};

  font-weight: 500;
  font-size: 14px;
  line-height: 18px;

  &::placeholder {
    color: ${({ hasError }) =>
      hasError ? colors.error[100] : colors.grey[200]};
  }

  /* we don't display the CTA green color if the input has an error */
  &:focus {
    color: ${({ color, hasError }) => (hasError ? color : colors.cta.green)};
    border: 1px solid
      ${({ borderColor, hasError }) =>
        hasError ? borderColor : colors.cta.green};
  }
`;
