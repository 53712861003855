import { FC } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { OrderRecap } from "../OrderRecap";
import { UpdateStepButtons } from "../UpdateStepButtons";
import { Alert } from "src/components/Alert";

import { useToastNotifications } from "src/hooks/useToastNotifications";
import { RequestStatus, useRequestStatus } from "src/hooks/useRequestStatus";
import { api } from "src/services/api";

import { colors } from "src/theme/colors";

import type { CommonStepProps } from "../EditContent";
import { Status } from "src/types/order";

interface RecapProps extends CommonStepProps {
  goToStepHandler: (newStep: number) => void;
}
export const Recap: FC<RecapProps> = props => {
  const {
    order,
    currentStep,
    previousStepHandler,
    nextStepHandler,
    goToStepHandler,
    updateOrderState,
  } = props;
  const { t } = useTranslation();
  const { sendToast } = useToastNotifications();
  const [status, setStatus] = useRequestStatus();

  const validateAndGoNextStep = async () => {
    if (status === RequestStatus.LOADING) {
      return;
    }

    setStatus(RequestStatus.LOADING);
    try {
      const res = await api.post<{ success: boolean }>(
        `/orders/${order.id}/validate`,
      );
      if (!res.data.success) {
        throw new Error();
      }
      updateOrderState({
        ...order,
        status: Status.TO_PAY,
      });
      nextStepHandler();
    } catch {
      sendToast(
        "error",
        "Votre commande n'a pas pu être validée, veuillez vous assurer que toutes les informations nécessaires ont été indiquées.",
        8000,
      );
      setStatus(RequestStatus.SERVER_ERROR);
    }
  };

  const mapStatusToNextStepHandler: Record<Status, () => void> = {
    [Status.DRAFT]: validateAndGoNextStep,
    [Status.TO_PAY]: nextStepHandler,
    [Status.PAID]: () => {},
    [Status.ARCHIVED]: () => {},
  };

  const mapStatusToNextStepLabel: Record<Status, string> = {
    [Status.DRAFT]:
      order.tourOperator?.facturationKind === "selfManaged"
        ? t("pages.orders.edit.recap.validate_and_pay")
        : t("pages.orders.edit.recap.validate"),
    [Status.TO_PAY]: t("pages.orders.edit.recap.pay"),
    [Status.PAID]: "",
    [Status.ARCHIVED]: "",
  };

  return (
    <Wrapper>
      <CustomAlert variant="info" transparent={false}>
        {t("pages.orders.edit.recap.please_check")}
      </CustomAlert>
      <OrderRecap order={order} editStep={step => goToStepHandler(step)} />
      <UpdateStepButtons
        order={order}
        currentStep={currentStep}
        previousHandler={previousStepHandler}
        loadingNext={status === RequestStatus.LOADING}
        nextHandler={mapStatusToNextStepHandler[order.status]}
        nextButtonLabel={mapStatusToNextStepLabel[order.status]}
        // we don't display the "Pay my order" button if it's already been paid or if the order was archived
        hideNext={
          order.status === Status.PAID || order.status === Status.ARCHIVED
        }
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  & > *:last-child {
    margin-top: 40px;
  }
`;

const CustomAlert = styled(Alert)`
  background-color: ${colors.main.white};
  margin-bottom: 16px;
`;
