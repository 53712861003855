import { FC } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { Logo } from "../Logo";

import { useAuth } from "src/contexts/AuthContext";

import { colors } from "src/theme/colors";

import { ReactComponent as SettingsIcon } from "src/assets/svg-icons/cog.svg";

import { Kind } from "src/types/user";

type LinkType = {
  label: string;
  href: string;
};

type ActionType = {
  key: string;
  icon: JSX.Element;
  onClickHandler: () => void;
};

export const Header: FC = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  const { t } = useTranslation();

  const tourOperatorLinks: LinkType[] = [
    {
      label: t("navbar.to.orders"),
      href: "/orders",
    },
    {
      label: t("navbar.to.poi"),
      href: "/lists",
    },
  ];
  const drbLinks: LinkType[] = [
    {
      label: t("navbar.drb.to"),
      href: "/tour-operators",
    },
    {
      label: t("navbar.drb.hotels"),
      href: "/hotels",
    },
    {
      label: t("navbar.drb.poi"),
      href: "/lists",
    },
  ];

  const { userPayload, logOut, user } = useAuth();

  const logoUrl = user?.tourOperator.logo
    ? `https://d2tbchnqphq2bv.cloudfront.net/${user?.tourOperator.logo}`
    : undefined;

  const settingsIsActive = pathname.includes("settings");
  const actions: ActionType[] = [
    {
      key: "settings",
      icon: (
        <SettingsIcon
          style={{
            color: settingsIsActive ? colors.cta.green : colors.main.black,
          }}
        />
      ),
      onClickHandler: settingsIsActive
        ? () => history.push("/orders")
        : () => history.push("/settings"),
    },
    {
      key: "logout",
      icon: <img alt="logout icon" src="/icons/logout.svg" />,
      onClickHandler: logOut,
    },
  ];

  const userLinks =
    userPayload?.kind === Kind.TO
      ? tourOperatorLinks
      : userPayload?.kind === Kind.DRB
      ? drbLinks
      : [];

  return (
    <Wrapper>
      <LogoWrapper>
        <Link to="/orders">
          <Logo width="170px" height="38px" url={logoUrl} />
        </Link>
      </LogoWrapper>
      <NavLinks>
        {userLinks.map(link => (
          <NavLink
            key={link.href}
            to={link.href}
            isActive={pathname.includes(link.href)}
          >
            {link.label}
          </NavLink>
        ))}
      </NavLinks>
      <Actions>
        {actions.map(action => (
          <Action key={action.key} onClick={action.onClickHandler}>
            {action.icon}
          </Action>
        ))}
      </Actions>
    </Wrapper>
  );
};

const Wrapper = styled.header`
  padding: 0 16px;
  background-color: ${colors.main.white};
  border-bottom: 1px solid ${colors.grey[100]};

  display: flex;
  justify-content: space-between;
  align-items: center;

  & > :first-child > a {
    display: block;
    height: 38px;
  }
`;

const LogoWrapper = styled.div`
  width: 170px;
`;

const NavLinks = styled.nav`
  display: flex;
`;

type NavLinkStyleProps = {
  isActive: boolean;
};
const NavLink = styled(Link)<NavLinkStyleProps>`
  min-height: 60px;
  padding: 0 16px;

  border-bottom: 3px solid
    ${({ isActive }) => (isActive ? colors.cta.green : "transparent")};

  display: flex;
  align-items: center;

  color: ${colors.main.black};
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;

  &:not(:first-child) {
    margin-left: 12px;
  }
  &:not(:last-child) {
    margin-right: 12px;
  }

  transition: opacity 250ms ease;
  &:hover {
    opacity: 0.7;
  }
`;

const Actions = styled.div`
  min-width: 170px;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  & > *:not(:first-child) {
    margin-left: 24px;
  }
`;

const Action = styled.div`
  width: 20px;
  height: 20px;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
`;
