import { FC, useState, FormEventHandler, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { Logo } from "src/components/Logo";
import { LabeledTextInput } from "src/components/form/LabeledTextInput";
import { Button } from "src/components/form/Button";
import { Alert } from "src/components/Alert";

import { useRequestStatus, RequestStatus } from "src/hooks/useRequestStatus";
import { api } from "src/services/api";

import {
  WavePageWrapper,
  Title,
  Description,
} from "src/styles/styled-components-library";

export const ResetPassword: FC = () => {
  const location = useLocation();
  const history = useHistory();
  const token = new URLSearchParams(location.search).get("token");
  const { t } = useTranslation();

  const [status, setStatus] = useRequestStatus();
  const [newPassword, setNewPassword] = useState("");

  const resetPasswordHandler: FormEventHandler<HTMLFormElement> = async e => {
    e.preventDefault();
    if (status === RequestStatus.LOADING) {
      return;
    }

    // if the reset was done, submitting the form means redirecting to the sign-in page
    if (status === RequestStatus.SUCCESS) {
      history.push("/sign-in");
      return;
    }

    if (!newPassword.trim()) {
      setStatus(RequestStatus.VALIDATION_ERROR);
      return;
    }

    setStatus(RequestStatus.LOADING);
    try {
      const res = await api.post<{ success: boolean }>("/auth/reset-password", {
        token,
        password: newPassword,
      });
      if (!res.data.success) {
        throw new Error();
      }
      setStatus(RequestStatus.SUCCESS);
    } catch {
      setStatus(RequestStatus.SERVER_ERROR);
    }
  };

  const renderAlert = (status: RequestStatus) => {
    if (status === RequestStatus.VALIDATION_ERROR) {
      return (
        <Alert variant="error">
          {t("pages.reset_password.valid_password")}
        </Alert>
      );
    } else if (status === RequestStatus.SERVER_ERROR) {
      return <Alert variant="error">{t("pages.reset_password.error")}</Alert>;
    } else if (status === RequestStatus.SUCCESS) {
      return (
        <Alert variant="success">{t("pages.reset_password.success")}</Alert>
      );
    }
    return null;
  };

  useEffect(() => {
    if (token === null) {
      history.push("/");
    }
  }, [token, history]);

  return (
    <ResetPasswordWrapper>
      <Logo />
      <FormWrapper onSubmit={resetPasswordHandler}>
        <Title>{t("pages.reset_password.title")}</Title>
        <Description>{t("pages.reset_password.description")}</Description>
        <LabeledTextInput
          type="password"
          label={t("forms.password")}
          placeholder="••••••••••"
          value={newPassword}
          hasError={status === RequestStatus.VALIDATION_ERROR}
          onChange={e => setNewPassword(e.target.value)}
        />
        {renderAlert(status)}
        <Button type="submit">
          {status === RequestStatus.SUCCESS
            ? t("pages.reset_password.connect")
            : t("pages.reset_password.modify")}
        </Button>
      </FormWrapper>
    </ResetPasswordWrapper>
  );
};

const ResetPasswordWrapper = styled(WavePageWrapper)`
  padding: 52px;
  align-items: center;
`;

const FormWrapper = styled.form`
  margin-top: 108px;
  width: 350px;

  & > *:not(:last-child) {
    margin-bottom: 24px;
  }
`;
