import { FC, useState } from "react";
import styled from "styled-components";

import { BasicPageWithHeader } from "src/components/layout/BasicPageWithHeader";
import { DisplayAll } from "src/components/pages/tour-operators/DisplayAll";
import { DisplayNewTo } from "src/components/pages/tour-operators/DisplayNewTo";

export enum Display {
  ALL = "ALL",
  NEW_TO = "NEW_TO",
}

export const TourOperators: FC = () => {
  const [display, setDisplay] = useState(Display.ALL);

  const updateDisplay = (display: Display) => {
    setDisplay(display);
  };

  return (
    <BasicPageWithHeader>
      <Wrapper>
        {display === Display.ALL && (
          <DisplayAll updateDisplay={updateDisplay} />
        )}
        {display === Display.NEW_TO && (
          <DisplayNewTo updateDisplay={updateDisplay} />
        )}
      </Wrapper>
    </BasicPageWithHeader>
  );
};

export const Wrapper = styled.div`
  flex: 1;
  padding: 16px;
  display: flex;
  flex-direction: column;

  & > *:not(:last-child) {
    margin-bottom: 12px;
  }
`;
