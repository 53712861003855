import { FilterValue, IdType, Row } from "react-table";

/**
 * `filter` function usable with component `EnumValuesFilter` in a table using `react-table`.
 */
export function enumFilter<T extends Record<string, unknown>>(
  rows: Array<Row<T>>,
  ids: Array<IdType<T>>,
  filterValue: FilterValue
) {
  // we select the specify field in the row
  const [id] = ids;

  // if no value is selected, we allow everything
  if (filterValue.length === 0) {
    return rows;
  }

  // we only allow the rows that match one the filter's values
  return rows.filter((row) => {
    const rowValue = row.values[id];
    return filterValue.includes(rowValue);
  });
}

/**
 * `filter` function usable with component `DateFilter` in a table using `react-table`.
 */
export function dateFilter<T extends Record<string, unknown>>(
  rows: Array<Row<T>>,
  ids: Array<IdType<T>>,
  filterValue: FilterValue
) {
  // we select the specify field in the row
  const [id] = ids;

  // if no value is selected, we allow everything
  if (filterValue === "") {
    return rows;
  }

  // we only allow values where they match the same exact date
  return rows.filter((row) => {
    const rowDate = new Date(row.values[id]);
    const filterDate = new Date(filterValue);
    return (
      filterDate.getDate() === rowDate.getDate() &&
      filterDate.getMonth() === rowDate.getMonth() &&
      filterDate.getFullYear() === rowDate.getFullYear()
    );
  });
}
