import { FC } from "react";
import styled from "styled-components";

import { colors } from "src/theme/colors";

export interface ReadOnlyInputProps {
  value?: string | number | null;
  disabled?: boolean;
}

export const ReadOnlyInput: FC<ReadOnlyInputProps> = (props) => {
  const { value, disabled = false } = props;
  return <FakeInput disabled={disabled}>{value}</FakeInput>;
};

type FakeInputStyleProps = {
  disabled: boolean;
};
const FakeInput = styled.div<FakeInputStyleProps>`
  width: 100%;
  padding: 16px;
  border-radius: 4px;
  color: ${({ disabled }) => (disabled ? colors.grey[600] : colors.main.black)};
  background-color: ${({ disabled }) =>
    disabled ? colors.grey[100] : colors.background[100]};
  border: 1px solid rgba(34, 34, 34, 0.05);
  outline: none;
  transition: all 200ms ease;
  cursor: ${({ disabled }) => (disabled ? "default" : "text")};

  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
`;
