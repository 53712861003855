import { colors } from "src/theme/colors";

import { Role } from "src/types/user";
import type { BadgeProps } from "src/components/Badge";

export const mapRoleToLabel: Record<Role, string> = {
  [Role.ADMIN]: "Administrateur",
  [Role.EMPLOYEE]: "Utilisateur",
};

export const mapRoleToBadgeStyle: Record<Role, BadgeProps> = {
  [Role.ADMIN]: {
    color: colors.turquoise[100],
    backgroundColor: colors.turquoise[900],
    borderColor: "rgba(0, 0, 0, 0.05)",
  },
  [Role.EMPLOYEE]: {
    color: colors.turquoise[900],
    backgroundColor: colors.turquoise[200],
    borderColor: "rgba(0, 0, 0, 0.05)",
  },
};

export const roleOptions: Array<{ label: string; value: string }> = [
  {
    label: mapRoleToLabel[Role.ADMIN],
    value: Role.ADMIN,
  },
  {
    label: mapRoleToLabel[Role.EMPLOYEE],
    value: Role.EMPLOYEE,
  },
];
