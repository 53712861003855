import { FC } from "react";
import { Toaster as LibToaster, ToastOptions } from "react-hot-toast";

// global default custom toast options
const toastOptions: ToastOptions = {
  duration: 6000,
  style: {
    borderRadius: 4,
    lineHeight: "20px",
    fontSize: "14px",
    fontWeight: 500,
  },
};

export const Toaster: FC = () => {
  return <LibToaster position="top-center" toastOptions={toastOptions} />;
};
