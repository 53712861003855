import { FC } from "react";
import styled from "styled-components";

import { Modal } from "src/components/Modal";
import { Button } from "src/components/form/Button";

import { RequestStatus, useRequestStatus } from "src/hooks/useRequestStatus";
import { api } from "src/services/api";

import { colors } from "src/theme/colors";
import { Title } from "src/styles/styled-components-library";

interface DeletePointOfInterestModalProps {
  pinpointId: string;
  showModal: boolean;
  closeModal: () => void;
  postDeleteCallback?: () => void;
}
export const DeletePointOfInterestModal: FC<
  DeletePointOfInterestModalProps
> = props => {
  const { pinpointId, showModal, closeModal, postDeleteCallback } = props;

  const [status, setStatus] = useRequestStatus();

  const deletePointOfInterestHandler = async () => {
    if (status === RequestStatus.LOADING) {
      return;
    }
    setStatus(RequestStatus.LOADING);

    try {
      await api.delete(`/lists/wemapPinpoints/${pinpointId}`);
      closeModal();
      if (postDeleteCallback) {
        postDeleteCallback();
      }
    } catch {
      setStatus(RequestStatus.SERVER_ERROR);
    }
  };

  return (
    <Modal showModal={showModal} closeModal={closeModal} width="800px">
      <ContentWrapper>
        <Title>Suppression d'un point d'intérêt</Title>
        <Description>
          Êtes-vous sûr de vouloir supprimer ce point d'intérêt ? La suppression
          des données sera permanente.
        </Description>
        <ButtonsWrapper>
          <Button variant="outline" onClick={closeModal}>
            Annuler
          </Button>
          <Button
            onClick={deletePointOfInterestHandler}
            loading={status === RequestStatus.LOADING}
          >
            Confirmer
          </Button>
        </ButtonsWrapper>
      </ContentWrapper>
    </Modal>
  );
};

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  & > *:not(:last-child) {
    margin-bottom: 36px;
  }
`;

const Description = styled.p`
  margin: 0;
  color: ${colors.main.black};
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
`;

const ButtonsWrapper = styled.div`
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr;
  grid-gap: 24px;
`;
