import { FC } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { Card } from "src/components/Card";
import { RecapSection } from "./RecapSection";
import { NotProvided } from "./NotProvided";
import { DisplayExtraTravels } from "../steps/ExtraTravels/DisplayExtraTravels";

import { useFilteredExtraTravels } from "src/hooks/useFilteredExtraTravels";
import { sections } from "src/constants/extra-travel";

import { colors } from "src/theme/colors";

import type { ExtraTravelDraft } from "src/types/order";
import type { CommonOrderRecapSectionProps } from "./index";

interface ExtraTravelsProps extends CommonOrderRecapSectionProps {}
export const ExtraTravels: FC<ExtraTravelsProps> = props => {
  const { t } = useTranslation();
  const { order, editStep } = props;
  const orderExtraTravels: ExtraTravelDraft[] = order.extraTravels ?? [];

  const { mapKindToExtraTravels } = useFilteredExtraTravels(orderExtraTravels);

  return (
    <RecapSection
      title={t("pages.orders.edit.steps.transport_and_activity")}
      editClickHandler={editStep && (() => editStep(4))}
      removeContentCard
    >
      {!!order.extraTravels?.length ? (
        sections.map(section => {
          if (mapKindToExtraTravels[section.kind].length === 0) {
            return null;
          }

          const SectionIcon = section.icon;
          return (
            <SectionWrapper key={section.kind}>
              <SectionTitle>
                <SectionIcon />
                <span>
                  {t(`pages.orders.edit.extra_travel.${section.title}`)}
                </span>
              </SectionTitle>
              <CardWrapper>
                <DisplayExtraTravels
                  kind={section.kind}
                  extraTravels={mapKindToExtraTravels[section.kind]}
                />
              </CardWrapper>
            </SectionWrapper>
          );
        })
      ) : (
        <Card>
          <NotProvided completeClickHandler={editStep && (() => editStep(4))} />
        </Card>
      )}
    </RecapSection>
  );
};

const CardWrapper = styled(Card)`
  padding: 16px;

  & > *:not(:last-child) {
    margin-bottom: 24px;
  }
`;

const SectionWrapper = styled.div`
  margin-bottom: 24px;

  ${CardWrapper}:not(:last-child) {
    margin-bottom: 24px;
  }
`;

const SectionTitle = styled.h2`
  margin-top: 0;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: -0.015em;
  color: ${colors.turquoise[800]};

  & > svg {
    width: 16px;
    height: 16px;
    margin-right: 8px;
  }
`;
