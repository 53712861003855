import { FC } from "react";
import styled from "styled-components";

import { colors } from "src/theme/colors";

import { ReactComponent as CheckIcon } from "src/assets/svg-icons/check.svg";

interface CheckboxProps {
  checked: boolean;
  size?: string;
  onChange: () => void;
}
export const Checkbox: FC<CheckboxProps> = (props) => {
  const { checked, size = "16px", onChange } = props;
  return (
    <Wrapper checked={checked} size={size} onClick={onChange}>
      {checked && <CheckIcon />}
    </Wrapper>
  );
};

type WrapperStyleProps = {
  size: string;
  checked: boolean;
};
const Wrapper = styled.div<WrapperStyleProps>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};

  cursor: pointer;
  border-radius: 4px;
  border: 1px solid
    ${({ checked }) => (checked ? "rgba(34, 34, 34, 0.2)" : colors.grey[200])};
  background-color: ${({ checked }) =>
    checked ? colors.cta.green : "transparent"};

  display: flex;
  justify-content: center;
  align-items: center;

  & > svg {
    width: ${({ size }) => `calc(${size} - 6px)`};
    height: ${({ size }) => `calc(${size} - 6px)`};
  }
`;
