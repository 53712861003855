import { FC, useEffect, useMemo, useState } from "react";
import {
  CellProps,
  Column,
  useFilters,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { Card } from "src/components/Card";
import { PaginationMenu } from "src/components/table/PaginationMenu";
import { Table } from "src/components/table/Table";
import { Button, StyledButton } from "src/components/form/Button";

import { GlobalFilterSearchBar } from "src/components/table/GlobalFilterSearchBar";
import { ReactComponent as DeleteIcon } from "src/assets/svg-icons/actions/delete.svg";
import { ReactComponent as EditActionIcon } from "src/assets/svg-icons/actions/edit.svg";
import { DeleteListModal } from "./specific/DeleteListModal";
import { IconWrapper } from "src/styles/styled-components-library";
import { useLists } from "src/hooks/useLists";
import { List } from "src/models/list";
import { Display as DisplayEnum } from "../../../pages/tour-operators/Lists";
import { useHistory } from "react-router-dom";
import { useAuth } from "src/contexts/AuthContext";
import { Kind } from "src/types/user";

interface DisplayAllProps {
  updateDisplay: (display: DisplayEnum) => void;
}

export const DisplayAll: FC<DisplayAllProps> = ({ updateDisplay }) => {
  const { t } = useTranslation();

  const [listIdToDelete, setListIdToDelete] = useState<string | null>(null);
  const { refreshLists, lists } = useLists();
  const history = useHistory();

  const { userPayload } = useAuth();

  const columns: Column<List>[] = useMemo(() => {
    return [
      {
        Header: `${t("pages.poi.country.country_name")}`,
        accessor: row => row.region.name,
      },
      {
        Header: `${t("forms.actions")}`,
        disableSortBy: true,
        Cell: ({ row }: CellProps<List>) => {
          const { original: currentList } = row;
          return (
            <IconsWrapper>
              {userPayload?.kind === Kind.TO ? (
                <IconWrapper
                  onClick={e => {
                    e.stopPropagation();
                    setListIdToDelete(currentList.id);
                  }}
                >
                  <DeleteIcon />
                </IconWrapper>
              ) : (
                <IconWrapper
                  onClick={e => {
                    e.stopPropagation();
                    history.push(`/lists/${currentList.id}/edit`);
                  }}
                >
                  <EditActionIcon />
                </IconWrapper>
              )}
            </IconsWrapper>
          );
        },
      },
    ];
  }, [history, userPayload?.kind, t]);

  const instance = useTable(
    {
      columns,
      data: lists,
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination,
  );

  useEffect(() => {
    refreshLists();
  }, [refreshLists]);

  return (
    <>
      <ActionsWrapper>
        <GlobalFilterSearchBar
          instance={instance}
          placeholder={t("pages.poi.country.search")}
        />
        <Button onClick={() => updateDisplay(DisplayEnum.NEW_TO)}>
          {userPayload?.kind !== Kind.TO
            ? t("pages.poi.country.create")
            : t("pages.poi.country.select")}
        </Button>
      </ActionsWrapper>
      <TableCardWrapper>
        <Table
          instance={instance}
          columnsWidths={["100%"]}
          rowClickHandler={list => history.push(`/lists/${list.id}`)}
        />
      </TableCardWrapper>
      <PaginationWrapper>
        <PaginationMenu
          instance={instance}
          label={t("pages.poi.country.country_per_page")}
        />
      </PaginationWrapper>
      {!!listIdToDelete && (
        <DeleteListModal
          listId={listIdToDelete}
          showModal
          closeModal={() => setListIdToDelete(null)}
          postDeleteCallback={refreshLists}
        />
      )}
    </>
  );
};

export const TableCardWrapper = styled(Card)`
  width: 100%;
  height: min-content;
  padding: 24px;
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ActionsWrapper = styled.div`
  display: flex;

  & > *:not(:first-child) {
    margin-left: 16px;
  }

  & > ${StyledButton} {
    width: min-content;
  }
`;

const IconsWrapper = styled.div`
  display: flex;

  & > *:not(:last-child) {
    margin-right: 8px;
  }
`;
