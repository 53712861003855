import { useCallback, useEffect, useState } from "react";
import { getAllPracticalInformations } from "src/services/practicalInformations";

enum FetchStatus {
  PENDING = "PENDING",
  DONE = "DONE",
}

export const usePracticalInformations = (listId: string) => {
  const [practicalInformations, setPracticalInformations] = useState<any[]>([]);
  const [fetchStatus, setFetchStatus] = useState(FetchStatus.PENDING);

  const refreshPracticalInformations = useCallback(async (listId: string) => {
    try {
      if (!listId) {
        throw new Error();
      }
      const res = await getAllPracticalInformations(listId);
      if (!res.data) {
        throw new Error();
      }
      setPracticalInformations(res.data);
    } catch {
      setPracticalInformations([]);
    } finally {
      setFetchStatus(FetchStatus.DONE);
    }
  }, []);

  useEffect(() => {
    refreshPracticalInformations(listId);
  }, [refreshPracticalInformations, listId]);

  return {
    practicalInformations,
    fetchStatus,
    refreshPracticalInformations,
    FetchStatus,
  };
};
