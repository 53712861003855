import { FC } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { LabeledReadOnlyInput } from "src/components/form/LabeledReadOnlyInput";
import { RecapSection } from "./RecapSection";
import { NotProvided } from "./NotProvided";

import type { CommonOrderRecapSectionProps } from "./index";

interface SpecificClientProps extends CommonOrderRecapSectionProps {}
export const SpecificClient: FC<SpecificClientProps> = props => {
  const { order, editStep } = props;
  const { t } = useTranslation();

  const [client] = order.clients || [];

  const isEndOfMonth = order.tourOperator?.facturationKind === "endOfMonth";

  return (
    <RecapSection
      title={t("pages.orders.edit.steps.client")}
      editClickHandler={editStep && (() => editStep(1))}
    >
      {client ? (
        <InputsWrapper>
          <LabeledReadOnlyInput
            label={t("forms.last_name")}
            value={client.tourOperatorClient?.lastName}
          />
          <LabeledReadOnlyInput
            label={t("forms.first_name")}
            value={client.tourOperatorClient?.firstName}
          />
          <LabeledReadOnlyInput
            label={`${t("pages.orders.edit.recap.client.code")} ${
              isEndOfMonth
                ? ""
                : t("pages.orders.edit.recap.client.code_information")
            }`}
            value={client.drbRef}
            disabled
          />
          <LabeledReadOnlyInput
            label={t("forms.email")}
            value={client.tourOperatorClient?.email}
          />
          <LabeledReadOnlyInput
            label={t("forms.birthday")}
            value={client.tourOperatorClient?.birthday}
          />
        </InputsWrapper>
      ) : (
        <NotProvided completeClickHandler={editStep && (() => editStep(1))} />
      )}
    </RecapSection>
  );
};

const InputsWrapper = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 24px;
`;
