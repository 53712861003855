import { FC, Key, useMemo, ChangeEvent, useEffect, useState } from "react";
import { FormikErrors, useFormik } from "formik";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { LabeledTextInput } from "src/components/form/LabeledTextInput";
import { Card } from "src/components/Card";
import { UpdateStepButtons } from "../UpdateStepButtons";

import { useRequestStatus, RequestStatus } from "src/hooks/useRequestStatus";
import { updateOrder } from "src/services/order";
import { validateNonEmptyFields } from "src/utils/validations";

import type { CommonStepProps } from "../EditContent";
import type { SelectOption } from "src/components/form/Select";
import { useLists } from "src/hooks/useLists";
import { LabeledSelect } from "src/components/form/LabeledSelect";
import { LabeledMultiSelect } from "src/components/form/LabeledMultiSelect";

interface GeneralInfoProps extends CommonStepProps {}
export const GeneralInfo: FC<GeneralInfoProps> = props => {
  const {
    order,
    currentStep,
    previousStepHandler,
    nextStepHandler,
    updateOrderState,
    goToRecapHandler,
  } = props;

  const { t } = useTranslation();
  const [status, setStatus] = useRequestStatus();
  const { refreshLists, lists } = useLists("drb");
  const [selectedLists, setSelectedLists] = useState<
    { label: string; value: string }[]
  >(
    order.lists.map(list => {
      return { label: list.region.name, value: list.id };
    }),
  );
  useEffect(() => {
    refreshLists("drb");
  }, [refreshLists]);

  const formInitialValues = {
    toReference: order.toReference || "",
    listRegion: order.listRegion?.id || "",
  };

  const formik = useFormik({
    initialValues: formInitialValues,
    validate: values => {
      const errors: FormikErrors<typeof formInitialValues> = {};

      const emptyFields = validateNonEmptyFields(
        values,
        "toReference",
        "listRegion",
      );
      if (emptyFields.length > 0) {
        for (const key of emptyFields) {
          errors[key] = "This field is empty.";
        }
      }
      if (Object.keys(errors).length > 0) {
        setStatus(RequestStatus.VALIDATION_ERROR);
      }
      return errors;
    },
    validateOnChange: false,
    onSubmit: async values => {
      if (status === RequestStatus.LOADING) {
        return { shouldExecuteCallback: false };
      }

      setStatus(RequestStatus.LOADING);
      try {
        const listToSaved = selectedLists?.map(list => {
          return { id: list.value };
        });

        const res = await updateOrder(order.id, {
          ...values,
          listRegionId: formik.values["listRegion"],
          lists: listToSaved,
        });
        if (!res.data.success) {
          throw new Error();
        }
        updateOrderState(res.data.order);
        return { shouldExecuteCallback: true };
      } catch {
        setStatus(RequestStatus.SERVER_ERROR);
        return { shouldExecuteCallback: false };
      }
    },
  });

  const listsOption = useMemo(() => {
    let options = lists.map(key => {
      return {
        label: key.region.name,
        value: key.id,
      };
    });

    options = options.sort((a, b) => {
      if (a.label.toLowerCase() < b.label.toLowerCase()) {
        return -1;
      }
      if (a.label.toLowerCase() > b.label.toLowerCase()) {
        return 1;
      }
      return 0;
    });
    return options;
  }, [lists]);

  let listRegionsOption: any[] = [];

  if (selectedLists && selectedLists.length > 0) {
    const listToKeep = lists.filter(list => {
      const res = selectedLists.filter(selectedList => {
        return selectedList.value === list.id;
      });
      return res && res.length > 0;
    });

    if (listToKeep.length > 0) {
      listToKeep.forEach(list => {
        list.listRegions.forEach(region => {
          listRegionsOption.push({
            label: region.name,
            value: region.id,
          });
        });
      });

      listRegionsOption = listRegionsOption.sort((a, b) => {
        if (a.label.toLowerCase() < b.label.toLowerCase()) {
          return -1;
        }
        if (a.label.toLowerCase() > b.label.toLowerCase()) {
          return 1;
        }
        return 0;
      });
    }

    listRegionsOption.unshift({ label: "", value: "" });
  }

  type FormKey = keyof typeof formInitialValues;

  type FormInput = {
    key: FormKey;
    label: string;
    placeholder: string;
    type: "text" | "select";
    options?: SelectOption[];
  };
  const inputs: FormInput[] = [
    {
      key: "toReference",
      label: t("pages.orders.edit.general_informations.ref"),
      placeholder: "USA1234",
      type: "text",
    },
  ];

  const submitFormHandler = async (successCallback: () => void) => {
    const res = (await formik.submitForm()) as {
      shouldExecuteCallback: boolean;
    };
    if (
      typeof res === "object" &&
      "shouldExecuteCallback" in res &&
      res.shouldExecuteCallback
    ) {
      successCallback();
    }
  };

  const canGoNextStep =
    !!formik.values["listRegion"] && !!formik.values["toReference"];

  const updateTextInputHandler = (
    e: ChangeEvent<HTMLInputElement>,
    key: Key,
  ) => {
    formik.setErrors({
      ...formik.errors,
      [key]: undefined,
    });
    formik.handleChange(e);
  };

  const change = (selectedOption: any) => {
    setSelectedLists(selectedOption);
    formik.values["listRegion"] = "";
  };

  return (
    <Wrapper>
      <CardWrapper>
        <InputsWrapper>
          {inputs.map(input => {
            return input.type === "select" ? (
              <LabeledSelect
                key={input.key}
                name={input.key}
                label={input.label}
                placeholder={input.placeholder}
                value={formik.values[input.key]}
                hasError={!!formik.errors[input.key]}
                onChange={(event: ChangeEvent<HTMLSelectElement>) => {
                  return formik.handleChange(event);
                }}
                options={input.options || []}
              />
            ) : (
              <LabeledTextInput
                key={input.key}
                name={input.key}
                type={input.type}
                label={input.label}
                placeholder={input.placeholder}
                value={formik.values[input.key]}
                hasError={!!formik.errors[input.key]}
                onChange={e => updateTextInputHandler(e, input.key)}
              />
            );
          })}

          <LabeledMultiSelect
            label={t("pages.orders.edit.general_informations.destination")}
            value={selectedLists}
            defaultValue={selectedLists}
            onChange={change}
            options={listsOption}
          />
          <LabeledSelect
            key={"listRegion"}
            name={"listRegion"}
            label={t("pages.orders.edit.general_informations.destination_city")}
            placeholder={""}
            value={formik.values["listRegion"]}
            hasError={!!formik.errors["listRegion"]}
            onChange={(event: ChangeEvent<HTMLSelectElement>) => {
              return formik.handleChange(event);
            }}
            options={listRegionsOption}
          />
        </InputsWrapper>
      </CardWrapper>
      <UpdateStepButtons
        order={order}
        disableNext={!canGoNextStep}
        currentStep={currentStep}
        loadingNext={status === RequestStatus.LOADING}
        previousHandler={previousStepHandler}
        nextHandler={() => submitFormHandler(nextStepHandler)}
        recapHandler={() => submitFormHandler(goToRecapHandler)}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  & > *:not(:last-child) {
    margin-bottom: 16px;
  }
`;

const CardWrapper = styled(Card)`
  padding: 16px;
  display: flex;
`;

const InputsWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 24px;

  & > * {
    flex: 1;
  }
`;
