import { FC, useEffect, useState } from "react";
import { useFormik } from "formik";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { Alert } from "src/components/Alert";
import { Button, StyledButton } from "src/components/form/Button";
import { LabeledDateInput } from "src/components/form/LabeledDateInput";
import { LabeledTextInput } from "src/components/form/LabeledTextInput";
import { LabeledSelect } from "src/components/form/LabeledSelect";
import { LabeledLocationAutocompleteInput } from "src/components/form/LabeledLocationAutocompleteInput";
import { DisplaySteps } from "./DisplaySteps";
import { CreateCustomHotelModal } from "./CreateCustomHotelModal";

import { useRequestStatus, RequestStatus } from "src/hooks/useRequestStatus";
import { computeStepsNewDate } from "src/utils/order-steps";
import { api } from "src/services/api";

import type { StepDraft } from "./index";
import type { SelectOption } from "src/components/form/Select";
import type { Location } from "src/models/location";
import type { Hotel } from "src/models/hotel";
import { LocationKind } from "src/types/location";

type FormValues = {
  type: "google" | "custom" | "free";
  location: string;
  nightsCount: string;
  city?: string;
};
const initialFormValues: FormValues = {
  type: "google",
  location: "",
  city: "",
  nightsCount: "",
};

interface StepsFormProps {
  initialStartDate: string;
  steps: StepDraft[];
  addStep: (step: StepDraft) => void;
  deleteStep: (step: StepDraft) => void;
  moveStep: (step: StepDraft[]) => void;
}
export const StepsForm: FC<StepsFormProps> = props => {
  const { initialStartDate, steps, addStep, deleteStep, moveStep } = props;
  const { t } = useTranslation();

  const hotelTypeOptions: SelectOption[] = [
    {
      value: "google",
      label: t("pages.orders.edit.order_steps.google"),
    },
    {
      value: "custom",
      label: t("pages.orders.edit.order_steps.custom"),
    },
    {
      value: "free",
      label: t("pages.orders.edit.order_steps.free_step"),
    },
  ];

  const [userHotels, setUserHotels] = useState<Hotel[]>([]);
  const [status, setStatus] = useRequestStatus();
  const [selectedLocation, setSelectedLocation] = useState<Location | null>(
    null,
  );
  const [showCreateHotelModal, setShowCreateHotelModal] = useState(false);

  const userHotelsOptions: SelectOption[] = userHotels.map(hotel => ({
    label: hotel.name,
    value: hotel.id,
  }));
  const stepsNewDate = computeStepsNewDate(initialStartDate, steps);

  const addHotelHandler = (hotel: Hotel) => {
    setUserHotels(prevHotels => [...prevHotels, hotel]);
    formik.setValues(values => ({ ...values, location: hotel.id }));
    setSelectedLocation(hotel.location);
  };

  const addBlankLocation = () => {
    const blankLocation = {
      kind: LocationKind.STEP,
      displayName: t("pages.orders.edit.order_steps.free_step"),
      street: " ",
      zipCode: " ",
      city: " ",
      country: " ",
      lat: null,
      lng: null,
      utcOffsetMinutes: 0,
      mediaUrl: " ",
    };
    setSelectedLocation(blankLocation);
  };

  const formik = useFormik({
    initialValues: initialFormValues,
    validate: values => {
      const errors: Record<string, string> = {};
      if (
        !values.nightsCount ||
        isNaN(+values.nightsCount) ||
        parseInt(values.nightsCount) > 10000
      ) {
        errors["nightsCount"] = "The number of nights is not valid.";
      }
      if (values["type"] === "free" && !values.city) {
        errors["city"] = "The name of the city is not valid.";
      }

      if (
        values["type"] !== "free" &&
        (!values["location"] || !selectedLocation)
      ) {
        errors["location"] = "This input is not valid.";
      }
      if (Object.keys(errors).length > 0) {
        setStatus(RequestStatus.VALIDATION_ERROR);
      } else {
        setStatus(RequestStatus.IDLE);
      }
      return errors;
    },
    validateOnChange: false,
    onSubmit: values => {
      if (!selectedLocation) {
        return;
      }

      let selectedLocationCustom: Location | null = null;

      if (values["type"] === "free") {
        selectedLocationCustom = {
          ...selectedLocation,
          city: values["city"] ?? " ",
          displayName: t("pages.orders.edit.order_steps.free_step"),
        };
      }

      addStep({
        date: stepsNewDate,
        rank: steps.length,
        nightsCount: +values.nightsCount,
        location: selectedLocationCustom ?? selectedLocation,
      });
      formik.setValues(initialFormValues);
    },
  });

  // effect selecting the first available user hotel when hotel type is "custom"
  useEffect(() => {
    function updateLocationValue(value: string) {
      formik.setValues(values => ({
        ...values,
        location: value,
      }));
    }

    if (formik.values["type"] === "custom") {
      const firstHotel = userHotels[0];
      updateLocationValue(firstHotel?.id ?? "");
      if (firstHotel) {
        setSelectedLocation(firstHotel.location);
      }
    } else if (formik.values["type"] === "google") {
      updateLocationValue("");
    } else if (formik.values["type"] === "free") {
      addBlankLocation();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values["type"]]);

  // onMount fetch of user hotels
  useEffect(() => {
    async function fetchUserHotels() {
      try {
        const res = await api.get<Hotel[]>("/hotels");
        if (!Array.isArray(res.data)) {
          throw new Error();
        }
        setUserHotels(res.data);
      } catch {}
    }
    fetchUserHotels();
  }, []);

  return (
    <Wrapper>
      <FormWrapper onSubmit={formik.handleSubmit}>
        <LabeledDateInput
          name="date"
          value={stepsNewDate}
          label={t("pages.orders.edit.order_steps.date")}
          disabled
        />
        <LabeledSelect
          options={hotelTypeOptions}
          name="type"
          value={formik.values["type"]}
          onChange={formik.handleChange}
          label={t("pages.orders.edit.order_steps.accommodation_type")}
          placeholder="Paris"
          hasError={
            status === RequestStatus.VALIDATION_ERROR && !!formik.errors["type"]
          }
        />
        {formik.values["type"] === "google" && (
          <LabeledLocationAutocompleteInput
            name="location"
            value={formik.values["location"]}
            onChange={formik.handleChange}
            label={t("pages.orders.edit.order_steps.name")}
            placeholder="Adresse google"
            hasError={
              status === RequestStatus.VALIDATION_ERROR &&
              !!formik.errors["location"]
            }
            autocompleteTypes={["establishment"]}
            onPlaceSelect={location => {
              formik.setValues(values => ({
                ...values,
                location: location.displayName || "",
              }));
              setSelectedLocation(location);
            }}
          />
        )}
        {formik.values["type"] === "custom" && (
          <LabeledSelect
            disabled={userHotelsOptions.length === 0}
            options={userHotelsOptions}
            name="location"
            value={formik.values["location"]}
            onChange={e => {
              formik.handleChange(e);

              const customHotelSelected = userHotels.find(
                hotel => hotel.id === e.target.value,
              );
              if (!!customHotelSelected) {
                setSelectedLocation(customHotelSelected.location);
              }
            }}
            label={t("pages.orders.edit.order_steps.where")}
            hasError={
              status === RequestStatus.VALIDATION_ERROR &&
              !!formik.errors["location"]
            }
          />
        )}
        {formik.values["type"] === "free" && (
          <LabeledTextInput
            name="city"
            value={formik.values["city"]}
            onChange={formik.handleChange}
            label={t("pages.orders.edit.order_steps.city_name")}
            placeholder="Paris"
            hasError={
              status === RequestStatus.VALIDATION_ERROR &&
              !!formik.errors["city"]
            }
          />
        )}
        <LabeledTextInput
          name="nightsCount"
          value={formik.values["nightsCount"]}
          onChange={formik.handleChange}
          label={t("pages.orders.edit.order_steps.night_number")}
          placeholder="3"
          hasError={
            status === RequestStatus.VALIDATION_ERROR &&
            !!formik.errors["nightsCount"]
          }
        />
        <Button type="submit">
          {t("pages.orders.edit.order_steps.add_step")}
        </Button>
      </FormWrapper>
      {formik.values["type"] === "custom" && (
        <CreateHotelAlert variant="info" transparent={false}>
          <span>
            {t("pages.orders.edit.order_steps.if_hotel_does_not_exist")}
          </span>
          <Button onClick={() => setShowCreateHotelModal(true)}>
            {t("pages.orders.edit.order_steps.create_hotel")}
          </Button>
        </CreateHotelAlert>
      )}
      <TableWrapper>
        <DisplaySteps
          steps={steps}
          deleteStepHandler={deleteStep}
          moveStep={moveStep}
        />
      </TableWrapper>
      {showCreateHotelModal && (
        <CreateCustomHotelModal
          showModal
          closeModal={() => setShowCreateHotelModal(false)}
          addHotelHandler={addHotelHandler}
        />
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  & > :not(:last-child) {
    margin-bottom: 12px;
  }
`;

const FormWrapper = styled.form`
  display: flex;
  align-items: flex-end;
  gap: 24px;

  & > :not(button) {
    width: 100%;
  }

  ${StyledButton} {
    width: min-content;
    height: 52px;
  }
`;

const TableWrapper = styled.div`
  margin: 12px 0;
`;

const CreateHotelAlert = styled(Alert)`
  padding: 12px 12px 8px 12px;
  align-items: center;

  & > :last-child {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > span {
      display: block;
      margin: 0;
    }
    ${StyledButton} {
      width: min-content;
    }
  }
`;
