export const getOrderIdLabel = (orderId: string) => {
  try {
    const firstUuidPart = orderId.split("-")[0];
    if (typeof firstUuidPart !== "string" || firstUuidPart.length !== 8) {
      throw new Error();
    }
    return firstUuidPart.toUpperCase();
  } catch {
    return "";
  }
};
