import { api } from "./api";

export const getAllPinpointsPerLanguage = (
  listId: string,
  pinpointId: string,
) => {
  return api.get(`/lists/${listId}/pinpoints/${pinpointId}`);
};

export const getAllPinpoints = (listId: string) => {
  return api.get(`/lists/${listId}/pinpoints`);
};
