import { FC } from "react";
import styled from "styled-components";

import { Card } from "src/components/Card";

import { colors } from "src/theme/colors";

import { ReactComponent as EditIcon } from "src/assets/svg-icons/actions/edit.svg";

interface RecapSectionProps {
  title: string;
  removeContentCard?: boolean;
  editClickHandler?: () => void;
}
export const RecapSection: FC<RecapSectionProps> = (props) => {
  const {
    children,
    title,
    removeContentCard = false,
    editClickHandler,
  } = props;
  return (
    <div>
      <SectionHeader>
        <SectionTitle>{title}</SectionTitle>
        {!!editClickHandler && (
          <CustomIconWrapper>
            <EditIcon onClick={editClickHandler} />
          </CustomIconWrapper>
        )}
      </SectionHeader>
      {removeContentCard ? children : <SectionCard>{children}</SectionCard>}
    </div>
  );
};

const SectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
`;

const SectionTitle = styled.h2`
  margin: 0;
  font-weight: 600;
  font-size: 18px;
  line-height: 18px;
  letter-spacing: -0.0446em;
  color: ${colors.main.black};
`;

const SectionCard = styled(Card)`
  padding: 16px;
`;

const CustomIconWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;

  & > svg {
    cursor: pointer;
    color: ${colors.cta.green};
    transition: color 200ms ease;
  }
`;
