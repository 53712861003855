import { FC } from "react";
import styled from "styled-components";

import { SpecificClient } from "./SpecificClient";
import { GeneralInformations } from "./GeneralInformations";
import { OrderSteps } from "./OrderSteps";
import { ExtraTravels } from "./ExtraTravels";

import type { Order } from "src/models/order";
import { OrderType, Status } from "src/types/order";
import { MultipleClients } from "./MultipleClients";

export interface CommonOrderRecapSectionProps {
  order: Order;
  editStep?: (step: number) => void;
}

interface OrderRecapProps {
  order: Order;
  // can either be a state change (if already in edit mode) or a history.push() (if in recap page)
  editStep: (step: number) => void;
}
export const OrderRecap: FC<OrderRecapProps> = props => {
  const { order, editStep } = props;

  const commonProps = {
    order,
    editStep: order.status !== Status.ARCHIVED ? editStep : undefined,
  };

  return (
    <SectionsWrapper>
      {order.type === OrderType.SIMPLE && <SpecificClient {...commonProps} />}
      {order.type === OrderType.MULTIPLE && (
        <MultipleClients {...commonProps} />
      )}
      <GeneralInformations {...commonProps} />
      <OrderSteps {...commonProps} />
      <ExtraTravels {...commonProps} />
    </SectionsWrapper>
  );
};

const SectionsWrapper = styled.div`
  & > *:not(:last-child) {
    margin-bottom: 40px;
  }
`;
