import { FC } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { Button } from "src/components/form/Button";

import { colors } from "src/theme/colors";

import { ReactComponent as EditIcon } from "src/assets/svg-icons/window-edit.svg";

interface NotProvidedProps {
  completeClickHandler?: () => void;
}
export const NotProvided: FC<NotProvidedProps> = props => {
  const { completeClickHandler } = props;
  const { t } = useTranslation();

  return (
    <Wrapper>
      <EditIcon />
      <Label>
        {!!completeClickHandler
          ? t("pages.orders.edit.recap.general_informations.not_provided_yet")
          : t("pages.orders.edit.recap.general_informations.not_provided")}
      </Label>
      {!!completeClickHandler && (
        <CompleteButton onClick={completeClickHandler}>
          {t("pages.orders.edit.recap.general_informations.complete")}
        </CompleteButton>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: min-content;
  margin: 36px auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > *:not(:last-child) {
    margin-bottom: 16px;
  }
`;

const Label = styled.p`
  margin: 0;
  white-space: nowrap;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: ${colors.grey[200]};
`;

const CompleteButton = styled(Button)`
  width: min-content;
`;
