import { useEffect, useState } from "react";

import { TourOpRoutes } from "./navigation/TourOpRoutes";
import { DrbRoutes } from "./navigation/DrbRoutes";
import { Toaster } from "./components/Toaster";

import TokenService from "./services/token";
import { useAuth } from "./contexts/AuthContext";
import { api } from "./services/api";
import { decodeAccessToken } from "./utils/api";

import { Kind } from "./types/user";
import "./translations/i18n";

/**
 * List of paths where the application prevent a redirect to the sign-in page if the user
 * is not currently authenticated.
 */
const pathnameAuthRedirectWhitelist = [
  "/sign-in",
  "/reset-password",
  "/sign-up",
  "/validate-account",
  "/first-connection",
];

function App() {
  const { userPayload, setUserPayload, logOut } = useAuth();
  const [isLoading, setIsLoading] = useState(true);

  // auth initialization
  useEffect(() => {
    const initAccessToken = async () => {
      return await api.post<{ accessToken: string }>("/auth/refresh-token", {
        refreshToken: TokenService.getLocalRefreshToken() || "",
      });
    };

    if (isLoading) {
      initAccessToken()
        .then(response => {
          const token = response.data.accessToken;
          if (!token) {
            throw new Error();
          }
          TokenService.updateLocalAccessToken(token);
          const decodedToken = decodeAccessToken(token);
          if (decodedToken) {
            setUserPayload(decodedToken);
          }
        })
        .catch(() => {
          logOut();
          // redirecting to the sign-in page if the user is not authenticated and the current pathname
          // isn't whitelisted
          if (
            !pathnameAuthRedirectWhitelist.find(
              path => path === window.location.pathname,
            )
          ) {
            window.location.pathname = "/sign-in";
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [isLoading, logOut, setUserPayload]);

  // navigation depending on the kind of the user
  const userRoutes =
    userPayload?.kind === Kind.DRB ? <DrbRoutes /> : <TourOpRoutes />;

  // rendering
  return !isLoading ? (
    <>
      {userRoutes}
      <Toaster />
    </>
  ) : null;
}

export default App;
