import { FC } from "react";
import styled from "styled-components";

import { TextInput, TextInputProps } from "./TextInput";

import { colors } from "src/theme/colors";

interface LabeledTextInputProps extends TextInputProps {
  label: string;
}
export const LabeledTextInput: FC<LabeledTextInputProps> = (props) => {
  const { label, hasError = false, ...inputProps } = props;
  return (
    <Wrapper>
      <Label hasError={hasError}>{label}</Label>
      <TextInput {...inputProps} hasError={hasError} />
    </Wrapper>
  );
};

const Wrapper = styled.label`
  display: block;
`;

type LabelStyleProps = {
  hasError: boolean;
};
const Label = styled.p<LabelStyleProps>`
  margin-top: 0;
  margin-bottom: 4px;

  color: ${({ hasError }) => (hasError ? colors.error[300] : colors.grey[600])};
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
`;
