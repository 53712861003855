import { ChangeEvent, FC, useEffect, useMemo } from "react";
import styled from "styled-components";
import { FormikErrors, useFormik } from "formik";
import { useTranslation } from "react-i18next";

import { Card } from "src/components/Card";
import { Button } from "src/components/form/Button";
import { LabeledSelect } from "src/components/form/LabeledSelect";
import { LabeledTextInput } from "src/components/form/LabeledTextInput";
import { Alert } from "src/components/Alert";

import { useRequestStatus, RequestStatus } from "src/hooks/useRequestStatus";
import { validateNonEmptyFields } from "src/utils/validations";
import { api } from "src/services/api";

import { Title } from "src/styles/styled-components-library";

import { Display as DisplayEnum } from "src/pages/drb/TourOperators";
import type { SelectOption } from "src/components/form/Select";

import { ReactComponent as GoBackIcon } from "src/assets/svg-icons/navigation-left.svg";
import { useRegions } from "src/hooks/useRegions";
import { useLists } from "src/hooks/useLists";
import { useAuth } from "src/contexts/AuthContext";
import { Kind } from "src/types/user";

type Key = "region";

type FormInput = {
  key: Key;
  label: string;
  placeholder: string;
  type: "text" | "select";
  options?: SelectOption[];
};

interface DisplayNewProps {
  updateDisplay: (display: DisplayEnum) => void;
}
export const DisplayNew: FC<DisplayNewProps> = props => {
  const { t } = useTranslation();
  const { updateDisplay } = props;
  const { regions } = useRegions();
  const { userPayload } = useAuth();
  const { refreshLists, lists } = useLists();
  const [status, setStatus] = useRequestStatus();

  const isDrbUser = userPayload?.kind === Kind.DRB;

  const formInitialValues = {
    region: "",
  };

  const formik = useFormik({
    initialValues: formInitialValues,
    validate: values => {
      const errors: FormikErrors<typeof formInitialValues> = {};
      const emptyFields = validateNonEmptyFields(values, "region");

      const filteredList = lists.filter(item => {
        return item.region.name === formik.values["region"];
      });

      if (filteredList.length > 0) {
        errors["region"] = t("pages.poi.country.double_region_error");
      }
      if (emptyFields.length > 0) {
        for (const key of emptyFields) {
          errors[key] = "This field is empty.";
        }
      }
      if (Object.keys(errors).length > 0) {
        setStatus(RequestStatus.VALIDATION_ERROR);
      }
      return errors;
    },
    validateOnChange: false,
    onSubmit: async values => {
      if (
        status === RequestStatus.LOADING ||
        status === RequestStatus.SUCCESS
      ) {
        return;
      }

      // !! TODO : déterminer le "locale" via la langue actuelle de l'app React
      // (quand on aura mis en place i18n)
      const body = {
        ...values,
        locale: "fr",
      };

      setStatus(RequestStatus.LOADING);
      try {
        if (isDrbUser) {
          const resRegion = await api.post("/regions", {
            name: formik.values["region"],
          });

          if (!resRegion.data) {
            throw new Error();
          }
          const res = await api.post<{ success: boolean }>("/lists", {
            regionId: resRegion.data.id,
          });

          if (!res.data) {
            throw new Error();
          }
        } else {
          const res = await api.post<{ success: boolean }>("/lists", {
            ...body,
            regionId: body.region,
          });

          if (!res.data) {
            throw new Error();
          }
        }

        setStatus(RequestStatus.SUCCESS);
      } catch {
        setStatus(RequestStatus.SERVER_ERROR);
      }
    },
  });

  const blankValue = useMemo(() => {
    return [{ label: "", value: "" }];
  }, []);

  const regionsOption = useMemo(() => {
    const regionsObject = regions.map(key => {
      return {
        label: key.name,
        value: key.id,
      };
    });

    const filteredRegions = regionsObject.filter(item => {
      return (
        lists.filter(list => {
          return list.region.name === item.label;
        }).length === 0
      );
    });

    filteredRegions.unshift(...blankValue);

    const sortedRegions = filteredRegions.sort((a, b) => {
      const nameA = a.label.toUpperCase();
      const nameB = b.label.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      return 0;
    });
    return sortedRegions;
  }, [regions, blankValue, lists]);

  const inputs: FormInput[] = [
    {
      key: "region",
      label: t("pages.poi.country.country_name"),
      placeholder: "",
      type: isDrbUser ? "text" : "select",
      options: isDrbUser ? [] : regionsOption,
    },
  ];

  useEffect(() => {
    refreshLists();
  }, [refreshLists]);

  const updateTextInputHandler = (
    e: ChangeEvent<HTMLInputElement>,
    key: Key,
  ) => {
    formik.setErrors({
      ...formik.errors,
      [key]: undefined,
    });
    formik.handleChange(e);
  };

  return (
    <>
      <NavigationWrapper>
        <StyledGoBackIcon onClick={() => updateDisplay(DisplayEnum.ALL)} />
      </NavigationWrapper>
      <CardWrapper>
        <FormWrapper onSubmit={formik.handleSubmit}>
          <Title>
            {isDrbUser
              ? t("pages.poi.country.create_this_country")
              : t("pages.poi.country.select_this_country")}
          </Title>
          <InputsWrapper>
            {inputs.map(input => {
              return input.type === "select" ? (
                <LabeledSelect
                  key={input.key}
                  name={input.key}
                  label={input.label}
                  placeholder={input.placeholder}
                  value={formik.values[input.key]}
                  hasError={!!formik.errors[input.key]}
                  onChange={formik.handleChange}
                  options={input.options || []}
                />
              ) : (
                <LabeledTextInput
                  key={input.key}
                  name={input.key}
                  type={input.type}
                  label={input.label}
                  placeholder={input.placeholder}
                  value={formik.values[input.key]}
                  hasError={!!formik.errors[input.key]}
                  onChange={e => updateTextInputHandler(e, input.key)}
                />
              );
            })}
          </InputsWrapper>
          {status === RequestStatus.VALIDATION_ERROR && (
            <>
              <Alert>{t("forms.invalid_form")}</Alert>
              <Alert>{formik.errors["region"]}</Alert>
            </>
          )}
          {status === RequestStatus.SERVER_ERROR && (
            <Alert>{t("pages.poi.country.server_error")}</Alert>
          )}
          {status === RequestStatus.SUCCESS && (
            <Alert variant="success">{t("pages.poi.country.success")}</Alert>
          )}
          <Button type="submit">
            {isDrbUser
              ? t("pages.poi.country.create_button")
              : t("pages.poi.country.select_button")}
          </Button>
        </FormWrapper>
      </CardWrapper>
    </>
  );
};

const NavigationWrapper = styled.div`
  width: 750px;
  margin: 0 auto;
`;

const StyledGoBackIcon = styled(GoBackIcon)`
  cursor: pointer;
`;

const CardWrapper = styled(Card)`
  margin: 0 auto;
  padding: 24px;
  width: 750px;
`;

const FormWrapper = styled.form`
  width: 100%;

  & > a:first-child {
    display: inline-block;
    margin-bottom: 12px;
  }

  & > *:not(:last-child) {
    margin-bottom: 24px;
  }
`;

const InputsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  & > * {
    width: 48%;
  }

  @supports (gap: 24px 28px) {
    gap: 24px 28px;
  }
  @supports not (gap: 24px 28px) {
    & > * {
      margin-bottom: 24px;
    }
  }
`;
