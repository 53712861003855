import { FC } from "react";
import styled from "styled-components";

import { BasicPageWithHeader } from "src/components/layout/BasicPageWithHeader";
import { DisplayAll } from "src/components/pages/hotels/DisplayAll";

export const Hotels: FC = () => {
  return (
    <BasicPageWithHeader>
      <Wrapper>
        <DisplayAll />
      </Wrapper>
    </BasicPageWithHeader>
  );
};

export const Wrapper = styled.div`
  flex: 1;
  padding: 16px;
  display: flex;
  flex-direction: column;

  & > *:not(:last-child) {
    margin-bottom: 12px;
  }
`;
