import { useCallback, useEffect, useState } from "react";
import { getAllPinpointsPerLanguage } from "src/services/pinpoints";
import { WemapPinpoint } from "src/types/wemapPinpoint";

enum FetchStatus {
  PENDING = "PENDING",
  DONE = "DONE",
}

type pinpointPerLanguage = {
  pinpointFR: WemapPinpoint;
  pinpointEN: WemapPinpoint;
  pinpointGE: WemapPinpoint;
  pinpointCH: WemapPinpoint;
};

export const usePinpoints = (listId: string, pinpointId: string) => {
  const [pinpoints, setPinpoints] = useState<pinpointPerLanguage | null>(null);
  const [fetchStatus, setFetchStatus] = useState(FetchStatus.PENDING);

  const refreshPinpoints = useCallback(
    async (listId: string, pinpointId: string) => {
      setFetchStatus(FetchStatus.PENDING);
      try {
        if (!pinpointId) {
          throw new Error();
        }
        const res = await getAllPinpointsPerLanguage(listId, pinpointId);
        if (!res.data) {
          throw new Error();
        }
        setPinpoints(res.data);
      } catch {
        setPinpoints(null);
      } finally {
        setFetchStatus(FetchStatus.DONE);
      }
    },
    [],
  );

  useEffect(() => {
    refreshPinpoints(listId, pinpointId);
  }, [refreshPinpoints, pinpointId, listId]);

  return {
    pinpoints,
    fetchStatus,
    refreshPinpoints,
    FetchStatus,
  };
};
