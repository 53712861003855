import { FC } from "react";
import styled from "styled-components";

import { ReadOnlyInput, ReadOnlyInputProps } from "./ReadOnlyInput";

import { colors } from "src/theme/colors";

interface LabeledReadOnlyInputProps extends ReadOnlyInputProps {
  label: string;
}
export const LabeledReadOnlyInput: FC<LabeledReadOnlyInputProps> = (props) => {
  const { label, ...inputProps } = props;
  return (
    <Wrapper>
      <Label>{label}</Label>
      <ReadOnlyInput {...inputProps} />
    </Wrapper>
  );
};

const Wrapper = styled.label`
  display: block;
`;

const Label = styled.p`
  margin-top: 0;
  margin-bottom: 4px;

  color: ${colors.grey[600]};
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
`;
