import axios from "axios";
import TokenService from "./token";

// !! TODO : déterminer l'URL de l'API de prod et comment l'appliquer correctement avec les variables d'environnement
const API_URL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:5001"
    : "https://api.digital-roadbook.com";

const api = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

api.interceptors.request.use(
  (config) => {
    const token = TokenService.getLocalAccessToken();
    if (token) {
      config.headers = { ...config.headers, Authorization: `Bearer ${token}` };
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

api.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;

    if (
      originalConfig.url !== "/auth/sign-in" &&
      err.response &&
      err.response.status === 401 &&
      !originalConfig._retry
    ) {
      // Access Token was expired
      originalConfig._retry = true;

      try {
        const rs = await api.post<{ accessToken: string }>(
          "/auth/refresh-token",
          {
            refreshToken: TokenService.getLocalRefreshToken(),
          },
        );

        const { accessToken } = rs.data;
        if (!accessToken) {
          TokenService.removeTokens();
          return Promise.reject(err);
        }

        TokenService.updateLocalAccessToken(accessToken);
        return api(originalConfig);
      } catch (_error) {
        return Promise.reject(_error);
      }
    }

    return Promise.reject(err);
  },
);

export { api, API_URL };
