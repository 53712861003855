import { FC, MouseEventHandler, SVGProps } from "react";
import styled from "styled-components";

import { colors } from "src/theme/colors";

interface ButtonWithIconProps {
  icon: FC<SVGProps<SVGSVGElement>>;
  onClick?: MouseEventHandler<HTMLButtonElement>;
}
export const ButtonWithIcon: FC<ButtonWithIconProps> = (props) => {
  const { icon: Icon, children, onClick } = props;
  return (
    <StyledButton onClick={onClick}>
      <Icon />
      {children}
    </StyledButton>
  );
};

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  cursor: pointer;
  width: min-content;
  white-space: nowrap;
  padding: 10px 16px;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  border: 1px solid rgba(34, 34, 34, 0.1);
  color: ${colors.main.black};
  background-color: ${colors.main.white};
  border-radius: 4px;
  transition: background-color 200ms ease;

  & > svg {
    width: 16px;
    height: 16px;
    margin-right: 10px;
  }

  &:hover {
    background-color: ${colors.grey[100]};
  }
`;
