import { FC, FormEventHandler, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";

import { Logo } from "src/components/Logo";
import { LabeledTextInput } from "src/components/form/LabeledTextInput";
import { Button } from "src/components/form/Button";
import { Alert } from "src/components/Alert";
import { Card } from "src/components/Card";

import TokenService from "src/services/token";
import { useAuth } from "src/contexts/AuthContext";
import { useAuthRedirect } from "src/hooks/useAuthRedirect";
import { api } from "src/services/api";
import { decodeAccessToken } from "src/utils/api";

import {
  WavePageWrapper,
  Description,
} from "src/styles/styled-components-library";

import type { Tokens } from "src/types/tokens";

enum Status {
  IDLE = "IDLE",
  LOADING = "LOADING",
  ERROR = "ERROR",
}

export const FirstConnection: FC = () => {
  // preventing authenticated users from accessing this page
  useAuthRedirect();

  const history = useHistory();
  const location = useLocation();
  const { setUserPayload } = useAuth();

  const { id, email } = useMemo(() => {
    const query = new URLSearchParams(location.search);
    try {
      const params = {
        id: query.get("id"),
        email: atob(query.get("email") || ""),
      };
      return params;
    } catch {
      history.push("/");
      return {
        id: null,
        email: null,
      };
    }
  }, [history, location]);

  const [status, setStatus] = useState(Status.IDLE);
  const [password, setPassword] = useState("");

  const submitHandler: FormEventHandler<HTMLFormElement> = async e => {
    e.preventDefault();
    if (status === Status.LOADING) {
      return;
    }
    setStatus(Status.LOADING);

    try {
      const res = await api.post<Tokens>("/auth/first-connection", {
        id,
        password,
      });
      if (!res.data?.accessToken || !res.data?.refreshToken) {
        throw new Error();
      }
      const decodedAccessToken = decodeAccessToken(res.data.accessToken);
      if (!decodedAccessToken) {
        throw new Error();
      }
      TokenService.setTokens(res.data);
      setUserPayload(decodedAccessToken);
      history.push("/orders");
    } catch {
      setStatus(Status.ERROR);
    }
  };

  return (
    <LoginPageWrapper>
      <Logo />
      <CardWrapper>
        <FormWrapper onSubmit={submitHandler}>
          <Title>Première connexion</Title>
          <Description>
            Renseignez votre adresse mail pour obtenir un mail de
            réinitialisation
          </Description>
          <LabeledTextInput
            label="Adresse email"
            hasError={status === Status.ERROR}
            value={email || ""}
            disabled
          />
          <LabeledTextInput
            label="Création du mot de passe"
            type="password"
            placeholder="••••••••••"
            hasError={status === Status.ERROR}
            value={password}
            onChange={e => setPassword(e.target.value)}
          />
          {status === Status.ERROR && (
            <Alert>La création de votre mot de passe n'a pas pu aboutir.</Alert>
          )}
          <Button type="submit">Créer mon mot de passe et me connecter</Button>
        </FormWrapper>
      </CardWrapper>
    </LoginPageWrapper>
  );
};

const LoginPageWrapper = styled(WavePageWrapper)`
  padding: 52px;
  align-items: center;
`;

const CardWrapper = styled(Card)`
  width: min-content;
  margin-top: 108px;
  padding: 80px 120px;
`;

const FormWrapper = styled.form`
  width: 350px;

  & > *:not(:last-child) {
    margin-bottom: 24px;
  }
`;

const Title = styled.h2`
  margin: 0;
  font-weight: 600;
  font-size: 24px;
  line-height: 25px;
  text-align: center;
`;
