import { FC, useMemo, useState } from "react";
import {
  CellProps,
  Column,
  useFilters,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { useHistory } from "react-router-dom";
import { add, format } from "date-fns";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { Card } from "src/components/Card";
import { Table } from "src/components/table/Table";
import { NotProvidedCell } from "src/components/table/NotProvidedCell";
import { Badge } from "src/components/Badge";
import { Button, StyledButton } from "src/components/form/Button";
import { PaginationMenu } from "src/components/table/PaginationMenu";
import { GlobalFilterSearchBar } from "src/components/table/GlobalFilterSearchBar";
import { DeleteOrderModal } from "./specific/DeleteOrderModal";
import { EnumValuesFilter } from "src/components/table/filters/EnumValuesFilter";

import {
  mapStatusToLabel,
  mapStatusToBadgeStyle,
  statusOptions,
} from "src/constants/status";
import { getOrderIdLabel } from "src/utils/order";
import { enumFilter } from "src/utils/table";

import { IconWrapper } from "src/styles/styled-components-library";

import type { Order } from "src/models/order";
import { Status } from "src/types/order";
import { Mode } from "./TourOpOrders";

import { ReactComponent as EditActionIcon } from "src/assets/svg-icons/actions/edit.svg";
import { ReactComponent as DeleteIcon } from "src/assets/svg-icons/actions/delete.svg";

interface MultipleOrdersProps {
  /**
   * A list of orders of type "multiple".
   */
  orders: Order[];
  updateModeHandler: (mode: Mode) => void;
  refreshOrders: () => void;
}
export const MultipleOrders: FC<MultipleOrdersProps> = props => {
  const { orders, updateModeHandler, refreshOrders } = props;
  const { t } = useTranslation();

  const history = useHistory();
  const [orderIdToDelete, setOrderIdToDelete] = useState<string | null>(null);

  const columns: Column<Order>[] = useMemo(
    () => [
      {
        Header: `${t("forms.order_number")}`,
        accessor: "id",
        Cell: (props: CellProps<Order>) => {
          const {
            row: { original: currentOrder },
          } = props;
          return <>{getOrderIdLabel(currentOrder.id)}</>;
        },
      },
      {
        Header: `${t("forms.ref")}`,
        accessor: "toReference",
        Cell: NotProvidedCell,
      },
      {
        Header: `${t("forms.start_date")}`,
        accessor: (row) => row.startDate ? format(new Date(row.startDate), "yyyy-MM-dd") : '',
        Cell: ({ row }: CellProps<Order>) => {
          const { original: currentOrder } = row;

          if (!currentOrder || !currentOrder.startDate) {
            return <UnavailableLabel>Non renseigné</UnavailableLabel>;
          }

          const newStartDate = add(new Date(currentOrder.startDate), {
            minutes: currentOrder.startLocation?.utcOffsetMinutes ?? 0,
          });
          return <>{format(new Date(newStartDate), "dd/MM/yyyy")}</>;
        },
      },
      {
        disableSortBy: true,
        Header: `${t("forms.customer_number")}`,
        accessor: "clients",
        Cell: (props: CellProps<{}, Order["clients"]>) => {
          const { value: clients } = props;
          return !!clients && clients.length > 0 ? (
            <>{clients.length}</>
          ) : (
            <NotProvidedCell {...props} value={null} />
          );
        },
      },
      {
        Header: `${t("forms.status")}`,
        accessor: "status",
        disableSortBy: true,
        Cell: ({ value }: CellProps<{}, Order["status"]>) => (
          <Badge {...mapStatusToBadgeStyle[value]}>
            {t(`enum.order_status.${mapStatusToLabel[value]}`)}
          </Badge>
        ),
        Filter: props => (
          <EnumValuesFilter
            {...props}
            options={statusOptions}
            styles={mapStatusToBadgeStyle}
          />
        ),
        filter: enumFilter,
      },
      {
        Header: `${t("forms.actions")}`,
        disableSortBy: true,
        Cell: ({ row }: CellProps<Order>) => {
          const { original: currentOrder } = row;
          return (
            <IconsWrapper>
              {(currentOrder.status === Status.DRAFT ||
                currentOrder.status === Status.TO_PAY) && (
                <IconWrapper
                  onClick={e => {
                    e.stopPropagation();
                    history.push(`/orders/${currentOrder.id}/edit`);
                  }}
                >
                  <EditActionIcon />
                </IconWrapper>
              )}
              {currentOrder.status === Status.DRAFT && (
                <IconWrapper
                  onClick={e => {
                    e.stopPropagation();
                    setOrderIdToDelete(currentOrder.id);
                  }}
                >
                  <DeleteIcon />
                </IconWrapper>
              )}
            </IconsWrapper>
          );
        },
      },
    ],
    [history, t],
  );

  const instance = useTable(
    {
      data: orders,
      columns,
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination,
  );

  return (
    <>
      <ActionsWrapper>
        <GlobalFilterSearchBar
          instance={instance}
          placeholder={t("pages.orders.order.search")}
        />
        <div>
          <Button
            variant="outline"
            onClick={() => updateModeHandler(Mode.PAY_ORDERS)}
          >
            {t("pages.orders.order.paid")}
          </Button>
          <Button onClick={() => history.push("/orders/new")}>
            {t("pages.orders.order.order")}
          </Button>
        </div>
      </ActionsWrapper>
      <Card>
        <Table
          instance={instance}
          rowClickHandler={order => history.push(`/orders/${order.id}`)}
          columnsWidths={["15%", "15%", "15%", "20%", "20%", "10%"]}
        />
      </Card>
      <PaginationWrapper>
        <PaginationMenu
          instance={instance}
          label={t("pages.orders.order.order_per_pages")}
        />
      </PaginationWrapper>
      {!!orderIdToDelete && (
        <DeleteOrderModal
          orderId={orderIdToDelete}
          showModal
          closeModal={() => setOrderIdToDelete(null)}
          postDeleteCallback={refreshOrders}
        />
      )}
    </>
  );
};

const IconsWrapper = styled.div`
  display: flex;

  & > *:not(:last-child) {
    margin-right: 8px;
  }
`;

const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;

  & > div {
    display: flex;
  }

  ${StyledButton} {
    width: min-content;
    margin-left: 8px;
  }
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const UnavailableLabel = styled.span`
  font-style: italic;
`;
