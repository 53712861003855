import { FC } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { Button } from "src/components/form/Button";

import type { Order } from "src/models/order";
import { Status } from "src/types/order";

interface UpdateStepButtonsProps {
  order: Order;
  currentStep: number;
  disableNext?: boolean;
  loadingNext?: boolean;
  hideNext?: boolean;
  nextButtonLabel?: string;
  previousHandler: () => void;
  nextHandler: () => void;
  recapHandler?: () => void;
}
export const UpdateStepButtons: FC<UpdateStepButtonsProps> = props => {
  const { t } = useTranslation();

  const {
    order,
    currentStep,
    disableNext = false,
    loadingNext = false,
    hideNext = false,
    nextButtonLabel = t("pages.orders.order.step_button.next"),
    previousHandler,
    nextHandler,
    recapHandler,
  } = props;

  const history = useHistory();
  const isFirstStep = currentStep === 1;

  return (
    <Wrapper
      style={{ justifyContent: isFirstStep ? "flex-end" : "space-between" }}
    >
      {!isFirstStep && (
        <StyledButton onClick={previousHandler}>
          {t("pages.orders.order.step_button.previous")}
        </StyledButton>
      )}
      <ButtonsWrapper>
        {currentStep < 5 && order.status === Status.TO_PAY && !!recapHandler && (
          <StyledButton
            variant="outline"
            onClick={recapHandler}
            loading={loadingNext}
          >
            {t("pages.orders.order.step_button.recap")}
          </StyledButton>
        )}
        {currentStep === 5 && (
          <StyledButton
            variant="outline"
            onClick={() => history.push("/orders")}
          >
            {t("pages.orders.order.step_button.complete_later")}
          </StyledButton>
        )}
        {!hideNext && (
          <StyledButton
            disabled={disableNext}
            onClick={nextHandler}
            loading={loadingNext}
          >
            {nextButtonLabel}
          </StyledButton>
        )}
      </ButtonsWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: flex-end;
`;

const StyledButton = styled(Button)`
  width: ${({ loading }) => (loading ? "100px" : "min-content")};
`;

const ButtonsWrapper = styled.div`
  & > *:not(:first-child) {
    margin-left: 8px;
  }
`;
