import { FC } from "react";
import styled from "styled-components";

import Select, { StylesConfig } from "react-select";

import { colors } from "src/theme/colors";

interface LabeledSelectProps {
  label: string;
  value: { label: string; value: string }[] | undefined;
  defaultValue: { label: string; value: string }[] | undefined;
  onChange: (selectedOption: any) => void;
  options: {
    value: string;
    label: string;
  }[];
}

const colourStyles: StylesConfig = {
  control: styles => ({
    ...styles,
    backgroundColor: colors.background[100],
    height: "52px",
    boxShadow: colors.grey[100],
    borderColor: colors.grey[100],
    ":hover": {
      borderColor: colors.grey[100],
    },
  }),
  multiValueRemove: styles => ({
    ...styles,
    color: colors.green[600],
    borderColor: colors.grey[100],
    ":hover": {
      backgroundColor: colors.green[600],
      color: "white",
    },
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    const color = colors.cta.green;
    return {
      ...styles,
      backgroundColor: isDisabled
        ? undefined
        : isSelected
        ? colors.main.black
        : isFocused
        ? color
        : undefined,

      cursor: isDisabled ? "not-allowed" : "default",

      ":active": {
        ...styles[":active"],
        backgroundColor: !isDisabled ? (isSelected ? color : color) : undefined,
      },
    };
  },
};

export const LabeledMultiSelect: FC<LabeledSelectProps> = props => {
  const { label, ...selectProps } = props;
  return (
    <Wrapper>
      <Label hasError={false}>{label}</Label>
      <Select {...selectProps} isMulti styles={colourStyles} />
    </Wrapper>
  );
};

const Wrapper = styled.label`
  display: block;
`;

type LabelStyleProps = {
  hasError: boolean;
};
const Label = styled.p<LabelStyleProps>`
  margin-top: 0;
  margin-bottom: 4px;

  color: ${({ hasError }) => (hasError ? colors.error[300] : colors.grey[600])};
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
`;
