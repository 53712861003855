import { FC } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";

import { ResetPassword } from "src/pages/common/ResetPassword";
import { Settings } from "src/pages/common/Settings";
import { SignUp } from "src/pages/tour-operators/SignUp";
import { Orders } from "src/pages/tour-operators/Orders";
import { ValidateAccount } from "src/pages/tour-operators/ValidateAccount";
import { FirstConnection } from "src/pages/tour-operators/FirstConnection";
import { NewOrder } from "src/components/pages/orders/NewOrder";
import { UpdateOrder } from "src/pages/tour-operators/UpdateOrder";
import { RecapOrder } from "src/pages/tour-operators/RecapOrder";
import { Checkout } from "src/pages/tour-operators/Checkout";

import { ProtectedRoute } from "./ProtectedRoute";
import { NewPointOfInterest } from "src/components/pages/points-of-interest/NewPointOfInterest";
import { Lists } from "src/pages/tour-operators/Lists";
import { EditPointOfInterest } from "src/components/pages/points-of-interest/EditPointOfInterest";
import { List } from "src/pages/tour-operators/List";
import { GeneratePDF } from "src/components/pages/orders/GeneratePDF";
import { SignIn } from "src/pages/common/SignIn";

export const TourOpRoutes: FC = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/sign-in" component={SignIn} />
        <Route exact path="/sign-up" component={SignUp} />
        <Route exact path="/validate-account" component={ValidateAccount} />
        <Route exact path="/reset-password" component={ResetPassword} />
        <Route exact path="/first-connection" component={FirstConnection} />
        <ProtectedRoute exact path="/checkout" component={Checkout} />
        <ProtectedRoute exact path="/lists" component={Lists} />
        <ProtectedRoute exact path="/lists/:listId" component={List} />
        <ProtectedRoute
          exact
          path="/lists/:listId/points-of-interest/new"
          component={NewPointOfInterest}
        />
        <ProtectedRoute
          exact
          path="/lists/:listId/points-of-interest/:pinpointId/edit"
          component={EditPointOfInterest}
        />
        <ProtectedRoute exact path="/orders/new" component={NewOrder} />
        <ProtectedRoute
          exact
          path="/orders/:orderId/edit"
          component={UpdateOrder}
        />
        <ProtectedRoute exact path="/orders/:orderId" component={RecapOrder} />
        <ProtectedRoute
          exact
          path="/orders/:orderId/pdf"
          component={GeneratePDF}
        />
        <ProtectedRoute exact path="/orders" component={Orders} />
        <ProtectedRoute exact path="/settings" component={Settings} />
        <Redirect to="/orders" />
      </Switch>
    </BrowserRouter>
  );
};
