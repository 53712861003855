import { useRef, useState } from "react";
import { ColumnInstance } from "react-table";
import styled from "styled-components";

import { useOnClickOutside } from "src/hooks/useOnClickOutside";

import { colors } from "src/theme/colors";

import { ReactComponent as FilterIcon } from "src/assets/svg-icons/filter.svg";

export function HeaderFilterCellContent<
  T extends Record<string, unknown>
>(props: { column: ColumnInstance<T> }) {
  const { column } = props;
  const { filterValue } = column;
  const [tooltipIsOpened, setTooltipIsOpened] = useState(false);

  const tooltipRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(tooltipRef, () =>
    setImmediate(() => setTooltipIsOpened(false))
  );

  const isFilterActive = Array.isArray(filterValue)
    ? filterValue.length > 0
    : !!filterValue;
  return (
    <Wrapper onClick={() => setTooltipIsOpened(true)}>
      <StyledFilterIcon isActive={isFilterActive} />
      {column.render("Header")}
      {tooltipIsOpened && (
        <Tooltip ref={tooltipRef}>{column.render("Filter")}</Tooltip>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: relative;
  cursor: pointer;
`;

type StyledFilterIconStyleProps = {
  isActive: boolean;
};
const StyledFilterIcon = styled(FilterIcon)<StyledFilterIconStyleProps>`
  color: ${({ isActive }) => (isActive ? colors.cta.green : colors.grey[200])};
`;

const Tooltip = styled.div`
  cursor: auto;
  position: absolute;
  z-index: 2;
  top: 105%;
  padding: 12px 16px;
  background: ${colors.main.white};
  border: 1px solid ${colors.grey[100]};
  box-shadow: 0px 12px 24px rgba(186, 186, 186, 0.19);
  border-radius: 4px;
`;
