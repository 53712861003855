import { FC, ChangeEvent } from "react";
import { FormikErrors, useFormik } from "formik";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { Logo } from "src/components/Logo";
import { Button } from "src/components/form/Button";
import { LabeledTextInput } from "src/components/form/LabeledTextInput";
import { LabeledSelect } from "src/components/form/LabeledSelect";
import { Alert } from "src/components/Alert";
import { SignUpSuccess } from "src/components/pages/sign-up/SignUpSuccess";

import { useAuthRedirect } from "src/hooks/useAuthRedirect";
import { useRequestStatus, RequestStatus } from "src/hooks/useRequestStatus";
import { api } from "src/services/api";
import { validateEmail, validateNonEmptyFields } from "src/utils/validations";
import { countryOptions } from "src/constants/countries";

import {
  WavePageWrapper,
  Title,
  Description,
} from "src/styles/styled-components-library";

import type { SelectOption } from "src/components/form/Select";

type Key =
  | "email"
  | "password"
  | "companyName"
  | "phone"
  | "lastName"
  | "firstName"
  | "addressStreet"
  | "addressZipCode"
  | "addressCity"
  | "addressCountry"
  | "vatNumber";

type FormInput = {
  key: Key;
  label: string;
  placeholder: string;
  type: "text" | "password" | "select";
  options?: SelectOption[];
};

export const SignUp: FC = () => {
  // preventing authenticated users from accessing this page
  useAuthRedirect();
  const { t } = useTranslation();

  const inputs: FormInput[] = [
    {
      key: "email",
      label: t("forms.email"),
      placeholder: "mail@mail.com",
      type: "text",
    },
    {
      key: "password",
      label: t("forms.password"),
      placeholder: "•••••••••••••••••",
      type: "password",
    },
    {
      key: "companyName",
      label: t("forms.company_name"),
      placeholder: "-",
      type: "text",
    },
    {
      key: "phone",
      label: t("forms.phone"),
      placeholder: "-",
      type: "text",
    },
    {
      key: "lastName",
      label: t("forms.last_name"),
      placeholder: "-",
      type: "text",
    },
    {
      key: "firstName",
      label: t("forms.first_name"),
      placeholder: "-",
      type: "text",
    },
    {
      key: "addressStreet",
      label: t("forms.address"),
      placeholder: "-",
      type: "text",
    },
    {
      key: "addressZipCode",
      label: t("forms.postal_code"),
      placeholder: "-",
      type: "text",
    },
    {
      key: "addressCity",
      label: t("forms.city"),
      placeholder: "-",
      type: "text",
    },
    {
      key: "addressCountry",
      label: t("forms.country"),
      placeholder: "France",
      type: "select",
      options: countryOptions,
    },
    {
      key: "vatNumber",
      label: t("forms.tva"),
      placeholder: "-",
      type: "text",
    },
  ];

  const [status, setStatus] = useRequestStatus();
  const formInitialValues = {
    email: "",
    password: "",
    companyName: "",
    phone: "",
    lastName: "",
    firstName: "",
    addressStreet: "",
    addressZipCode: "",
    addressCity: "",
    addressCountry: "FRA",
    vatNumber: "",
  };

  const formik = useFormik({
    initialValues: formInitialValues,
    validate: values => {
      const errors: FormikErrors<typeof formInitialValues> = {};
      if (!validateEmail(values.email)) {
        errors.email = "The email provided is not valid.";
      }
      const emptyFields = validateNonEmptyFields(
        values,
        "addressStreet",
        "addressCity",
        "addressCountry",
        "addressZipCode",
        "companyName",
        "firstName",
        "lastName",
        "password",
        "phone",
        "vatNumber",
      );
      if (emptyFields.length > 0) {
        for (const key of emptyFields) {
          errors[key] = "This field is empty.";
        }
      }
      if (Object.keys(errors).length > 0) {
        setStatus(RequestStatus.VALIDATION_ERROR);
      }
      return errors;
    },
    validateOnChange: false,
    onSubmit: async values => {
      if (
        status === RequestStatus.LOADING ||
        status === RequestStatus.SUCCESS
      ) {
        return;
      }

      // !! TODO : déterminer le "locale" via la langue actuelle de l'app React
      // (quand on aura mis en place i18n)
      const body = {
        ...values,
        locale: "fr",
      };

      setStatus(RequestStatus.LOADING);
      try {
        const res = await api.post<{ success: boolean }>("/auth/sign-up", body);
        if (!res.data.success) {
          throw new Error();
        }
        setStatus(RequestStatus.SUCCESS);
      } catch {
        setStatus(RequestStatus.SERVER_ERROR);
      }
    },
  });

  const updateTextInputHandler = (
    e: ChangeEvent<HTMLInputElement>,
    key: Key,
  ) => {
    formik.setErrors({
      ...formik.errors,
      [key]: false,
    });
    formik.handleChange(e);
  };

  return (
    <PageWrapper>
      {status !== RequestStatus.SUCCESS ? (
        <>
          <Logo />
          <FormWrapper onSubmit={formik.handleSubmit}>
            <Link to="/sign-in">
              <TitleWrapper>
                <img alt="go back" src="/icons/navigation-left.svg" />
                <Title>{t("pages.sign_up.title")}</Title>
              </TitleWrapper>
            </Link>
            <Description>{t("pages.sign_up.description")}</Description>
            <InputsWrapper>
              {inputs.map(input => {
                return input.type === "select" ? (
                  <LabeledSelect
                    key={input.key}
                    name={input.key}
                    label={input.label}
                    placeholder={input.placeholder}
                    value={formik.values[input.key]}
                    hasError={!!formik.errors[input.key]}
                    onChange={formik.handleChange}
                    options={input.options || []}
                  />
                ) : (
                  <LabeledTextInput
                    key={input.key}
                    name={input.key}
                    type={input.type}
                    label={input.label}
                    placeholder={input.placeholder}
                    value={formik.values[input.key]}
                    hasError={!!formik.errors[input.key]}
                    onChange={e => updateTextInputHandler(e, input.key)}
                  />
                );
              })}
            </InputsWrapper>
            {status === RequestStatus.VALIDATION_ERROR && (
              <Alert>{t("forms.invalid_form")}</Alert>
            )}
            {status === RequestStatus.SERVER_ERROR && (
              <Alert>{t("pages.sign_up.error")}</Alert>
            )}
            <Button type="submit" loading={status === RequestStatus.LOADING}>
              {t("pages.sign_up.sign_up")}
            </Button>
          </FormWrapper>
        </>
      ) : (
        <SignUpSuccess />
      )}
    </PageWrapper>
  );
};

const PageWrapper = styled(WavePageWrapper)`
  padding: 52px;
  align-items: center;
`;

const FormWrapper = styled.form`
  margin-top: 108px;
  width: 750px;

  & > a:first-child {
    display: inline-block;
    margin-bottom: 12px;
  }

  & > *:not(:last-child) {
    margin-bottom: 24px;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  & > img {
    width: 20px;
    height: 16.25px;
    margin-right: 8px;
  }
`;

const InputsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  & > * {
    width: 48%;
  }

  @supports (gap: 24px 28px) {
    gap: 24px 28px;
  }
  @supports not (gap: 24px 28px) {
    & > * {
      margin-bottom: 24px;
    }
  }
`;
