import { useMemo } from "react";
import { ColumnInstance } from "react-table";
import styled from "styled-components";

import { colors } from "src/theme/colors";

import { ReactComponent as SortIcon } from "src/assets/svg-icons/sort.svg";
import { ReactComponent as SortAscIcon } from "src/assets/svg-icons/sort-asc-active.svg";
import { ReactComponent as SortDescIcon } from "src/assets/svg-icons/sort-desc-active.svg";
import { HeaderFilterCellContent } from "./filters/HeaderFilterCellContent";

export interface HeaderCellProps<T extends Record<string, unknown>> {
  column: ColumnInstance<T>;
  width?: StringPercentage;
}
export function HeaderCell<T extends Record<string, unknown>>(
  props: HeaderCellProps<T>,
) {
  const { column, width } = props;
  const headerPropsGetter = column.canSort
    ? column.getSortByToggleProps // allows us to sort by clicking the <th> tag
    : undefined;

  // For unknown reasons, "react-table" triggers the 'canFilter' flag when using 'useGlobalFilter', even
  // though it is not documented that way.
  // The following code makes sure we have a 'Filter' key in the associated column (which is used
  // with 'useFilters')
  const canFilterSpecificColumn = useMemo(
    () =>
      column.canFilter &&
      (() => {
        try {
          const filterJSX = column.render("Filter");
          return !!filterJSX;
        } catch {
          return false;
        }
      })(),
    [column],
  );

  const isSelectorHeaderCell = column.id === "_selector";
  return (
    <StyledTh
      {...column.getHeaderProps(headerPropsGetter && headerPropsGetter())}
      width={isSelectorHeaderCell ? "3%" : width}
      title="">
      {canFilterSpecificColumn ? (
        <HeaderFilterCellContent column={column} />
      ) : (
        <div>
          {column.canSort &&
            (column.isSortedDesc ? (
              <SortDescIcon />
            ) : column.isSorted ? (
              <SortAscIcon />
            ) : (
              <SortIcon />
            ))}
          {column.render("Header")}
        </div>
      )}
    </StyledTh>
  );
}

type StyledThStyleProps = {
  width?: StringPercentage;
};
const StyledTh = styled.th<StyledThStyleProps>`
  width: ${({ width }) => width ?? "auto"};
  user-select: none;
  text-align: left;
  color: ${colors.grey[200]};
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  padding: 12px 8px;
  border-bottom: 1px solid ${colors.background[100]};

  & > div {
    display: flex;
    align-items: center;

    & > svg {
      margin-right: 8px;
      width: 16px;
      height: 16px;
    }
  }
`;
