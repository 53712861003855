import {
  add,
  addMinutes,
  format,
  isMatch,
  Locale,
  parse,
  subMinutes,
} from "date-fns";

export const formatDateToLocalString = (date: Date, locale: Locale) => {
  return format(date, "cccc d MMMM", {
    locale,
  });
};

export const formatHtmlStringDateToIsoString = (htmlDate: string) => {
  return add(parse(htmlDate, "yyyy-MM-dd", new Date()), {
    hours: 1,
  }).toISOString();
};

export const fromHtmlDateAndHourToJsDate = (
  htmlDate: string,
  htmlHour: string,
  hasSeconds?: boolean,
): Date => {
  return parse(
    `${htmlDate} ${htmlHour}`,
    `yyyy-MM-dd HH:mm${hasSeconds ? ":ss" : ""}`,
    new Date(),
  );
};

export const getLocalDateWithDestinationUTC = (
  date: string,
  offset: number,
) => {
  const localDate = new Date(addMinutes(new Date(date), offset));

  const dateOffset = localDate.getTimezoneOffset();
  const dateWithoutOffset = addMinutes(localDate, dateOffset);
  return dateWithoutOffset;
};

export const getDestinationLocalDate = (
  date: string,
  hour: string,
  offset: number,
) => {
  const dateWithOffset = fromHtmlDateAndHourToJsDate(
    date,
    hour,
    isMatch(hour, "HH:mm:ss"),
  );

  const dateOffset = dateWithOffset.getTimezoneOffset();

  const dateWithoutOffset = subMinutes(dateWithOffset, dateOffset);

  return subMinutes(dateWithoutOffset, offset);
};
