import { FC } from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
  Image,
} from "@react-pdf/renderer";
import useLoadData from "src/hooks/useLoadData";
import { getOrderPDF } from "src/services/order";
import { useParams } from "react-router-dom";
import DRB from "./drb.png";

// url("/images/waves.png")

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#E4E4E4",
    paddingTop: "5%",
    paddingBottom: "5%",
    paddingLeft: "10%",
    paddingRight: "10%",
    justifyContent: "space-between",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  row: {
    display: "flex",
    flexDirection: "row",
  },
  rowHeadreColumn: {
    width: "20%",
    fontSize: 14,
    fontWeight: 700,
    marginBottom: 15,
  },
  rowColumn: {
    width: "20%",
    fontSize: 11,
    marginBottom: 4,
  },
  total: {
    flexDirection: "row",
    fontSize: 12,
    justifyContent: "flex-end",
  },
  headerText: {
    fontSize: 12,
  },
});

export const GeneratePDF: FC = () => {
  const { orderId } = useParams<{ orderId: string }>();

  const { data } = useLoadData(() => getOrderPDF(orderId));

  if (!data) {
    return <></>;
  }

  const hasTVA = data.totalHT ? true : false;

  return (
    <PDFViewer style={{ width: "100%", height: window.innerHeight }}>
      <Document>
        <Page size="A4" style={styles.page}>
          <View>
            <Image src={DRB} style={{ width: "50%", alignSelf: "center" }} />
            <View
              style={{
                height: 2,
                width: "100%",
                backgroundColor: "#000",
                marginTop: 25,
                marginBottom: 25,
              }}
            />

            <View style={styles.row}>
              <View style={{ width: "50%", textAlign: "left" }}>
                <Text style={styles.headerText}>
                  Facture N°{data.invoiceNumber}
                </Text>
                <Text style={styles.headerText}>{data.date}</Text>
              </View>
              <View style={{ width: "50%", textAlign: "right" }}>
                <Text style={styles.headerText}>{data.toName}</Text>
                <Text style={styles.headerText}>{data.toAddress}</Text>
                <Text style={styles.headerText}>{data.city}</Text>
                <Text style={styles.headerText}>{data.tva}</Text>
              </View>
            </View>
            <View
              style={{
                height: 2,
                width: "100%",
                backgroundColor: "#000",
                marginTop: 25,
                marginBottom: 25,
              }}
            />
            <View style={styles.row}>
              <Text style={styles.rowHeadreColumn}>Order N°</Text>
              <Text style={styles.rowHeadreColumn}>Ref.</Text>
              <Text style={styles.rowHeadreColumn}>
                {hasTVA ? "Commande HT" : "Order price"}
              </Text>
              <Text style={styles.rowHeadreColumn}>
                {hasTVA ? "Code RB HT" : "Code Price"}
              </Text>
              <Text style={styles.rowHeadreColumn}>
                {hasTVA ? "Sous total HT" : "Total order"}
              </Text>
            </View>
            {data.items.map((item) => {
              return (
                <View style={styles.row}>
                  <Text style={styles.rowColumn}>{item.orderNumber}</Text>
                  <Text style={styles.rowColumn}>{item.ref}</Text>
                  <Text style={styles.rowColumn}>{item.orderPrice}</Text>
                  <Text style={styles.rowColumn}>{item.codePrice}</Text>
                  <Text style={styles.rowColumn}>{item.totalPrice}</Text>
                </View>
              );
            })}
            <View style={[styles.total, { marginTop: 35 }]}>
              <Text style={{ marginRight: 10 }}>
                {hasTVA ? "TOTAL HT" : "TOTAL"}
              </Text>
              <Text>{hasTVA ? data.totalHT : data.totalPrice}</Text>
            </View>
            {hasTVA ? (
              <>
                <View style={styles.total}>
                  <Text style={{ marginRight: 10 }}>TVA 20%</Text>
                  <Text>{data.totalTVA}</Text>
                </View>
                <View style={styles.total}>
                  <Text style={{ marginRight: 10 }}>TOTAL TTC</Text>
                  <Text>{data.totalPrice}</Text>
                </View>
              </>
            ) : null}
          </View>
          {hasTVA ? (
            <View>
              <Text style={{ fontSize: 10, textAlign: "center" }}>
                Pas d'escompte pour règlement anticipé. En cas de retard de
                paiement, une pénalité égale à 3 fois le taux intérêt légal sera
                exigible (Article L 441-10, alinéa 12 du Code de Commerce). Pour
                tout professionnel, en sus des indemnités de retard, toute
                somme, y compris l'acompte, non payée à sa date d'exigibilité
                produira de plein droit le paiement d'une indemnité forfaitaire
                de 40 euros due au titre des frais de recouvrement (Art. 441-6,
                I al. 12 du code de commerce et D. 441-5 ibidem).
              </Text>
              <Text style={{ fontSize: 10, textAlign: "center" }}>
                LBR TRAVEL - 64 Rue Hoche 91700 Sainte-Geneviève-des-Bois -
                Email : compta@lbrtravel.com - Code NAF (APE) 5811Z - SASU au
                capital social de 1000 € - Siret : 92489454600010 – TVA
                Intracommunautaire : FR08924894546
              </Text>
            </View>
          ) : (
            <View>
              <View>
                <Text
                  style={{
                    fontSize: 10,
                    textAlign: "center",
                    marginBottom: 20,
                  }}
                >
                  Exonération TVA article 262 ter I du Code Général des impôts
                </Text>
              </View>
              <View>
                <Text style={{ fontSize: 10, textAlign: "center" }}>
                  Pas d'escompte pour règlement anticipé. En cas de retard de
                  paiement, une pénalité égale à 3 fois le taux intérêt légal
                  sera exigible (Article L 441-10, alinéa 12 du Code de
                  Commerce). Pour tout professionnel, en sus des indemnités de
                  retard, toute somme, y compris l'acompte, non payée à sa date
                  d'exigibilité produira de plein droit le paiement d'une
                  indemnité forfaitaire de 40 euros due au titre des frais de
                  recouvrement (Art. 441-6, I al. 12 du code de commerce et D.
                  441-5 ibidem).
                </Text>
                <Text style={{ fontSize: 10, textAlign: "center" }}>
                  LBR TRAVEL - 64 Rue Hoche 91700 Sainte-Geneviève-des-Bois -
                  Email : compta@lbrtravel.com - Code NAF (APE) 5811Z - SASU au
                  capital social de 1000 € - Siret : 92489454600010 – TVA
                  Intracommunautaire : FR08924894546
                </Text>
              </View>
            </View>
          )}
        </Page>
      </Document>
    </PDFViewer>
  );
};
