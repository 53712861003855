import { FC, useMemo, useState } from "react";
import { format } from "date-fns";
import styled from "styled-components";

import { Card } from "src/components/Card";
import { UpdateStepButtons } from "../../UpdateStepButtons";
import { ExtraTravelForm } from "./ExtraTravelForm";
import { DisplayExtraTravels } from "./DisplayExtraTravels";
import { useTranslation } from "react-i18next";

import { useRequestStatus, RequestStatus } from "src/hooks/useRequestStatus";
import { useFilteredExtraTravels } from "src/hooks/useFilteredExtraTravels";
import { updateOrder } from "src/services/order";
import { sections } from "src/constants/extra-travel";

import { colors } from "src/theme/colors";

import type { CommonStepProps } from "../../EditContent";
import { ExtraTravelDraft } from "src/types/order";

interface ExtraTravelsProps extends CommonStepProps {}
export const ExtraTravels: FC<ExtraTravelsProps> = props => {
  const {
    order,
    currentStep,
    previousStepHandler,
    nextStepHandler,
    updateOrderState,
    goToRecapHandler,
  } = props;
  const { t } = useTranslation();

  const [status, setStatus] = useRequestStatus();
  const [extraTravels, setExtraTravels] = useState<ExtraTravelDraft[]>(
    order.extraTravels || [],
  );
  const { mapKindToExtraTravels } = useFilteredExtraTravels(extraTravels);

  const addExtraTravel = (newExtraTravel: ExtraTravelDraft) => {
    setExtraTravels(prevTravels => [...prevTravels, newExtraTravel]);
  };

  const submitStepHandler = async (successCallback: () => void) => {
    if (status === RequestStatus.LOADING) {
      return;
    }

    setStatus(RequestStatus.LOADING);
    try {
      const res = await updateOrder(order.id, {
        extraTravels,
      });
      if (!res.data.success) {
        throw new Error();
      }
      successCallback();
      updateOrderState(res.data.order);
    } catch {
      setStatus(RequestStatus.SERVER_ERROR);
    }
  };

  const minDate = useMemo(() => {
    return format(new Date(order.startDate || new Date()), "yyyy-MM-dd");
  }, [order]);

  return (
    <Wrapper>
      {sections.map(section => {
        const SectionIcon = section.icon;
        return (
          <SectionWrapper key={section.kind}>
            <SectionTitle>
              <SectionIcon />
              <span>
                {t(`pages.orders.edit.extra_travel.${section.title}`)}
              </span>
            </SectionTitle>
            {mapKindToExtraTravels[section.kind].length > 0 && (
              <CardWrapper>
                <DisplayExtraTravels
                  kind={section.kind}
                  extraTravels={mapKindToExtraTravels[section.kind]}
                  deleteExtraTravelHandler={extraTravel =>
                    setExtraTravels(prevTravels =>
                      prevTravels.filter(travel => travel !== extraTravel),
                    )
                  }
                />
              </CardWrapper>
            )}
            <CardWrapper>
              <FormTitle>
                {t(`pages.orders.edit.extra_travel.${section.formTitle}`)}
              </FormTitle>
              <ExtraTravelForm
                kind={section.kind}
                labels={section.labels}
                minDate={minDate}
                addExtraTravel={addExtraTravel}
              />
            </CardWrapper>
          </SectionWrapper>
        );
      })}
      <UpdateStepButtons
        order={order}
        disableNext={false}
        currentStep={currentStep}
        loadingNext={status === RequestStatus.LOADING}
        nextButtonLabel={
          extraTravels.length === 0
            ? t(`pages.orders.edit.extra_travel.pass`)
            : t(`pages.orders.edit.extra_travel.next`)
        }
        previousHandler={previousStepHandler}
        nextHandler={() => submitStepHandler(nextStepHandler)}
        recapHandler={() => submitStepHandler(goToRecapHandler)}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const CardWrapper = styled(Card)`
  padding: 16px;

  & > *:not(:last-child) {
    margin-bottom: 24px;
  }
`;

const SectionWrapper = styled.div`
  margin-bottom: 24px;

  ${CardWrapper}:not(:last-child) {
    margin-bottom: 24px;
  }
`;

const SectionTitle = styled.h2`
  margin-top: 0;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: -0.015em;
  color: ${colors.turquoise[800]};

  & > svg {
    width: 16px;
    height: 16px;
    margin-right: 8px;
  }
`;

const FormTitle = styled.h3`
  margin-top: 0;
  margin-bottom: 24px;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: ${colors.main.black};
`;
