// prettier-ignore
export const countries = [
  { alpha2Code: "AF", alpha3Code: "AFG", name: "Afghanistan", nationality: "Afghan" },
  { alpha2Code: "AX", alpha3Code: "ALA", name: "\u00c5land Islands", nationality: "\u00c5land Island" },
  { alpha2Code: "AL", alpha3Code: "ALB", name: "Albania", nationality: "Albanian" },
  { alpha2Code: "DZ", alpha3Code: "DZA", name: "Algeria", nationality: "Algerian" },
  { alpha2Code: "AS", alpha3Code: "ASM", name: "American Samoa", nationality: "American Samoan" },
  { alpha2Code: "AD", alpha3Code: "AND", name: "Andorra", nationality: "Andorran" },
  { alpha2Code: "AO", alpha3Code: "AGO", name: "Angola", nationality: "Angolan" },
  { alpha2Code: "AI", alpha3Code: "AIA", name: "Anguilla", nationality: "Anguillan" },
  { alpha2Code: "AQ", alpha3Code: "ATA", name: "Antarctica", nationality: "Antarctic" },
  { alpha2Code: "AG", alpha3Code: "ATG", name: "Antigua and Barbuda", nationality: "Antiguan or Barbudan" },
  { alpha2Code: "AR", alpha3Code: "ARG", name: "Argentina", nationality: "Argentine" },
  { alpha2Code: "AM", alpha3Code: "ARM", name: "Armenia", nationality: "Armenian" },
  { alpha2Code: "AW", alpha3Code: "ABW", name: "Aruba", nationality: "Aruban" },
  { alpha2Code: "AU", alpha3Code: "AUS", name: "Australia", nationality: "Australian" },
  { alpha2Code: "AT", alpha3Code: "AUT", name: "Austria", nationality: "Austrian" },
  { alpha2Code: "AZ", alpha3Code: "AZE", name: "Azerbaijan", nationality: "Azerbaijani, Azeri" },
  { alpha2Code: "BS", alpha3Code: "BHS", name: "Bahamas", nationality: "Bahamian" },
  { alpha2Code: "BH", alpha3Code: "BHR", name: "Bahrain", nationality: "Bahraini" },
  { alpha2Code: "BD", alpha3Code: "BGD", name: "Bangladesh", nationality: "Bangladeshi" },
  { alpha2Code: "BB", alpha3Code: "BRB", name: "Barbados", nationality: "Barbadian" },
  { alpha2Code: "BY", alpha3Code: "BLR", name: "Belarus", nationality: "Belarusian" },
  { alpha2Code: "BE", alpha3Code: "BEL", name: "Belgium", nationality: "Belgian" },
  { alpha2Code: "BZ", alpha3Code: "BLZ", name: "Belize", nationality: "Belizean" },
  { alpha2Code: "BJ", alpha3Code: "BEN", name: "Benin", nationality: "Beninese, Beninois" },
  { alpha2Code: "BM", alpha3Code: "BMU", name: "Bermuda", nationality: "Bermudian, Bermudan" },
  { alpha2Code: "BT", alpha3Code: "BTN", name: "Bhutan", nationality: "Bhutanese" },
  { alpha2Code: "BO", alpha3Code: "BOL", name: "Bolivia (Plurinational State of)", nationality: "Bolivian" },
  { alpha2Code: "BQ", alpha3Code: "BES", name: "Bonaire, Sint Eustatius and Saba", nationality: "Bonaire" },
  { alpha2Code: "BA", alpha3Code: "BIH", name: "Bosnia and Herzegovina", nationality: "Bosnian or Herzegovinian" },
  { alpha2Code: "BW", alpha3Code: "BWA", name: "Botswana", nationality: "Motswana, Botswanan" },
  { alpha2Code: "BV", alpha3Code: "BVT", name: "Bouvet Island", nationality: "Bouvet Island" },
  { alpha2Code: "BR", alpha3Code: "BRA", name: "Brazil", nationality: "Brazilian" },
  { alpha2Code: "IO", alpha3Code: "IOT", name: "British Indian Ocean Territory", nationality: "BIOT" },
  { alpha2Code: "BN", alpha3Code: "BRN", name: "Brunei Darussalam", nationality: "Bruneian" },
  { alpha2Code: "BG", alpha3Code: "BGR", name: "Bulgaria", nationality: "Bulgarian" },
  { alpha2Code: "BF", alpha3Code: "BFA", name: "Burkina Faso", nationality: "Burkinab\u00e9" },
  { alpha2Code: "BI", alpha3Code: "BDI", name: "Burundi", nationality: "Burundian" },
  { alpha2Code: "CV", alpha3Code: "CPV", name: "Cabo Verde", nationality: "Cabo Verdean" },
  { alpha2Code: "KH", alpha3Code: "KHM", name: "Cambodia", nationality: "Cambodian" },
  { alpha2Code: "CM", alpha3Code: "CMR", name: "Cameroon", nationality: "Cameroonian" },
  { alpha2Code: "CA", alpha3Code: "CAN", name: "Canada", nationality: "Canadian" },
  { alpha2Code: "KY", alpha3Code: "CYM", name: "Cayman Islands", nationality: "Caymanian" },
  { alpha2Code: "CF", alpha3Code: "CAF", name: "Central African Republic", nationality: "Central African" },
  { alpha2Code: "TD", alpha3Code: "TCD", name: "Chad", nationality: "Chadian" },
  { alpha2Code: "CL", alpha3Code: "CHL", name: "Chile", nationality: "Chilean" },
  { alpha2Code: "CN", alpha3Code: "CHN", name: "China", nationality: "Chinese" },
  { alpha2Code: "CX", alpha3Code: "CXR", name: "Christmas Island", nationality: "Christmas Island" },
  { alpha2Code: "CC", alpha3Code: "CCK", name: "Cocos (Keeling) Islands", nationality: "Cocos Island" },
  { alpha2Code: "CO", alpha3Code: "COL", name: "Colombia", nationality: "Colombian" },
  { alpha2Code: "KM", alpha3Code: "COM", name: "Comoros", nationality: "Comoran, Comorian" },
  { alpha2Code: "CG", alpha3Code: "COG", name: "Congo (Republic of the)", nationality: "Congolese" },
  { alpha2Code: "CD", alpha3Code: "COD", name: "Congo (Democratic Republic of the)", nationality: "Congolese" },
  { alpha2Code: "CK", alpha3Code: "COK", name: "Cook Islands", nationality: "Cook Island" },
  { alpha2Code: "CR", alpha3Code: "CRI", name: "Costa Rica", nationality: "Costa Rican" },
  { alpha2Code: "CI", alpha3Code: "CIV", name: "C\u00f4te d'Ivoire", nationality: "Ivorian" },
  { alpha2Code: "HR", alpha3Code: "HRV", name: "Croatia", nationality: "Croatian" },
  { alpha2Code: "CU", alpha3Code: "CUB", name: "Cuba", nationality: "Cuban" },
  { alpha2Code: "CW", alpha3Code: "CUW", name: "Cura\u00e7ao", nationality: "Cura\u00e7aoan" },
  { alpha2Code: "CY", alpha3Code: "CYP", name: "Cyprus", nationality: "Cypriot" },
  { alpha2Code: "CZ", alpha3Code: "CZE", name: "Czech Republic", nationality: "Czech" },
  { alpha2Code: "DK", alpha3Code: "DNK", name: "Denmark", nationality: "Danish" },
  { alpha2Code: "DJ", alpha3Code: "DJI", name: "Djibouti", nationality: "Djiboutian" },
  { alpha2Code: "DM", alpha3Code: "DMA", name: "Dominica", nationality: "Dominican" },
  { alpha2Code: "DO", alpha3Code: "DOM", name: "Dominican Republic", nationality: "Dominican" },
  { alpha2Code: "EC", alpha3Code: "ECU", name: "Ecuador", nationality: "Ecuadorian" },
  { alpha2Code: "EG", alpha3Code: "EGY", name: "Egypt", nationality: "Egyptian" },
  { alpha2Code: "SV", alpha3Code: "SLV", name: "El Salvador", nationality: "Salvadoran" },
  { alpha2Code: "GQ", alpha3Code: "GNQ", name: "Equatorial Guinea", nationality: "Equatorial Guinean, Equatoguinean" },
  { alpha2Code: "XK", alpha3Code: "XXK", name: "Republic of Kosovo", nationality: "Kosovar" },
  { alpha2Code: "ER", alpha3Code: "ERI", name: "Eritrea", nationality: "Eritrean" },
  { alpha2Code: "EE", alpha3Code: "EST", name: "Estonia", nationality: "Estonian" },
  { alpha2Code: "ET", alpha3Code: "ETH", name: "Ethiopia", nationality: "Ethiopian" },
  { alpha2Code: "FK", alpha3Code: "FLK", name: "Falkland Islands (Malvinas)", nationality: "Falkland Island" },
  { alpha2Code: "FO", alpha3Code: "FRO", name: "Faroe Islands", nationality: "Faroese" },
  { alpha2Code: "FJ", alpha3Code: "FJI", name: "Fiji", nationality: "Fijian" },
  { alpha2Code: "FI", alpha3Code: "FIN", name: "Finland", nationality: "Finnish" },
  { alpha2Code: "FR", alpha3Code: "FRA", name: "France", nationality: "French" },
  { alpha2Code: "GF", alpha3Code: "GUF", name: "French Guiana", nationality: "French Guianese" },
  { alpha2Code: "PF", alpha3Code: "PYF", name: "French Polynesia", nationality: "French Polynesian" },
  { alpha2Code: "TF", alpha3Code: "ATF", name: "French Southern Territories", nationality: "French Southern Territories" },
  { alpha2Code: "GA", alpha3Code: "GAB", name: "Gabon", nationality: "Gabonese" },
  { alpha2Code: "GM", alpha3Code: "GMB", name: "Gambia", nationality: "Gambian" },
  { alpha2Code: "GE", alpha3Code: "GEO", name: "Georgia", nationality: "Georgian" },
  { alpha2Code: "DE", alpha3Code: "DEU", name: "Germany", nationality: "German" },
  { alpha2Code: "GH", alpha3Code: "GHA", name: "Ghana", nationality: "Ghanaian" },
  { alpha2Code: "GI", alpha3Code: "GIB", name: "Gibraltar", nationality: "Gibraltar" },
  { alpha2Code: "GR", alpha3Code: "GRC", name: "Greece", nationality: "Greek" },
  { alpha2Code: "GL", alpha3Code: "GRL", name: "Greenland", nationality: "Greenlandic" },
  { alpha2Code: "GD", alpha3Code: "GRD", name: "Grenada", nationality: "Grenadian" },
  { alpha2Code: "GP", alpha3Code: "GLP", name: "Guadeloupe", nationality: "Guadeloupe" },
  { alpha2Code: "GU", alpha3Code: "GUM", name: "Guam", nationality: "Guamanian, Guambat" },
  { alpha2Code: "GT", alpha3Code: "GTM", name: "Guatemala", nationality: "Guatemalan" },
  { alpha2Code: "GG", alpha3Code: "GGY", name: "Guernsey", nationality: "Channel Island" },
  { alpha2Code: "GN", alpha3Code: "GIN", name: "Guinea", nationality: "Guinean" },
  { alpha2Code: "GW", alpha3Code: "GNB", name: "Guinea-Bissau", nationality: "Bissau-Guinean" },
  { alpha2Code: "GY", alpha3Code: "GUY", name: "Guyana", nationality: "Guyanese" },
  { alpha2Code: "HT", alpha3Code: "HTI", name: "Haiti", nationality: "Haitian" },
  { alpha2Code: "HM", alpha3Code: "HMD", name: "Heard Island and McDonald Islands", nationality: "Heard Island or McDonald Islands" },
  { alpha2Code: "VA", alpha3Code: "VAT", name: "Vatican City State", nationality: "Vatican" },
  { alpha2Code: "HN", alpha3Code: "HND", name: "Honduras", nationality: "Honduran" },
  { alpha2Code: "HK", alpha3Code: "HKG", name: "Hong Kong", nationality: "Hong Kong, Hong Kongese" },
  { alpha2Code: "HU", alpha3Code: "HUN", name: "Hungary", nationality: "Hungarian, Magyar" },
  { alpha2Code: "IS", alpha3Code: "ISL", name: "Iceland", nationality: "Icelandic" },
  { alpha2Code: "IN", alpha3Code: "IND", name: "India", nationality: "Indian" },
  { alpha2Code: "ID", alpha3Code: "IDN", name: "Indonesia", nationality: "Indonesian" },
  { alpha2Code: "IR", alpha3Code: "IRN", name: "Iran", nationality: "Iranian" },
  { alpha2Code: "IQ", alpha3Code: "IRQ", name: "Iraq", nationality: "Iraqi" },
  { alpha2Code: "IE", alpha3Code: "IRL", name: "Ireland", nationality: "Irish" },
  { alpha2Code: "IM", alpha3Code: "IMN", name: "Isle of Man", nationality: "Manx" },
  { alpha2Code: "IL", alpha3Code: "ISR", name: "Israel", nationality: "Israeli" },
  { alpha2Code: "IT", alpha3Code: "ITA", name: "Italy", nationality: "Italian" },
  { alpha2Code: "JM", alpha3Code: "JAM", name: "Jamaica", nationality: "Jamaican" },
  { alpha2Code: "JP", alpha3Code: "JPN", name: "Japan", nationality: "Japanese" },
  { alpha2Code: "JE", alpha3Code: "JEY", name: "Jersey", nationality: "Channel Island" },
  { alpha2Code: "JO", alpha3Code: "JOR", name: "Jordan", nationality: "Jordanian" },
  { alpha2Code: "KZ", alpha3Code: "KAZ", name: "Kazakhstan", nationality: "Kazakhstani, Kazakh" },
  { alpha2Code: "KE", alpha3Code: "KEN", name: "Kenya", nationality: "Kenyan" },
  { alpha2Code: "KI", alpha3Code: "KIR", name: "Kiribati", nationality: "I-Kiribati" },
  { alpha2Code: "KP", alpha3Code: "PRK", name: "Korea (Democratic People's Republic of)", nationality: "North Korean" },
  { alpha2Code: "KR", alpha3Code: "KOR", name: "Korea (Republic of)", nationality: "South Korean" },
  { alpha2Code: "KW", alpha3Code: "KWT", name: "Kuwait", nationality: "Kuwaiti" },
  { alpha2Code: "KG", alpha3Code: "KGZ", name: "Kyrgyzstan", nationality: "Kyrgyzstani, Kyrgyz, Kirgiz, Kirghiz" },
  { alpha2Code: "LA", alpha3Code: "LAO", name: "Lao People's Democratic Republic", nationality: "Lao, Laotian" },
  { alpha2Code: "LV", alpha3Code: "LVA", name: "Latvia", nationality: "Latvian" },
  { alpha2Code: "LB", alpha3Code: "LBN", name: "Lebanon", nationality: "Lebanese" },
  { alpha2Code: "LS", alpha3Code: "LSO", name: "Lesotho", nationality: "Basotho" },
  { alpha2Code: "LR", alpha3Code: "LBR", name: "Liberia", nationality: "Liberian" },
  { alpha2Code: "LY", alpha3Code: "LBY", name: "Libya", nationality: "Libyan" },
  { alpha2Code: "LI", alpha3Code: "LIE", name: "Liechtenstein", nationality: "Liechtenstein" },
  { alpha2Code: "LT", alpha3Code: "LTU", name: "Lithuania", nationality: "Lithuanian" },
  { alpha2Code: "LU", alpha3Code: "LUX", name: "Luxembourg", nationality: "Luxembourg, Luxembourgish" },
  { alpha2Code: "MO", alpha3Code: "MAC", name: "Macao", nationality: "Macanese, Chinese" },
  { alpha2Code: "MK", alpha3Code: "MKD", name: "Macedonia (the former Yugoslav Republic of)", nationality: "Macedonian" },
  { alpha2Code: "MG", alpha3Code: "MDG", name: "Madagascar", nationality: "Malagasy" },
  { alpha2Code: "MW", alpha3Code: "MWI", name: "Malawi", nationality: "Malawian" },
  { alpha2Code: "MY", alpha3Code: "MYS", name: "Malaysia", nationality: "Malaysian" },
  { alpha2Code: "MV", alpha3Code: "MDV", name: "Maldives", nationality: "Maldivian" },
  { alpha2Code: "ML", alpha3Code: "MLI", name: "Mali", nationality: "Malian, Malinese" },
  { alpha2Code: "MT", alpha3Code: "MLT", name: "Malta", nationality: "Maltese" },
  { alpha2Code: "MH", alpha3Code: "MHL", name: "Marshall Islands", nationality: "Marshallese" },
  { alpha2Code: "MQ", alpha3Code: "MTQ", name: "Martinique", nationality: "Martiniquais, Martinican" },
  { alpha2Code: "MR", alpha3Code: "MRT", name: "Mauritania", nationality: "Mauritanian" },
  { alpha2Code: "MU", alpha3Code: "MUS", name: "Mauritius", nationality: "Mauritian" },
  { alpha2Code: "YT", alpha3Code: "MYT", name: "Mayotte", nationality: "Mahoran" },
  { alpha2Code: "MX", alpha3Code: "MEX", name: "Mexico", nationality: "Mexican" },
  { alpha2Code: "FM", alpha3Code: "FSM", name: "Micronesia (Federated States of)", nationality: "Micronesian" },
  { alpha2Code: "MD", alpha3Code: "MDA", name: "Moldova (Republic of)", nationality: "Moldovan" },
  { alpha2Code: "MC", alpha3Code: "MCO", name: "Monaco", nationality: "Mon\u00e9gasque, Monacan" },
  { alpha2Code: "MN", alpha3Code: "MNG", name: "Mongolia", nationality: "Mongolian" },
  { alpha2Code: "ME", alpha3Code: "MNE", name: "Montenegro", nationality: "Montenegrin" },
  { alpha2Code: "MS", alpha3Code: "MSR", name: "Montserrat", nationality: "Montserratian" },
  { alpha2Code: "MA", alpha3Code: "MAR", name: "Morocco", nationality: "Moroccan" },
  { alpha2Code: "MZ", alpha3Code: "MOZ", name: "Mozambique", nationality: "Mozambican" },
  { alpha2Code: "MM", alpha3Code: "MMR", name: "Myanmar", nationality: "Burmese" },
  { alpha2Code: "NA", alpha3Code: "NAM", name: "Namibia", nationality: "Namibian" },
  { alpha2Code: "NR", alpha3Code: "NRU", name: "Nauru", nationality: "Nauruan" },
  { alpha2Code: "NP", alpha3Code: "NPL", name: "Nepal", nationality: "Nepali, Nepalese" },
  { alpha2Code: "NL", alpha3Code: "NLD", name: "Netherlands", nationality: "Dutch, Netherlandic" },
  { alpha2Code: "NC", alpha3Code: "NCL", name: "New Caledonia", nationality: "New Caledonian" },
  { alpha2Code: "NZ", alpha3Code: "NZL", name: "New Zealand", nationality: "New Zealand, NZ" },
  { alpha2Code: "NI", alpha3Code: "NIC", name: "Nicaragua", nationality: "Nicaraguan" },
  { alpha2Code: "NE", alpha3Code: "NER", name: "Niger", nationality: "Nigerien" },
  { alpha2Code: "NG", alpha3Code: "NGA", name: "Nigeria", nationality: "Nigerian" },
  { alpha2Code: "NU", alpha3Code: "NIU", name: "Niue", nationality: "Niuean" },
  { alpha2Code: "NF", alpha3Code: "NFK", name: "Norfolk Island", nationality: "Norfolk Island" },
  { alpha2Code: "MP", alpha3Code: "MNP", name: "Northern Mariana Islands", nationality: "Northern Marianan" },
  { alpha2Code: "NO", alpha3Code: "NOR", name: "Norway", nationality: "Norwegian" },
  { alpha2Code: "OM", alpha3Code: "OMN", name: "Oman", nationality: "Omani" },
  { alpha2Code: "PK", alpha3Code: "PAK", name: "Pakistan", nationality: "Pakistani" },
  { alpha2Code: "PW", alpha3Code: "PLW", name: "Palau", nationality: "Palauan" },
  { alpha2Code: "PS", alpha3Code: "PSE", name: "Palestine, State of", nationality: "Palestinian" },
  { alpha2Code: "PA", alpha3Code: "PAN", name: "Panama", nationality: "Panamanian" },
  { alpha2Code: "PG", alpha3Code: "PNG", name: "Papua New Guinea", nationality: "Papua New Guinean, Papuan" },
  { alpha2Code: "PY", alpha3Code: "PRY", name: "Paraguay", nationality: "Paraguayan" },
  { alpha2Code: "PE", alpha3Code: "PER", name: "Peru", nationality: "Peruvian" },
  { alpha2Code: "PH", alpha3Code: "PHL", name: "Philippines", nationality: "Philippine, Filipino" },
  { alpha2Code: "PN", alpha3Code: "PCN", name: "Pitcairn", nationality: "Pitcairn Island" },
  { alpha2Code: "PL", alpha3Code: "POL", name: "Poland", nationality: "Polish" },
  { alpha2Code: "PT", alpha3Code: "PRT", name: "Portugal", nationality: "Portuguese" },
  { alpha2Code: "PR", alpha3Code: "PRI", name: "Puerto Rico", nationality: "Puerto Rican" },
  { alpha2Code: "QA", alpha3Code: "QAT", name: "Qatar", nationality: "Qatari" },
  { alpha2Code: "RE", alpha3Code: "REU", name: "R\u00e9union", nationality: "R\u00e9unionese, R\u00e9unionnais" },
  { alpha2Code: "RO", alpha3Code: "ROU", name: "Romania", nationality: "Romanian" },
  { alpha2Code: "RU", alpha3Code: "RUS", name: "Russian Federation", nationality: "Russian" },
  { alpha2Code: "RW", alpha3Code: "RWA", name: "Rwanda", nationality: "Rwandan" },
  { alpha2Code: "BL", alpha3Code: "BLM", name: "Saint Barth\u00e9lemy", nationality: "Barth\u00e9lemois" },
  { alpha2Code: "SH", alpha3Code: "SHN", name: "Saint Helena, Ascension and Tristan da Cunha", nationality: "Saint Helenian" },
  { alpha2Code: "KN", alpha3Code: "KNA", name: "Saint Kitts and Nevis", nationality: "Kittitian or Nevisian" },
  { alpha2Code: "LC", alpha3Code: "LCA", name: "Saint Lucia", nationality: "Saint Lucian" },
  { alpha2Code: "MF", alpha3Code: "MAF", name: "Saint Martin (French part)", nationality: "Saint-Martinoise" },
  { alpha2Code: "PM", alpha3Code: "SPM", name: "Saint Pierre and Miquelon", nationality: "Saint-Pierrais or Miquelonnais" },
  { alpha2Code: "VC", alpha3Code: "VCT", name: "Saint Vincent and the Grenadines", nationality: "Saint Vincentian, Vincentian" },
  { alpha2Code: "WS", alpha3Code: "WSM", name: "Samoa", nationality: "Samoan" },
  { alpha2Code: "SM", alpha3Code: "SMR", name: "San Marino", nationality: "Sammarinese" },
  { alpha2Code: "ST", alpha3Code: "STP", name: "Sao Tome and Principe", nationality: "S\u00e3o Tom\u00e9an" },
  { alpha2Code: "SA", alpha3Code: "SAU", name: "Saudi Arabia", nationality: "Saudi, Saudi Arabian" },
  { alpha2Code: "SN", alpha3Code: "SEN", name: "Senegal", nationality: "Senegalese" },
  { alpha2Code: "RS", alpha3Code: "SRB", name: "Serbia", nationality: "Serbian" },
  { alpha2Code: "SC", alpha3Code: "SYC", name: "Seychelles", nationality: "Seychellois" },
  { alpha2Code: "SL", alpha3Code: "SLE", name: "Sierra Leone", nationality: "Sierra Leonean" },
  { alpha2Code: "SG", alpha3Code: "SGP", name: "Singapore", nationality: "Singaporean" },
  { alpha2Code: "SX", alpha3Code: "SXM", name: "Sint Maarten (Dutch part)", nationality: "Sint Maarten" },
  { alpha2Code: "SK", alpha3Code: "SVK", name: "Slovakia", nationality: "Slovak" },
  { alpha2Code: "SI", alpha3Code: "SVN", name: "Slovenia", nationality: "Slovenian, Slovene" },
  { alpha2Code: "SB", alpha3Code: "SLB", name: "Solomon Islands", nationality: "Solomon Island" },
  { alpha2Code: "SO", alpha3Code: "SOM", name: "Somalia", nationality: "Somali, Somalian" },
  { alpha2Code: "ZA", alpha3Code: "ZAF", name: "South Africa", nationality: "South African" },
  {
    alpha2Code: "GS",
    alpha3Code: "SGS",
    name: "South Georgia and the South Sandwich Islands",
    nationality: "South Georgia or South Sandwich Islands",
  },
  { alpha2Code: "SS", alpha3Code: "SSD", name: "South Sudan", nationality: "South Sudanese" },
  { alpha2Code: "ES", alpha3Code: "ESP", name: "Spain", nationality: "Spanish" },
  { alpha2Code: "LK", alpha3Code: "LKA", name: "Sri Lanka", nationality: "Sri Lankan" },
  { alpha2Code: "SD", alpha3Code: "SDN", name: "Sudan", nationality: "Sudanese" },
  { alpha2Code: "SR", alpha3Code: "SUR", name: "Suriname", nationality: "Surinamese" },
  { alpha2Code: "SJ", alpha3Code: "SJM", name: "Svalbard and Jan Mayen", nationality: "Svalbard" },
  { alpha2Code: "SZ", alpha3Code: "SWZ", name: "Swaziland", nationality: "Swazi" },
  { alpha2Code: "SE", alpha3Code: "SWE", name: "Sweden", nationality: "Swedish" },
  { alpha2Code: "CH", alpha3Code: "CHE", name: "Switzerland", nationality: "Swiss" },
  { alpha2Code: "SY", alpha3Code: "SYR", name: "Syrian Arab Republic", nationality: "Syrian" },
  { alpha2Code: "TW", alpha3Code: "TWN", name: "Taiwan, Province of China", nationality: "Chinese, Taiwanese" },
  { alpha2Code: "TJ", alpha3Code: "TJK", name: "Tajikistan", nationality: "Tajikistani" },
  { alpha2Code: "TZ", alpha3Code: "TZA", name: "Tanzania, United Republic of", nationality: "Tanzanian" },
  { alpha2Code: "TH", alpha3Code: "THA", name: "Thailand", nationality: "Thai" },
  { alpha2Code: "TL", alpha3Code: "TLS", name: "Timor-Leste", nationality: "Timorese" },
  { alpha2Code: "TG", alpha3Code: "TGO", name: "Togo", nationality: "Togolese" },
  { alpha2Code: "TK", alpha3Code: "TKL", name: "Tokelau", nationality: "Tokelauan" },
  { alpha2Code: "TO", alpha3Code: "TON", name: "Tonga", nationality: "Tongan" },
  { alpha2Code: "TT", alpha3Code: "TTO", name: "Trinidad and Tobago", nationality: "Trinidadian or Tobagonian" },
  { alpha2Code: "TN", alpha3Code: "TUN", name: "Tunisia", nationality: "Tunisian" },
  { alpha2Code: "TR", alpha3Code: "TUR", name: "Turkey", nationality: "Turkish" },
  { alpha2Code: "TM", alpha3Code: "TKM", name: "Turkmenistan", nationality: "Turkmen" },
  { alpha2Code: "TC", alpha3Code: "TCA", name: "Turks and Caicos Islands", nationality: "Turks and Caicos Island" },
  { alpha2Code: "TV", alpha3Code: "TUV", name: "Tuvalu", nationality: "Tuvaluan" },
  { alpha2Code: "UG", alpha3Code: "UGA", name: "Uganda", nationality: "Ugandan" },
  { alpha2Code: "UA", alpha3Code: "UKR", name: "Ukraine", nationality: "Ukrainian" },
  { alpha2Code: "AE", alpha3Code: "ARE", name: "United Arab Emirates", nationality: "Emirati, Emirian, Emiri" },
  { alpha2Code: "GB", alpha3Code: "GBR", name: "United Kingdom", nationality: "British" },
  { alpha2Code: "UM", alpha3Code: "UMI", name: "United States Minor Outlying Islands", nationality: "American" },
  { alpha2Code: "US", alpha3Code: "USA", name: "United States of America", nationality: "American" },
  { alpha2Code: "UY", alpha3Code: "URY", name: "Uruguay", nationality: "Uruguayan" },
  { alpha2Code: "UZ", alpha3Code: "UZB", name: "Uzbekistan", nationality: "Uzbekistani, Uzbek" },
  { alpha2Code: "VU", alpha3Code: "VUT", name: "Vanuatu", nationality: "Ni-Vanuatu, Vanuatuan" },
  { alpha2Code: "VE", alpha3Code: "VEN", name: "Venezuela (Bolivarian Republic of)", nationality: "Venezuelan" },
  { alpha2Code: "VN", alpha3Code: "VNM", name: "Vietnam", nationality: "Vietnamese" },
  { alpha2Code: "VG", alpha3Code: "VGB", name: "Virgin Islands (British)", nationality: "British Virgin Island" },
  { alpha2Code: "VI", alpha3Code: "VIR", name: "Virgin Islands (U.S.)", nationality: "U.S. Virgin Island" },
  { alpha2Code: "WF", alpha3Code: "WLF", name: "Wallis and Futuna", nationality: "Wallis and Futuna, Wallisian or Futunan" },
  { alpha2Code: "EH", alpha3Code: "ESH", name: "Western Sahara", nationality: "Sahrawi, Sahrawian, Sahraouian" },
  { alpha2Code: "YE", alpha3Code: "YEM", name: "Yemen", nationality: "Yemeni" },
  { alpha2Code: "ZM", alpha3Code: "ZMB", name: "Zambia", nationality: "Zambian" },
  { alpha2Code: "ZW", alpha3Code: "ZWE", name: "Zimbabwe", nationality: "Zimbabwean" },
];

export const languages = [
  { alpha2Code: "CH", alpha3Code: "CHE", name: "Allemand (Suisse)" },
  { alpha2Code: "FR", alpha3Code: "FRA", name: "Français" },
  { alpha2Code: "GE", alpha3Code: "GER", name: "Allemand" },
  { alpha2Code: "EN", alpha3Code: "ENG", name: "Anglais" },
];

export const countryOptions = countries.map(country => ({
  label: country.name,
  value: country.alpha3Code,
}));

export const languagesOptions = languages
  .map(language => ({
    label: language.name,
    value: language.alpha2Code,
  }))
  .sort((a, b) => {
    const nameA = a.label.toUpperCase();
    const nameB = b.label.toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });

export const mapCountryAlpha3CodeToName = countries.reduce<{
  [key: string]: string;
}>(
  (acc, curr) => ({
    ...acc,
    [curr.alpha3Code]: curr.name,
  }),
  {},
);
