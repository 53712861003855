import { FC } from "react";
import styled from "styled-components";

import { SaveableTextInput, SaveableTextInputProps } from "./SaveableTextInput";

import { colors } from "src/theme/colors";

interface LabeledSaveableTextInputProps extends SaveableTextInputProps {
  label: string;
}
export const LabeledSaveableTextInput: FC<
  LabeledSaveableTextInputProps
> = props => {
  const { label, hasError = false, ...inputProps } = props;
  return (
    <Wrapper>
      <Label hasError={hasError}>{label}</Label>
      <SaveableTextInput {...inputProps} hasError={hasError} />
    </Wrapper>
  );
};

const Wrapper = styled.label`
  display: block;
`;

type LabelStyleProps = {
  hasError: boolean;
};
const Label = styled.p<LabelStyleProps>`
  margin-top: 0;
  margin-bottom: 4px;

  color: ${({ hasError }) => (hasError ? colors.error[300] : colors.grey[600])};
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
`;
