import { FC, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { Modal } from "src/components/Modal";
import { Button } from "src/components/form/Button";
import { Alert } from "src/components/Alert";

import { useHistory } from "react-router";
import { api } from "src/services/api";

import { Title } from "src/styles/styled-components-library";

import { ReactComponent as AddUsersIcon } from "src/assets/svg-icons/multiple-users-add.svg";

import { OrderType } from "src/types/order";
import type { Order } from "src/models/order";

enum SubmitStatus {
  IDLE = "IDLE",
  LOADING = "LOADING",
  SERVER_ERROR = "SERVER_ERROR",
}

interface CreateDraftModalProps {
  showModal: boolean;
  closeModal: () => void;
}
export const CreateDraftOrderModal: FC<CreateDraftModalProps> = props => {
  const { t } = useTranslation();

  const history = useHistory();
  const [submitStatus, setSubmitStatus] = useState(SubmitStatus.IDLE);

  const createDraftOrderHandler = async (type: OrderType) => {
    if (submitStatus === SubmitStatus.LOADING) {
      return;
    }
    setSubmitStatus(SubmitStatus.LOADING);

    try {
      const res = await api.post<{ success: boolean; order: Order }>(
        "/orders",
        { type },
      );
      if (!res.data.success) {
        throw new Error();
      }
      history.push(`/orders/${res.data.order.id}/edit`);
    } catch {
      setSubmitStatus(SubmitStatus.SERVER_ERROR);
    }
  };

  return (
    <Modal {...props} width="650px">
      <ContentWrapper>
        <TitleWrapper>
          <AddUsersIcon />
          <Title>{t("pages.orders.order.draft.title")}</Title>
        </TitleWrapper>
        <OptionWrapper>
          <Button onClick={() => createDraftOrderHandler(OrderType.SIMPLE)}>
            {t("pages.orders.order.draft.one")}
          </Button>
          <Alert variant="info" transparent={false}>
            {t("pages.orders.order.draft.one_description")}
          </Alert>
        </OptionWrapper>
        <OptionWrapper>
          <Button onClick={() => createDraftOrderHandler(OrderType.MULTIPLE)}>
            {t("pages.orders.order.draft.many")}
          </Button>
          <Alert variant="info" transparent={false}>
            {t("pages.orders.order.draft.many_description")}
          </Alert>
        </OptionWrapper>
        {submitStatus === SubmitStatus.SERVER_ERROR && (
          <Alert variant="error" transparent={false}>
            {t("pages.orders.order.draft.server_error")}
          </Alert>
        )}
      </ContentWrapper>
    </Modal>
  );
};

const ContentWrapper = styled.div`
  & > *:not(:last-child) {
    margin-bottom: 40px;
  }
`;

const TitleWrapper = styled.div`
  width: 90%;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;

  & > svg {
    margin-bottom: 16px;
  }
`;

const OptionWrapper = styled.div`
  & > *:not(:last-child) {
    margin-bottom: 12px;
  }
`;
