import { useState } from "react";
import { colors } from "src/theme/colors";
import styled from "styled-components";
import { TextInput } from "./TextInput";
import { Chip } from "@mui/material";

export type TextArrayInputProps = {
  values: string[];
  onDelete: (index: number) => void;
  onSubmit: (text: string) => void;
  placeholder?: string;
  sugestValues?: string[];
  deleteZoomTag?: boolean;
  title: string;
};

export const TextArrayInput = ({
  values,
  onDelete,
  onSubmit,
  placeholder,
  sugestValues,
  deleteZoomTag,
  title,
}: TextArrayInputProps) => {
  const [text, setText] = useState("");

  const suggestValuesFiltered = sugestValues?.filter(value => {
    return !values.includes(value);
  });

  if (deleteZoomTag) {
    values = values?.filter(value => {
      return value !== "1" && value !== "2" && value !== "3";
    });
  }

  return (
    <Container>
      <FakeInput>
        {values.map((val, index) => {
          return (
            <Chip
              key={index}
              label={val}
              style={{ margin: "5px" }}
              onDelete={() => {
                onDelete(index);
              }}
            />
          );
        })}
      </FakeInput>

      <InputForm
        onSubmit={e => {
          e.preventDefault();
          onSubmit(text);
          setText("");
        }}
      >
        <TextInput
          name={"test"}
          type={"text"}
          placeholder={placeholder}
          value={text}
          onChange={e => {
            setText(e.target.value);
          }}
        />
      </InputForm>

      {sugestValues ? (
        <>
          <Label>{title}</Label>
          <SugestInput>
            {suggestValuesFiltered &&
              suggestValuesFiltered.map((val, index) => {
                return (
                  <Chip
                    key={index}
                    label={val}
                    onClick={() => {
                      onSubmit(val);
                    }}
                    style={{ margin: "5px" }}
                  />
                );
              })}
          </SugestInput>
        </>
      ) : null}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;

  & > *:first-child {
    width: 100%;
    margin-bottom: 10px;
  }
`;

const FakeInput = styled.div`
  width: 100%;
  padding: 16px;
  border-radius: 4px;
  color: ${colors.main.black};
  background-color: ${colors.background[100]};
  border: 1px solid rgba(34, 34, 34, 0.05);
  outline: none;
  transition: all 200ms ease;
  cursor: text;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
`;

const SugestInput = styled.div`
  width: 100%;
  padding: 16px;
  border-radius: 4px;
  color: ${colors.main.black};
  background-color: ${colors.background[100]};
  border: 1px solid rgba(34, 34, 34, 0.05);
  outline: none;
  transition: all 200ms ease;
  cursor: text;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 24px;
`;

const InputForm = styled.form`
  margin-bottom: 24px;
`;

const Label = styled.p`
  margin-top: 0;
  margin-bottom: 4px;

  color: ${colors.grey[600]};
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
`;
