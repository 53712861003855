import { TableInstance } from "react-table";
import styled from "styled-components";

import { HeaderCell } from "./HeaderCell";

import { colors } from "src/theme/colors";

export function Table<T extends Record<string, unknown>>(props: {
  instance: TableInstance<T>;
  paginationActivated?: boolean; // pagination activated by default
  columnsWidths?: Array<StringPercentage>; // list of CSS percentage widths for each column
  rowClickHandler?: (genericEntity: T) => void;
}) {
  const {
    instance,
    paginationActivated = true,
    columnsWidths = [],
    rowClickHandler,
  } = props;
  const { headers, page, rows, prepareRow, getTableProps, getTableBodyProps } =
    instance;

  const rowsList = paginationActivated ? page : rows;
  const hasSelectorColumn = headers.some((column) => column.id === "_selector");
  return (
    <StyledTable {...getTableProps()}>
      <thead>
        <tr>
          {headers.map((column, index) => {
            // in case we have row selection enabled, we need to ignore the "width" prop of the selector column
            const isHeaderSelectorCell = column.id === "_selector";
            const cellWidth = !isHeaderSelectorCell
              ? columnsWidths[hasSelectorColumn ? index - 1 : index]
              : undefined;
            return (
              <HeaderCell key={column.id} column={column} width={cellWidth} />
            );
          })}
        </tr>
      </thead>
      <tbody {...getTableBodyProps()}>
        {rowsList.map((row, index) => {
          prepareRow(row);
          const isLastRow = rowsList.length === index + 1;
          return (
            <StyledTr
              {...row.getRowProps()}
              onClick={rowClickHandler && (() => rowClickHandler(row.original))}
              canRowClick={!!rowClickHandler}
              isRowSelected={row.isSelected}
              isLastRow={isLastRow}
            >
              {row.cells.map((cell) => (
                <StyledTd {...cell.getCellProps()}>
                  {cell.render("Cell")}
                </StyledTd>
              ))}
            </StyledTr>
          );
        })}
      </tbody>
    </StyledTable>
  );
}

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const StyledTd = styled.td`
  color: ${colors.main.black};
  font-weight: 500;
  font-size: 13px;
  padding: 12px 8px;

  transition: background-color 200ms ease;
`;

type StyledTrStyleProps = {
  canRowClick: boolean;
  isRowSelected: boolean;
  isLastRow: boolean;
  width?: StringPercentage;
};
const StyledTr = styled.tr<StyledTrStyleProps>`
  width: ${({ width }) => width ?? "auto"};
  cursor: ${({ canRowClick }) => (canRowClick ? "pointer" : "inherit")};

  & ${StyledTd} {
    background-color: ${({ isRowSelected }) =>
      isRowSelected ? colors.turquoise[100] : "transparent"};
    border-bottom: ${({ isLastRow }) =>
      isLastRow ? "none" : `1px solid ${colors.background[100]}`};
  }

  &:hover ${StyledTd} {
    background-color: ${({ canRowClick, isRowSelected }) =>
      isRowSelected
        ? colors.turquoise[100]
        : canRowClick
        ? colors.grey[100]
        : "transparent"};
  }
`;
