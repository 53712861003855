import { FC, useMemo } from "react";
import {
  CellProps,
  Column,
  useFilters,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";
import { format } from "date-fns";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { Button } from "src/components/form/Button";
import { Table } from "src/components/table/Table";
import { PaginationMenu } from "src/components/table/PaginationMenu";
import { NotProvidedCell } from "src/components/table/NotProvidedCell";
import { Card } from "src/components/Card";
import { Alert } from "src/components/Alert";

import { useToastNotifications } from "src/hooks/useToastNotifications";
import { RequestStatus, useRequestStatus } from "src/hooks/useRequestStatus";
import { makeSelectionHook } from "src/components/table/hooks/selection";
import { api } from "src/services/api";
import { getOrderIdLabel } from "src/utils/order";
import { PaymentIntentService } from "src/services/payment-intent";

import { colors } from "src/theme/colors";

import type { Order } from "src/models/order";

import { ReactComponent as GoBackIcon } from "src/assets/svg-icons/navigation-left.svg";

interface PayOrdersProps {
  orders: Order[];
  goBackHandler: () => void;
}
export const PayOrders: FC<PayOrdersProps> = props => {
  const { orders, goBackHandler } = props;
  const { t } = useTranslation();

  const { sendToast } = useToastNotifications();
  const selectionHook = makeSelectionHook(false);
  const [status, setStatus] = useRequestStatus();

  const columns: Column<Order>[] = useMemo(() => {
    return [
      {
        Header: `${t("forms.order_number")}`,
        accessor: "id",
        Cell: (props: CellProps<Order>) => {
          const {
            row: { original: currentOrder },
          } = props;
          return <>{getOrderIdLabel(currentOrder.id)}</>;
        },
      },
      {
        Header: `${t("forms.ref")}`,
        accessor: "toReference",
        Cell: NotProvidedCell,
      },
      {
        Header: `${t("forms.start_date")}`,
        accessor: (row) => row.startDate ? format(new Date(row.startDate), "yyyy-MM-dd") : '',
        Cell: (props: CellProps<{}, Order["startDate"]>) => (
          <>
            {props.value ? (
              <>{format(new Date(props.value), "dd/MM/yyyy")}</>
            ) : (
              <NotProvidedCell {...props} />
            )}
          </>
        ),
      },
      {
        disableSortBy: true,
        Header: `${t("forms.code_number")}`,
        accessor: "clients",
        Cell: (props: CellProps<{}, Order["clients"]>) => {
          const { value: clients } = props;
          return !!clients && clients.length > 0 ? (
            <>{clients.length}</>
          ) : (
            <NotProvidedCell {...props} value={null} />
          );
        },
      },
    ];
  }, [t]);

  const instance = useTable(
    {
      data: orders,
      columns,
    },
    useFilters,
    useSortBy,
    usePagination,
    useRowSelect,
    selectionHook,
  );

  const { selectedFlatRows } = instance;

  const payOrdersHandler = async () => {
    if (status === RequestStatus.LOADING) {
      return;
    }
    setStatus(RequestStatus.LOADING);

    try {
      const res = await api.post<{ success: true; url: string }>("/payments", {
        orderIds: selectedFlatRows.map(row => row.original.id),
      });
      if (!res.data.success) {
        throw new Error();
      }

      // adding order payment metadata to visualize after checkout which codes are now valid
      PaymentIntentService.setPaymentIntentData(
        selectedFlatRows.map(row => {
          const order = row.original;
          return {
            orderName: getOrderIdLabel(order.id),
            codes: order.clients?.map(client => client.drbRef) || [],
          };
        }),
      );

      window.location.href = res.data.url;
    } catch {
      sendToast("error", t("pages.orders.to_paid.payment_error"));
      setStatus(RequestStatus.IDLE);
    }
  };

  return (
    <Wrapper>
      <ActionsWrapper>
        <GoBackWrapper onClick={goBackHandler}>
          <GoBackIcon />
          <h2>{t("pages.orders.to_paid.title")}</h2>
        </GoBackWrapper>
        <div>
          <Button
            onClick={payOrdersHandler}
            loading={status === RequestStatus.LOADING}
            disabled={
              selectedFlatRows.length === 0 || selectedFlatRows.length > 50
            }
          >
            {t("pages.orders.to_paid.paid_selected_order")}
          </Button>
        </div>
      </ActionsWrapper>
      <Description>{t("pages.orders.to_paid.description")}</Description>
      {selectedFlatRows.length > 50 && (
        <Alert variant="error" transparent={false}>
          {t("pages.orders.to_paid.number_error")}
        </Alert>
      )}
      <Card>
        <Table
          instance={instance}
          columnsWidths={["25%", "25%", "25%", "25%"]}
        />
      </Card>
      <PaginationWrapper>
        <PaginationMenu
          instance={instance}
          label={t("pages.orders.to_paid.order_per_pages")}
        />
      </PaginationWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  & > *:not(:last-child) {
    margin-bottom: 16px;
  }
`;

const ActionsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > * {
    width: min-content;
  }
`;

const GoBackWrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  white-space: nowrap;

  & > *:first-child {
    margin-right: 16px;
  }

  & > h2 {
    margin: 0;
  }
`;

const Description = styled.p`
  margin: 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: ${colors.grey[600]};
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: end;
`;
