import { Order } from "src/models/order";
import { api } from "./api";

export const updateOrder = (
  orderId: Order["id"],
  payload: Record<string, unknown>,
) => {
  return api.patch<{ success: boolean; order: Order }>(
    `/orders/${orderId}`,
    payload,
  );
};

type orderPDFType = {
  totalPrice: string;
  items: {
    orderNumber: string;
    ref: string;
    orderPrice: string;
    codePrice: string;
    totalPrice: string;
  }[];
  city: string;
  toAddress: string;
  toName: string;
  date: string;
  invoiceNumber: string;
  totalHT?: string;
  totalTVA?: string;
  tva?: string;
};

export const getOrderPDF = async (orderId: string) => {
  const { data } = await api.get<orderPDFType>(`/orders/${orderId}/pdf`);
  return data;
};
