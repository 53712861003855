import { FC, ChangeEventHandler, CSSProperties, useState, useRef } from "react";
import usePlacesService, {
  usePlacesAutocompleteServiceConfig,
} from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import styled from "styled-components";

import { Card } from "../Card";

import { useOnClickOutside } from "src/hooks/useOnClickOutside";
import { generateLocationFromGooglePlaceResult } from "src/utils/location";

import { colors } from "src/theme/colors";

import type { Location } from "src/models/location";

import { ReactComponent as LocationIcon } from "src/assets/svg-icons/location.svg";

const options: usePlacesAutocompleteServiceConfig = {
  apiKey: process.env.REACT_APP_GOOGLE_PLACES_API_KEY,
};

type InputState = "default" | "error";
type InputStyles = {
  color: NonNullable<CSSProperties["color"]>;
  borderColor: NonNullable<CSSProperties["borderColor"]>;
};
const mapStateToStyles: Record<InputState, InputStyles> = {
  default: {
    color: colors.main.black,
    borderColor: colors.grey[100],
  },
  error: {
    color: colors.error[300],
    borderColor: colors.error[100],
  },
};

export interface LocationAutocompleteInputProps {
  value: string;
  name?: string;
  placeholder?: string;
  hasError?: boolean;
  /**
   * Must follow the list of supported types by Google Places API : https://developers.google.com/maps/documentation/places/web-service/supported_types#table3
   */
  autocompleteTypes?: google.maps.places.AutocompletionRequest["types"];
  onChange: ChangeEventHandler<HTMLInputElement>;
  onPlaceSelect: (location: Location) => void;
  getPicture?: (url: string) => void;
}
export const LocationAutocompleteInput: FC<
  LocationAutocompleteInputProps
> = props => {
  const {
    value,
    name,
    placeholder,
    hasError = false,
    autocompleteTypes,
    onChange,
    onPlaceSelect,
    getPicture,
  } = props;

  const [inputIsFocused, setInputIsFocused] = useState(false);
  const [shouldShowSuggestions, setShouldShowSuggestions] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const state: InputState = hasError ? "error" : "default";
  const inputStyles: InputStyles = mapStateToStyles[state];

  const wrapperClickHandler = () => {
    inputRef.current?.focus();
  };

  const { placesService, placePredictions, getPlacePredictions } =
    usePlacesService(options);

  const changeInputHandler: ChangeEventHandler<HTMLInputElement> = e => {
    getPlacePredictions({ input: e.target.value, types: autocompleteTypes });
    if (!shouldShowSuggestions) {
      setShouldShowSuggestions(true);
    }
    onChange(e);
  };

  const getSuggestionDetails = (placeId: string, placeDescription: string) => {
    if (!placesService) {
      return;
    }

    placesService.getDetails(
      {
        placeId,
      },
      placeDetails => {
        if (!placeDetails) {
          return;
        }
        let picture = "";
        if (placeDetails.photos) {
          picture = placeDetails?.photos[0].getUrl();
        }
        if (getPicture) {
          getPicture(picture);
        }
        onPlaceSelect({
          ...generateLocationFromGooglePlaceResult(placeDetails),
          mediaUrl: picture,
        });
        setShouldShowSuggestions(false);
      },
    );
  };

  useOnClickOutside(wrapperRef, () => {
    setShouldShowSuggestions(false);
  });

  return (
    <Wrapper
      {...inputStyles}
      hasError={hasError}
      focused={inputIsFocused}
      onClick={wrapperClickHandler}
      ref={wrapperRef}
    >
      <input
        ref={inputRef}
        name={name}
        placeholder={placeholder}
        value={value}
        autoComplete="off"
        onChange={changeInputHandler}
        onFocus={() => setInputIsFocused(true)}
        onBlur={() => setInputIsFocused(false)}
      />
      <IconWrapper>
        <LocationIcon />
      </IconWrapper>
      {shouldShowSuggestions && placePredictions.length > 0 && (
        <SuggestionsWrapper>
          {placePredictions.map(prediction => (
            <Suggestion
              key={prediction.place_id}
              onClick={() =>
                getSuggestionDetails(
                  prediction.place_id,
                  prediction.description,
                )
              }
            >
              {prediction.description}
            </Suggestion>
          ))}
        </SuggestionsWrapper>
      )}
    </Wrapper>
  );
};

type WrapperStyleProps = InputStyles & {
  hasError: boolean;
  focused: boolean;
};
const Wrapper = styled.div<WrapperStyleProps>`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  height: 52px;
  padding: 16px;
  border-radius: 4px;
  color: ${({ color, hasError, focused }) =>
    focused && !hasError ? colors.cta.green : color};
  background-color: ${colors.background[100]};
  border: 1px solid
    ${({ borderColor, hasError, focused }) =>
      focused && !hasError ? colors.cta.green : borderColor};
  outline: none;
  transition: all 200ms ease;

  font-weight: 500;
  font-size: 14px;
  line-height: 18px;

  & > input {
    flex: 1;
    background-color: transparent;
    border: none;
    outline: none;
    color: inherit;

    &::placeholder {
      color: ${({ hasError }) =>
        hasError ? colors.error[100] : colors.grey[200]};
    }
  }
`;

const IconWrapper = styled.div`
  height: 16px;

  & > svg {
    width: 16px;
    height: 16px;
  }
`;

const SuggestionsWrapper = styled(Card)`
  position: absolute;
  top: 103%;
  left: 0;
  z-index: 2;
  color: ${colors.main.black};

  & > *:not(:last-child) {
    border-bottom: 1px solid ${colors.grey[100]};
  }
`;

const Suggestion = styled.div`
  cursor: pointer;
  padding: 8px;
  transition: background-color 200ms ease;

  &:hover {
    background-color: ${colors.background[100]};
  }
`;
