import { FC, useEffect, useMemo } from "react";
import {
  CellProps,
  Column,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { Card } from "src/components/Card";
import { PaginationMenu } from "src/components/table/PaginationMenu";
import { Table } from "src/components/table/Table";
import { Button, StyledButton } from "src/components/form/Button";

import { GlobalFilterSearchBar } from "src/components/table/GlobalFilterSearchBar";
import { useHistory, useParams } from "react-router-dom";
import { ListRegion } from "src/models/listRegion";
import { IconWrapper } from "src/styles/styled-components-library";
import { ReactComponent as EditActionIcon } from "src/assets/svg-icons/actions/edit.svg";

interface AllListRegionsProps {
  listRegions: ListRegion[];
  refreshListRegions: (listId: string) => void;
}

export const AllListRegions: FC<AllListRegionsProps> = props => {
  const { t } = useTranslation();

  const history = useHistory();
  const params = useParams<{ listId: string }>();
  const { listRegions, refreshListRegions } = props;

  const columns: Column<ListRegion>[] = useMemo(() => {
    return [
      {
        Header: `${t("forms.name")}`,
        accessor: "name",
      },
      {
        Header: `${t("forms.picture")}`,
        disableSortBy: true,
        Cell: ({ row }: CellProps<ListRegion>) => {
          const { original: currentListRegion } = row;
          return (
            <>
              {currentListRegion.media_name ? (
                <Logo
                  src={`https://d2q4io46p490bd.cloudfront.net/${currentListRegion.media_name}`}
                  alt="Logo"
                />
              ) : null}
            </>
          );
        },
      },
      {
        Header: `${t("forms.actions")}`,
        disableSortBy: true,
        Cell: ({ row }: CellProps<ListRegion>) => {
          const { original: currentListRegion } = row;
          return (
            <IconsWrapper>
              <IconWrapper
                onClick={e => {
                  e.stopPropagation();
                  history.push(
                    `/lists/${params.listId}/regions/${currentListRegion.id}/edit`,
                  );
                }}
              >
                <EditActionIcon />
              </IconWrapper>
            </IconsWrapper>
          );
        },
      },
    ];
  }, [history, params.listId, t]);

  const instance = useTable(
    {
      columns,
      data: listRegions,
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  useEffect(() => {
    refreshListRegions(params.listId);
  }, [refreshListRegions, params.listId]);

  return (
    <>
      <ActionsWrapper>
        <GlobalFilterSearchBar
          instance={instance}
          placeholder={t("pages.poi.regions.search")}
        />
        <Button
          onClick={() => {
            history.push(`/lists/${params.listId}/regions/new`);
          }}
        >
          {t("pages.poi.regions.create")}
        </Button>
      </ActionsWrapper>
      <TableCardWrapper>
        <Table
          instance={instance}
          columnsWidths={["30%", "70%"]}
          rowClickHandler={list =>
            history.push(`/lists/${params.listId}/regions/${list.id}/edit`)
          }
        />
      </TableCardWrapper>
      <PaginationWrapper>
        <PaginationMenu
          instance={instance}
          label={t("pages.poi.regions.regions_per_page")}
        />
      </PaginationWrapper>
    </>
  );
};

const TableCardWrapper = styled(Card)`
  width: 100%;
  height: min-content;
  padding: 24px;
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ActionsWrapper = styled.div`
  display: flex;

  & > *:not(:first-child) {
    margin-left: 16px;
  }

  & > ${StyledButton} {
    width: min-content;
  }
`;

const IconsWrapper = styled.div`
  display: flex;

  & > *:not(:last-child) {
    margin-right: 8px;
  }
`;

const Logo = styled.img`
  width: 50px;
  margin-bottom: 10px;
`;
