import { Location } from "src/models/location";

export enum Status {
  DRAFT = "draft",
  TO_PAY = "toPay",
  PAID = "paid",
  ARCHIVED = "archived",
}

export enum OrderType {
  SIMPLE = "simple",
  MULTIPLE = "multiple",
}

export enum TravelKind {
  TRAIN = "train",
  PLANE = "plane",
  BOAT = "boat",
  CAR_RENTAL = "carRental",
  ACTIVITY = "activity",
}

// draft type for existing or new order extra travels
export type ExtraTravelDraft = {
  id?: string;
  tempId?: string; // useful for brand-new extra travels, avoiding key-mapping React errors
  kind: TravelKind;
  name?: string | null;
  fromDate: string;
  fromLocation: Location;
  toDate: string;
  toLocation: Location;
};
