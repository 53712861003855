import { FC } from "react";
import { add, parse } from "date-fns";
import { FormikErrors, FormikValues, useFormik } from "formik";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { LabeledLocationAutocompleteInput } from "src/components/form/LabeledLocationAutocompleteInput";
import { LabeledDateInput } from "src/components/form/LabeledDateInput";
import { LabeledTimeInput } from "src/components/form/LabeledTimeInput";
import { SelectableBadge } from "src/components/SelectableBadge";

import { validateDate } from "src/utils/validations";
import { formatDateToLocalString } from "src/utils/date";
import { getDateFnsLocaleFromUserLocale } from "src/utils/locale";

import type { Location } from "src/models/location";
import { Locale } from "src/types/user";
import { RequestStatus } from "src/hooks/useRequestStatus";

interface AirportFormProps {
  status: RequestStatus;
  labels: {
    dateLabel: string;
    locationLabel: string;
    hourLabel: string;
  };
  dateKey: string;
  locationKey: string;
  values: FormikValues;
  errors: FormikErrors<FormikValues>;
  delay: number;
  minDate?: string;
  disabled?: boolean;
  initialDate?: string;
  updateDelay: (newDelay: number) => void;
  handleChange: ReturnType<typeof useFormik>["handleChange"];
  handleLocationSelected: (location: Location) => void;
}
export const AirportForm: FC<AirportFormProps> = props => {
  const {
    status,
    labels,
    dateKey,
    locationKey,
    values,
    errors,
    delay,
    minDate,
    disabled,
    initialDate,
    updateDelay,
    handleChange,
    handleLocationSelected,
  } = props;
  const { t } = useTranslation();

  const delayOptions = [
    { value: 0, label: t("pages.orders.edit.order_steps.arrival_day_0") },
    { value: 1, label: t("pages.orders.edit.order_steps.arrival_day_1") },
    { value: 2, label: t("pages.orders.edit.order_steps.arrival_day_2") },
  ];

  const generateDelayOptionLabel = (
    optionLabel: string,
    optionValue: number,
    dateString: string,
  ) => {
    return (
      optionLabel +
      (!!dateString && validateDate(dateString)
        ? ` - ${formatDateToLocalString(
            add(parse(dateString, "yyyy-MM-dd", new Date()), {
              days: optionValue,
            }),
            getDateFnsLocaleFromUserLocale(Locale.FR),
          )}`
        : "")
    );
  };

  if (initialDate) {
    values[`${dateKey}Date`] = initialDate;
  }

  return (
    <>
      <InputsWrapper>
        <LabeledDateInput
          label={labels.dateLabel}
          name={`${dateKey}Date`}
          value={initialDate ?? values[`${dateKey}Date`]}
          hasError={
            !!errors[`${dateKey}Date`] &&
            status === RequestStatus.VALIDATION_ERROR
          }
          min={minDate}
          onChange={handleChange}
          disabled={disabled}
        />

        <LabeledTimeInput
          label={labels.hourLabel}
          placeholder="-"
          name={`${dateKey}Hour`}
          value={
            values[`${dateKey}Hour`] === "11:59:42"
              ? ""
              : values[`${dateKey}Hour`]
          }
          onChange={handleChange}
          hasError={
            !!errors[`${dateKey}Hour`] &&
            status === RequestStatus.VALIDATION_ERROR
          }
        />
        <LabeledLocationAutocompleteInput
          label={labels.locationLabel}
          placeholder="Orly"
          name={locationKey}
          value={values[locationKey]}
          hasError={
            !!errors[locationKey] && status === RequestStatus.VALIDATION_ERROR
          }
          onChange={handleChange}
          onPlaceSelect={handleLocationSelected}
        />
      </InputsWrapper>
      <OptionsWrapper>
        {delayOptions.map(option => {
          const optionLabel = generateDelayOptionLabel(
            option.label,
            option.value,
            values[dateKey],
          );
          return (
            <SelectableBadge
              key={option.value}
              selected={delay === option.value}
              label={optionLabel}
              onClick={() => updateDelay(option.value)}
            />
          );
        })}
      </OptionsWrapper>
    </>
  );
};

const InputsWrapper = styled.div`
  display: grid;
  margin-bottom: 24px;
  grid-template-columns: 1fr 2fr 2fr;
  gap: 24px;

  & > * {
    min-width: 300px;
  }
`;

const OptionsWrapper = styled.div`
  display: flex;

  & > :not(:last-child) {
    margin-right: 24px;
  }
`;
