import { FC, useState } from "react";
import { FormikErrors, useFormik } from "formik";
import { format, parse } from "date-fns";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { Modal } from "src/components/Modal";
import { Alert } from "src/components/Alert";
import { LabeledSelect } from "src/components/form/LabeledSelect";
import { LabeledTextInput } from "src/components/form/LabeledTextInput";
import { LabeledDateInput } from "src/components/form/LabeledDateInput";
import { Button } from "src/components/form/Button";

import { api } from "src/services/api";
import {
  validateDate,
  validateEmail,
  validateNonEmptyFields,
} from "src/utils/validations";
import { languagesOptions } from "src/constants/countries";

import { Title } from "src/styles/styled-components-library";

import { ReactComponent as AddUsersIcon } from "src/assets/svg-icons/multiple-users-add.svg";
import { TourOperatorClient } from "src/models/tour-operator-client";

enum Status {
  IDLE = "IDLE",
  LOADING = "LOADING",
  VALIDATION_ERROR = "VALIDATION_ERROR",
  SERVER_ERROR = "SERVER_ERROR",
}

interface CreateClientModalProps {
  tourOperatorId: string;
  showModal: boolean;
  closeModal: () => void;
  addAndSelectClient: (newClient: TourOperatorClient) => void;
}
export const CreateClientModal: FC<CreateClientModalProps> = props => {
  const { tourOperatorId, showModal, closeModal, addAndSelectClient } = props;
  const { t } = useTranslation();
  const [status, setStatus] = useState(Status.IDLE);

  const formInitialValues = {
    lastName: "",
    firstName: "",
    birthday: "",
    country: "FR",
    email: "",
  };
  type FormKey = keyof typeof formInitialValues;
  type FormInput = {
    key: FormKey;
    label: string;
    placeholder: string;
    isSelect?: boolean;
    isDate?: boolean;
    options?: Array<{ label: string; value: string }>;
  };
  const inputs: FormInput[] = [
    {
      key: "lastName",
      label: t("forms.last_name"),
      placeholder: "Dourlens",
    },
    {
      key: "firstName",
      label: t("forms.first_name"),
      placeholder: "Clément",
    },
    {
      key: "email",
      label: t("pages.orders.edit.client.email"),
      placeholder: "mail@mail.com",
    },
    {
      key: "birthday",
      label: t("forms.birthday"),
      placeholder: "13/03/1923",
      isDate: true,
    },
    {
      key: "country",
      label: t("forms.language"),
      placeholder: "Langue",
      isSelect: true,
      options: languagesOptions,
    },
  ];

  const formik = useFormik({
    initialValues: formInitialValues,
    validate: values => {
      const errors: FormikErrors<typeof formInitialValues> = {};
      if (!validateEmail(values.email)) {
        errors.email = "The email provided is not valid.";
      }
      if (!validateDate(values.birthday)) {
        errors.birthday = "The birthday provided is not valid.";
      }
      const emptyFields = validateNonEmptyFields(
        values,
        "lastName",
        "firstName",
        "country",
      );
      if (emptyFields.length > 0) {
        for (const key of emptyFields) {
          errors[key] = "This field is empty.";
        }
      }
      if (Object.keys(errors).length > 0) {
        setStatus(Status.VALIDATION_ERROR);
      }
      return errors;
    },
    validateOnChange: false,
    onSubmit: async values => {
      if (status === Status.LOADING) {
        return;
      }

      try {
        const birthdayDate = parse(values.birthday, "yyyy-MM-dd", new Date());
        const body = {
          ...values,
          birthday: format(birthdayDate, "dd/MM/yyyy"),
          locale: "fr", // !! TODO : déterminer le "locale" via la langue actuelle de l'app React (quand on aura mis en place i18n)
        };

        const res = await api.post<{
          success: boolean;
          client: TourOperatorClient;
        }>(`/tour-operators/${tourOperatorId}/clients`, body);
        if (!res.data.success) {
          throw new Error();
        }
        addAndSelectClient(res.data.client);
        closeModal();
      } catch {
        setStatus(Status.SERVER_ERROR);
      }
    },
  });

  return (
    <Modal showModal={showModal} closeModal={closeModal} width="900px">
      <ContentWrapper>
        <AddUsersIcon />
        <Title>{t("pages.orders.edit.client.create_this")}</Title>
        <Form onSubmit={formik.handleSubmit}>
          <InputsWrapper>
            {inputs.map(input =>
              input.isSelect ? (
                <LabeledSelect
                  key={input.key}
                  options={input.options || []}
                  value={formik.values[input.key]}
                  label={input.label}
                  name={input.key}
                  onChange={formik.handleChange}
                />
              ) : input.isDate ? (
                <LabeledDateInput
                  key={input.key}
                  name={input.key}
                  label={input.label}
                  placeholder={input.placeholder}
                  value={formik.values[input.key]}
                  onChange={formik.handleChange}
                  hasError={
                    status === Status.VALIDATION_ERROR &&
                    !!formik.errors[input.key]
                  }
                />
              ) : (
                <LabeledTextInput
                  key={input.key}
                  name={input.key}
                  label={input.label}
                  placeholder={input.placeholder}
                  value={formik.values[input.key]}
                  onChange={formik.handleChange}
                  hasError={
                    status === Status.VALIDATION_ERROR &&
                    !!formik.errors[input.key]
                  }
                />
              ),
            )}
          </InputsWrapper>
          {status === Status.VALIDATION_ERROR && (
            <Alert>{t("forms.invalid_form")}</Alert>
          )}
          {status === Status.SERVER_ERROR && (
            <Alert>{t("pages.orders.edit.client.server_error")}</Alert>
          )}
          <CustomButton type="submit">
            {t("pages.orders.edit.client.button")}
          </CustomButton>
        </Form>
      </ContentWrapper>
    </Modal>
  );
};

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  & > *:not(:last-child) {
    margin-bottom: 16px;
  }
`;

const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const InputsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  & > * {
    width: 47.5%;
    margin-bottom: 24px;
  }
`;

const CustomButton = styled(Button)`
  width: min-content;
  margin: 24px auto 0 auto;
`;
