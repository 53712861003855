import {
  ChangeEvent,
  FC,
  MutableRefObject,
  useEffect,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import { FormikErrors, useFormik } from "formik";
import { useTranslation } from "react-i18next";

import { Card } from "src/components/Card";
import { Button } from "src/components/form/Button";
import { LabeledTextInput } from "src/components/form/LabeledTextInput";
import { Alert } from "src/components/Alert";

import { useRequestStatus, RequestStatus } from "src/hooks/useRequestStatus";
import { validateNonEmptyFields } from "src/utils/validations";
import { api } from "src/services/api";

import { Title } from "src/styles/styled-components-library";

import type { SelectOption } from "src/components/form/Select";

import { ReactComponent as GoBackIcon } from "src/assets/svg-icons/navigation-left.svg";
import { BasicPageWithHeader } from "src/components/layout/BasicPageWithHeader";
import { Link, useParams } from "react-router-dom";
import { getS3Url, uploadFile } from "src/services/image";
import { colors } from "src/theme/colors";
import { ReactComponent as UploadIcon } from "src/assets/svg-icons/download-square.svg";
import { useListRegion } from "src/hooks/useListRegion";

type Key = "name" | "media_name";

type FormInput = {
  key: Key;
  label: string;
  placeholder: string;
  type: "text";
  options?: SelectOption[];
};

export const EditListRegion: FC = () => {
  const { t } = useTranslation();

  const [status, setStatus] = useRequestStatus();
  const params = useParams<{ listId: string; listRegionId: string }>();
  const inputFile = useRef() as MutableRefObject<HTMLInputElement>;
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const { listRegion, refreshListRegion } = useListRegion(params.listRegionId);

  const inputs: FormInput[] = [
    {
      key: "name",
      label: t("forms.name"),
      placeholder: "Nom de la région",
      type: "text",
    },
  ];

  const formInitialValues = {
    name: listRegion?.name ?? "",
    media_name: listRegion?.media_name ?? "",
  };

  const formik = useFormik({
    initialValues: formInitialValues,
    enableReinitialize: true,
    validate: values => {
      const errors: FormikErrors<typeof formInitialValues> = {};

      const emptyFields = validateNonEmptyFields(values, "name");
      if (emptyFields.length > 0) {
        for (const key of emptyFields) {
          errors[key] = "This field is empty.";
        }
      }
      if (Object.keys(errors).length > 0) {
        setStatus(RequestStatus.VALIDATION_ERROR);
      }
      return errors;
    },
    validateOnChange: false,
    onSubmit: async values => {
      if (
        status === RequestStatus.LOADING ||
        status === RequestStatus.SUCCESS
      ) {
        return;
      }

      // !! TODO : déterminer le "locale" via la langue actuelle de l'app React
      // (quand on aura mis en place i18n)
      const body = {
        ...values,
        locale: "fr",
      };

      setStatus(RequestStatus.LOADING);
      try {
        await api.patch(`/list-regions/${params.listRegionId}`, {
          ...body,
        });
        setStatus(RequestStatus.SUCCESS);
      } catch {
        setStatus(RequestStatus.SERVER_ERROR);
      }
    },
  });

  useEffect(() => {
    refreshListRegion(params.listRegionId);
  }, [refreshListRegion, params.listRegionId]);

  const updateTextInputHandler = (
    e: ChangeEvent<HTMLInputElement>,
    key: Key,
  ) => {
    formik.setErrors({
      ...formik.errors,
      [key]: false,
    });
    formik.handleChange(e);
  };

  const uploadImage = async (image: File) => {
    const data = await getS3Url("regions");

    if (data.errorMessage) {
      setErrorMessage(data.errorMessage);
      return;
    }

    const res = await uploadFile(image, data.url, "regions");

    if (res.errorMessage) {
      setErrorMessage(res.errorMessage);
    } else {
      setErrorMessage(null);
      formik.setValues({
        ...formik.values,
        media_name: res.uploadedFilename ?? "",
      });
    }
  };

  const deleteLogo = async () => {
    inputFile.current.value = "";
    formik.setValues({
      ...formik.values,
      media_name: "",
    });
  };

  return (
    <BasicPageWithHeader>
      <NavigationWrapper>
        <Link to={`/lists/${params.listId}`}>
          <StyledGoBackIcon />
        </Link>
      </NavigationWrapper>
      <CardWrapper>
        <FormWrapper>
          <Title>{t("pages.poi.regions.edit.title")}</Title>
          <InputsWrapper>
            {inputs.map(input => {
              return (
                <LabeledTextInput
                  key={input.key}
                  name={input.key}
                  type={input.type}
                  label={input.label}
                  placeholder={input.placeholder}
                  value={formik.values[input.key]}
                  hasError={!!formik.errors[input.key]}
                  onChange={e => updateTextInputHandler(e, input.key)}
                />
              );
            })}
          </InputsWrapper>
        </FormWrapper>

        <input
          ref={inputFile}
          type="file"
          style={{ display: "none" }}
          onChange={(e: any) => uploadImage(e.target.files[0])}
        />
        {formik.values["media_name"] !== "" ? (
          <UploadContainer>
            <Label>{t("pages.poi.regions.logo_title")}</Label>
            <Upload>
              {formik.values["media_name"] ? (
                <Logo
                  src={`https://d2q4io46p490bd.cloudfront.net/${formik.values.media_name}`}
                  alt="Logo"
                />
              ) : (
                <UploadText>{t("pages.poi.regions.no_logo")}</UploadText>
              )}
              <ButtonContainer>
                <Button variant="secondary" onClick={deleteLogo}>
                  {t("pages.poi.regions.delete")}
                </Button>
              </ButtonContainer>
            </Upload>
          </UploadContainer>
        ) : (
          <UploadContainer>
            <Label>{"Logo (facultatif)"}</Label>
            <Upload onClick={() => inputFile.current.click()}>
              <UploadIcon />
              <UploadText>
                {t("pages.poi.regions.logo_description")}
                <br />
              </UploadText>
            </Upload>
          </UploadContainer>
        )}

        {errorMessage ? (
          <Alert>
            {t("forms.error")}: {errorMessage}
          </Alert>
        ) : null}

        {status === RequestStatus.VALIDATION_ERROR && (
          <Alert>{t("forms.invalid_form")}</Alert>
        )}
        {status === RequestStatus.SERVER_ERROR && (
          <Alert>{t("pages.poi.regions.edit.server_error")}</Alert>
        )}
        {status === RequestStatus.SUCCESS && (
          <Alert variant="success">{t("pages.poi.regions.edit.success")}</Alert>
        )}
        <Button type="submit" onClick={() => formik.handleSubmit()}>
          {t("pages.poi.regions.logo_description")}
        </Button>
      </CardWrapper>
    </BasicPageWithHeader>
  );
};

const NavigationWrapper = styled.div`
  width: 750px;
  margin: 0 auto;
  padding: 16px;
  padding-left: 0;
`;

const StyledGoBackIcon = styled(GoBackIcon)`
  cursor: pointer;
`;

const CardWrapper = styled(Card)`
  margin: 0 auto;
  padding: 24px;
  width: 750px;
`;

const FormWrapper = styled.form`
  width: 100%;

  & > a:first-child {
    display: inline-block;
    margin-bottom: 12px;
  }

  & > * {
    margin-bottom: 24px;
  }
`;

const InputsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  & > * {
    width: 48%;
  }

  @supports (gap: 24px 28px) {
    gap: 24px 28px;
  }
  @supports not (gap: 24px 28px) {
    & > * {
      margin-bottom: 24px;
    }
  }
`;

const UploadContainer = styled.div`
  width: 100%;
  margin-bottom: 24px;
`;

const Upload = styled.div`
  width: 100%;
  border: 1px dotted ${colors.grey[200]};
  padding: 50px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Label = styled.p`
  margin-top: 0;
  margin-bottom: 4px;
  color: ${colors.grey[600]};
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
`;
const UploadText = styled.p`
  color: ${colors.grey[200]};
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
`;

const Logo = styled.img`
  width: 50%;
  margin-bottom: 10px;
`;

const ButtonContainer = styled.div`
  width: 50%;
`;
