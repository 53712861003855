import {
  useContext,
  useState,
  createContext,
  ReactNode,
  useCallback,
  useEffect,
} from "react";
import { useUser } from "src/hooks/useUser";
import { User } from "src/models/user";

import TokenService from "src/services/token";

import { JwtPayload } from "src/types/jwt-payload";

type AuthState = {
  userPayload: JwtPayload | null;
  isLoggedIn: boolean;
  user: User | null;
};
type ContextValue = AuthState & {
  setUserPayload: (user: JwtPayload) => void;
  setUser: (user: User | null) => void;
  logOut: () => void;
};
type AuthProviderProps = { children: ReactNode };

const AuthContext = createContext<ContextValue | undefined>(undefined);
const initialState: AuthState = {
  userPayload: null,
  user: null,
  isLoggedIn: false,
};

function AuthProvider({ children }: AuthProviderProps) {
  const [auth, setAuth] = useState<AuthState>(initialState);
  const { user, refreshUserData } = useUser(null);

  useEffect(() => {
    setAuth(prevState => ({
      ...prevState,
      user: user,
    }));
  }, [user]);

  const setUserPayload = useCallback(
    (userPayload: JwtPayload) => {
      refreshUserData(userPayload.id);
      setAuth(prevState => ({
        ...prevState,
        userPayload,
        isLoggedIn: true,
      }));
    },
    [refreshUserData],
  );

  const setUser = useCallback((user: User | null) => {
    setAuth(prevState => ({
      ...prevState,
      user: user,
    }));
  }, []);

  const logOut = useCallback(() => {
    // updating context state
    setAuth(prevState => ({
      ...prevState,
      userPayload: null,
      isLoggedIn: false,
    }));
    // removing localStorage access & refresh tokens
    TokenService.removeTokens();
  }, []);

  const contextValue = { ...auth, setUserPayload, logOut, setUser };
  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
}

function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within a AuthProvider");
  }
  return context;
}

export { AuthProvider, useAuth };
