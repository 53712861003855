import { FilterProps } from "react-table";
import styled from "styled-components";

import { Badge, BadgeProps } from "src/components/Badge";
import { Checkbox } from "src/components/form/Checkbox";
import { useTranslation } from "react-i18next";

export function EnumValuesFilter<T extends Record<string, unknown>>(
  props: FilterProps<T> & {
    options: Array<{ label: string; value: string }>;
    styles: Record<string, BadgeProps>;
  },
) {
  const {
    options,
    styles,
    column: { id: columnId, filterValue = [] },
    setFilter,
  } = props;
  const { t } = useTranslation();

  const selectCheckboxHandler = (value: string) => {
    const valueSelected = filterValue.includes(value);
    if (valueSelected) {
      setFilter(
        columnId,
        filterValue.filter((v: string) => v !== value),
      );
    } else {
      setFilter(columnId, filterValue.concat(value));
    }
  };

  return (
    <Wrapper>
      {options.map(option => (
        <EnumOption key={option.value}>
          <Checkbox
            checked={filterValue.includes(option.value)}
            onChange={() => selectCheckboxHandler(option.value)}
          />
          <Badge {...styles[option.value]}>
            {t(`enum.order_status.${option.label}`)}
          </Badge>
        </EnumOption>
      ))}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  & > *:not(:last-child) {
    margin-bottom: 8px;
  }
`;

const EnumOption = styled.div`
  display: flex;
  align-items: center;

  & > :first-child {
    margin-right: 8px;
  }
`;
