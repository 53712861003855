import type { PaymentIntentMetadata } from "src/types/payment-intent-metadata";

const LOCAL_STORAGE_PAYMENT_INTENT_KEY = "payment-intent-metadata";
export class PaymentIntentService {
  static getPaymentIntentData(): PaymentIntentMetadata | null {
    try {
      const storageContent = localStorage.getItem(
        LOCAL_STORAGE_PAYMENT_INTENT_KEY,
      );
      if (storageContent === null) {
        return null;
      }
      return JSON.parse(storageContent) as PaymentIntentMetadata;
    } catch {
      return null;
    }
  }

  static setPaymentIntentData(data: PaymentIntentMetadata) {
    localStorage.setItem(
      LOCAL_STORAGE_PAYMENT_INTENT_KEY,
      JSON.stringify(data),
    );
  }

  static deleteLatestData() {
    localStorage.removeItem(LOCAL_STORAGE_PAYMENT_INTENT_KEY);
  }
}
