import { FC, useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";

import { BasicPageWithHeader } from "src/components/layout/BasicPageWithHeader";
import { ActionsHeader } from "src/components/pages/orders/specific/OrderRecap/ActionsHeader";
import { OrderRecap } from "src/components/pages/orders/specific/OrderRecap";

import { api } from "src/services/api";

import type { Order } from "src/models/order";

export const RecapOrder: FC = () => {
  const history = useHistory();
  const { orderId } = useParams<{ orderId: string }>();
  const [order, setOrder] = useState<Order | null>(null);

  const editStepHandler = (step: number) => {
    if (!order) {
      return;
    }

    history.push(`/orders/${order.id}/edit?step=${step}`);
  };

  const fetchOrderData = useCallback(async () => {
    try {
      if (!orderId) {
        throw new Error();
      }

      const res = await api.get<Order>(`/orders/${orderId}`);
      if (!res.data.id) {
        throw new Error();
      }
      setOrder(res.data);
    } catch {
      history.push("/orders");
    }
  }, [orderId, history]);

  useEffect(() => {
    fetchOrderData();
  }, [fetchOrderData]);

  return (
    <BasicPageWithHeader>
      {!!order && (
        <>
          <ActionsHeader order={order} fetchOrderData={fetchOrderData} />
          <RecapWrapper>
            <OrderRecap order={order} editStep={editStepHandler} />
          </RecapWrapper>
        </>
      )}
    </BasicPageWithHeader>
  );
};

const RecapWrapper = styled.div`
  width: 85%;
  margin: 24px auto;
`;
