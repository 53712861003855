import { Locale as DateFnsLocale } from "date-fns";
import { enGB, fr } from "date-fns/locale";

import { Locale as UserLocale } from "src/types/user";

const mapUserLocaleToDateFnsLocale: Record<UserLocale, DateFnsLocale> = {
  [UserLocale.EN]: enGB,
  [UserLocale.FR]: fr,
};

export const getDateFnsLocaleFromUserLocale = (
  locale: UserLocale
): DateFnsLocale => {
  return mapUserLocaleToDateFnsLocale[locale];
};
