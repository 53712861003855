import { FC, Fragment, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import { BasicPageWithHeader } from "src/components/layout/BasicPageWithHeader";
import { Card } from "src/components/Card";
import { Button } from "src/components/form/Button";
import { OrderAndCodes } from "src/components/pages/checkout/OrderAndCodes";

import { PaymentIntentService } from "src/services/payment-intent";

import {
  Description,
  EmphasizedTitle,
  Separator,
} from "src/styles/styled-components-library";

import type { PaymentIntentMetadata } from "src/types/payment-intent-metadata";

export const Checkout: FC = () => {
  const history = useHistory();
  const [checkoutMetadata, setCheckoutMetadata] =
    useState<PaymentIntentMetadata | null>(null);

  useEffect(() => {
    const metadata = PaymentIntentService.getPaymentIntentData();
    if (!!metadata) {
      PaymentIntentService.deleteLatestData();
      setCheckoutMetadata(metadata);
    }
  }, []);

  return (
    <BasicPageWithHeader>
      <ContentWrapper>
        <CardWrapper>
          <img alt="success icon" src="/icons/check-circle.svg" />
          <EmphasizedTitle>
            Vos commandes Digital <span className="emphasized">Roadbook</span>{" "}
            ont été réglées !
          </EmphasizedTitle>
          <Description>
            Vous pouvez modifier ce voyage avant la date du départ.
          </Description>
          {checkoutMetadata && (
            <>
              <Separator />
              {checkoutMetadata.map((orderData, index) => (
                <Fragment key={orderData.orderName}>
                  <OrderAndCodes {...orderData} />
                  {checkoutMetadata.length > index + 1 && <Separator />}
                </Fragment>
              ))}
            </>
          )}
          <ButtonWrapper>
            <Button onClick={() => history.push("/orders")}>
              Revenir vers mes commandes
            </Button>
          </ButtonWrapper>
        </CardWrapper>
      </ContentWrapper>
    </BasicPageWithHeader>
  );
};

const ContentWrapper = styled.div`
  flex: 1;
  padding: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CardWrapper = styled(Card)`
  width: 900px;
  padding: 60px 120px;

  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  & > *:not(:last-child) {
    margin-bottom: 24px;
  }
`;

const ButtonWrapper = styled.div`
  width: 60%;
  margin: 0 auto;
`;
