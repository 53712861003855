export enum Role {
  ADMIN = "admin",
  EMPLOYEE = "employee",
}

export enum Locale {
  EN = "en",
  FR = "fr",
}

export enum Kind {
  TO = "to",
  DRB = "drb",
}
