import { FC, ChangeEventHandler, CSSProperties, useState, useRef } from "react";
import styled from "styled-components";

import { colors } from "src/theme/colors";

import { ReactComponent as EditIcon } from "src/assets/svg-icons/pencil-edit.svg";
import { ReactComponent as SaveIcon } from "src/assets/svg-icons/save.svg";
import { ReactComponent as CloseIcon } from "src/assets/svg-icons/close.svg";

enum EditStatus {
  IDLE = "IDLE",
  EDITING = "EDITING",
}

type SaveableTextAreaState = "default" | "error";
type SaveableTextAreaStyles = {
  color: NonNullable<CSSProperties["color"]>;
  borderColor: NonNullable<CSSProperties["borderColor"]>;
};
const mapStateToStyles: Record<SaveableTextAreaState, SaveableTextAreaStyles> =
  {
    default: {
      color: colors.main.black,
      borderColor: colors.grey[100],
    },
    error: {
      color: colors.error[300],
      borderColor: colors.error[100],
    },
  };

export interface SaveableTextAreaProps {
  value?: string | number;
  placeholder?: string;
  name?: string;
  onChange?: ChangeEventHandler<HTMLTextAreaElement>;
  onSave: () => void;
  onSaveError: () => void;
  onCancel: () => void;
  hasError?: boolean;
  autoComplete?: "on" | "off";
  rows?: number;
  isValidInput: boolean;
}

export const SaveableTextArea: FC<SaveableTextAreaProps> = props => {
  const {
    value,
    placeholder,
    name,
    autoComplete = "off",
    hasError = false,
    rows = 5,
    isValidInput,
    onSave,
    onSaveError,
    onCancel,
    onChange,
  } = props;

  const [editStatus, setEditStatus] = useState(EditStatus.IDLE);
  const [isTextAreaFocused, setIsTextAreaFocused] = useState(false);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const state: SaveableTextAreaState = hasError ? "error" : "default";
  const textAreaStyles: SaveableTextAreaStyles = mapStateToStyles[state];

  const wrapperClickHandler = () => {
    if (editStatus === EditStatus.EDITING) {
      textAreaRef.current?.focus();
    }
  };
  return (
    <Wrapper
      {...textAreaStyles}
      hasError={hasError}
      focused={isTextAreaFocused}
      onClick={wrapperClickHandler}
      isEditing={editStatus === EditStatus.EDITING}
    >
      <StyledTextArea
        disabled={editStatus === "IDLE"}
        {...textAreaStyles}
        autoComplete={autoComplete}
        value={value}
        hasError={hasError}
        name={name}
        placeholder={placeholder}
        rows={rows}
        onChange={onChange}
        onFocus={() => setIsTextAreaFocused(true)}
        onBlur={() => setIsTextAreaFocused(false)}
      />
      <RightIconsWrapper>
        {editStatus === EditStatus.IDLE && (
          <EditIcon
            onClick={() => {
              setEditStatus(EditStatus.EDITING);
              setImmediate(() => textAreaRef.current?.focus());
            }}
          />
        )}
        {editStatus === EditStatus.EDITING && (
          <>
            <SaveIcon
              onClick={() => {
                if (isValidInput) {
                  onSave();
                  setEditStatus(EditStatus.IDLE);
                } else {
                  onSaveError();
                }
              }}
            />
            <CloseIcon
              onClick={() => {
                setEditStatus(EditStatus.IDLE);
                onCancel();
              }}
            />
          </>
        )}
      </RightIconsWrapper>
    </Wrapper>
  );
};

type WrapperStyleProps = SaveableTextAreaStyles & {
  isEditing: boolean;
  hasError: boolean;
  focused: boolean;
};
const Wrapper = styled.div<WrapperStyleProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;

  cursor: ${({ isEditing }) => (isEditing ? "text" : "inherit")};
  width: 100%;
  padding: 16px;
  border-radius: 4px;
  color: ${({ color, hasError, focused }) =>
    focused && !hasError ? colors.cta.green : color};
  background-color: ${colors.background[100]};
  border: 1px solid
    ${({ borderColor, hasError, focused }) =>
      focused && !hasError ? colors.cta.green : borderColor};
  outline: none;
  transition: all 200ms ease;

  font-weight: 500;
  font-size: 14px;
  line-height: 18px;

  & > p {
    margin: 0;
    flex: 1;
    display: ${({ isEditing }) => (!isEditing ? "block" : "none")};
  }

  & > input {
    flex: 1;
    background-color: transparent;
    border: none;
    outline: none;
    color: inherit;
    display: ${({ isEditing }) => (isEditing ? "inline" : "none")};

    &::placeholder {
      color: ${({ hasError }) =>
        hasError ? colors.error[100] : colors.grey[200]};
    }
  }
`;

type StyleTextAreaProps = SaveableTextAreaStyles & { hasError: boolean };
const StyledTextArea = styled.textarea<StyleTextAreaProps>`
  resize: none;
  width: 100%;
  padding: 16px;
  border-radius: 4px;
  color: ${({ color }) => color};
  background-color: ${colors.background[100]};
  border: 0px solid ${({ borderColor }) => borderColor};
  outline: none;
  transition: all 200ms ease;

  font-weight: 500;
  font-size: 14px;
  line-height: 18px;

  &::placeholder {
    color: ${({ hasError }) =>
      hasError ? colors.error[100] : colors.grey[200]};
  }

  /* we don't display the CTA green color if the textarea has an error */
  &:focus {
    color: ${({ color, hasError }) => (hasError ? color : colors.cta.green)};
    border: 0px solid
      ${({ borderColor, hasError }) =>
        hasError ? borderColor : colors.cta.green};
  }
  height: 300px;
`;

const RightIconsWrapper = styled.div`
  height: 16px;
  color: ${colors.main.black};

  flex-direction: row;
  display: flex;
  & > * {
    cursor: pointer;
    margin-left: 16px;
    width: 16px;
    height: 16px;
  }
`;
