import { FC, useEffect, useMemo, useRef, useState } from "react";
import {
  Column,
  useGlobalFilter,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { Button, StyledButton } from "src/components/form/Button";
import { Card } from "src/components/Card";
import { Table } from "src/components/table/Table";
import { GlobalFilterSearchBar } from "src/components/table/GlobalFilterSearchBar";
import { UpdateStepButtons } from "../UpdateStepButtons";
import { CreateClientModal } from "../CreateClientModal";

import { useRequestStatus, RequestStatus } from "src/hooks/useRequestStatus";
import { api } from "src/services/api";
import { updateOrder } from "src/services/order";
import { makeSelectionHook } from "src/components/table/hooks/selection";

import type { TourOperatorClient } from "src/models/tour-operator-client";
import type { CommonStepProps } from "../EditContent";

interface ClientSelectionProps extends CommonStepProps {}
export const ClientSelection: FC<ClientSelectionProps> = props => {
  const {
    order,
    currentStep,
    previousStepHandler,
    nextStepHandler,
    updateOrderState,
    goToRecapHandler,
  } = props;

  const { t } = useTranslation();
  const [status, setStatus] = useRequestStatus();

  const [showCreateClientModal, setShowCreateClientModal] = useState(false);
  const [existingClients, setExistingClients] = useState<TourOperatorClient[]>(
    [],
  );
  const [toClientIdToSelect, setToClientIdToSelect] = useState<string | null>(
    null,
  );
  const initialSelectionRef = useRef<"pending" | "done">("pending");

  const columns: Column<TourOperatorClient>[] = useMemo(
    () => [
      {
        accessor: "lastName",
        Header: `${t("forms.last_name")}`,
      },
      {
        accessor: "firstName",
        Header: `${t("forms.first_name")}`,
      },
      {
        accessor: "birthday",
        Header: `${t("forms.birthday")}`,
      },
      {
        accessor: "email",
        Header: `${t("forms.email")}`,
      },
    ],
    [t],
  );

  const addAndSelectClient = (client: TourOperatorClient) => {
    setExistingClients(prevClients => [client, ...prevClients]);
    setToClientIdToSelect(client.id);
  };

  const selectionHook = makeSelectionHook(true);
  const instance = useTable(
    {
      columns,
      data: existingClients,
    },
    useGlobalFilter,
    useSortBy,
    // client selection hooks
    useRowSelect,
    selectionHook,
  );

  const { selectedFlatRows, toggleRowSelected } = instance;
  const canGoNextStep = selectedFlatRows.length === 1;

  const submitStepHandler = async (successCallback: () => void) => {
    if (status === RequestStatus.LOADING) {
      return;
    }
    const selectedToClient = selectedFlatRows[0]?.original;
    if (!selectedToClient) {
      return;
    }

    setStatus(RequestStatus.LOADING);
    try {
      const res = await updateOrder(order.id, {
        clients: [{ id: selectedToClient.id }],
      });
      if (!res.data.success) {
        throw new Error();
      }
      updateOrderState(res.data.order);
      successCallback();
    } catch {
      setStatus(RequestStatus.SERVER_ERROR);
    }
  };

  // effect fetching all the tour-op clients
  useEffect(() => {
    async function fetchClients() {
      try {
        const res = await api.get<TourOperatorClient[]>(
          `/tour-operators/${order.tourOperator?.id}/clients`,
        );
        if (!res.data) {
          throw new Error();
        }
        setExistingClients(res.data);
      } catch {
        setExistingClients([]);
      }
    }
    fetchClients();
  }, [order.tourOperator?.id]);

  // effect selecting the client that is already linked to the order
  useEffect(() => {
    if (
      existingClients.length > 0 &&
      order.clients &&
      order.clients.length > 0 &&
      initialSelectionRef.current === "pending"
    ) {
      initialSelectionRef.current = "done";
      setToClientIdToSelect(order.clients[0].tourOperatorClient?.id || null);
    }
  }, [existingClients, order]);

  // effect reacting to the state value "toClientIdToSelect" to select the tour-op client
  useEffect(() => {
    if (!!toClientIdToSelect) {
      const rowId = existingClients.findIndex(
        client => client.id === toClientIdToSelect,
      );
      if (rowId < 0) {
        return;
      }
      toggleRowSelected(String(rowId), true);
      setToClientIdToSelect(null);
    }
  }, [existingClients, toClientIdToSelect, toggleRowSelected]);

  return (
    <Wrapper>
      <ActionsHeader>
        <GlobalFilterSearchBar
          instance={instance}
          placeholder={t("pages.orders.edit.client.search")}
        />
        <Button onClick={() => setShowCreateClientModal(true)}>
          {t("pages.orders.edit.client.create")}
        </Button>
      </ActionsHeader>
      <CardWrapper>
        <Table
          instance={instance}
          paginationActivated={false}
          columnsWidths={["23%", "23%", "20%", "25%"]}
        />
      </CardWrapper>
      <UpdateStepButtons
        order={order}
        disableNext={!canGoNextStep}
        currentStep={currentStep}
        loadingNext={status === RequestStatus.LOADING}
        previousHandler={previousStepHandler}
        nextHandler={() => submitStepHandler(nextStepHandler)}
        recapHandler={() => submitStepHandler(goToRecapHandler)}
      />
      <CreateClientModal
        tourOperatorId={order.tourOperator?.id || ""}
        showModal={showCreateClientModal}
        closeModal={() => setShowCreateClientModal(false)}
        addAndSelectClient={addAndSelectClient}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  & > *:not(:last-child) {
    margin-bottom: 16px;
  }
`;

const ActionsHeader = styled.div`
  display: flex;

  ${StyledButton} {
    width: min-content;
    margin-left: 12px;
  }
`;

const CardWrapper = styled(Card)`
  padding: 24px;
`;
