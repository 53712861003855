import { useEffect } from "react";
import { useHistory } from "react-router";

import { useAuth } from "src/contexts/AuthContext";

/**
 * Hooks redirecting the user if he tries to access a offline page (like "sign-in" or "sign-up")
 * while he already is connected.
 */
export const useAuthRedirect = () => {
  const history = useHistory();
  const { isLoggedIn } = useAuth();

  useEffect(() => {
    if (isLoggedIn) {
      history.push("/");
    }
  }, [history, isLoggedIn]);
};
