import { useCallback, useEffect, useState } from "react";
import { List } from "src/models/list";
import { getAllLists } from "src/services/lists";

enum FetchStatus {
  PENDING = "PENDING",
  DONE = "DONE",
}

export const useLists = (type?: string) => {
  const [lists, setLists] = useState<List[]>([]);
  const [fetchStatus, setFetchStatus] = useState(FetchStatus.PENDING);

  const refreshLists = useCallback(async (type?: string) => {
    try {
      const res = await getAllLists(type);
      if (!res.data) {
        throw new Error();
      }

      setLists(res.data);
    } catch {
      setLists([]);
    } finally {
      setFetchStatus(FetchStatus.DONE);
    }
  }, []);

  useEffect(() => {
    refreshLists(type);
  }, [refreshLists, type]);

  return {
    lists,
    fetchStatus,
    refreshLists,
    FetchStatus,
  };
};
