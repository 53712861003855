import { FC, useState, useEffect, useCallback } from "react";
import { useLocation, useHistory, Link } from "react-router-dom";
import styled from "styled-components";

import { Loader } from "src/components/Loader";
import { Logo } from "src/components/Logo";
import { Button } from "src/components/form/Button";

import { useAuth } from "src/contexts/AuthContext";
import { api } from "src/services/api";

import { Title, WavePageWrapper } from "src/styles/styled-components-library";
import { colors } from "src/theme/colors";

enum Status {
  LOADING = "LOADING",
  ERROR = "ERROR",
  SUCCESS = "SUCCESS",
}

export const ValidateAccount: FC = () => {
  const history = useHistory();
  const location = useLocation();

  const { isLoggedIn, userPayload } = useAuth();
  const [status, setStatus] = useState(Status.LOADING);

  const verifyAccountHandler = useCallback(
    async (token: string) => {
      if (status !== Status.LOADING) {
        return;
      }

      try {
        const res = await api.post<{ success: boolean }>(
          "/auth/validate-account",
          { token },
        );
        if (!res.data.success) {
          throw new Error();
        }
        setStatus(Status.SUCCESS);
      } catch {
        setStatus(Status.ERROR);
      }
    },
    [status],
  );

  // effect running on mount to verify account with token
  useEffect(() => {
    const token = new URLSearchParams(location.search).get("token");
    if (token) {
      verifyAccountHandler(token);
    } else {
      history.push("/");
    }
  }, [history, location, verifyAccountHandler]);

  // effect verifying authenticated user are not validating their account twice
  useEffect(() => {
    if (isLoggedIn && userPayload?.accountIsActivated) {
      history.push("/");
    }
  }, [isLoggedIn, userPayload, history]);

  return (
    <ValidateAccountWrapper>
      <Logo />
      {status === Status.LOADING && (
        <ContentWrapper>
          <Loader size="64px" />
        </ContentWrapper>
      )}
      {status === Status.ERROR && (
        <ContentWrapper>
          <ErrorTitle>
            Une erreur s'est produite lors de la vérification de votre compte,
            veuillez réessayer plus tard.
          </ErrorTitle>
        </ContentWrapper>
      )}
      {status === Status.SUCCESS && (
        <ContentWrapper>
          <SuccessIcon width="32" height="32" src="/icons/check-circle.svg" />
          <SuccessTitle>
            Votre compte a bien été vérifié. Vous avez désormais accès à toutes
            les features proposées par le back-office DigitalRoadbook.
          </SuccessTitle>
          <Link to="/">
            <Button>Accéder au back-office</Button>
          </Link>
        </ContentWrapper>
      )}
    </ValidateAccountWrapper>
  );
};

const ValidateAccountWrapper = styled(WavePageWrapper)`
  padding: 52px;
  align-items: center;
`;

const ContentWrapper = styled.div`
  flex: 1;
  max-width: 600px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  & > *:not(:last-child) {
    margin-bottom: 24px;
  }
`;

const ErrorTitle = styled(Title)`
  color: ${colors.error[300]};
`;

const SuccessTitle = styled(Title)`
  color: ${colors.cta.green};
`;

const SuccessIcon = styled.img`
  display: block;
  margin: 0 auto;
  width: 32px;
  height: 32px;
`;
