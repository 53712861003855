import { CSSProperties } from "react";
import { toast, ToastOptions } from "react-hot-toast";

import { colors } from "src/theme/colors";

/* ************* CUSTOM THEMES ************* */
type ToastVariant = "error" | "success";
const mapVariantToToastStyle: Record<ToastVariant, CSSProperties> = {
  error: {
    backgroundColor: colors.custom.error[50],
    color: colors.error[300],
  },
  success: {
    backgroundColor: colors.turquoise[100],
    color: colors.turquoise[600],
  },
};
const mapVariantToToastIcon: Record<ToastVariant, ToastOptions["icon"]> = {
  error: <img alt="error icon" src="/icons/error.svg" />,
  success: <img alt="success icon" src="/icons/check-circle.svg" />,
};
/* ***************************************** */

export const useToastNotifications = () => {
  const sendToast = (
    variant: ToastVariant,
    message: string,
    duration?: number
  ) => {
    toast(message, {
      style: mapVariantToToastStyle[variant],
      icon: mapVariantToToastIcon[variant],
      duration,
    });
  };

  return {
    sendToast,
  };
};
