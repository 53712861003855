import { useCallback, useEffect, useState } from "react";

function useLoadData<T>(
  loadFunction: (bagId?: number) => Promise<T | null>,
  condition = true,
) {
  const [data, setData] = useState<T | null>(null);
  const [refreshing, setRefreshing] = useState(false);

  const loadData = async () => {
    try {
      if (condition) {
        const res = await loadFunction();
        setData(res);
      } else {
        setData(null);
      }
    } catch (error) {
      // If it fails, handle the api message error if the error is actually an api error.
    }
  };

  const onRefresh = useCallback(async () => {
    setRefreshing(true);
    await loadData();
    setRefreshing(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { refreshing, loadData, onRefresh, data };
}

export default useLoadData;
