import { ChangeEvent, FC, useEffect } from "react";
import styled from "styled-components";
import { FormikErrors, useFormik } from "formik";

import { Card } from "src/components/Card";
import { Button } from "src/components/form/Button";
import { LabeledSelect } from "src/components/form/LabeledSelect";
import { LabeledTextInput } from "src/components/form/LabeledTextInput";
import { Alert } from "src/components/Alert";

import { useRequestStatus, RequestStatus } from "src/hooks/useRequestStatus";
import { validateNonEmptyFields } from "src/utils/validations";
import { api } from "src/services/api";

import { Title } from "src/styles/styled-components-library";

import type { SelectOption } from "src/components/form/Select";

import { ReactComponent as GoBackIcon } from "src/assets/svg-icons/navigation-left.svg";
import { useLists } from "src/hooks/useLists";
import { useList } from "src/hooks/useList";
import { useHistory, useParams } from "react-router-dom";
import { BasicPageWithHeader } from "src/components/layout/BasicPageWithHeader";

type Key = "region";

type FormInput = {
  key: Key;
  label: string;
  placeholder: string;
  type: "text" | "select";
  options?: SelectOption[];
};

interface EditListProps {}
export const EditList: FC<EditListProps> = props => {
  const params = useParams<{ listId: string }>();
  const { refreshLists, lists } = useLists();
  const { list } = useList(params.listId);
  const [status, setStatus] = useRequestStatus();
  const history = useHistory();

  const formInitialValues = {
    region: list?.region.name,
  };

  const formik = useFormik({
    initialValues: formInitialValues,
    enableReinitialize: true,
    validate: values => {
      const errors: FormikErrors<typeof formInitialValues> = {};
      const emptyFields = validateNonEmptyFields(values, "region");

      const filteredList = lists.filter(item => {
        return item.region.name === formik.values["region"];
      });

      if (filteredList.length > 0) {
        errors["region"] = "La région existe déjà";
      }
      if (emptyFields.length > 0) {
        for (const key of emptyFields) {
          errors[key] = "This field is empty.";
        }
      }
      if (Object.keys(errors).length > 0) {
        setStatus(RequestStatus.VALIDATION_ERROR);
      }
      return errors;
    },
    validateOnChange: false,
    onSubmit: async values => {
      if (
        status === RequestStatus.LOADING ||
        status === RequestStatus.SUCCESS
      ) {
        return;
      }

      // !! TODO : déterminer le "locale" via la langue actuelle de l'app React
      // (quand on aura mis en place i18n)
      const body = {
        ...values,
        locale: "fr",
      };

      setStatus(RequestStatus.LOADING);
      try {
        const resRegion = await api.patch(`/lists/${params.listId}`, {
          ...body,
          name: formik.values["region"],
        });
        refreshLists();

        if (!resRegion.data) {
          throw new Error();
        }

        setStatus(RequestStatus.SUCCESS);
      } catch {
        setStatus(RequestStatus.SERVER_ERROR);
      }
    },
  });

  const inputs: FormInput[] = [
    {
      key: "region",
      label: "Nom du pays",
      placeholder: "",
      type: "text",
    },
  ];

  useEffect(() => {
    refreshLists();
  }, [refreshLists]);

  const updateTextInputHandler = (
    e: ChangeEvent<HTMLInputElement>,
    key: Key,
  ) => {
    formik.setErrors({
      ...formik.errors,
      [key]: undefined,
    });
    formik.handleChange(e);
  };

  return (
    <BasicPageWithHeader>
      <Wrapper>
        <NavigationWrapper>
          <StyledGoBackIcon
            onClick={() => {
              history.push("/lists");
            }}
          />
        </NavigationWrapper>
        <CardWrapper>
          <FormWrapper onSubmit={formik.handleSubmit}>
            <Title>Modification d'un pays</Title>
            <InputsWrapper>
              {inputs.map(input => {
                return input.type === "select" ? (
                  <LabeledSelect
                    key={input.key}
                    name={input.key}
                    label={input.label}
                    placeholder={input.placeholder}
                    value={formik.values[input.key]}
                    hasError={!!formik.errors[input.key]}
                    onChange={formik.handleChange}
                    options={input.options || []}
                  />
                ) : (
                  <LabeledTextInput
                    key={input.key}
                    name={input.key}
                    type={input.type}
                    label={input.label}
                    placeholder={input.placeholder}
                    value={formik.values[input.key]}
                    hasError={!!formik.errors[input.key]}
                    onChange={e => updateTextInputHandler(e, input.key)}
                  />
                );
              })}
            </InputsWrapper>
            {status === RequestStatus.VALIDATION_ERROR && (
              <>
                <Alert>
                  Certains champs ne sont pas valides, veuillez les modifier et
                  réessayer.
                </Alert>
                <Alert>{formik.errors["region"]}</Alert>
              </>
            )}
            {status === RequestStatus.SERVER_ERROR && (
              <Alert>
                La modification n'a pas pu aboutir. Veuillez réessayer plus
                tard.
              </Alert>
            )}
            {status === RequestStatus.SUCCESS && (
              <Alert variant="success">
                Le pays a été modifié avec succès !
              </Alert>
            )}
            <Button type="submit">Modifier le pays</Button>
          </FormWrapper>
        </CardWrapper>
      </Wrapper>
    </BasicPageWithHeader>
  );
};

export const Wrapper = styled.div`
  flex: 1;
  padding: 16px;
  display: flex;
  flex-direction: column;

  & > *:not(:last-child) {
    margin-bottom: 12px;
  }
`;

const NavigationWrapper = styled.div`
  width: 750px;
  margin: 0 auto;
`;

const StyledGoBackIcon = styled(GoBackIcon)`
  cursor: pointer;
`;

const CardWrapper = styled(Card)`
  margin: 0 auto;
  padding: 24px;
  width: 750px;
`;

const FormWrapper = styled.form`
  width: 100%;

  & > a:first-child {
    display: inline-block;
    margin-bottom: 12px;
  }

  & > *:not(:last-child) {
    margin-bottom: 24px;
  }
`;

const InputsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  & > * {
    width: 48%;
  }

  @supports (gap: 24px 28px) {
    gap: 24px 28px;
  }
  @supports not (gap: 24px 28px) {
    & > * {
      margin-bottom: 24px;
    }
  }
`;
