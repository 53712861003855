import { FC } from "react";

import { NoPointsOfInterest } from "src/components/pages/points-of-interest/NoPointsOfInterest";
import { AllPointsOfInterest } from "src/components/pages/points-of-interest/AllPointsOfInterest";
import styled from "styled-components";
import { Pinpoint } from "src/models/pinpoint";

type PointsOfInterestProps = {
  refreshPinpoints: (listId: string) => Promise<void>;
  pinpoints: Pinpoint[];
};

export const PointsOfInterest: FC<PointsOfInterestProps> = ({
  pinpoints,
  refreshPinpoints,
}) => {
  return (
    <>
      {pinpoints && pinpoints.length === 0 ? (
        <NoPointsOfInterest />
      ) : (
        <Wrapper>
          <AllPointsOfInterest
            pinpoints={pinpoints}
            refreshPinpoints={refreshPinpoints}
          />
        </Wrapper>
      )}
    </>
  );
};

export const Wrapper = styled.div`
  flex: 1;
  padding: 16px;
  display: flex;
  flex-direction: column;

  & > *:not(:last-child) {
    margin-bottom: 12px;
  }
`;
