import { useCallback, useEffect, useState } from "react";
import { User } from "src/models/user";
import { api } from "src/services/api";

enum FetchStatus {
  PENDING = "PENDING",
  DONE = "DONE",
}

export const useUser = (userId: string | null) => {
  const [user, setUser] = useState<User | null>(null);
  const [fetchStatus, setFetchStatus] = useState(FetchStatus.PENDING);

  const refreshUserData = useCallback((userId: string | null) => {
    try {
      if (!userId) {
        throw new Error();
      }

      api.get<{ user: User }>(`/users/${userId}`).then(res => {
        if (res.data.user) {
          setUser(res.data.user);
        }
      });
    } catch {
      setUser(null);
    } finally {
      setFetchStatus(FetchStatus.DONE);
    }
  }, []);

  useEffect(() => {
    refreshUserData(userId);
  }, [refreshUserData, userId]);

  return {
    user,
    refreshUserData,
    fetchStatus,
    FetchStatus,
  };
};
