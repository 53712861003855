import { FC } from "react";

import { NoPracticalInformations } from "src/components/pages/practical-informations/NoPracticalInformations";
import styled from "styled-components";
import { PracticalInformation } from "src/models/practicalInformation";
import { AllPracticalInformations } from "src/components/pages/practical-informations/AllPracticalInformations";

interface PracticalInformationsProps {
  practicalInformations: PracticalInformation[];
  refreshPracticalInformations: (listId: string) => void;
}

export const PracticalInformations: FC<PracticalInformationsProps> = ({
  practicalInformations,
  refreshPracticalInformations,
}) => {
  return (
    <>
      {practicalInformations && practicalInformations.length === 0 ? (
        <NoPracticalInformations />
      ) : (
        <Wrapper>
          <AllPracticalInformations
            practicalInformations={practicalInformations}
            refreshPracticalInformations={refreshPracticalInformations}
          />
        </Wrapper>
      )}
    </>
  );
};

export const Wrapper = styled.div`
  flex: 1;
  padding: 16px;
  display: flex;
  flex-direction: column;

  & > *:not(:last-child) {
    margin-bottom: 12px;
  }
`;
