import { FC, useEffect, useMemo, useRef } from "react";
import { useFormik } from "formik";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { Card } from "src/components/Card";
import { LabeledSaveableTextInput } from "src/components/form/LabeledSaveableTextInput";
import { Alert } from "src/components/Alert";

import { useRequestStatus, RequestStatus } from "src/hooks/useRequestStatus";
import { validateEmail } from "src/utils/validations";
import { api } from "src/services/api";

import type { User } from "src/models/user";

interface PersonalInformationsProps {
  user: User;
}
export const PersonalInformations: FC<PersonalInformationsProps> = props => {
  const { user } = props;
  const { t } = useTranslation();

  // form init & type definitions
  const formInitialValues = useMemo(
    () => ({
      lastName: user.lastName,
      firstName: user.firstName,
      email: user.email,
      phone: user.phone,
    }),
    [user],
  );
  type FormKey = keyof typeof formInitialValues;
  type FormInput = {
    key: FormKey;
    label: string;
    placeholder: string;
    isValid: (input: string) => boolean;
  };
  const inputs: FormInput[] = [
    {
      key: "lastName",
      label: t("forms.last_name"),
      placeholder: "Nom",
      isValid: input => !!input.trim(),
    },
    {
      key: "firstName",
      label: t("forms.first_name"),
      placeholder: "Prénom",
      isValid: input => !!input.trim(),
    },
    {
      key: "email",
      label: t("forms.email"),
      placeholder: "mail@mail.com",
      isValid: input => validateEmail(input),
    },
    {
      key: "phone",
      label: t("forms.phone"),
      placeholder: "+ 33 07 83 32 42 42",
      isValid: input => !!input.trim(),
    },
  ];

  // hooks
  const formik = useFormik({
    initialValues: formInitialValues,
    onSubmit: () => {},
  });
  const latestSavedValuesRef =
    useRef<typeof formInitialValues>(formInitialValues);
  const [status, setStatus] = useRequestStatus();

  // callbacks
  const cancelInputHandler = (key: FormKey) => {
    formik.setValues({
      ...formik.values,
      [key]: latestSavedValuesRef.current[key],
    });
  };

  const saveInputHandler = async (key: FormKey) => {
    if (
      status === RequestStatus.LOADING ||
      formik.values[key] === latestSavedValuesRef.current[key]
    ) {
      return;
    }

    setStatus(RequestStatus.LOADING);
    try {
      const res = await api.patch<{ success: boolean }>(`/users/${user.id}`, {
        [key]: formik.values[key],
      });
      if (!res.data.success) {
        throw new Error();
      }

      latestSavedValuesRef.current = {
        ...latestSavedValuesRef.current,
        [key]: formik.values[key],
      };
      setStatus(RequestStatus.SUCCESS);
    } catch {
      cancelInputHandler(key);
      setStatus(RequestStatus.SERVER_ERROR);
    }
  };

  // effect making sure the form data is up-to-date when the "user" prop changes
  useEffect(() => {
    formik.setValues(formInitialValues);
    latestSavedValuesRef.current = formInitialValues;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formInitialValues]);

  return (
    <CardWrapper>
      <Form onSubmit={e => e.preventDefault()}>
        {inputs.map(input => {
          const isValidInput = input.isValid(formik.values[input.key]);
          return (
            <LabeledSaveableTextInput
              key={input.key}
              name={input.key}
              label={input.label}
              placeholder={input.placeholder}
              value={formik.values[input.key]}
              onChange={formik.handleChange}
              onCancel={() => cancelInputHandler(input.key)}
              onSave={() => saveInputHandler(input.key)}
              onSaveError={() => setStatus(RequestStatus.VALIDATION_ERROR)}
              hasError={
                status === RequestStatus.VALIDATION_ERROR && !isValidInput
              }
              isValidInput={isValidInput}
            />
          );
        })}
      </Form>
      {status === RequestStatus.VALIDATION_ERROR && (
        <Alert>
          Les champs indiqués ne sont pas valides. La modification n'a pas pu
          être effectuée.
        </Alert>
      )}
      {status === RequestStatus.SERVER_ERROR && (
        <Alert>
          Une erreur s'est produite pendant la modification de vos informations.
        </Alert>
      )}
      {status === RequestStatus.SUCCESS && (
        <Alert variant="success">Les informations ont été modifiées.</Alert>
      )}
    </CardWrapper>
  );
};

const CardWrapper = styled(Card)`
  padding: 64px 110px;

  & > *:not(:last-child) {
    margin-bottom: 24px;
  }
`;

const Form = styled.form`
  display: flex;
  flex-wrap: wrap;
  gap: 24px 30px;

  & > * {
    width: 47.5%;
  }
`;
