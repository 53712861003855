import { FC, useCallback, useEffect, useState } from "react";
import styled from "styled-components";

import { DisplayAll } from "./ToUsers/DisplayAll";
import { DisplayUser } from "./ToUsers/DisplayUser";
import { DisplayNewUser } from "./ToUsers/DisplayNewUser";

import { api } from "src/services/api";

import type { User } from "src/models/user";

export enum Display {
  ALL = "ALL",
  USER = "USER",
  NEW_USER = "NEW_USER",
}

interface ToUsersProps {
  user: User;
}
export const ToUsers: FC<ToUsersProps> = props => {
  const { user } = props;
  const [display, setDisplay] = useState(Display.ALL);
  const [users, setUsers] = useState<User[]>([]);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);

  const updateDisplay = (display: Display) => {
    setDisplay(display);
  };

  const selectUser = useCallback((user: User) => {
    setSelectedUser(user);
    setDisplay(Display.USER);
  }, []);

  const refreshTourOperatorUsers = useCallback(async () => {
    const res = await api.get<User[]>(
      `/tour-operators/${user.tourOperator.id}/users`,
    );
    if (!res.data) {
      setUsers([]);
    }
    setUsers(res.data);
  }, [user]);

  // users fetching
  useEffect(() => {
    refreshTourOperatorUsers();
  }, [refreshTourOperatorUsers]);

  return (
    <Wrapper>
      {display === Display.ALL && (
        <DisplayAll
          users={users}
          updateDisplay={updateDisplay}
          selectUser={selectUser}
        />
      )}
      {display === Display.USER && selectedUser && (
        <DisplayUser
          user={selectedUser}
          updateDisplay={updateDisplay}
          refreshUsersData={refreshTourOperatorUsers}
        />
      )}
      {display === Display.NEW_USER && (
        <DisplayNewUser
          updateDisplay={updateDisplay}
          refreshUsersData={refreshTourOperatorUsers}
        />
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  & > *:not(:last-child) {
    margin-bottom: 12px;
  }
`;
