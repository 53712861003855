import { FC, ChangeEventHandler, CSSProperties } from "react";
import styled from "styled-components";

import { colors } from "src/theme/colors";

type SelectState = "default" | "error" | "disabled";
type SelectStyles = {
  color: NonNullable<CSSProperties["color"]>;
  borderColor: NonNullable<CSSProperties["borderColor"]>;
  backgroundColor: NonNullable<CSSProperties["backgroundColor"]>;
};
const mapStateToStyles: Record<SelectState, SelectStyles> = {
  default: {
    color: colors.main.black,
    borderColor: colors.grey[100],
    backgroundColor: colors.background[100],
  },
  error: {
    color: colors.error[300],
    borderColor: colors.error[100],
    backgroundColor: colors.background[100],
  },
  disabled: {
    color: colors.grey[600],
    borderColor: "rgba(34, 34, 34, 0.05)",
    backgroundColor: colors.grey[100],
  },
};

export type SelectOption = {
  label: string;
  value: string;
};
export interface SelectProps {
  value?: string | number;
  name?: string;
  placeholder?: string;
  className?: string;
  disabled?: boolean;
  onChange?: ChangeEventHandler<HTMLSelectElement>;
  hasError?: boolean;
  autoComplete?: "on" | "off";
  options: SelectOption[];
}

export const Select: FC<SelectProps> = (props) => {
  const {
    options,
    value,
    name,
    className,
    placeholder,
    autoComplete = "off",
    hasError = false,
    disabled = false,
    onChange,
  } = props;

  const state: SelectState = disabled
    ? "disabled"
    : hasError
    ? "error"
    : "default";
  const selectStyles: SelectStyles = mapStateToStyles[state];
  return (
    <StyledSelect
      {...selectStyles}
      name={name}
      className={className}
      autoComplete={autoComplete}
      value={value}
      hasError={hasError}
      placeholder={placeholder}
      disabled={disabled}
      onChange={onChange}
    >
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </StyledSelect>
  );
};

type StyledSelectProps = SelectStyles & { hasError: boolean };
const StyledSelect = styled.select<StyledSelectProps>`
  width: 100%;
  height: 52px;
  padding: 16px;
  border-radius: 4px;
  color: ${({ color }) => color};
  background-color: ${({ backgroundColor }) => backgroundColor};
  border: 1px solid ${({ borderColor }) => borderColor};
  outline: none;
  transition: all 200ms ease;

  font-weight: 500;
  font-size: 14px;
  line-height: 18px;

  &::placeholder {
    color: ${({ hasError }) =>
      hasError ? colors.error[100] : colors.grey[200]};
  }

  /* we don't display the CTA green color if the input has an error */
  &:focus {
    color: ${({ color, hasError }) => (hasError ? color : colors.cta.green)};
    border: 1px solid
      ${({ borderColor, hasError }) =>
        hasError ? borderColor : colors.cta.green};

    option {
      color: ${({ color }) => color};
    }
  }
`;
