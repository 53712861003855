import { FC } from "react";
import styled from "styled-components";

export interface BadgeProps {
  color: string;
  borderColor: string;
  backgroundColor: string;
}
export const Badge: FC<BadgeProps> = (props) => {
  const { children, ...styleProps } = props;
  return <BadgeWrapper {...styleProps}>{children}</BadgeWrapper>;
};

const BadgeWrapper = styled.span<BadgeProps>`
  display: inline-block;
  padding: 8px;
  border-radius: 4px;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: ${({ color }) => color};
  border: 1px solid ${({ borderColor }) => borderColor};
  background-color: ${({ backgroundColor }) => backgroundColor};
`;
