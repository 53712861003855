import { FC } from "react";
import styled from "styled-components";

import { rotate } from "src/styles/styled-animations";
import { colors } from "src/theme/colors";

interface LoaderProps {
  color?: string;
  size?: string;
  borderSize?: string;
}
export const Loader: FC<LoaderProps> = (props) => {
  const { color = colors.cta.green, size = "48px", borderSize = "5px" } = props;
  return <StyledLoader color={color} size={size} borderSize={borderSize} />;
};

type StyledLoaderStyleProps = {
  color: string;
  size: string;
  borderSize: string;
};
const StyledLoader = styled.span<StyledLoaderStyleProps>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  border: ${({ borderSize }) => borderSize} solid ${({ color }) => color};
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: ${rotate} 1s linear infinite;
`;
