import { useCallback, useEffect, useState } from "react";
import { List } from "src/models/list";
import { getList } from "src/services/lists";

enum FetchStatus {
  PENDING = "PENDING",
  DONE = "DONE",
}

export const useList = (listId: string) => {
  const [list, setList] = useState<List | null>(null);
  const [fetchStatus, setFetchStatus] = useState(FetchStatus.PENDING);

  const refreshList = useCallback(async (listId: string) => {
    try {
      const res = await getList(listId);
      if (!res.data) {
        throw new Error();
      }

      setList(res.data);
    } catch {
      setList(null);
    } finally {
      setFetchStatus(FetchStatus.DONE);
    }
  }, []);

  useEffect(() => {
    refreshList(listId);
  }, [refreshList, listId]);

  return {
    list,
    fetchStatus,
    refreshList,
    FetchStatus,
  };
};
