import { FC } from "react";
import styled from "styled-components";

import { colors } from "src/theme/colors";

type Variant = "error" | "success" | "info";

const mapVariantToIconSrc: Record<Variant, string> = {
  error: "/icons/error.svg",
  success: "/icons/check-circle.svg",
  info: "/icons/question-bubble.svg",
};
const mapVariantToColor: Record<Variant, string> = {
  error: colors.error[300],
  success: colors.turquoise[600],
  info: colors.grey[200],
};
const mapVariantToBackgroundColor: Record<Variant, string> = {
  error: colors.custom.error[50], // soft light red
  success: colors.turquoise[100],
  info: colors.background[100],
};

interface AlertProps {
  variant?: Variant;
  transparent?: boolean;
  className?: string;
}
export const Alert: FC<AlertProps> = (props) => {
  const { variant = "error", transparent = true, className, children } = props;
  return (
    <Wrapper
      color={mapVariantToColor[variant]}
      backgroundColor={mapVariantToBackgroundColor[variant]}
      transparent={transparent}
      className={className}
    >
      <IconWrapper>
        <img alt="alert icon" src={mapVariantToIconSrc[variant]} />
      </IconWrapper>
      <p>{children}</p>
    </Wrapper>
  );
};

type WrapperStyleProps = {
  color: string;
  backgroundColor: string;
  transparent: boolean;
};
const Wrapper = styled.div<WrapperStyleProps>`
  width: 100%;
  display: flex;
  color: ${({ color }) => color};
  background-color: ${({ transparent, backgroundColor }) =>
    transparent ? "transparent" : backgroundColor};
  border-radius: 4px;
  padding: ${({ transparent }) => (transparent ? "0" : "16px 16px 12px 16px")};

  & > p {
    text-align: left;
    font-weight: 500;
    margin: 2px 0 0 0;
    flex: 1;
    font-size: 12px;
    line-height: 18px;
  }
`;

const IconWrapper = styled.div`
  margin-right: 8px;
  & > img {
    width: 20px;
    height: 20px;
  }
`;
