import { FC, useMemo } from "react";
import {
  CellProps,
  Column,
  useFilters,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { Badge } from "src/components/Badge";
import { PaginationMenu } from "src/components/table/PaginationMenu";
import { Table } from "src/components/table/Table";
import { Card } from "src/components/Card";
import { TableActions } from "./GeneralActions";
import { EnumValuesFilter } from "src/components/table/filters/EnumValuesFilter";

import { enumFilter } from "src/utils/table";
import {
  mapRoleToLabel,
  mapRoleToBadgeStyle,
  roleOptions,
} from "src/constants/role";

import { colors } from "src/theme/colors";

import { User } from "src/models/user";
import { Display as DisplayEnum } from "../ToUsers";

import { ReactComponent as EditActionIcon } from "src/assets/svg-icons/actions/edit.svg";

interface DisplayAllProps {
  users: User[];
  updateDisplay: (display: DisplayEnum) => void;
  selectUser: (user: User) => void;
}
export const DisplayAll: FC<DisplayAllProps> = props => {
  const { users, updateDisplay, selectUser } = props;
  const { t } = useTranslation();
  const columns: Column<User>[] = useMemo(
    () => [
      {
        Header: `${t("forms.last_name")}`,
        accessor: "lastName",
        disableFilters: true,
      },
      {
        Header: `${t("forms.first_name")}`,
        accessor: "firstName",
        disableFilters: true,
      },
      {
        Header: `${t("forms.email")}`,
        accessor: "email",
        disableFilters: true,
      },
      {
        Header: `${t("forms.status")}`,
        accessor: "role",
        disableSortBy: true,
        Cell: ({ value }: CellProps<{}, User["role"]>) => (
          <Badge {...mapRoleToBadgeStyle[value]}>{mapRoleToLabel[value]}</Badge>
        ),
        Filter: props => (
          <EnumValuesFilter
            {...props}
            options={roleOptions}
            styles={mapRoleToBadgeStyle}
          />
        ),
        filter: enumFilter,
      },
      {
        Header: `${t("forms.actions")}`,
        accessor: "id",
        disableSortBy: true,
        disableFilters: true,
        Cell: ({ value }: CellProps<{}, User["id"]>) => (
          <StyledEditActionIcon
            onClick={e => {
              e.stopPropagation();
              const user = users.find(user => user.id === value);
              if (!user) {
                return;
              }
              selectUser(user);
            }}
          />
        ),
      },
    ],
    [users, selectUser, t],
  );

  const tableInstance = useTable(
    {
      columns,
      data: users,
      initialState: {
        globalFilter: "",
        pageSize: 10,
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  return (
    <>
      <TableActions instance={tableInstance} updateDisplay={updateDisplay} />
      <CardWrapper>
        <Table
          instance={tableInstance}
          columnsWidths={["15%", "15%", "30%", "25%", "15%"]}
        />
      </CardWrapper>
      <PaginationMenuWrapper>
        <PaginationMenu
          instance={tableInstance}
          label={t("pages.settings.users.per_pages")}
        />
      </PaginationMenuWrapper>
    </>
  );
};

const CardWrapper = styled(Card)`
  padding: 24px;
`;

const PaginationMenuWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const StyledEditActionIcon = styled(EditActionIcon)`
  color: ${colors.grey[600]};
  transition: color 150ms ease;
  cursor: pointer;

  &:hover {
    color: ${colors.cta.green};
  }
`;
