import { FC, useState } from "react";
import styled from "styled-components";

import { LabeledReadOnlyInput } from "src/components/form/LabeledReadOnlyInput";

import { Title } from "src/styles/styled-components-library";
import { colors } from "src/theme/colors";

interface OrderAndCodesProps {
  orderName: string;
  codes: string[];
}
export const OrderAndCodes: FC<OrderAndCodesProps> = props => {
  const { orderName, codes } = props;

  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <Wrapper>
      <ClickableTitle onClick={() => setIsExpanded(prevState => !prevState)}>
        Commande #{orderName}
      </ClickableTitle>
      {isExpanded &&
        codes.map(code => (
          <LabeledReadOnlyInput
            key={code}
            label="Code RB associé au voyage"
            value={code}
          />
        ))}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  min-width: 60%;
  text-align: left;

  & > *:not(:last-child) {
    width: 100%;
    margin-bottom: 24px;
  }
`;

const ClickableTitle = styled(Title)`
  cursor: pointer;
  transition: background-color 200ms ease;
  padding: 12px;

  &:hover {
    background-color: ${colors.background[100]};
  }
`;
