import { FC, FormEventHandler, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { Logo } from "src/components/Logo";
import { LabeledTextInput } from "src/components/form/LabeledTextInput";
import { Button } from "src/components/form/Button";
import { Alert } from "src/components/Alert";

import TokenService from "src/services/token";
import { useAuth } from "src/contexts/AuthContext";
import { useAuthRedirect } from "src/hooks/useAuthRedirect";
import { api } from "src/services/api";
import { decodeAccessToken } from "src/utils/api";

import {
  WavePageWrapper,
  Description,
} from "src/styles/styled-components-library";

import type { Tokens } from "src/types/tokens";

enum Mode {
  LOGIN = "LOGIN",
  RESET_PASSWORD = "RESET_PASSWORD",
}

enum Status {
  IDLE = "IDLE",
  LOADING = "LOADING",
  ERROR = "ERROR",
  SUCCESS = "SUCCESS",
}

export const SignIn: FC = () => {
  // preventing authenticated users from accessing this page
  useAuthRedirect();

  const { t } = useTranslation();

  const mapModeToButtonLabel: Record<Mode, string> = {
    [Mode.LOGIN]: t("pages.sign_in.sign_in"),
    [Mode.RESET_PASSWORD]: t("pages.sign_in.reset"),
  };

  const mapModeToTitle: Record<Mode, string> = {
    [Mode.LOGIN]: t("pages.sign_in.pro"),
    [Mode.RESET_PASSWORD]: t("pages.sign_in.forgot_password"),
  };

  const history = useHistory();
  const { setUserPayload } = useAuth();

  const [mode, setMode] = useState(Mode.LOGIN);
  const [status, setStatus] = useState(Status.IDLE);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const submitHandler: FormEventHandler = (e) => {
    e.preventDefault();
    if (status === Status.LOADING || status === Status.SUCCESS) {
      return;
    }
    setStatus(Status.LOADING);

    if (mode === Mode.LOGIN) {
      signInHandler();
    } else if (mode === Mode.RESET_PASSWORD) {
      resetPasswordHandler();
    }
  };

  const signInHandler = async () => {
    try {
      const res = await api.post<Tokens>("/auth/sign-in", { email, password });
      if (!res.data?.accessToken || !res.data?.refreshToken) {
        throw new Error();
      }
      const decodedAccessToken = decodeAccessToken(res.data.accessToken);
      if (!decodedAccessToken) {
        throw new Error();
      }
      TokenService.setTokens(res.data);
      setUserPayload(decodedAccessToken);
      history.push("/orders");
    } catch {
      setStatus(Status.ERROR);
    }
  };

  const resetPasswordHandler = async () => {
    try {
      const res = await api.post<{ success: boolean }>(
        "/auth/reset-password-mail",
        {
          email,
        }
      );
      if (!res.data.success) {
        throw new Error();
      }
      setStatus(Status.SUCCESS);
    } catch {
      setStatus(Status.ERROR);
    }
  };

  const toggleModeHandler = () => {
    if (mode === Mode.LOGIN) {
      setMode(Mode.RESET_PASSWORD);
    } else if (mode === Mode.RESET_PASSWORD) {
      setMode(Mode.LOGIN);
    }
  };

  const changeInputHandler = (kind: "email" | "password", value: string) => {
    if (status === Status.ERROR) {
      setStatus(Status.IDLE);
    }

    if (kind === "email") {
      setEmail(value);
    } else if (kind === "password") {
      setPassword(value);
    }
  };

  useEffect(() => {
    if (!!mode) {
      setStatus(Status.IDLE);
    }
  }, [mode]);

  const canGoBack = mode === Mode.RESET_PASSWORD;
  return (
    <LoginPageWrapper>
      <Logo />
      <FormWrapper onSubmit={submitHandler}>
        <TitleWrapper
          canGoBack={canGoBack}
          onClick={canGoBack ? toggleModeHandler : undefined}
        >
          {mode === Mode.RESET_PASSWORD && (
            <img alt="go back" src="/icons/navigation-left.svg" />
          )}
          <Title>{mapModeToTitle[mode]}</Title>
        </TitleWrapper>
        {mode === Mode.RESET_PASSWORD && (
          <Description>{t("pages.sign_in.email_for_reset")}</Description>
        )}
        <LabeledTextInput
          label={t("forms.email")}
          placeholder="mail@mail.com"
          hasError={status === Status.ERROR}
          value={email}
          onChange={(e) => changeInputHandler("email", e.target.value)}
        />
        {mode === Mode.LOGIN && (
          <LabeledTextInput
            label={t("forms.password")}
            type="password"
            placeholder="••••••••••"
            hasError={status === Status.ERROR}
            value={password}
            onChange={(e) => changeInputHandler("password", e.target.value)}
          />
        )}
        {status === Status.ERROR && <Alert>{t("pages.sign_in.error")}</Alert>}
        {status === Status.SUCCESS && (
          // Only available for RESET_PASSWORD mode
          <Alert variant="success">{t("pages.sign_in.success")}</Alert>
        )}
        {mode === Mode.LOGIN && (
          <ForgottenPassword onClick={toggleModeHandler}>
            {t("pages.sign_in.forgot_password")}
          </ForgottenPassword>
        )}
        <ButtonsWrapper>
          <Button type="submit">{mapModeToButtonLabel[mode]}</Button>
          {mode === Mode.LOGIN && (
            <Link to="/sign-up">
              <Button type="button" variant="outline">
                {t("pages.sign_in.create_society_account")}
              </Button>
            </Link>
          )}
        </ButtonsWrapper>
      </FormWrapper>
      <div />
    </LoginPageWrapper>
  );
};

const LoginPageWrapper = styled(WavePageWrapper)`
  display: flex;
  padding: 52px;
  align-items: center;
  justify-content: space-between;
`;

const FormWrapper = styled.form`
  margin-top: 108px;
  width: 350px;

  & > *:not(:last-child) {
    margin-bottom: 24px;
  }
`;

type TitleWrapperStyleProps = {
  canGoBack: boolean;
};
const TitleWrapper = styled.div<TitleWrapperStyleProps>`
  display: flex;
  align-items: center;
  cursor: ${({ canGoBack }) => (canGoBack ? "pointer" : "inherit")};
  & > img {
    width: 20px;
    height: 16.25px;
    margin-right: 8px;
  }
`;

const Title = styled.h2`
  margin: 0;
  font-weight: 600;
  font-size: 24px;
  line-height: 25px;
`;

const ForgottenPassword = styled.p`
  cursor: pointer;
  margin: 0;
  margin-left: auto;
  width: min-content;
  white-space: nowrap;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;

  &:hover {
    text-decoration: underline;
  }
`;

const ButtonsWrapper = styled.div`
  & > *:not(:last-child) {
    margin-bottom: 12px;
  }
`;
