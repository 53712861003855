import { ChangeEvent, FC, MutableRefObject, useRef, useState } from "react";
import styled from "styled-components";
import { FormikErrors, useFormik } from "formik";
import { useTranslation } from "react-i18next";

import { Card } from "src/components/Card";
import { Button } from "src/components/form/Button";
import { LabeledTextInput } from "src/components/form/LabeledTextInput";
import { Alert } from "src/components/Alert";

import { useRequestStatus, RequestStatus } from "src/hooks/useRequestStatus";
import { validateNonEmptyFields } from "src/utils/validations";
import { api } from "src/services/api";

import { Title } from "src/styles/styled-components-library";

import type { SelectOption } from "src/components/form/Select";

import { ReactComponent as GoBackIcon } from "src/assets/svg-icons/navigation-left.svg";
import { BasicPageWithHeader } from "src/components/layout/BasicPageWithHeader";
import { Link, useParams } from "react-router-dom";
import { LabeledLocationAutocompleteInput } from "src/components/form/LabeledLocationAutocompleteInput";
import type { Location } from "src/models/location";
import { LabeledTextArrayInput } from "src/components/form/LabeledTextArrayInput";
import { getS3Url, uploadFile } from "src/services/image";
import { colors } from "src/theme/colors";
import { ReactComponent as UploadIcon } from "src/assets/svg-icons/download-square.svg";
import { LabeledTextArea } from "src/components/form/LabeledTextArea";

type Key = "name" | "address" | "media_url" | "description" | "media_credits";

type FormInput = {
  key: Key;
  label: string;
  placeholder: string;
  type: "text";
  options?: SelectOption[];
};

export const NewPointOfInterest: FC = () => {
  const { t } = useTranslation();

  const [status, setStatus] = useRequestStatus();
  const [addressSelected, setAddressSelected] = useState<Location | null>(null);
  const params = useParams<{ listId: string }>();
  const [tags, setTags] = useState<string[]>([]);
  const inputFile = useRef() as MutableRefObject<HTMLInputElement>;
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const inputs: FormInput[] = [
    {
      key: "name",
      label: t("forms.name"),
      placeholder: t("pages.poi.poi.form.name_placeholder"),
      type: "text",
    },
  ];

  const formInitialValues = {
    name: "",
    address: "",
    description: "",
    media_url: "",
    media_credits: "",
  };

  const formik = useFormik({
    initialValues: formInitialValues,
    validate: values => {
      const errors: FormikErrors<typeof formInitialValues> = {};

      const emptyFields = validateNonEmptyFields(values, "name", "address");
      if (emptyFields.length > 0) {
        for (const key of emptyFields) {
          errors[key] = "This field is empty.";
        }
      }
      if (Object.keys(errors).length > 0) {
        setStatus(RequestStatus.VALIDATION_ERROR);
      }
      return errors;
    },
    validateOnChange: false,
    onSubmit: async values => {
      if (
        status === RequestStatus.LOADING ||
        status === RequestStatus.SUCCESS
      ) {
        return;
      }

      // !! TODO : déterminer le "locale" via la langue actuelle de l'app React
      // (quand on aura mis en place i18n)
      const body = {
        ...values,
        locale: "fr",
      };

      setStatus(RequestStatus.LOADING);
      try {
        await api.post(`/lists/${params.listId}/pinpoints`, {
          ...body,
          longitude: addressSelected?.lng,
          latitude: addressSelected?.lat,
          tags: [...tags, "3"],
        });
        setStatus(RequestStatus.SUCCESS);
      } catch {
        setStatus(RequestStatus.SERVER_ERROR);
      }
    },
  });

  const updateTextInputHandler = (
    e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>,
    key: Key,
  ) => {
    formik.setErrors({
      ...formik.errors,
      [key]: false,
    });
    formik.handleChange(e);
  };

  const handleLocationSelect = (location: Location) => {
    setAddressSelected(location);

    formik.setValues(values => ({
      ...values,
      address: location.displayName ?? "",
    }));
  };

  const uploadImage = async (image: File) => {
    const data = await getS3Url("pinpoint");

    if (data.errorMessage) {
      setErrorMessage(data.errorMessage);
      return;
    }

    const res = await uploadFile(image, data.url, "pinpoint");

    if (res.errorMessage) {
      setErrorMessage(res.errorMessage);
    } else {
      setErrorMessage(null);
      formik.setValues({
        ...formik.values,
        media_url: res.uploadedFilename
          ? `https://d2ubtjs5i7apus.cloudfront.net/${res.uploadedFilename}`
          : "",
      });
    }
  };

  const deleteLogo = async () => {
    inputFile.current.value = "";
    formik.setValues({
      ...formik.values,
      media_url: "",
    });
  };

  return (
    <BasicPageWithHeader>
      <NavigationWrapper>
        <Link to={`/lists/${params.listId}`}>
          <StyledGoBackIcon />
        </Link>
      </NavigationWrapper>
      <CardWrapper>
        <FormWrapper>
          <Title>{t("pages.poi.poi.create.title")}</Title>
          <InputsWrapper>
            {inputs.map(input => {
              return (
                <LabeledTextInput
                  key={input.key}
                  name={input.key}
                  type={input.type}
                  label={input.label}
                  placeholder={input.placeholder}
                  value={formik.values[input.key]}
                  hasError={!!formik.errors[input.key]}
                  onChange={e => updateTextInputHandler(e, input.key)}
                />
              );
            })}
            <LabeledLocationAutocompleteInput
              label={t("forms.address")}
              placeholder="Orly"
              name={"address"}
              value={formik.values["address"]}
              hasError={
                !!formik.errors["address"] &&
                status === RequestStatus.VALIDATION_ERROR
              }
              onChange={e => updateTextInputHandler(e, "address")}
              onPlaceSelect={location => handleLocationSelect(location)}
              getPicture={url => {
                if (!formik.values["media_url"]) {
                  formik.values["media_url"] = url;
                }
              }}
            />
          </InputsWrapper>
          <LabeledTextArea
            name={"description"}
            label={t("forms.description")}
            placeholder={"Description"}
            value={formik.values["description"]}
            hasError={!!formik.errors["description"]}
            onChange={e => {
              updateTextInputHandler(e, "description");
            }}
          />
        </FormWrapper>

        <LabeledTextArrayInput
          label={t("forms.tags")}
          placeholder={t("pages.poi.poi.form.tags_placeholder")}
          values={tags}
          onDelete={indexToDelte => {
            setTags(tags.filter((tag, index) => indexToDelte !== index));
          }}
          onSubmit={text => {
            setTags([...tags, text]);
          }}
          sugestValues={[
            t("enum.wemap_tags.activity"),
            t("enum.wemap_tags.building"),
            t("enum.wemap_tags.states"),
            t("enum.wemap_tags.monument"),
            t("enum.wemap_tags.museum"),
            t("enum.wemap_tags.province"),
            t("enum.wemap_tags.hiking"),
            t("enum.wemap_tags.natural_site"),
            t("enum.wemap_tags.top"),
          ]}
          deleteZoomTag={true}
          title={t("pages.poi.poi.form.suggested_tags")}
        />
        <input
          ref={inputFile}
          type="file"
          style={{ display: "none" }}
          onChange={(e: any) => uploadImage(e.target.files[0])}
        />
        {formik.values["media_url"] !== "" ? (
          <UploadContainer>
            <Label>{t("pages.poi.poi.form.logo_title")}</Label>
            <Upload>
              {formik.values["media_url"] ? (
                <Logo src={formik.values.media_url} alt="Logo" />
              ) : (
                <UploadText>{t("pages.poi.poi.form.no_logo")}</UploadText>
              )}
              <ButtonContainer>
                <Button variant="secondary" onClick={deleteLogo}>
                  {t("pages.poi.poi.form.delete_logo")}
                </Button>
              </ButtonContainer>
            </Upload>
          </UploadContainer>
        ) : (
          <UploadContainer>
            <Label>{t("pages.poi.poi.form.logo_title")}</Label>
            <Upload onClick={() => inputFile.current.click()}>
              <UploadIcon />
              <UploadText>
                {t("pages.poi.poi.form.logo_description")}
                <br />
              </UploadText>
            </Upload>
          </UploadContainer>
        )}

        <FormWrapper>
          <LabeledTextInput
            key={"media_credits"}
            name={"media_credits"}
            type={"text"}
            label={t("forms.media_credits")}
            placeholder={t("forms.media_credits")}
            value={formik.values["media_credits"]}
            hasError={!!formik.errors["media_credits"]}
            onChange={e => updateTextInputHandler(e, "media_credits")}
          />
        </FormWrapper>

        {errorMessage ? (
          <Alert>
            {t("pages.poi.poi.form.error")}: {errorMessage}
          </Alert>
        ) : null}

        {status === RequestStatus.VALIDATION_ERROR && (
          <Alert>{t("forms.invalid_form")}</Alert>
        )}
        {status === RequestStatus.SERVER_ERROR && (
          <Alert>{t("pages.poi.poi.create.server_error")}</Alert>
        )}
        {status === RequestStatus.SUCCESS && (
          <Alert variant="success">{t("pages.poi.poi.create.success")}</Alert>
        )}
        <Button type="submit" onClick={() => formik.handleSubmit()}>
          {t("pages.poi.poi.create.create_this_poi")}
        </Button>
      </CardWrapper>
    </BasicPageWithHeader>
  );
};

const NavigationWrapper = styled.div`
  width: 750px;
  margin: 0 auto;
  padding: 16px;
  padding-left: 0;
`;

const StyledGoBackIcon = styled(GoBackIcon)`
  cursor: pointer;
`;

const CardWrapper = styled(Card)`
  margin: 0 auto;
  padding: 24px;
  width: 750px;
`;

const FormWrapper = styled.form`
  width: 100%;

  & > a:first-child {
    display: inline-block;
    margin-bottom: 12px;
  }

  & > * {
    margin-bottom: 24px;
  }
`;

const InputsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  & > * {
    width: 48%;
  }

  @supports (gap: 24px 28px) {
    gap: 24px 28px;
  }
  @supports not (gap: 24px 28px) {
    & > * {
      margin-bottom: 24px;
    }
  }
`;

const UploadContainer = styled.div`
  width: 100%;
  margin-bottom: 24px;
`;

const Upload = styled.div`
  width: 100%;
  border: 1px dotted ${colors.grey[200]};
  padding: 50px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Label = styled.p`
  margin-top: 0;
  margin-bottom: 4px;
  color: ${colors.grey[600]};
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
`;
const UploadText = styled.p`
  color: ${colors.grey[200]};
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
`;

const Logo = styled.img`
  width: 50%;
  margin-bottom: 10px;
`;

const ButtonContainer = styled.div`
  width: 50%;
`;
