import { FC } from "react";
import { compareAsc, format } from "date-fns";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { LabeledReadOnlyInput } from "src/components/form/LabeledReadOnlyInput";
import { SelectableBadge } from "src/components/SelectableBadge";
import { RecapSection } from "./RecapSection";
import { NotProvided } from "./NotProvided";
import { DisplaySteps } from "../steps/OrderSteps/DisplaySteps";

import { colors } from "src/theme/colors";

import type { CommonOrderRecapSectionProps } from "./index";

import { ReactComponent as PinPlaneIcon } from "src/assets/svg-icons/transports/plane.svg";
import { ReactComponent as FootstepsIcon } from "src/assets/svg-icons/footsteps.svg";
import { StepDraft } from "../steps/OrderSteps";
import { getLocalDateWithDestinationUTC } from "src/utils/date";

interface OrderStepsProps extends CommonOrderRecapSectionProps {}
export const OrderSteps: FC<OrderStepsProps> = props => {
  const { order, editStep } = props;
  const { t } = useTranslation();

  const mapDelayValueToLabel: Record<number, string> = {
    0: t("pages.orders.edit.order_steps.arrival_day_0"),
    1: t("pages.orders.edit.order_steps.arrival_day_1"),
    2: t("pages.orders.edit.order_steps.arrival_day_2"),
  };

  const compareTwoSteps = (firstStep: StepDraft, secondStep: StepDraft) => {
    return compareAsc(new Date(firstStep.date), new Date(secondStep.date));
  };
  const sortedSteps = order.steps ? order.steps.sort(compareTwoSteps) : [];

  const formatDate = (isoDate: Date | null, utcOffset: number | null) => {
    if (!isoDate) return "";

    return format(new Date(isoDate), "dd/MM/yyyy");
  };

  const startDate = order.startDate
    ? getLocalDateWithDestinationUTC(
        order.startDate,
        order.startLocation.utcOffsetMinutes ?? 0,
      )
    : null;
  const endDate = order.endDate
    ? getLocalDateWithDestinationUTC(
        order.endDate,
        order.endLocation.utcOffsetMinutes ?? 0,
      )
    : null;

  return (
    <RecapSection
      title={t("pages.orders.edit.steps.step_and_hotel")}
      editClickHandler={editStep && (() => editStep(3))}
    >
      {!!order.startDate &&
      !!order.startLocation?.displayName &&
      order.startDelay !== null &&
      order.endDelay !== null &&
      !!order.endDate &&
      !!order.endLocation?.displayName ? (
        <StepSectionsWrapper>
          <StepSection>
            <StepSectionTitle>
              <PinPlaneIcon />
              <span>{t("pages.orders.edit.order_steps.arrival_airport")}</span>
            </StepSectionTitle>
            <InputsWrapper>
              <LabeledReadOnlyInput
                label={t("pages.orders.edit.order_steps.arrival_date")}
                value={formatDate(
                  startDate,
                  order.startLocation.utcOffsetMinutes,
                )}
              />
              <LabeledReadOnlyInput
                label={t("pages.orders.edit.order_steps.arrival_airport")}
                value={order.startLocation?.displayName}
              />
            </InputsWrapper>
            <SelectableBadge
              selected
              label={mapDelayValueToLabel[order.startDelay]}
            />
          </StepSection>
          <StepSection>
            <StepSectionTitle>
              <FootstepsIcon />
              <span>{t("pages.orders.edit.steps.step_and_hotel")}</span>
            </StepSectionTitle>
            <DisplaySteps steps={sortedSteps} />
          </StepSection>
          <StepSection>
            <StepSectionTitle>
              <PinPlaneIcon />
              <span>
                {t("pages.orders.edit.order_steps.departure_airport")}
              </span>
            </StepSectionTitle>
            <InputsWrapper>
              <LabeledReadOnlyInput
                label={t("pages.orders.edit.order_steps.departure_date")}
                value={formatDate(endDate, order.endLocation.utcOffsetMinutes)}
              />
              <LabeledReadOnlyInput
                label={t("pages.orders.edit.order_steps.departure_airport")}
                value={order.endLocation?.displayName}
              />
            </InputsWrapper>
            <SelectableBadge
              selected
              label={mapDelayValueToLabel[order.endDelay]}
            />
          </StepSection>
        </StepSectionsWrapper>
      ) : (
        <NotProvided completeClickHandler={editStep && (() => editStep(3))} />
      )}
    </RecapSection>
  );
};

const InputsWrapper = styled.div`
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 24px;
`;

const StepSectionsWrapper = styled.div`
  & > *:not(:last-child) {
    margin-bottom: 24px;
  }
`;

const StepSection = styled.div`
  display: flex;
  flex-direction: column;

  & > *:not(:last-child) {
    margin-bottom: 16px;
  }
`;

const StepSectionTitle = styled.div`
  display: flex;
  align-items: center;
  color: ${colors.turquoise[800]};
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: -0.015em;
  text-transform: uppercase;
  margin-bottom: 12px;

  & > svg {
    width: 16px;
    height: 16px;
    margin-right: 8px;
  }
`;
