import { FC, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { colors } from "src/theme/colors";

import { PointsOfInterest } from "./PointsOfInterest";
import { BasicPageWithHeader } from "src/components/layout/BasicPageWithHeader";
import { useParams } from "react-router-dom";
import { usePinpoints } from "src/hooks/usePinpoints";
import { ListRegions } from "./ListRegions";
import { useListRegions } from "src/hooks/useListRegions";
import { useAuth } from "src/contexts/AuthContext";
import { Kind } from "src/types/user";
import { usePracticalInformations } from "src/hooks/usePracticalInformations";
import { PracticalInformations } from "../drb/PracticalInformations";

export enum Mode {
  PINPOINT = "PINPOINT",
  REGION = "REGION",
  INFORMATION = "INFORMATION",
}

export const List: FC = () => {
  const { t } = useTranslation();

  const { userPayload } = useAuth();
  const [mode, setMode] = useState(
    userPayload?.kind === Kind.DRB ? Mode.REGION : Mode.PINPOINT,
  );
  const params = useParams<{ listId: string }>();

  const {
    pinpoints,
    refreshPinpoints,
    fetchStatus: fetchStatusPinpoint,
  } = usePinpoints(params.listId);
  const {
    listRegions,
    refreshListRegions,
    fetchStatus: fetchStatusListRegion,
    FetchStatus,
  } = useListRegions(params.listId);

  const {
    practicalInformations,
    fetchStatus: fetchStatusPracticalInformations,
    refreshPracticalInformations,
  } = usePracticalInformations(params.listId);

  const getToggleButtonProps = (toggleMode: Mode) => {
    return {
      isActive: mode === toggleMode,
      onClick: () => setMode(toggleMode),
    };
  };

  return (
    <BasicPageWithHeader>
      <Wrapper>
        {userPayload?.kind === Kind.DRB ? (
          <ToggleWrapper>
            <ToggleButton {...getToggleButtonProps(Mode.REGION)}>
              {t("pages.poi.navbar.regions")}
            </ToggleButton>
            <ToggleButton {...getToggleButtonProps(Mode.PINPOINT)}>
              {t("pages.poi.navbar.poi")}
            </ToggleButton>
            <ToggleButton {...getToggleButtonProps(Mode.INFORMATION)}>
              {t("pages.poi.navbar.practical_informations")}
            </ToggleButton>
          </ToggleWrapper>
        ) : null}

        {mode === Mode.REGION &&
          fetchStatusListRegion !== FetchStatus.PENDING && (
            <ListRegions
              listRegions={listRegions}
              refreshListRegions={refreshListRegions}
            />
          )}
        {mode === Mode.PINPOINT &&
          fetchStatusPinpoint !== FetchStatus.PENDING && (
            <PointsOfInterest
              pinpoints={pinpoints}
              refreshPinpoints={refreshPinpoints}
            />
          )}
        {mode === Mode.INFORMATION &&
          fetchStatusPracticalInformations !== FetchStatus.PENDING && (
            <PracticalInformations
              practicalInformations={practicalInformations}
              refreshPracticalInformations={refreshPracticalInformations}
            />
          )}
      </Wrapper>
    </BasicPageWithHeader>
  );
};

const Wrapper = styled.div`
  padding: 16px;

  & > *:not(:last-child) {
    margin-bottom: 24px;
  }
`;

const ToggleWrapper = styled.div`
  margin: 0 auto;
  padding: 4px;
  border-radius: 50px;
  background-color: ${colors.grey[100]};

  width: min-content;
  display: flex;

  & > :not(:first-child) {
    margin-left: 2px;
  }
  & > :not(:last-child) {
    margin-right: 2px;
  }
`;

type ToggleButtonStyleProps = {
  isActive: boolean;
};
const ToggleButton = styled.div<ToggleButtonStyleProps>`
  background-color: ${({ isActive }) =>
    isActive ? colors.grey[600] : "transparent"};
  color: ${({ isActive }) =>
    isActive ? colors.main.white : colors.main.black};
  padding: 8px 16px;
  border-radius: 50px;
  cursor: pointer;
  opacity: ${({ isActive }) => (isActive ? "1" : "0.6")};
  transition: all 200ms ease;
  white-space: nowrap;

  &:hover {
    background-color: ${({ isActive }) =>
      isActive ? colors.grey[600] : colors.grey[200]};
  }
  display: flex;
  align-items: center;
`;
