import { RefObject } from "react";

import { useEventListener } from "./useEventListener";

type Handler = (event: MouseEvent) => void;

export function useOnClickOutside<T extends HTMLElement = HTMLElement>(
  ref: RefObject<T>,
  handler: Handler
): void {
  useEventListener("mouseup", (event) => {
    const el = ref?.current;
    if (!el || el.contains(event.target as Node)) {
      return;
    }

    handler(event as MouseEvent);
  });
}
