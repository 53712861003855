import { FC, useCallback, useEffect, useState } from "react";
import styled from "styled-components";

import { BasicPageWithHeader } from "src/components/layout/BasicPageWithHeader";
import { ModeMenu } from "src/components/pages/settings/ModeMenu";
import { GeneralInformations } from "src/components/pages/settings/GeneralInformations";
import { ToUsers } from "src/components/pages/settings/ToUsers";

import { useAuth } from "src/contexts/AuthContext";
import { api } from "src/services/api";

import { SettingMode } from "src/types/settings";
import { Kind, Role } from "src/types/user";
import { User } from "src/models/user";

export const Settings: FC = () => {
  const { userPayload: jwtUser } = useAuth();

  const [mode, setMode] = useState(SettingMode.GENERAL_INFO);
  const [user, setUser] = useState<User | null>(null);

  const refreshUserData = useCallback(() => {
    if (!jwtUser) {
      return;
    }

    api.get<{ user: User }>(`/users/${jwtUser.id}`).then(res => {
      if (res.data.user) {
        setUser(res.data.user);
      }
    });
  }, [jwtUser]);

  useEffect(() => {
    if (mode === SettingMode.GENERAL_INFO) {
      refreshUserData();
    }
  }, [mode, refreshUserData]);

  return (
    <BasicPageWithHeader>
      <Wrapper>
        {jwtUser?.role === Role.ADMIN && jwtUser.kind === Kind.TO && (
          <MenuWrapper>
            <ModeMenu mode={mode} changeMode={mode => setMode(mode)} />
          </MenuWrapper>
        )}
        {!!user && (
          <ModeContentWrapper>
            {mode === SettingMode.GENERAL_INFO && (
              <GeneralInformations user={user} />
            )}
            {mode === SettingMode.USERS &&
              user.role === Role.ADMIN &&
              user.kind === Kind.TO && <ToUsers user={user} />}
          </ModeContentWrapper>
        )}
      </Wrapper>
    </BasicPageWithHeader>
  );
};

const Wrapper = styled.div`
  flex: 1;
  padding: 16px;
  display: flex;
`;

const MenuWrapper = styled.div`
  margin-right: 42px;
`;

const ModeContentWrapper = styled.div`
  flex: 1;
`;
