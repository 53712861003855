import { colors } from "src/theme/colors";

import { Status } from "src/types/order";
import type { BadgeProps } from "src/components/Badge";

export const mapStatusToLabel: Record<Status, string> = {
  [Status.DRAFT]: "draft",
  [Status.TO_PAY]: "to_pay",
  [Status.PAID]: "paid",
  [Status.ARCHIVED]: "archived",
};

export const mapStatusToBadgeStyle: Record<Status, BadgeProps> = {
  [Status.DRAFT]: {
    color: "#4F541A",
    backgroundColor: "#F9FFB9",
    borderColor: "rgba(34, 34, 34, 0.05)",
  },
  [Status.TO_PAY]: {
    color: "#851404",
    backgroundColor: colors.error[100],
    borderColor: "rgba(0, 0, 0, 0.05)",
  },
  [Status.PAID]: {
    color: colors.turquoise[900],
    backgroundColor: colors.green[200],
    borderColor: "rgba(0, 0, 0, 0.05)",
  },
  [Status.ARCHIVED]: {
    color: colors.main.white,
    backgroundColor: colors.main.black,
    borderColor: colors.main.black,
  },
};

export const statusOptions: Array<{ label: string; value: string }> = [
  {
    label: mapStatusToLabel[Status.DRAFT],
    value: Status.DRAFT,
  },
  {
    label: mapStatusToLabel[Status.TO_PAY],
    value: Status.TO_PAY,
  },
  {
    label: mapStatusToLabel[Status.PAID],
    value: Status.PAID,
  },
  {
    label: mapStatusToLabel[Status.ARCHIVED],
    value: Status.ARCHIVED,
  },
];
