import { JwtPayload } from "src/types/jwt-payload";

export const decodeAccessToken = (accessToken: string): JwtPayload | null => {
  try {
    const tokenPayload = accessToken.split(".")[1];
    const decodedPayload = atob(tokenPayload);
    const parsedPayload: JwtPayload = JSON.parse(decodedPayload);
    return parsedPayload;
  } catch {
    return null;
  }
};
