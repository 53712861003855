import { FC, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";

import { BasicPageWithHeader } from "src/components/layout/BasicPageWithHeader";
import { EditContent } from "src/components/pages/orders/specific/EditContent";

import { api } from "src/services/api";

import type { Order } from "src/models/order";
import { Status } from "src/types/order";

import { ReactComponent as GoBackIcon } from "src/assets/svg-icons/navigation-left.svg";

export const UpdateOrder: FC = () => {
  const history = useHistory();
  const { orderId } = useParams<{ orderId: string }>();
  const [order, setOrder] = useState<Order | null>(null);

  const updateOrderState = (order: Order) => {
    setOrder(order);
  };

  useEffect(() => {
    async function fetchOrder() {
      try {
        if (!orderId) {
          throw new Error();
        }

        const res = await api.get<Order>(`/orders/${orderId}`);
        // we cannot edit an archived order
        if (!res.data.id || res.data.status === Status.ARCHIVED) {
          throw new Error();
        }
        setOrder(res.data);
      } catch {
        history.push("/orders");
      }
    }

    if (order === null) {
      fetchOrder();
    }
  }, [orderId, order, history]);

  return (
    <BasicPageWithHeader>
      <ContentWrapper>
        <StyledGoBackIcon onClick={() => history.push("/orders")} />
        {order && (
          <EditContent order={order} updateOrderState={updateOrderState} />
        )}
      </ContentWrapper>
    </BasicPageWithHeader>
  );
};

const ContentWrapper = styled.div`
  flex: 1;
  padding: 24px 18px;
  display: flex;
  position: relative;
`;

const StyledGoBackIcon = styled(GoBackIcon)`
  cursor: pointer;
  position: absolute;
  top: 24px;
  left: 18px;
`;
