import { FC, useEffect, useMemo, useState } from "react";
import {
  CellProps,
  Column,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Card } from "src/components/Card";
import { PaginationMenu } from "src/components/table/PaginationMenu";
import { Table } from "src/components/table/Table";
import { StyledButton } from "src/components/form/Button";

import { api } from "src/services/api";

import { Hotel } from "src/models/hotel";
import { GlobalFilterSearchBar } from "src/components/table/GlobalFilterSearchBar";
import { Badge } from "src/components/Badge";
import { colors } from "src/theme/colors";
import { IconWrapper } from "src/styles/styled-components-library";
import { ReactComponent as EditActionIcon } from "src/assets/svg-icons/actions/edit.svg";
import { ReactComponent as DeleteIcon } from "src/assets/svg-icons/actions/delete.svg";
import { DeleteHotelModal } from "./specific/DeleteHotelModal";

export const DisplayAll: FC = () => {
  const { t } = useTranslation();

  const [hotels, setHotels] = useState<Hotel[]>([]);
  const history = useHistory();
  const [hotelIdToDelete, setHotelIdToDelete] = useState<string | null>(null);

  const fetchHotels = async () => {
    const res = await api.get<Hotel[]>("/hotels");
    setHotels(res.data || []);
  };

  const columns: Column<Hotel>[] = useMemo(() => {
    return [
      {
        Header: `${t("forms.name")}`,
        accessor: "name",
      },
      {
        Header: `${t("forms.city")}`,
        accessor: "city",
      },
      {
        Header: `${t("forms.country")}`,
        accessor: "country",
      },
      {
        Header: `${t("forms.status")}`,
        accessor: "location",
        disableSortBy: true,
        disableFilters: true,
        Cell: (props: CellProps<Hotel, Hotel["location"]>) => {
          const { value } = props;
          const isFinished = !value.lng || !value.lat ? false : true;

          return (
            <Badge
              {...{
                color: isFinished ? colors.turquoise[900] : colors.red[500],
                backgroundColor: isFinished
                  ? colors.green[200]
                  : colors.error[100],
                borderColor: "rgba(0, 0, 0, 0.05)",
              }}
            >
              {isFinished
                ? t("pages.hotel.finished")
                : t("pages.hotel.not_finished")}
            </Badge>
          );
        },
      },
      {
        Header: `${t("forms.actions")}`,
        disableSortBy: true,
        Cell: ({ row }: CellProps<Hotel>) => {
          const { original: currentHotel } = row;
          return (
            <IconsWrapper>
              <IconWrapper
                onClick={e => {
                  e.stopPropagation();
                  history.push(`/hotels/${currentHotel.id}/edit`);
                }}
              >
                <EditActionIcon />
              </IconWrapper>
              <IconWrapper
                onClick={e => {
                  e.stopPropagation();
                  setHotelIdToDelete(currentHotel.id);
                }}
              >
                <DeleteIcon />
              </IconWrapper>
            </IconsWrapper>
          );
        },
      },
    ];
  }, [history, t]);

  const instance = useTable(
    {
      columns,
      data: hotels,
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  useEffect(() => {
    fetchHotels();
  }, []);

  return (
    <>
      <ActionsWrapper>
        <GlobalFilterSearchBar
          instance={instance}
          placeholder={t("pages.hotel.search")}
        />
      </ActionsWrapper>
      <TableCardWrapper>
        <Table
          instance={instance}
          columnsWidths={["25%", "25%", "20%", "20%", "10%"]}
        />
      </TableCardWrapper>
      <PaginationWrapper>
        <PaginationMenu
          instance={instance}
          label={t("pages.hotel.hotel_per_page")}
        />
      </PaginationWrapper>
      {!!hotelIdToDelete && (
        <DeleteHotelModal
          hotelId={hotelIdToDelete}
          showModal
          closeModal={() => setHotelIdToDelete(null)}
          postDeleteCallback={fetchHotels}
        />
      )}
    </>
  );
};

export const TableCardWrapper = styled(Card)`
  width: 100%;
  height: min-content;
  padding: 24px;
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ActionsWrapper = styled.div`
  display: flex;

  & > *:not(:first-child) {
    margin-left: 16px;
  }

  & > ${StyledButton} {
    width: min-content;
  }
`;

const IconsWrapper = styled.div`
  display: flex;

  & > *:not(:last-child) {
    margin-right: 8px;
  }
`;
