import { FC, useEffect } from "react";
import ModalLib, { Styles } from "react-modal";
import styled from "styled-components";

import { colors } from "src/theme/colors";

interface ModalProps {
  showModal: boolean;
  closeModal: () => void;
  title?: string;
  width?: string;
}
export const Modal: FC<ModalProps> = (props) => {
  const { children, showModal, title, width, closeModal } = props;

  const customStyles: Styles = {
    content: {
      width: width ?? "60vw",
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      padding: 0,
      borderRadius: 8,
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
    overlay: {
      backgroundColor: "rgba(34, 34, 34, 0.4)",
    },
  };

  useEffect(() => {
    ModalLib.setAppElement(document.querySelector("body") ?? "body");
  }, []);

  return (
    <ModalLib
      isOpen={showModal}
      onRequestClose={closeModal}
      style={customStyles}
    >
      <ContentWrapper>
        {title && <ModalTitle>{title}</ModalTitle>}
        {children}
      </ContentWrapper>
    </ModalLib>
  );
};

const ContentWrapper = styled.div`
  padding: 70px;
  display: flex;
  flex-direction: column;
  max-height: 98vh;
  overflow-y: auto;
`;

const ModalTitle = styled.p`
  color: ${colors.main.black};
  margin-top: 0;
  margin-bottom: 8px;
  font-weight: 600;
  font-size: 24px;
  line-height: 25px;
`;
