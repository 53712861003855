import { FC } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { colors } from "src/theme/colors";

import type { Order } from "src/models/order";
import { FacturationKind } from "src/types/tour-operator";
import { Status } from "src/types/order";

type StepState = "active" | "complete" | "disabled";
type StepType = {
  label: string;
  hideStep?: boolean;
};

const mapStateToColor: Record<StepState, string> = {
  active: colors.cta.green,
  complete: colors.turquoise[500],
  disabled: colors.grey[200],
};

interface OrderStepperProps {
  order: Order;
  currentStep: number;
}
export const OrderStepper: FC<OrderStepperProps> = props => {
  const { order, currentStep } = props;
  const { t } = useTranslation();

  const steps: StepType[] = [
    {
      label: t("pages.orders.edit.steps.client"),
    },
    {
      label: t("pages.orders.edit.steps.general_informations"),
    },
    {
      label: t("pages.orders.edit.steps.step_and_hotel"),
    },
    {
      label: t("pages.orders.edit.steps.transport_and_activity"),
    },
    {
      label: t("pages.orders.edit.steps.recap"),
    },
    {
      label: t("pages.orders.edit.steps.payment"),
      hideStep:
        order.tourOperator?.facturationKind === FacturationKind.END_OF_MONTH ||
        order.status === Status.PAID,
    },
  ];

  return (
    <StepperWrapper>
      {steps.map((step, index) => {
        const state: StepState =
          currentStep === index + 1
            ? "active"
            : currentStep > index
            ? "complete"
            : "disabled";
        return !step.hideStep ? (
          <Step key={step.label} color={mapStateToColor[state]}>
            <span>{step.label}</span>
            <div />
          </Step>
        ) : null;
      })}
    </StepperWrapper>
  );
};

const StepperWrapper = styled.div`
  display: flex;

  & > * {
    flex: 1;
  }
`;

type StepStyleProps = {
  color: string;
};
const Step = styled.div<StepStyleProps>`
  display: flex;
  flex-direction: column;
  margin: 0 7px;

  & > span {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: ${({ color }) => color};
  }

  & > div {
    height: 4px;
    border-radius: 4px;
    background-color: ${({ color }) => color};
  }
`;
