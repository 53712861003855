import { useCallback, useEffect, useState } from "react";
import { ListRegion } from "src/models/listRegion";
import { getListRegion } from "src/services/listRegions";

enum FetchStatus {
  PENDING = "PENDING",
  DONE = "DONE",
}

export const useListRegion = (listRegionId: string) => {
  const [listRegion, setListRegion] = useState<ListRegion | null>(null);
  const [fetchStatus, setFetchStatus] = useState(FetchStatus.PENDING);

  const refreshListRegion = useCallback(async listRegionId => {
    try {
      if (!listRegionId) return;
      const res = await getListRegion(listRegionId);
      if (!res.data) {
        throw new Error();
      }

      setListRegion(res.data);
    } catch {
      setListRegion(null);
    } finally {
      setFetchStatus(FetchStatus.DONE);
    }
  }, []);

  useEffect(() => {
    refreshListRegion(listRegionId);
  }, [refreshListRegion, listRegionId]);

  return {
    listRegion,
    fetchStatus,
    refreshListRegion,
    FetchStatus,
  };
};
