import { add, format, parse } from "date-fns";

import type { StepDraft } from "src/components/pages/orders/specific/steps/OrderSteps";

/**
 * Function computing the default date for a new step while editing an order's steps.
 *
 * Returns a "yyyy-MM-dd"-formatted string date.
 */
export const computeStepsNewDate = (
  initialStartDate: string,
  steps: StepDraft[]
) => {
  const parsedInitialDate = parse(initialStartDate, "yyyy-MM-dd", new Date());
  const totalOfDays = steps.reduce((acc, curr) => acc + +curr.nightsCount, 0);
  return format(add(parsedInitialDate, { days: totalOfDays }), "yyyy-MM-dd");
};
