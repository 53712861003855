import { useCallback, useEffect, useState } from "react";
import { PracticalInformation } from "src/models/practicalInformation";
import { getPracticalInformation } from "src/services/practicalInformations";

enum FetchStatus {
  PENDING = "PENDING",
  DONE = "DONE",
}

export const usePracticalInformation = (practicalInformationId: string) => {
  const [practicalInformation, setPracticalInformation] =
    useState<PracticalInformation | null>(null);
  const [fetchStatus, setFetchStatus] = useState(FetchStatus.PENDING);

  const refreshPracticalInformation = useCallback(
    async (practicalInformationId: string) => {
      try {
        if (!practicalInformationId) {
          throw new Error();
        }
        const res = await getPracticalInformation(practicalInformationId);
        if (!res.data) {
          throw new Error();
        }
        setPracticalInformation(res.data);
      } catch {
        setPracticalInformation(null);
      } finally {
        setFetchStatus(FetchStatus.DONE);
      }
    },
    [],
  );

  useEffect(() => {
    refreshPracticalInformation(practicalInformationId);
  }, [refreshPracticalInformation, practicalInformationId]);

  return {
    practicalInformation,
    fetchStatus,
    refreshPracticalInformation,
    FetchStatus,
  };
};
