import { FC } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { Logo } from "src/components/Logo";
import { Button } from "src/components/form/Button";

import { SuccessWrapper } from "src/styles/styled-components-library";
import { colors } from "src/theme/colors";

export const SignUpSuccess: FC = () => {
  return (
    <Wrapper>
      <Logo />
      <div>
        <SuccessIcon width="32" height="32" src="/icons/check-circle.svg" />
        <Description>
          Votre inscription a bien été pris en compte, vous pouvez désormais
          vous connecter à notre plateforme et passer votre première commande.
        </Description>
      </div>
      <Link to="/sign-in">
        <Button>Se connecter à l'Espace Pro</Button>
      </Link>
    </Wrapper>
  );
};

const Wrapper = styled(SuccessWrapper)`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > *:not(:last-child) {
    margin-bottom: 24px;
  }
`;

const SuccessIcon = styled.img`
  display: block;
  margin: auto;
  width: 32px;
  height: 32px;
  margin-bottom: 24px;
`;

const Description = styled.p`
  margin: 0;
  max-width: 550px;
  font-weight: 500;
  font-size: 17px;
  line-height: 23px;
  text-align: center;
  letter-spacing: -0.0446em;
  color: ${colors.turquoise[500]};
`;
