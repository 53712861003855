import { TableInstance } from "react-table";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { Button } from "src/components/form/Button";
import { GlobalFilterSearchBar } from "src/components/table/GlobalFilterSearchBar";

import { Display as DisplayEnum } from "../ToUsers";

export function TableActions<T extends Record<string, unknown>>(props: {
  instance: TableInstance<T>;
  updateDisplay: (display: DisplayEnum) => void;
}) {
  const { t } = useTranslation();

  const { instance, updateDisplay } = props;

  return (
    <Wrapper>
      <GlobalFilterSearchBar
        instance={instance}
        placeholder={t("pages.settings.users.search")}
      />
      <StyledButton onClick={() => updateDisplay(DisplayEnum.NEW_USER)}>
        {t("pages.settings.users.create")}
      </StyledButton>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledButton = styled(Button)`
  width: min-content;
  margin-left: 16px;
`;
