import { useState } from "react";

export enum RequestStatus {
  IDLE = "IDLE",
  LOADING = "LOADING",
  VALIDATION_ERROR = "VALIDATION_ERROR",
  SERVER_ERROR = "SERVER_ERROR",
  SUCCESS = "SUCCESS",
}

/**
 * Hooks usually used when submitting a form / calling an API endpoint.
 *
 * Provides a simple state return, to prevent code duplication with the same enum everytime.
 */
export function useRequestStatus() {
  return useState(RequestStatus.IDLE);
}
