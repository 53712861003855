import { isValid, parse } from "date-fns";

export const validateFrenchPhoneNumber = (input: string): boolean => {
  return /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/.test(input);
};

export const validateEmail = (input: string): boolean => {
  // eslint-disable-next-line no-useless-escape
  return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    input
  );
};

/**
 * Validating each key in a object, making sure they are not empty (i.e. falsy) fields.
 */
export const validateNonEmptyFields = <T extends object, U extends keyof T>(
  obj: T,
  ...keys: U[]
): U[] => {
  const emptyKeys: U[] = [];
  for (const key of keys) {
    const value = obj[key];
    if (!value || (typeof value === "string" && !value.trim())) {
      emptyKeys.push(key);
    }
  }
  return emptyKeys;
};

export const validateDate = (input: string): boolean => {
  const parsedDate = parse(input, "yyyy-MM-dd", new Date());
  return isValid(parsedDate);
};

export const validateHour = (input: string): boolean => {
  return input.length === 5 && /[0-9]{2}:[0-9]{2}/.test(input);
};
