import { FC, SVGProps } from "react";

import { TravelKind } from "src/types/order";

import { ReactComponent as CarIcon } from "src/assets/svg-icons/transports/car.svg";
import { ReactComponent as PlaneIcon } from "src/assets/svg-icons/transports/plane.svg";
import { ReactComponent as TrainIcon } from "src/assets/svg-icons/transports/train.svg";
import { ReactComponent as BoatIcon } from "src/assets/svg-icons/transports/boat.svg";
import { ReactComponent as OtherIcon } from "src/assets/svg-icons/other.svg";

type Section = {
  kind: TravelKind;
  title: string;
  formTitle: string;
  labels: {
    fromLocation: string;
    toLocation: string;
    submitButton: string;
  };
  icon: FC<SVGProps<SVGSVGElement>>;
};
export const sections: Section[] = [
  {
    kind: TravelKind.CAR_RENTAL,
    title: "car_title",
    formTitle: "car_form_title",
    labels: {
      fromLocation: "car_from_location",
      toLocation: "car_to_location",
      submitButton: "car_submit_button",
    },
    icon: CarIcon,
  },
  {
    kind: TravelKind.PLANE,
    title: "plane_title",
    formTitle: "plane_form_title",
    labels: {
      fromLocation: "plane_from_location",
      toLocation: "plane_to_location",
      submitButton: "plane_submit_button",
    },
    icon: PlaneIcon,
  },
  {
    kind: TravelKind.TRAIN,
    title: "train_title",
    formTitle: "train_form_title",
    labels: {
      fromLocation: "train_from_location",
      toLocation: "train_to_location",
      submitButton: "train_submit_button",
    },
    icon: TrainIcon,
  },
  {
    kind: TravelKind.BOAT,
    title: "boat_title",
    formTitle: "boat_form_title",
    labels: {
      fromLocation: "boat_from_location",
      toLocation: "boat_to_location",
      submitButton: "boat_submit_button",
    },
    icon: BoatIcon,
  },
  {
    kind: TravelKind.ACTIVITY,
    title: "activity_title",
    formTitle: "activity_formTitle",
    labels: {
      fromLocation: "activity_from_location",
      toLocation: "activity_to_location",
      submitButton: "activity_submit_button",
    },
    icon: OtherIcon,
  },
];
