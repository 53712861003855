import { FC } from "react";
import styled from "styled-components";

import { Select, SelectProps } from "./Select";

import { colors } from "src/theme/colors";

interface LabeledSelectProps extends SelectProps {
  label: string;
}
export const LabeledSelect: FC<LabeledSelectProps> = (props) => {
  const { label, hasError = false, ...selectProps } = props;
  return (
    <Wrapper>
      <Label hasError={hasError}>{label}</Label>
      <Select {...selectProps} hasError={hasError} />
    </Wrapper>
  );
};

const Wrapper = styled.label`
  display: block;
`;

type LabelStyleProps = {
  hasError: boolean;
};
const Label = styled.p<LabelStyleProps>`
  margin-top: 0;
  margin-bottom: 4px;

  color: ${({ hasError }) => (hasError ? colors.error[300] : colors.grey[600])};
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
`;
