import { ChangeEventHandler, FC, useRef } from "react";
import styled from "styled-components";

import { ReactComponent as SearchIcon } from "src/assets/svg-icons/search.svg";
import { colors } from "src/theme/colors";

interface SearchTextInputProps {
  value?: string;
  placeholder?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
}
export const SearchTextInput: FC<SearchTextInputProps> = (props) => {
  const { value, placeholder, onChange } = props;

  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <Wrapper onClick={() => inputRef.current?.focus()}>
      <SearchIcon />
      <input
        ref={inputRef}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  background-color: ${colors.main.white};
  border: 1px solid rgba(34, 34, 34, 0.1);
  border-radius: 4px;
  padding: 10px 16px;
  cursor: text;

  display: flex;
  align-items: center;

  & > svg {
    margin-right: 10px;
  }

  & > input {
    flex: 1;
    background-color: transparent;
    outline: none;
    border: none;
  }
`;
