import { TableInstance } from "react-table";
import styled from "styled-components";

import { Select } from "../form/Select";

import { pageSizeOptions } from "src/constants/pagination";

import { colors } from "src/theme/colors";

import { ReactComponent as PreviousIcon } from "src/assets/svg-icons/pagination-previous.svg";
import { ReactComponent as NextIcon } from "src/assets/svg-icons/pagination-next.svg";

const selectSizeOptions = pageSizeOptions.map((option) => ({
  label: option,
  value: option,
}));

export function PaginationMenu<T extends Record<string, unknown>>(props: {
  instance: TableInstance<T>;
  label: string;
}) {
  const { label, instance } = props;
  const {
    state: { pageSize },
    canPreviousPage,
    canNextPage,
    previousPage,
    nextPage,
    setPageSize,
  } = instance;

  return (
    <Wrapper>
      <Label>{label}</Label>
      <StyledSelect
        options={selectSizeOptions}
        value={pageSize}
        onChange={(e) => {
          setPageSize(+e.target.value || 10);
        }}
      />
      <StyledPreviousIcon
        onClick={canPreviousPage ? previousPage : undefined}
        canPreviousPage={canPreviousPage}
      />
      <StyledNextIcon
        onClick={canNextPage ? nextPage : undefined}
        canNextPage={canNextPage}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;

  & > *:not(:first-child) {
    margin-left: 13px;
  }
`;

const Label = styled.span`
  color: ${colors.main.black};
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
`;

const StyledSelect = styled(Select)`
  height: 36px;
  padding: 8px 16px;
  width: min-content;
`;

const StyledPreviousIcon = styled(PreviousIcon)<{ canPreviousPage: boolean }>`
  color: ${({ canPreviousPage }) =>
    canPreviousPage ? colors.cta.green : colors.grey[200]};
  cursor: ${({ canPreviousPage }) => (canPreviousPage ? "pointer" : "default")};
`;

const StyledNextIcon = styled(NextIcon)<{ canNextPage: boolean }>`
  color: ${({ canNextPage }) =>
    canNextPage ? colors.cta.green : colors.grey[200]};
  cursor: ${({ canNextPage }) => (canNextPage ? "pointer" : "default")};
`;
