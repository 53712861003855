import { TableInstance } from "react-table";

import { SearchTextInput } from "src/components/SearchTextInput";

export function GlobalFilterSearchBar<
  T extends Record<string, unknown>
>(props: { instance: TableInstance<T>; placeholder: string }) {
  const { placeholder, instance } = props;
  const {
    state: { globalFilter },
    setGlobalFilter,
  } = instance;

  return (
    <SearchTextInput
      value={globalFilter || ""}
      onChange={(e) => {
        setGlobalFilter(e.target.value);
      }}
      placeholder={placeholder}
    />
  );
}
