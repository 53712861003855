import { FC } from "react";
import { FormikErrors, useFormik } from "formik";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { Card } from "src/components/Card";
import { LabeledTextInput } from "src/components/form/LabeledTextInput";
import { LabeledSelect } from "src/components/form/LabeledSelect";
import { Button } from "src/components/form/Button";
import { Alert } from "src/components/Alert";

import { useRequestStatus, RequestStatus } from "src/hooks/useRequestStatus";
import { validateEmail, validateNonEmptyFields } from "src/utils/validations";
import { api } from "src/services/api";

import { EmphasizedTitle } from "src/styles/styled-components-library";

import { Display as DisplayEnum } from "../ToUsers";

import { ReactComponent as GoBackIcon } from "src/assets/svg-icons/navigation-left.svg";
import { ReactComponent as AddIcon } from "src/assets/svg-icons/add.svg";

interface DisplayNewUserProps {
  updateDisplay: (display: DisplayEnum) => void;
  refreshUsersData: () => void;
}
export const DisplayNewUser: FC<DisplayNewUserProps> = props => {
  const { updateDisplay, refreshUsersData } = props;

  const [status, setStatus] = useRequestStatus();
  const { t } = useTranslation();

  const formInitialValues = {
    lastName: "",
    firstName: "",
    email: "",
    phone: "",
    role: "employee",
  };
  type FormKey = keyof typeof formInitialValues;
  type FormInput = {
    key: FormKey;
    label: string;
    placeholder: string;
    isSelect?: boolean;
    options?: Array<{ label: string; value: string }>;
  };
  const inputs: FormInput[] = [
    {
      key: "lastName",
      label: t("forms.last_name"),
      placeholder: "Dourlens",
    },
    {
      key: "firstName",
      label: t("forms.first_name"),
      placeholder: "Clément",
    },
    {
      key: "email",
      label: t("forms.email"),
      placeholder: "mail@mail.com",
    },
    {
      key: "phone",
      label: t("forms.phone"),
      placeholder: "+ 33 07 83 32 42 42",
    },
    {
      key: "role",
      label: t("forms.status"),
      placeholder: "Statut",
      isSelect: true,
      options: [
        {
          label: t("roles.user"),
          value: "employee",
        },
        {
          label: t("roles.admin"),
          value: "admin",
        },
      ],
    },
  ];

  const formik = useFormik({
    initialValues: formInitialValues,
    validate: values => {
      const errors: FormikErrors<typeof formInitialValues> = {};
      if (!validateEmail(values.email)) {
        errors.email = "The email provided is not valid.";
      }
      const emptyFields = validateNonEmptyFields(
        values,
        "lastName",
        "firstName",
        "phone",
        "role",
      );
      if (emptyFields.length > 0) {
        for (const key of emptyFields) {
          errors[key] = "This field is empty.";
        }
      }
      if (Object.keys(errors).length > 0) {
        setStatus(RequestStatus.VALIDATION_ERROR);
      }
      return errors;
    },
    validateOnChange: false,
    onSubmit: async values => {
      if (
        status === RequestStatus.LOADING ||
        status === RequestStatus.SUCCESS
      ) {
        return;
      }

      try {
        const res = await api.post<{ success: boolean }>("/users", values);
        if (!res.data.success) {
          throw new Error();
        }
        setStatus(RequestStatus.SUCCESS);
      } catch {
        setStatus(RequestStatus.SERVER_ERROR);
      }
    },
  });

  return (
    <>
      <NavigationWrapper>
        <StyledGoBackIcon
          onClick={() => {
            updateDisplay(DisplayEnum.ALL);
            refreshUsersData();
          }}
        />
      </NavigationWrapper>
      <CardWrapper>
        <AddIcon />
        <StyledEmphasizedTitle>
          {t("pages.settings.users.create_new")}{" "}
          <span className="emphasized"> {t("roles.user")}</span>
        </StyledEmphasizedTitle>
        <Form onSubmit={formik.handleSubmit}>
          {inputs.map(input =>
            input.isSelect ? (
              <LabeledSelect
                key={input.key}
                options={input.options || []}
                value={formik.values[input.key]}
                label={input.label}
                name={input.key}
                onChange={formik.handleChange}
              />
            ) : (
              <LabeledTextInput
                key={input.key}
                name={input.key}
                label={input.label}
                placeholder={input.placeholder}
                value={formik.values[input.key]}
                onChange={formik.handleChange}
                hasError={
                  status === RequestStatus.VALIDATION_ERROR &&
                  !!formik.errors[input.key]
                }
              />
            ),
          )}
          {status === RequestStatus.VALIDATION_ERROR && (
            <Alert>{t("forms.invalid_form")}</Alert>
          )}
          {status === RequestStatus.SERVER_ERROR && (
            <Alert>{t("pages.settings.users.server_error")}</Alert>
          )}
          {status === RequestStatus.SUCCESS && (
            <Alert variant="success">{t("pages.settings.users.success")}</Alert>
          )}
          <Button type="submit">
            {t("pages.settings.users.create_this_user")}
          </Button>
        </Form>
      </CardWrapper>
    </>
  );
};

const NavigationWrapper = styled.div`
  width: 585px;
  margin: 0 auto;
`;

const StyledGoBackIcon = styled(GoBackIcon)`
  cursor: pointer;
`;

const CardWrapper = styled(Card)`
  width: 585px;
  margin: 0 auto;
  padding: 80px 120px;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledEmphasizedTitle = styled(EmphasizedTitle)`
  margin-top: 16px;
  margin-bottom: 40px;
`;

const Form = styled.form`
  width: 100%;

  & > *:not(:last-child) {
    margin-bottom: 24px;
  }
`;
