export const TRANSLATIONS_FR = {
  pages: {
    sign_up: {
      title: "Inscription",
      description:
        "Complétez ce formulaire pour inscrire votre société et accéder à votre espace Pro. Si votre organisation a déjà un compte, demandez à votre administrateur de vous créer un accès utilisateur.",
      sign_up: "S'inscrire",
      error: "L inscription n'a pas pu aboutir. Veuillez réessayer plus tard",
    },
    sign_in: {
      sign_in: "Se connecter",
      create_society_account: "Créer un compte société",
      reset: "Demander la réinitialisation",
      pro: "Espace Pro",
      forgot_password: "Mot de passe oublié ?",
      email_for_reset:
        "Renseignez votre adresse mail pour obtenir un mail de réinitialisation",
      error: "L’adresse email et/ou le mot de passe sont incorrects.",
      success:
        "La demande de réinitialisation a été effectuée. Veuillez vérifier vos spams.",
    },
    reset_password: {
      title: "Modification du mot de passe",
      description:
        "Veuillez entrer le nouveau mot de passe pour accéder à votre compte",
      connect: "Se connecter à l’Espace Pro",
      modify: "Modifier le mot de passe",
      valid_password: "Veuillez entrer un mot de passe valide.",
      error:
        "La réinitialisation de votre mot de passe n'a pas pu aboutir. Veuillez réessayer en faisant une nouvelle demande.",
      success:
        "La réinitialisation du mot de passe a été effectuée avec succès.",
    },
    settings: {
      general_information: {
        admin: "Administrateur du compte",
        society: "Informations société",
        logo: "Logo (facultatif)",
        no_logo: "Aucun logo d'upload",
        delete_logo: "Supprimer le logo",
        logo_line_1:
          "Téléchargez votre logo pour qu’il apparaisse sur l’application client. ",
        logo_line_2:
          "En cas d'absence de votre logo, le logo Digital Roadbook apparaitra dans l'application client.",
        logo_line_3: "(png, jpg ou jpeg)",
        form_error:
          "Les champs indiqués ne sont pas valides. La modification n'a pas pu être effectuée.",
        server_error:
          "Une erreur s'est produite pendant la modification de vos informations.",
        success: "Vos informations ont été modifiées.",
      },
      users: {
        per_pages: "Utilisateurs par page",
        search: "Rechercher un utilisateur",
        create: "Créer un utilisateur",
        delete: "Supprimer l’utilisateur",
        create_new: "Créer un nouvel",
        server_error:
          "Votre utilisateur n'a pas pu être créé. L'email indiqué est peut-être déjà existant. Veuillez réessayer.",
        success:
          "L’utilisateur a été créé avec succès. Un email lui a été envoyé afin qu’il définisse son mot de passe.",
        create_this_user: "Créer l'utilisateur",
      },
    },
    orders: {
      simple: "Simple",
      multiple: "Multiple",
      order: {
        validation_error:
          "Votre commande n'a pas pu être validée, veuillez vous assurer que toutes les informations nécessaires ont été indiquées.",
        search:
          "Rechercher une commande, référence, client, date de départ, code rb",
        paid: "Payer mes commandes",
        order: "Passer une commande",
        order_per_pages: "Commandes par page",
        delete_title: "Suppression d'une commande",
        delete_description:
          "Êtes-vous sûr de vouloir supprimer cette commande ? La suppression des données sera permanente.",
        cancel: "Annuler",
        confirm: "Confirmer",
        name: "Commande #",
        validate: "Valider la commande",
        delete: "Supprimer la commande",
        pay_order: "Payer la commande",
        modify: "Modifier la commande",
        archive_order: "Archiver",
        draft: {
          title:
            "Combien de code(s) Roadbook souhaitez-vous commander pour un même dossier ?",
          one: "Un seul",
          one_description: "Passer une commande d'un seul code roadbook",
          many: "Plusieurs",
          many_description:
            "passer une commande unique avec plusieurs codes (groupes, bus, etc.)",
          server_error:
            "Le brouillon de votre nouvelle commande n'a pas pu être créé. Veuillez réessayer plus tard.",
        },
        new: {
          first: "Ma première commande de",
          new: "Nouvelle commande de",
          alert: "Vous devez créer un client afin de lui associer ce voyage",
        },
        archive: {
          title: "Archive d'une commande",
          description:
            "Êtes-vous sûr de vouloir archiver cette commande ? La commande ne sera plus modifiable si elle est archivée.",
          confirm: "Confirmer",
          cancel: "Annuler",
        },
        step_button: {
          next: "Suivant",
          previous: "Précédent",
          recap: " Aller au récapitulatif",
          complete_later: "Compléter plus tard",
        },
      },
      no_order: {
        order: "Passer une commande",
        description:
          "Quelques étapes pour créer un roadbook à forte valeur ajoutée pour le voyage de vos clients",
        first_ordre: "Première commande",
      },
      to_paid: {
        title: "Payer mes commandes",
        description: "Sélectionner les commandes que vous souhaitez régler",
        paid_selected_order: "Payer les commandes sélectionnées",
        number_error:
          "Vous ne pouvez pas payer plus de 50 commandes simultanément.",
        payment_error:
          "La redirection vers la page de paiement n'a pas pu être effectuée. Veuillez réessayer plus tard.",
        order_per_pages: "Commandes par page",
      },
      edit: {
        steps: {
          client: "Client",
          general_informations: "Informations générales",
          step_and_hotel: "Étapes & hôtels",
          transport_and_activity: "Transports et activités",
          recap: "Récapitulatif",
          payment: "Paiement",
        },
        client: {
          search: "Rechercher un client",
          create: "Créer un client",
          create_this: "Création d'un client",
          email: "Email d’envoi du code Roadbook",
          server_error:
            "Le client n'a pas pu être créé. Veuillez réessayer ultérieurement.",
          button: "Créer et associer le client",
          how_many: "Combien de codes roadbook souhaitez-vous ?",
          code_number: "Nombre de codes roadbook",
        },
        general_informations: {
          ref: "Référence dossier",
          destination: "Pays de destination",
          destination_city: "1ère ville du voyage ou région",
        },
        order_steps: {
          arrival_airport: "Aéroport d’arrivée",
          arrival_date: "Date d'arrivée",
          arrival_hour: "Heure d’arrivée (facultatif)",
          step_and_hotel: "Étapes et hôtels",
          departure_airport: "Aéroport de départ",
          departure_date: "Date de départ",
          departure_hour: "Heure de départ (falcultatif)",
          arrival_day_0: "Arrivée le même jour",
          arrival_day_1: "Arrivée J+1",
          arrival_day_2: "Arrivée J+2",
          custom: "Autre hôtel",
          google: "Hôtel par recherche Google",
          free_step: "Séjour libre",
          date: "Date",
          hotel: "Hôtel",
          city: "Ville",
          address: "Adresse",
          accommodation_type: "Type d'hébergement",
          name: "Nom et adresse",
          where: "Où",
          city_name: "Nom de la ville",
          night_number: "Nombre de nuits",
          add_step: "Ajouter l'étape",
          create_hotel: "Créer un hôtel",
          if_hotel_does_not_exist:
            "Si l'hôtel n'existe pas dans la base, créez-le via le bouton suivant.",
          hotel_name: "Nom de l'hôtel",
          mandatory_address: "Adresse",
          create_hotel_title: "Création d'un hôtel non référencé",
          create_hotel_db: "Créer l'hôtel dans la base",
        },
        extra_travel: {
          car_title: "LOCATION DE VOITURE (OU MOTO / MOTORHOME)",
          car_form_title: "Ajouter une nouvelle location",
          car_from_location: "Lieu de prise en charge",
          car_to_location: "Lieu de retour",
          car_submit_button: "Ajouter cette location",
          car_name: "Loueur / Type de véhicule",
          car_hour: "Date et heure de prise en charge",

          plane_title: "VOL INTÉRIEUR",
          plane_form_title: "Ajouter un vol intérieur",
          plane_from_location: "Aéroport de départ",
          plane_to_location: "Aéroport d'arrivée",
          plane_submit_button: "Ajouter ce vol",

          train_title: "TRAIN",
          train_form_title: "Ajouter un train",
          train_from_location: "Gare de départ",
          train_to_location: "Gare d'arrivée",
          train_submit_button: "Ajouter ce train",

          boat_title: "FERRY OU CROISIÈRE",
          boat_form_title: "Ajouter un ferry ou une croisière",
          boat_from_location: "Port / Terminal de ferry de départ",
          boat_to_location: "Port / Terminal de ferry d’arrivée",
          boat_submit_button: "Ajouter ce ferry ou cette croisière",

          activity_title: "EXCURSIONS ET ACTIVITÉS",
          activity_formTitle: "Ajouter une excursion ou une activité",
          activity_from_location: "Lieu",
          activity_to_location: "Lieu",
          activity_submit_button: "Ajouter cette excursion ou cette activité",
          activity_name: "Nom de l’excursion/activité",
          activity_hour: "Date et heure de retour",

          next: "Suivant",
          pass: "Passer l'étape",
          date: "Date",
          hour: "Heure",
          date_hour: "Date et heure",
        },
        recap: {
          please_check:
            "Veuillez s’il vous plaît vérifier les informations saisies. Il sera possible d’établir des modifications jusqu'au moment du départ.",
          client: {
            order_client: "Clients de la commande",
            code: "Code à fournir au client ",
            code_information: "(inactif jusqu’au paiement)",
            title: "Index",
          },
          general_informations: {
            not_provided_yet: "Non renseigné pour le moment",
            not_provided: "Non renseigné",
            complete: "Compléter",
          },
          validate: "Valider ma commande",
          validate_and_pay: "Valider et payer ma commande",
          pay: "Payer ma commande",
        },
        payment: {
          title: "Paiement",
          price: "Le tarif est de {{price}}€ TTC",
          description_0: "Je déclare avoir pris connaissance des",
          description_1: "conditions générales de vente",
          description_2: "et les accepter sans réserve",
          pay: "Payer {{price}}€ TTC",
          next_month_0: "Merci pour votre commande.",
          next_month_1:
            "Pour la régler, rendez vous chaque fin de mois sur la page récapitulative de vos commandes. Sélectionnez celles que vous souhaitez régler et payez-les facilement avec votre carte bancaire.",
        },
      },
    },
    poi: {
      navbar: {
        regions: "Régions",
        poi: "Points d'intérêt",
        practical_informations: "Informations pratiques",
      },
      country: {
        search: "Rechercher un pays",
        country_name: "Nom du pays",
        create: "Créer un pays",
        select: "Sélectionner un pays",
        country_per_page: "Pays par page",
        create_this_country: "Création d'un Pays",
        select_this_country:
          "Sélection d’un pays pour créer un point d’intérêt",
        create_button: "Créer le pays",
        select_button: "Sélectionner le pays",

        delete_country_title: "Suppression d'un pays",
        delete_country_description:
          "Êtes-vous sûr de vouloir supprimer ce pays ? La suppression des données sera permanente.",

        cancel: "Annuler",
        confirm: "Confirmer",
        server_error:
          "La création n'a pas pu aboutir. Veuillez réessayer plus tard.",
        double_region_error: "La région existe déjà",
        success: "Le pays a été créé avec succès !",
        delete_error: "La supression de ce pays n'a pas pu aboutir.",
      },
      poi: {
        search: "Rechercher un point d'intérêt",
        create_button: "Créer un point d'intérêt",
        poi_per_page: "Point d'intéret par page",
        form: {
          name_placeholder: "Nom du lieu",
          tags_placeholder: "Ajouter un tag",
          logo_title: "Photo / Picto / Logo (facultatif)",
          no_logo: "Aucun logo d'upload",
          delete_logo: "Supprimer le logo",
          logo_description:
            "Téléchargez votre image pour qu’elle apparaisse sur l’application mobile. ",
          suggested_tags: "Tags suggérés",
          error: "Erreur",
        },
        no_poi: {
          title: "Ajouter un point d'intérêt",
          description: "Quelques étapes pour créer un nouveau point d’intérêt",
          create: "Créer un point d’intérêt",
        },
        create: {
          title: "Création d'un point d'intérêt",
          server_error:
            "La création n'a pas pu aboutir. Veuillez réessayer plus tard.",
          success: "Le point d'intérêt a été créé avec succès !",
          create_this_poi: "Créer le point d'intérêt",
        },
        modify: {
          title: "Modification d'un point d'intérêt",

          server_error:
            "La modification n'a pas pu aboutir. Veuillez réessayer plus tard.",
          success: "Le point d'intérêt a été modifié avec succès !",
          modify_this_poi: "Modifier le point d'intérêt",
        },
      },
      regions: {
        search: "Rechercher une région",
        create: "Créer une région",
        regions_per_page: "Régions par page",
        no_logo: "Aucune image d'upload",
        logo_title: "Logo (facultatif)",
        delete: "Supprimer l'image",
        logo_description:
          "Téléchargez votre image pour qu’elle apparaisse sur l’application mobile.",

        no_region: {
          title: "Ajouter une region",
          description: "Quelques étapes pour créer une region",
          first_region: "Première région",
        },
        edit: {
          title: "Modification d'une region",
          success: "La region a été modifiée avec succès !",
          button: "Modifier la région",
          server_error:
            "La modification n'a pas pu aboutir. Veuillez réessayer plus tard.",
        },
        new: {
          title: "Création d'une region",
          success: "La region a été créée avec succès !",
          button: "Créer la région",
          server_error:
            "La création n'a pas pu aboutir. Veuillez réessayer plus tard.",
        },
      },
      practical_informations: {
        search: "Rechercher une information pratique",
        create: "Créer une information pratique",
        practical_informations_per_page: "Informations pratiques par page",
        no_logo: "Aucun logo d'upload",
        delete_logo: "Supprimer le logo",
        logo_title: "Photo",
        server_error:
          "La création n'a pas pu aboutir. Veuillez réessayer plus tard.",
        logo_description:
          "Téléchargez votre image pour qu’elle apparaisse sur l’application mobile.",
        no_practical_informations: {
          title: "Ajouter une information pratique sur l'application mobile",
          description:
            "Quelques étapes pour créer une information pratique pour le voyage de vos clients",
          button: "Premiere information pratique",
        },
        new: {
          title: "Création d'une information pratique",
          success: "L'information pratique a été créée avec succès !",
          create: "Créer l'information pratique",
        },
        edit: {
          title: "Modification d'une information pratique",
          success: "L'information pratique a été modifiée avec succès !",
          button: "Modifier l'information pratique",
        },
        delete: {
          title: "Suppression d'une information pratique",
          description:
            "Êtes-vous sûr de vouloir supprimer cette information pratique ? La suppression des données sera permanente.",
          cancel: "Annuler",
          confirm: "Confirmer",
        },
      },
    },
    hotel: {
      search: "Rechercher un hotel",
      finished: "Terminé",
      not_finished: "Pas encore terminé",
      hotel_per_page: "Hôtel par page",
      delete: {
        error: "La supression de cet hotel n'a pas pu aboutir.",
        title: "Suppression d'un hotel",
        description:
          "Êtes-vous sûr de vouloir supprimer cet hotel ? La suppression des données sera permanente.",
        cancel: "Annuler",
        confirm: "Confirmer",
      },
      edit: {
        server_error:
          "Une erreur s'est produite pendant la modification de vos informations.",
        success: "Les informations ont été modifiées.",
      },
    },
    to: {
      search: "Rechercher un tour opérateur",
      create: "Créer un tour opérateur",
      to_per_page: "Tour opérateurs par page",
      billing: {
        now: "Direct",
        end_of_the_month: "Fin du mois",
        success:
          "La facturation de ce tour opérateur a été modifiée avec succès.",
        error: "La facturation de ce tour opérateur n'a pas pu aboutir.",
      },
      new: {
        title: "Création d'un tour opérateur",
        server_error:
          "La création n'a pas pu aboutir. Veuillez réessayer plus tard.",
        success:
          "Le tour opérateur et l'administrateur de celui-ci ont été créés avec succès !",
        button: "Créer le tour opérateur et son administrateur",
      },
    },
  },
  navbar: {
    to: {
      orders: "Commandes",
      poi: "Gestion des points d’intérêts",
    },
    drb: {
      to: "Tour opérateurs",
      hotels: "Hotels",
      poi: "Gestion des points d’intérêts",
    },
  },
  roles: {
    user: "Utilisateur",
    users: "Utilisateurs",
    admin: "Administrateur",
  },
  forms: {
    email: "Adresse email",
    password: "Mot de passe",
    company_name: "Nom de la société",
    phone: "Numéro de téléphone",
    last_name: "Nom",
    first_name: "Prénom",
    address: "Adresse",
    postal_code: "Code postal",
    city: "Ville",
    country: "Pays",
    tva: "Numéro de TVA",
    status: "Statut",
    actions: "Actions",
    order_number: "N° de commande",
    ref: "Réf. Dossier",
    start_date: "Date de départ",
    customer: "Client associé",
    customer_number: "Nb de clients",
    code_number: "Nb de codes",
    birthday: "Date de naissance",
    language: "Langue",
    longitude: "Longitude",
    latitude: "Latitude",
    description: "Description",
    tags: "Tags",
    media_credits: "Media crédit",
    picture: "Photo",
    error: "Erreur",
    name: "Nom",
    billing: "Facturation",
    admin_last_name: "Nom de l'administrateur",
    admin_first_name: "Prénom de l'admnistrateur",
    invalid_form:
      "Certains champs ne sont pas valides, veuillez les modifier et réessayer.",
  },
  enum: {
    order_status: {
      draft: "Commande non terminée",
      to_pay: "En attente de paiement",
      paid: "Payée",
      archived: "Archivée",
    },
    wemap_tags: {
      activity: "Activité",
      building: "Bâtiment",
      states: "Etat",
      monument: "Monument",
      museum: "Musée",
      province: "Province",
      hiking: "Randonnée",
      natural_site: "Site Naturel",
      top: "Top !",
    },
  },
};
