import { CellProps, HeaderProps, Hooks } from "react-table";
import styled from "styled-components";

import { Checkbox } from "src/components/form/Checkbox";

/**
 * Create a custom selection hook for react-table, to be combined with `useRowSelect`.
 */
export const makeSelectionHook = (disableAllSelection: boolean) => {
  const selectionHook = <T extends Record<string, unknown>>(
    hooks: Hooks<T>
  ) => {
    hooks.allColumns.push((columns) => [
      {
        id: "_selector",
        disableResizing: true,
        disableGroupBy: true,
        minWidth: 45,
        width: 45,
        maxWidth: 45,
        Aggregated: undefined,
        Header: ({
          getToggleAllRowsSelectedProps,
          toggleAllRowsSelected,
          isAllRowsSelected,
        }: HeaderProps<T>) => {
          if (disableAllSelection) {
            // if we disable the row selection, then we don't display the header checkbox
            return null;
          }
          const { checked } = getToggleAllRowsSelectedProps();
          return (
            <CheckboxWrapper onClick={(e) => e.stopPropagation()}>
              <Checkbox
                checked={!!checked}
                onChange={() => toggleAllRowsSelected(!isAllRowsSelected)}
              />
            </CheckboxWrapper>
          );
        },
        Cell: ({ row }: CellProps<T>) => {
          const { checked } = row.getToggleRowSelectedProps();
          return (
            <CheckboxWrapper onClick={(e) => e.stopPropagation()}>
              <Checkbox
                checked={!!checked}
                onChange={() => row.toggleRowSelected()}
              />
            </CheckboxWrapper>
          );
        },
      },
      ...columns,
    ]);
  };

  return selectionHook;
};

const CheckboxWrapper = styled.div`
  width: min-content;
`;
