import { useCallback, useMemo } from "react";

import { TravelKind, ExtraTravelDraft } from "src/types/order";

export const useFilteredExtraTravels = (extraTravels: ExtraTravelDraft[]) => {
  const getExtraTravelsFromKind = useCallback(
    (kind: TravelKind) =>
      extraTravels.filter((extraTravel) => extraTravel.kind === kind),
    [extraTravels]
  );

  const carRentalExtraTravels = useMemo(
    () => getExtraTravelsFromKind(TravelKind.CAR_RENTAL),
    [getExtraTravelsFromKind]
  );
  const planeExtraTravels = useMemo(
    () => getExtraTravelsFromKind(TravelKind.PLANE),
    [getExtraTravelsFromKind]
  );
  const trainExtraTravels = useMemo(
    () => getExtraTravelsFromKind(TravelKind.TRAIN),
    [getExtraTravelsFromKind]
  );
  const boatExtraTravels = useMemo(
    () => getExtraTravelsFromKind(TravelKind.BOAT),
    [getExtraTravelsFromKind]
  );
  const activityExtraTravels = useMemo(
    () => getExtraTravelsFromKind(TravelKind.ACTIVITY),
    [getExtraTravelsFromKind]
  );
  const mapKindToExtraTravels: Record<TravelKind, ExtraTravelDraft[]> = {
    [TravelKind.CAR_RENTAL]: carRentalExtraTravels,
    [TravelKind.PLANE]: planeExtraTravels,
    [TravelKind.TRAIN]: trainExtraTravels,
    [TravelKind.BOAT]: boatExtraTravels,
    [TravelKind.ACTIVITY]: activityExtraTravels,
  };

  return {
    mapKindToExtraTravels,
  };
};
