import { FC } from "react";
import { FormikErrors, useFormik } from "formik";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { Modal } from "src/components/Modal";
import { Button } from "src/components/form/Button";
import { LabeledTextInput } from "src/components/form/LabeledTextInput";

import { RequestStatus, useRequestStatus } from "src/hooks/useRequestStatus";
import { validateNonEmptyFields } from "src/utils/validations";
import { api } from "src/services/api";

import { Title } from "src/styles/styled-components-library";

import type { Hotel } from "src/models/hotel";

import { ReactComponent as PinAddIcon } from "src/assets/svg-icons/pin-add.svg";

const formInitialValues = {
  name: "",
  country: "",
  city: "",
  address: "",
};
type FormKey = keyof typeof formInitialValues;

type Input = {
  key: FormKey;
  label: string;
  placeholder: string;
};

interface CreateCustomHotelModalProps {
  showModal: boolean;
  closeModal: () => void;
  addHotelHandler: (hotel: Hotel) => void;
}
export const CreateCustomHotelModal: FC<
  CreateCustomHotelModalProps
> = props => {
  const { showModal, closeModal, addHotelHandler } = props;
  const { t } = useTranslation();

  const [status, setStatus] = useRequestStatus();

  const inputs: Input[] = [
    {
      key: "name",
      label: t("pages.orders.edit.order_steps.hotel_name"),
      placeholder: "Hôtel",
    },
    {
      key: "country",
      label: t("forms.country"),
      placeholder: "France",
    },
    {
      key: "city",
      label: t("forms.city"),
      placeholder: "Abbeville",
    },
    {
      key: "address",
      label: t("pages.orders.edit.order_steps.address"),
      placeholder: "Adresse complète",
    },
  ];

  const formik = useFormik({
    initialValues: formInitialValues,
    validateOnChange: false,
    validate: values => {
      const errors: FormikErrors<typeof formInitialValues> = {};

      const emptyFields = validateNonEmptyFields(
        values,
        "name",
        "country",
        "city",
      );
      if (emptyFields.length > 0) {
        for (const key of emptyFields) {
          errors[key] = "This field is empty.";
        }
      }
      if (Object.keys(errors).length > 0) {
        setStatus(RequestStatus.VALIDATION_ERROR);
      }
      return errors;
    },
    onSubmit: async values => {
      if (status === RequestStatus.LOADING) {
        return;
      }

      setStatus(RequestStatus.LOADING);
      try {
        const res = await api.post<{ success: boolean; hotel: Hotel }>(
          "/hotels",
          {
            ...values,
            // address optional field
            address: values.address.trim() || undefined,
          },
        );
        if (!res.data.success) {
          throw new Error();
        }
        addHotelHandler(res.data.hotel);
        closeModal();
      } catch {
        setStatus(RequestStatus.SERVER_ERROR);
      }
    },
  });

  return (
    <Modal showModal={showModal} closeModal={closeModal}>
      <ContentWrapper>
        <PinAddIcon />
        <Title>{t("pages.orders.edit.order_steps.create_hotel_title")}</Title>
        <FormWrapper onSubmit={formik.handleSubmit}>
          <InputsWrapper>
            {inputs.map(input => (
              <LabeledTextInput
                key={input.key}
                name={input.key}
                label={input.label}
                placeholder={input.placeholder}
                value={formik.values[input.key]}
                hasError={
                  status === RequestStatus.VALIDATION_ERROR &&
                  !!formik.errors[input.key]
                }
                onChange={formik.handleChange}
                autoComplete="off"
              />
            ))}
          </InputsWrapper>
          <SubmitButton
            type="submit"
            loading={status === RequestStatus.LOADING}
          >
            {t("pages.orders.edit.order_steps.create_hotel_db")}
          </SubmitButton>
        </FormWrapper>
      </ContentWrapper>
    </Modal>
  );
};

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  & > *:not(:last-child) {
    margin-bottom: 16px;
  }
`;

const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  & > *:not(:last-child) {
    margin-bottom: 48px;
  }
`;

const InputsWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 24px;
`;

const SubmitButton = styled(Button)`
  width: min-content;
`;
