import { FC } from "react";
import { useTranslation } from "react-i18next";

import { Select, SelectOption } from "src/components/form/Select";

import { useToastNotifications } from "src/hooks/useToastNotifications";
import { RequestStatus, useRequestStatus } from "src/hooks/useRequestStatus";
import { api } from "src/services/api";

import type { TourOperator } from "src/models/tour-operator";
import { FacturationKind } from "src/types/tour-operator";

interface UpdateFacturationKindProps {
  tourOperatorId: TourOperator["id"];
  kind: FacturationKind;
  updateKind: (newKind: FacturationKind) => void;
}
export const UpdateFacturationKind: FC<UpdateFacturationKindProps> = props => {
  const { t } = useTranslation();

  const options: SelectOption[] = [
    {
      label: t("pages.to.billing.now"),
      value: FacturationKind.SELF_MANAGED,
    },
    {
      label: t("pages.to.billing.end_of_the_month"),
      value: FacturationKind.END_OF_MONTH,
    },
  ];

  const { tourOperatorId, kind, updateKind } = props;

  const { sendToast } = useToastNotifications();
  const [status, setStatus] = useRequestStatus();

  const updateKindHandler = async (newKind: FacturationKind) => {
    if (status === RequestStatus.LOADING) {
      return;
    }

    setStatus(RequestStatus.LOADING);
    try {
      const res = await api.patch<{ success: boolean }>(
        `tour-operators/${tourOperatorId}/facturation`,
        { kind: newKind },
      );
      if (!res.data.success) {
        throw new Error();
      }
      updateKind(newKind);
      setStatus(RequestStatus.IDLE);
      sendToast("success", t("pages.to.billing.success"));
    } catch {
      setStatus(RequestStatus.IDLE);
      sendToast("error", t("pages.to.billing.error"));
    }
  };

  return (
    <Select
      value={kind}
      options={options}
      onChange={e => updateKindHandler(e.target.value as FacturationKind)}
    />
  );
};
