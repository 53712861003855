import { FC, useState } from "react";
import { useFormik } from "formik";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { LabeledTextInput } from "src/components/form/LabeledTextInput";
import { LabeledDateInput } from "src/components/form/LabeledDateInput";
import { LabeledTimeInput } from "src/components/form/LabeledTimeInput";
import { Button } from "src/components/form/Button";

import { validateDate, validateHour } from "src/utils/validations";
import { getDestinationLocalDate } from "src/utils/date";

import type { Location } from "src/models/location";
import { TravelKind, ExtraTravelDraft } from "src/types/order";
import { LabeledLocationAutocompleteInput } from "src/components/form/LabeledLocationAutocompleteInput";

enum FormStatus {
  IDLE = "IDLE",
  VALIDATION_ERROR = "VALIDATION_ERROR",
}

interface ExtraTravelFormProps {
  kind: TravelKind;
  labels: {
    fromLocation: string;
    toLocation: string;
    submitButton: string;
  };
  minDate?: string;
  addExtraTravel: (extraTravel: ExtraTravelDraft) => void;
}
export const ExtraTravelForm: FC<ExtraTravelFormProps> = props => {
  const { kind, labels, addExtraTravel } = props;
  const { t } = useTranslation();

  const initialFormValues = {
    name: "",
    fromLocation: "",
    fromDate: "",
    fromHour: "",
    toLocation: "",
    toDate: "",
    toHour: "",
  };

  const [fromLocationSelected, setFromLocationSelected] =
    useState<Location | null>(null);
  const [toLocationSelected, setToLocationSelected] = useState<Location | null>(
    null,
  );
  const [status, setStatus] = useState(FormStatus.IDLE);
  const formik = useFormik({
    initialValues: initialFormValues,
    validateOnChange: false,
    validate: values => {
      const errors: Record<string, string> = {};
      if (
        (kind === TravelKind.CAR_RENTAL || kind === TravelKind.ACTIVITY) &&
        values.name.trim() === ""
      ) {
        errors.name = "This field cannot be empty.";
      }

      ["fromDate", "toDate"].forEach(date => {
        if (!validateDate(formik.values[date as "fromDate" | "toDate"])) {
          if (kind !== TravelKind.ACTIVITY && date !== "toDate") {
            errors[date] = "This field is not valid.";
          }
        }
      });

      ["fromHour", "toHour"].forEach(hour => {
        if (
          formik.values[hour as "fromHour" | "toHour"] &&
          !validateHour(formik.values[hour as "fromHour" | "toHour"])
        ) {
          if (kind !== TravelKind.ACTIVITY && hour !== "toHour") {
            errors[hour] = "This field is not valid.";
          }
        }
      });

      if (!values["fromLocation"] || !fromLocationSelected) {
        errors["fromLocation"] = "This input is not valid.";
      }
      if (!values["toLocation"] || !toLocationSelected) {
        if (kind !== TravelKind.ACTIVITY) {
          errors["toLocation"] = "This input is not valid.";
        }
      }
      if (Object.keys(errors).length > 0) {
        setStatus(FormStatus.VALIDATION_ERROR);
      }
      return errors;
    },
    onSubmit: values => {
      if (
        !fromLocationSelected ||
        (kind !== TravelKind.ACTIVITY && !toLocationSelected)
      ) {
        return;
      }

      const fromHour = values.fromHour === "" ? "11:59:42" : values.fromHour;
      const toHour = values.toHour === "" ? "11:59:42" : values.toHour;

      const newDraft: ExtraTravelDraft = {
        tempId: Math.random().toString(),
        kind,
        fromLocation: fromLocationSelected,
        fromDate: getDestinationLocalDate(
          values.fromDate,
          fromHour,
          fromLocationSelected?.utcOffsetMinutes ?? 0,
        ).toISOString(),
        toLocation:
          kind === TravelKind.ACTIVITY
            ? fromLocationSelected
            : toLocationSelected!,
        toDate:
          kind === TravelKind.ACTIVITY
            ? getDestinationLocalDate(
                values.fromDate,
                fromHour,
                fromLocationSelected?.utcOffsetMinutes ?? 0,
              ).toISOString()
            : getDestinationLocalDate(
                values.toDate,
                toHour,
                toLocationSelected?.utcOffsetMinutes ?? 0,
              ).toISOString(),
      };

      if (kind === TravelKind.CAR_RENTAL || kind === TravelKind.ACTIVITY) {
        newDraft.name = values.name;
      }
      addExtraTravel(newDraft);
      formik.setValues(initialFormValues);
    },
  });

  const fieldHasError = (key: keyof typeof initialFormValues) => {
    return status === FormStatus.VALIDATION_ERROR && !!formik.errors[key];
  };

  return (
    <Form onSubmit={formik.handleSubmit}>
      {(kind === TravelKind.CAR_RENTAL || kind === TravelKind.ACTIVITY) && (
        <Row>
          <LabeledTextInput
            label={
              kind === TravelKind.CAR_RENTAL
                ? t("pages.orders.edit.extra_travel.car_name")
                : t("pages.orders.edit.extra_travel.activity_name")
            }
            placeholder="-"
            name="name"
            value={formik.values["name"]}
            onChange={formik.handleChange}
            hasError={fieldHasError("name")}
          />
          <div />
          <div />
        </Row>
      )}
      <Row>
        <LabeledLocationAutocompleteInput
          label={t(`pages.orders.edit.extra_travel.${labels.fromLocation}`)}
          placeholder="-"
          name="fromLocation"
          value={formik.values["fromLocation"]}
          onChange={formik.handleChange}
          hasError={fieldHasError("fromLocation")}
          onPlaceSelect={location => {
            setFromLocationSelected(location);
            formik.setValues(values => ({
              ...values,
              fromLocation: location.displayName
                ? `${location.displayName} (${location.street ?? ""} ${
                    location.city ?? ""
                  })`
                : `${location.street ?? location.city ?? location.country}`,
            }));
          }}
        />
        <LabeledDateInput
          label={t(`pages.orders.edit.extra_travel.date`)}
          placeholder="-"
          name="fromDate"
          value={formik.values["fromDate"]}
          onChange={formik.handleChange}
          hasError={fieldHasError("fromDate")}
        />
        <LabeledTimeInput
          label={t(`pages.orders.edit.extra_travel.hour`)}
          placeholder="-"
          name="fromHour"
          value={formik.values["fromHour"]}
          onChange={formik.handleChange}
          hasError={fieldHasError("fromHour")}
        />
      </Row>
      {kind !== TravelKind.ACTIVITY ? (
        <Row>
          <LabeledLocationAutocompleteInput
            label={t(`pages.orders.edit.extra_travel.${labels.toLocation}`)}
            placeholder="-"
            name="toLocation"
            value={formik.values["toLocation"]}
            onChange={formik.handleChange}
            hasError={fieldHasError("toLocation")}
            onPlaceSelect={location => {
              setToLocationSelected(location);
              formik.setValues(values => ({
                ...values,
                toLocation: location.displayName
                  ? `${location.displayName} (${location.street ?? ""} ${
                      location.city ?? ""
                    })`
                  : `${location.street ?? location.city ?? location.country}`,
              }));
            }}
          />
          <LabeledDateInput
            label={t(`pages.orders.edit.extra_travel.date`)}
            placeholder="-"
            name="toDate"
            value={formik.values["toDate"]}
            onChange={formik.handleChange}
            hasError={fieldHasError("toDate")}
          />
          <LabeledTimeInput
            label={t(`pages.orders.edit.extra_travel.hour`)}
            placeholder="-"
            name="toHour"
            value={formik.values["toHour"]}
            onChange={formik.handleChange}
            hasError={fieldHasError("toHour")}
          />
        </Row>
      ) : null}
      <SubmitButton type="submit">
        {t(`pages.orders.edit.extra_travel.${labels.submitButton}`)}
      </SubmitButton>
    </Form>
  );
};

const Form = styled.form`
  & > *:not(:last-child) {
    margin-bottom: 24px;
  }
`;

const Row = styled.div`
  display: flex;
  gap: 24px;

  & > * {
    flex: 1;
  }
`;

const SubmitButton = styled(Button)`
  width: min-content;
`;
