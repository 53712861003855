import { FC, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";

import { BasicPageWithHeader } from "src/components/layout/BasicPageWithHeader";
import { Hotel } from "src/models/hotel";
import { api } from "src/services/api";
import { ReactComponent as GoBackIcon } from "src/assets/svg-icons/navigation-left.svg";
import { HotelInformations } from "src/components/pages/hotels/HotelInformations";

export const UpdateHotel: FC = () => {
  const history = useHistory();
  const { hotelId } = useParams<{ hotelId: string }>();
  const [hotel, setHotel] = useState<Hotel | null>(null);

  useEffect(() => {
    async function fetchHotel() {
      try {
        if (!hotelId) {
          throw new Error();
        }

        const res = await api.get<Hotel>(`/hotels/${hotelId}`);
        setHotel(res.data);
      } catch {
        history.push("/hotels");
      }
    }

    if (hotel === null) {
      fetchHotel();
    }
  }, [hotelId, hotel, history]);

  return (
    <BasicPageWithHeader>
      <ContentWrapper>
        <StyledGoBackIcon onClick={() => history.push("/hotels")} />
        {hotel && (
          <Wrapper>
            <HotelInformations hotel={hotel} />
          </Wrapper>
        )}
      </ContentWrapper>
    </BasicPageWithHeader>
  );
};
const Wrapper = styled.div`
  margin: 0 auto;
  padding: 8px 0;
  width: 90%;

  & > * {
    margin-bottom: 30px;
  }
`;

const ContentWrapper = styled.div`
  flex: 1;
  padding: 24px 18px;
  display: flex;
  position: relative;
`;

const StyledGoBackIcon = styled(GoBackIcon)`
  cursor: pointer;
  position: absolute;
  top: 24px;
  left: 18px;
`;
