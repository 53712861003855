import styled, { css } from "styled-components";

import { colors } from "src/theme/colors";

export const PageWrapper = styled.div`
  background-color: ${colors.background[100]};
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

export const WaveBackground = css`
  background-image: url("/images/waves.png");
  background-repeat: repeat-x;
  background-size: 653px 653px;
`;

export const WavePageWrapper = styled(PageWrapper)`
  ${WaveBackground};
`;

const BaseTitleStyle = css`
  color: ${colors.main.black};
  font-weight: 600;
  font-size: 24px;
  line-height: 25px;
  letter-spacing: -0.0446em;
`;
export const Title = styled.h2`
  margin: 0;
  ${BaseTitleStyle}
`;
export const EmphasizedTitle = styled.h2`
  margin: 0;
  ${BaseTitleStyle}

  & > .emphasized {
    color: ${colors.cta.green};
  }
`;

export const Description = styled.p`
  margin: 0;
  color: ${colors.main.black};
  font-weight: 500;
  font-size: 17px;
  line-height: 23px;
  letter-spacing: -0.0446em;
`;

// used in modals to display a form submitted successfully
export const SuccessWrapper = styled.div`
  min-height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

export const BasicTable = styled.table`
  border-collapse: collapse;
  width: 100%;

  & td,
  th {
    padding: 12px 6px;
  }

  & th {
    color: ${colors.grey[200]};
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    text-align: left;

    border-bottom: 1px solid ${colors.background[100]};
  }

  & td {
    color: ${colors.main.black};
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    border-bottom: 1px solid ${colors.background[100]};
    transition: background-color 200ms ease;
  }

  & tr:hover td {
    background-color: ${colors.grey[100]};
  }

  & tr:last-child td {
    border-bottom: none;
  }
`;

export const IconWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;

  & > svg {
    cursor: pointer;
    color: ${colors.grey[600]};
    transition: color 200ms ease;

    &:hover {
      color: ${colors.cta.green};
    }
  }
`;

export const Separator = styled.div`
  width: 100%;
  border-bottom: 1px solid ${colors.grey[100]};
`;
