import { FC, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { Button } from "src/components/form/Button";
import { Badge } from "src/components/Badge";
import { ButtonWithIcon } from "src/components/ButtonWithIcon";
import { DeleteOrderModal } from "../DeleteOrderModal";
import { ArchiveOrderModal } from "../ArchiveOrderModal";

import { RequestStatus, useRequestStatus } from "src/hooks/useRequestStatus";
import { useToastNotifications } from "src/hooks/useToastNotifications";
import { api } from "src/services/api";
import { getOrderIdLabel } from "src/utils/order";
import { mapStatusToBadgeStyle, mapStatusToLabel } from "src/constants/status";

import { colors } from "src/theme/colors";

import type { Order } from "src/models/order";
import { Status } from "src/types/order";
import { FacturationKind } from "src/types/tour-operator";

import { ReactComponent as GoBackIcon } from "src/assets/svg-icons/navigation-left.svg";
import { ReactComponent as BinIcon } from "src/assets/svg-icons/bin.svg";
import { ReactComponent as ModifyIcon } from "src/assets/svg-icons/modify.svg";
import { ReactComponent as ArchiveIcon } from "src/assets/svg-icons/archive.svg";

interface ActionsHeaderProps {
  order: Order;
  fetchOrderData: () => void;
}
export const ActionsHeader: FC<ActionsHeaderProps> = props => {
  const { order, fetchOrderData } = props;

  const { t } = useTranslation();
  const history = useHistory();
  const { sendToast } = useToastNotifications();
  const [validateStatus, setValidateStatus] = useRequestStatus();
  const [showDeleteOrderModal, setShowDeleteOrderModal] = useState(false);
  const [showArchiveOrderModal, setShowArchiveOrderModal] = useState(false);

  const validateOrderHandler = async () => {
    if (
      validateStatus === RequestStatus.LOADING ||
      order.status !== Status.DRAFT
    ) {
      return;
    }

    setValidateStatus(RequestStatus.LOADING);
    try {
      const res = await api.post<{ success: boolean }>(
        `/orders/${order.id}/validate`,
      );
      if (!res.data.success) {
        throw new Error();
      }
      fetchOrderData();
      setValidateStatus(RequestStatus.IDLE);
    } catch {
      sendToast("error", t("pages.orders.order.validation_error"), 8000);
      setValidateStatus(RequestStatus.IDLE);
    }
  };

  const mapStatusToActions: Record<Status, JSX.Element> = {
    [Status.DRAFT]: (
      <>
        <Button
          onClick={validateOrderHandler}
          loading={validateStatus === RequestStatus.LOADING}
        >
          {t("pages.orders.order.validate")}
        </Button>
        <ButtonWithIcon
          onClick={() => setShowDeleteOrderModal(true)}
          icon={BinIcon}
        >
          {t("pages.orders.order.delete")}
        </ButtonWithIcon>
      </>
    ),
    [Status.TO_PAY]: (
      <>
        {order.tourOperator?.facturationKind ===
          FacturationKind.SELF_MANAGED && (
          <Button
            onClick={() => history.push(`/orders/${order.id}/edit?step=6`)}
          >
            {t("pages.orders.order.pay_order")}
          </Button>
        )}
        <ButtonWithIcon
          onClick={() => history.push(`/orders/${order.id}/edit`)}
          icon={ModifyIcon}
        >
          {t("pages.orders.order.modify")}
        </ButtonWithIcon>
        <ButtonWithIcon
          onClick={() => setShowDeleteOrderModal(true)}
          icon={ArchiveIcon}
        >
          {t("pages.orders.order.delete")}
        </ButtonWithIcon>
      </>
    ),
    [Status.PAID]: (
      <>
        <ButtonWithIcon
          onClick={() => history.push(`/orders/${order.id}/edit`)}
          icon={ModifyIcon}
        >
          {t("pages.orders.order.modify")}
        </ButtonWithIcon>
        <ButtonWithIcon
          onClick={() => setShowArchiveOrderModal(true)}
          icon={ArchiveIcon}
        >
          {t("pages.orders.order.archive_order")}
        </ButtonWithIcon>
      </>
    ),
    [Status.ARCHIVED]: <></>,
  };

  return (
    <HeaderWrapper>
      <InfoWrapper>
        <div>
          <GoBackIcon onClick={() => history.push("/orders")} />
        </div>
        <div>
          <p>
            {t("pages.orders.order.name")}
            {getOrderIdLabel(order.id)}
          </p>
          <Badge {...mapStatusToBadgeStyle[order.status]}>
            {t(`enum.order_status.${mapStatusToLabel[order.status]}`)}
          </Badge>
        </div>
      </InfoWrapper>
      <ActionsWrapper>{mapStatusToActions[order.status]}</ActionsWrapper>
      <ArchiveOrderModal
        orderId={order.id}
        showModal={showArchiveOrderModal}
        closeModal={() => setShowArchiveOrderModal(false)}
        postArchiveCallback={fetchOrderData}
      />
      <DeleteOrderModal
        orderId={order.id}
        showModal={showDeleteOrderModal}
        closeModal={() => setShowDeleteOrderModal(false)}
        postDeleteCallback={() => history.push("/orders")}
      />
    </HeaderWrapper>
  );
};

const HeaderWrapper = styled.div`
  padding: 24px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${colors.main.white};
`;

const InfoWrapper = styled.div`
  display: flex;

  & svg {
    cursor: pointer;
    width: 20px;
    height: 20px;
    margin-right: 16px;
  }

  & p {
    margin-top: 0px;
    margin-bottom: 8px;
    font-weight: 600;
    font-size: 18px;
    line-height: 18px;
    letter-spacing: -0.0446em;
    color: ${colors.main.black};
  }
`;

const ActionsWrapper = styled.div`
  display: flex;
  height: min-content;

  & > *:not(:first-child) {
    margin-left: 12px;
  }
`;
