import { FC, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { Card } from "src/components/Card";
import { LabeledTextInput } from "src/components/form/LabeledTextInput";
import { UpdateStepButtons } from "../UpdateStepButtons";

import { useRequestStatus, RequestStatus } from "src/hooks/useRequestStatus";
import { api } from "src/services/api";

import { Title } from "src/styles/styled-components-library";

import type { CommonStepProps } from "../EditContent";

interface MultipleClientsSelectionProps extends CommonStepProps {}
export const MultipleClientsSelection: FC<
  MultipleClientsSelectionProps
> = props => {
  const {
    order,
    currentStep,
    previousStepHandler,
    nextStepHandler,
    goToRecapHandler,
  } = props;
  const { t } = useTranslation();
  const [status, setStatus] = useRequestStatus();
  const [numberOfCodes, setNumberOfCodes] = useState(
    String(order.clients?.length || ""),
  );

  const canGoNextStep =
    !!numberOfCodes &&
    !isNaN(+numberOfCodes) &&
    +numberOfCodes > 0 &&
    +numberOfCodes <= 1000; // max amount of codes

  const submitStepHandler = async (successCallback: () => void) => {
    if (status === RequestStatus.LOADING) {
      return;
    }

    setStatus(RequestStatus.LOADING);
    try {
      const res = await api.patch<{ success: boolean }>(`/orders/${order.id}`, {
        clients: Array.from({ length: +numberOfCodes }, _ => ({
          type: "shallow",
        })),
      });
      if (!res.data.success) {
        throw new Error();
      }
      successCallback();
    } catch {
      setStatus(RequestStatus.SERVER_ERROR);
    }
  };

  return (
    <Wrapper>
      <CardWrapper>
        <Title>{t("pages.orders.edit.client.how_many")}</Title>
        <InputWrapper>
          <LabeledTextInput
            label={t("pages.orders.edit.client.code_number")}
            placeholder="2"
            value={numberOfCodes}
            onChange={e => setNumberOfCodes(e.target.value)}
          />
        </InputWrapper>
      </CardWrapper>
      <UpdateStepButtons
        order={order}
        disableNext={!canGoNextStep}
        currentStep={currentStep}
        loadingNext={status === RequestStatus.LOADING}
        previousHandler={previousStepHandler}
        nextHandler={() => submitStepHandler(nextStepHandler)}
        recapHandler={() => submitStepHandler(goToRecapHandler)}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  & > *:not(:last-child) {
    margin-bottom: 16px;
  }
`;

const CardWrapper = styled(Card)`
  padding: 80px 24px;

  display: flex;
  flex-direction: column;
  align-items: center;

  & > *:not(:last-child) {
    margin-bottom: 16px;
  }
`;

const InputWrapper = styled.div`
  width: 350px;
`;
