import { FC, useEffect, useMemo, useState } from "react";
import {
  CellProps,
  Column,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { Card } from "src/components/Card";
import { GlobalFilterSearchBar } from "src/components/table/GlobalFilterSearchBar";
import { PaginationMenu } from "src/components/table/PaginationMenu";
import { Table } from "src/components/table/Table";
import { Button, StyledButton } from "src/components/form/Button";
import { UpdateFacturationKind } from "./UpdateFacturationKind";

import { api } from "src/services/api";
import { mapCountryAlpha3CodeToName } from "src/constants/countries";

import { TourOperator } from "src/models/tour-operator";
import { Display as DisplayEnum } from "src/pages/drb/TourOperators";
import { FacturationKind } from "src/types/tour-operator";

interface DisplayAllProps {
  updateDisplay: (display: DisplayEnum) => void;
}
export const DisplayAll: FC<DisplayAllProps> = props => {
  const { t } = useTranslation();

  const { updateDisplay } = props;
  const [tourOperators, setTourOperators] = useState<TourOperator[]>([]);

  const updateTourOperatorFacturationKind = (
    tourOperatorId: TourOperator["id"],
    newKind: FacturationKind,
  ) => {
    setTourOperators(prevState =>
      prevState.map(tourOperator => {
        if (tourOperator.id === tourOperatorId) {
          return {
            ...tourOperator,
            facturationKind: newKind,
          };
        }
        return tourOperator;
      }),
    );
  };

  const columns: Column<TourOperator>[] = useMemo(() => {
    return [
      {
        Header: `${t("forms.name")}`,
        accessor: "name",
      },
      {
        Header: `${t("forms.email")}`,
        accessor: "email",
      },
      {
        Header: `${t("forms.phone")}`,
        accessor: "phone",
        disableSortBy: true,
        disableFilters: true,
      },
      {
        Header: `${t("forms.country")}`,
        accessor: "addressCountry",
        disableSortBy: true,
        disableFilters: true,
        Cell: ({ value }: CellProps<TourOperator, string>) => (
          <>{mapCountryAlpha3CodeToName[value]}</>
        ),
      },
      {
        Header: `${t("forms.billing")}`,
        accessor: "facturationKind",
        disableSortBy: true,
        disableFilters: true,
        Cell: (
          props: CellProps<TourOperator, TourOperator["facturationKind"]>,
        ) => {
          const {
            value,
            row: { original: tourOperator },
          } = props;
          return (
            <UpdateFacturationKind
              tourOperatorId={tourOperator.id}
              kind={value}
              updateKind={newKind =>
                updateTourOperatorFacturationKind(tourOperator.id, newKind)
              }
            />
          );
        },
      },
    ];
  }, [t]);

  const instance = useTable(
    {
      columns,
      data: tourOperators,
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  useEffect(() => {
    async function fetchTourOperators() {
      const res = await api.get<TourOperator[]>("/tour-operators");
      setTourOperators(res.data || []);
    }
    fetchTourOperators();
  }, []);

  return (
    <>
      <ActionsWrapper>
        <GlobalFilterSearchBar
          instance={instance}
          placeholder={t("pages.to.search")}
        />
        <Button onClick={() => updateDisplay(DisplayEnum.NEW_TO)}>
          {t("pages.to.create")}
        </Button>
      </ActionsWrapper>
      <TableCardWrapper>
        <Table
          instance={instance}
          columnsWidths={["20%", "25%", "20%", "20%", "15%"]}
        />
      </TableCardWrapper>
      <PaginationWrapper>
        <PaginationMenu instance={instance} label={t("pages.to.to_per_page")} />
      </PaginationWrapper>
    </>
  );
};

export const TableCardWrapper = styled(Card)`
  width: 100%;
  height: min-content;
  padding: 24px;
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ActionsWrapper = styled.div`
  display: flex;

  & > *:not(:first-child) {
    margin-left: 16px;
  }

  & > ${StyledButton} {
    width: min-content;
  }
`;
