import { FC } from "react";
import { add } from "date-fns";
import { useTranslation } from "react-i18next";

import { BasicTable, IconWrapper } from "src/styles/styled-components-library";

import type { StepDraft } from "./index";

import { ReactComponent as DeleteIcon } from "src/assets/svg-icons/actions/delete.svg";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

interface DisplayStepsProps {
  steps: StepDraft[];
  deleteStepHandler?: (step: StepDraft) => void;
  moveStep?: (step: StepDraft[]) => void;
}
export const DisplaySteps: FC<DisplayStepsProps> = props => {
  const { steps, deleteStepHandler, moveStep } = props;
  const { t } = useTranslation();

  function handleOnDragEnd(dragStaps: any) {
    if (!dragStaps.destination) return;

    const items: StepDraft[] = Array.from(steps);
    const [reorderedItem] = items.splice(dragStaps.source.index, 1);
    items.splice(dragStaps.destination.index, 0, reorderedItem);

    if (moveStep && items) moveStep(items);
  }

  const formatHour = (dateString: string) => {
    const date = new Date(dateString);

    const day = date.getUTCDate();
    const month = date.getUTCMonth() + 1; //Janvier === 0
    const year = date.getUTCFullYear();

    const getWellFormated = (number: number) => {
      if (number < 10) {
        return `0${number}`;
      }
      return number;
    };

    const formatedDate = `${getWellFormated(day)}/${getWellFormated(
      month,
    )}/${getWellFormated(year)}`;

    return formatedDate;
  };

  return (
    <>
      <BasicTable>
        <thead>
          <tr>
            <th>{t("pages.orders.edit.order_steps.date")}</th>
            <th>{t("pages.orders.edit.order_steps.city")}</th>
            <th>{t("pages.orders.edit.order_steps.hotel")}</th>
            <th>{t("pages.orders.edit.order_steps.mandatory_address")}</th>
            <th>{t("pages.orders.edit.order_steps.night_number")}</th>
            <th />
          </tr>
        </thead>
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="steps">
            {provided => (
              <tbody {...provided.droppableProps} ref={provided.innerRef}>
                {steps.map((step, index) => (
                  <Draggable
                    key={index}
                    draggableId={`${index}`}
                    index={index}
                    isDragDisabled={Boolean(!deleteStepHandler)}
                  >
                    {provided => (
                      <tr
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <td>
                          {formatHour(step.date)}
                          {" - "}
                          {formatHour(
                            add(new Date(step.date), {
                              days: step.nightsCount,
                            }).toISOString(),
                          )}
                        </td>
                        <td>{step.location.city}</td>
                        <td>{step.location.displayName}</td>
                        <td>{step.location.street}</td>
                        <td>{step.nightsCount}</td>
                        <td>
                          {!!deleteStepHandler && (
                            <IconWrapper>
                              <DeleteIcon
                                onClick={() => deleteStepHandler(step)}
                              />
                            </IconWrapper>
                          )}
                        </td>
                      </tr>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </tbody>
            )}
          </Droppable>
        </DragDropContext>
      </BasicTable>
      {steps.length === 0 && <p>Aucune étape encore enregistrée.</p>}
    </>
  );
};
