import { FC } from "react";

import { Header } from "src/components/layout/Header";
import { WavePageWrapper } from "src/styles/styled-components-library";

export const BasicPageWithHeader: FC = ({ children }) => {
  return (
    <WavePageWrapper>
      <Header />
      {children}
    </WavePageWrapper>
  );
};
