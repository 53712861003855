import { useFormik } from "formik";
import { FC, useEffect, useMemo, useRef } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { Alert } from "src/components/Alert";
import { Card } from "src/components/Card";
import { LabeledSaveableTextInput } from "src/components/form/LabeledSaveableTextInput";
import { RequestStatus, useRequestStatus } from "src/hooks/useRequestStatus";
import { Hotel } from "src/models/hotel";
import { api } from "src/services/api";

interface HotelInformationsProps {
  hotel: Hotel;
}

export const HotelInformations: FC<HotelInformationsProps> = props => {
  const { t } = useTranslation();

  const { hotel } = props;

  // form init & type definitions
  const formInitialValues = useMemo(
    () => ({
      name: hotel.name,
      country: hotel.country,
      city: hotel.city,
      longitude: hotel.location.lng,
      latitude: hotel.location.lat,
    }),
    [hotel],
  );
  type FormKey = keyof typeof formInitialValues;
  type FormInput = {
    key: FormKey;
    label: string;
    placeholder: string;
    isValid: (input: string) => boolean;
  };
  const inputs: FormInput[] = [
    {
      key: "name",
      label: t("forms.name"),
      placeholder: "Name",
      isValid: input => !!input.trim(),
    },
    {
      key: "country",
      label: t("forms.country"),
      placeholder: "Pays",
      isValid: input => !!input.trim(),
    },
    {
      key: "city",
      label: t("forms.city"),
      placeholder: "Ville",
      isValid: input => !!input.trim(),
    },
    {
      key: "longitude",
      label: t("forms.longitude"),
      placeholder: "Longitude",
      isValid: input => !!input.trim(),
    },
    {
      key: "latitude",
      label: t("forms.latitude"),
      placeholder: "Latitude",
      isValid: input => !!input.trim(),
    },
  ];

  // hooks
  const formik = useFormik({
    initialValues: formInitialValues,
    onSubmit: () => {},
  });
  const latestSavedValuesRef =
    useRef<typeof formInitialValues>(formInitialValues);
  const [status, setStatus] = useRequestStatus();

  // callbacks
  const cancelInputHandler = (key: FormKey) => {
    formik.setValues({
      ...formik.values,
      [key]: latestSavedValuesRef.current[key],
    });
  };

  const saveInputHandler = async (key: FormKey) => {
    if (
      status === RequestStatus.LOADING ||
      formik.values[key] === latestSavedValuesRef.current[key]
    ) {
      return;
    }

    setStatus(RequestStatus.LOADING);
    try {
      const res = await api.patch<{ success: boolean }>(`/hotels/${hotel.id}`, {
        [key]: formik.values[key],
      });

      if (!res.data.success) {
        throw new Error();
      }

      latestSavedValuesRef.current = {
        ...latestSavedValuesRef.current,
        [key]: formik.values[key],
      };
      setStatus(RequestStatus.SUCCESS);
    } catch {
      cancelInputHandler(key);
      setStatus(RequestStatus.SERVER_ERROR);
    }
  };

  useEffect(() => {
    formik.setValues(formInitialValues);
    latestSavedValuesRef.current = formInitialValues;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formInitialValues]);

  return (
    <CardWrapper>
      <Form onSubmit={e => e.preventDefault()}>
        {inputs.map(input => {
          const value = formik.values[input.key];
          const isValidInput =
            typeof value === "string" ? input.isValid(value) : value !== 0;
          return (
            <LabeledSaveableTextInput
              key={input.key}
              name={input.key}
              label={input.label}
              placeholder={input.placeholder}
              value={value ?? ""}
              onChange={formik.handleChange}
              onCancel={() => cancelInputHandler(input.key)}
              onSave={() => saveInputHandler(input.key)}
              onSaveError={() => setStatus(RequestStatus.VALIDATION_ERROR)}
              hasError={
                status === RequestStatus.VALIDATION_ERROR && !isValidInput
              }
              isValidInput={isValidInput}
            />
          );
        })}
      </Form>
      {status === RequestStatus.VALIDATION_ERROR && (
        <Alert>{t("forms.invalid_form")}</Alert>
      )}
      {status === RequestStatus.SERVER_ERROR && (
        <Alert>{t("pages.hotel.edit.server_error")}</Alert>
      )}
      {status === RequestStatus.SUCCESS && (
        <Alert variant="success">{t("pages.hotel.edit.success")}</Alert>
      )}
    </CardWrapper>
  );
};

const CardWrapper = styled(Card)`
  padding: 64px 110px;

  & > *:not(:last-child) {
    margin-bottom: 24px;
  }
`;

const Form = styled.form`
  display: flex;
  flex-wrap: wrap;
  gap: 24px 30px;

  & > * {
    width: 47.5%;
  }
`;
