import { FC } from "react";
import { useTranslation } from "react-i18next";

import { RecapSection } from "./RecapSection";
import { NotProvided } from "./NotProvided";

import { BasicTable } from "src/styles/styled-components-library";

import type { CommonOrderRecapSectionProps } from "./index";

interface MultipleClientsProps extends CommonOrderRecapSectionProps {}
export const MultipleClients: FC<MultipleClientsProps> = props => {
  const { order, editStep } = props;
  const { t } = useTranslation();

  const clients = order.clients || [];
  const isEndOfMonth = order.tourOperator?.facturationKind === "endOfMonth";

  return (
    <RecapSection
      title={t("pages.orders.edit.steps.client")}
      editClickHandler={editStep && (() => editStep(1))}
    >
      {clients.length > 0 ? (
        <BasicTable>
          <thead>
            <tr>
              <th style={{ width: "20%" }}>
                {t("pages.orders.edit.recap.client.title")}
              </th>
              <th>{`${t("pages.orders.edit.recap.client.code")} ${
                isEndOfMonth
                  ? ""
                  : t("pages.orders.edit.recap.client.code_information")
              }`}</th>
            </tr>
          </thead>
          <tbody>
            {clients.map((client, index) => (
              <tr key={client.drbRef}>
                <td style={{ width: "20%" }}>{index + 1}</td>
                <td>{client.drbRef}</td>
              </tr>
            ))}
          </tbody>
        </BasicTable>
      ) : (
        <NotProvided completeClickHandler={editStep && (() => editStep(1))} />
      )}
    </RecapSection>
  );
};
