import { FC, MouseEventHandler } from "react";
import styled from "styled-components";

import { colors } from "src/theme/colors";

import { ReactComponent as CheckIcon } from "src/assets/svg-icons/check-circle.svg";

interface SelectableBadgeProps {
  selected: boolean;
  label: string;
  onClick?: MouseEventHandler<HTMLDivElement>;
}
export const SelectableBadge: FC<SelectableBadgeProps> = (props) => {
  const { selected, label, onClick } = props;
  return (
    <Badge onClick={onClick} selected={selected}>
      {selected ? <CheckIcon /> : <div />}
      {label}
    </Badge>
  );
};

type BadgeStyleProps = {
  selected: boolean;
};
const Badge = styled.div<BadgeStyleProps>`
  display: flex;
  align-items: center;
  width: min-content;
  white-space: nowrap;

  padding: 10px 12px;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  border-radius: 4px;

  user-select: none;
  cursor: ${({ onClick }) => (!!onClick ? "pointer" : "inherit")};
  color: ${({ selected }) =>
    selected ? colors.turquoise[800] : colors.grey[600]};
  background-color: ${({ selected }) =>
    selected ? colors.turquoise[100] : colors.background[100]};
  border: 1px solid
    ${({ selected }) => (selected ? colors.turquoise[100] : colors.grey[100])};
  transition: all 200ms ease;

  & > *:first-child {
    margin-right: 8px;
    width: 12px;
    height: 12px;

    &:is(div) {
      border: 1px solid ${colors.grey[200]};
      border-radius: 50px;
      background-color: ${colors.background[100]};
    }
  }

  ${({ onClick, selected }) =>
    !!onClick && !selected ? `&:hover { background-color: #F1F1F1; }` : ""}
`;
