import { FC, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { SimpleOrders } from "./SimpleOrders";
import { MultipleOrders } from "./MultipleOrders";

import { colors } from "src/theme/colors";

import type { Order } from "src/models/order";
import { OrderType, Status } from "src/types/order";
import { PayOrders } from "./PayOrders";

export enum Mode {
  SIMPLE = "SIMPLE",
  MULTIPLE = "MULTIPLE",
  PAY_ORDERS = "PAY_ORDERS",
}

interface TourOpOrdersProps {
  orders: Order[];
  refreshOrders: () => void;
}
export const TourOpOrders: FC<TourOpOrdersProps> = props => {
  const { orders, refreshOrders } = props;

  const { t } = useTranslation();
  const [mode, setMode] = useState(Mode.SIMPLE);
  const previousModeRef = useRef<Mode | null>(null);

  const simpleOrders = useMemo(
    () => orders.filter(order => order.type === OrderType.SIMPLE),
    [orders],
  );
  const multipleOrders = useMemo(
    () => orders.filter(order => order.type === OrderType.MULTIPLE),
    [orders],
  );
  const unpaidOrders = useMemo(
    () => orders.filter(order => order.status === Status.TO_PAY),
    [orders],
  );

  const getToggleButtonProps = (toggleMode: Mode) => {
    return {
      isActive: mode === toggleMode,
      onClick: () => setMode(toggleMode),
    };
  };

  const updateModeHandler = (newMode: Mode) => {
    previousModeRef.current = mode;
    setMode(newMode);
  };

  const goBackHandler = () => {
    if (!!previousModeRef.current) {
      setMode(previousModeRef.current);
    }
  };

  const commonProps = {
    refreshOrders,
    updateModeHandler,
  };
  return (
    <Wrapper>
      {mode !== Mode.PAY_ORDERS && (
        <ToggleWrapper>
          <ToggleButton {...getToggleButtonProps(Mode.SIMPLE)}>
            {t("pages.orders.simple")}
          </ToggleButton>
          <ToggleButton {...getToggleButtonProps(Mode.MULTIPLE)}>
            {t("pages.orders.multiple")}
          </ToggleButton>
        </ToggleWrapper>
      )}
      {mode === Mode.SIMPLE && (
        <SimpleOrders orders={simpleOrders} {...commonProps} />
      )}
      {mode === Mode.MULTIPLE && (
        <MultipleOrders orders={multipleOrders} {...commonProps} />
      )}
      {mode === Mode.PAY_ORDERS && (
        <PayOrders orders={unpaidOrders} goBackHandler={goBackHandler} />
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 16px;

  & > *:not(:last-child) {
    margin-bottom: 24px;
  }
`;

const ToggleWrapper = styled.div`
  margin: 0 auto;
  padding: 4px;
  border-radius: 50px;
  background-color: ${colors.grey[100]};

  width: min-content;
  display: flex;

  & > :not(:first-child) {
    margin-left: 2px;
  }
  & > :not(:last-child) {
    margin-right: 2px;
  }
`;

type ToggleButtonStyleProps = {
  isActive: boolean;
};
const ToggleButton = styled.div<ToggleButtonStyleProps>`
  background-color: ${({ isActive }) =>
    isActive ? colors.grey[600] : "transparent"};
  color: ${({ isActive }) =>
    isActive ? colors.main.white : colors.main.black};
  padding: 8px 16px;
  border-radius: 50px;
  cursor: pointer;
  opacity: ${({ isActive }) => (isActive ? "1" : "0.6")};
  transition: all 200ms ease;

  &:hover {
    background-color: ${({ isActive }) =>
      isActive ? colors.grey[600] : colors.grey[200]};
  }
`;
