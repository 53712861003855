import { FC } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";

import { ResetPassword } from "src/pages/common/ResetPassword";
import { Settings } from "src/pages/common/Settings";
import { TourOperators } from "src/pages/drb/TourOperators";
import { Hotels } from "src/pages/drb/Hotels";
import { UpdateHotel } from "src/pages/drb/UpdateHotel";
import { ProtectedRoute } from "./ProtectedRoute";
import { Lists } from "src/pages/tour-operators/Lists";
import { NewPointOfInterest } from "src/components/pages/points-of-interest/NewPointOfInterest";
import { EditPointOfInterest } from "src/components/pages/points-of-interest/EditPointOfInterest";
import { EditList } from "src/components/pages/lists/EditList";
import { NewListRegion } from "src/components/pages/list-regions/NewListRegion";
import { List } from "src/pages/tour-operators/List";
import { EditListRegion } from "src/components/pages/list-regions/EditListRegion";
import { PracticalInformations } from "src/pages/drb/PracticalInformations";
import { NewPracticalInformation } from "src/components/pages/practical-informations/specific/NewPracticalInformation";
import { EditPracticalInformation } from "src/components/pages/practical-informations/specific/EditPracticalInformation";
import { GeneratePDF } from "src/components/pages/orders/GeneratePDF";
import { SignIn } from "src/pages/common/SignIn";

export const DrbRoutes: FC = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/sign-in" component={SignIn} />
        <Route exact path="/reset-password" component={ResetPassword} />
        <ProtectedRoute
          exact
          path="/tour-operators"
          component={TourOperators}
        />
        <ProtectedRoute exact path="/hotels" component={Hotels} />
        <ProtectedRoute
          exact
          path="/hotels/:hotelId/edit"
          component={UpdateHotel}
        />
        <ProtectedRoute exact path="/lists" component={Lists} />
        <ProtectedRoute exact path="/lists/:listId/edit" component={EditList} />
        <ProtectedRoute exact path="/lists/:listId" component={List} />

        {/* REGION */}
        <ProtectedRoute
          exact
          path="/lists/:listId/regions/new"
          component={NewListRegion}
        />
        <ProtectedRoute
          exact
          path="/lists/:listId/regions/:listRegionId/edit"
          component={EditListRegion}
        />

        {/* POI */}
        <ProtectedRoute
          exact
          path="/lists/:listId/points-of-interest/:pinpointId/edit"
          component={EditPointOfInterest}
        />
        <ProtectedRoute
          exact
          path="/lists/:listId/points-of-interest/new"
          component={NewPointOfInterest}
        />

        {/* Informations Pratiques */}
        <ProtectedRoute
          exact
          path="/lists/:listId/practical-informations"
          component={PracticalInformations}
        />
        <ProtectedRoute
          exact
          path="/lists/:listId/practical-informations/new"
          component={NewPracticalInformation}
        />
        <ProtectedRoute
          exact
          path="/lists/:listId/practical-informations/:practicalInformationId/edit"
          component={EditPracticalInformation}
        />

        <ProtectedRoute
          exact
          path="/orders/:orderId/pdf"
          component={GeneratePDF}
        />

        <ProtectedRoute exact path="/settings" component={Settings} />
        <Redirect to="/tour-operators" />
      </Switch>
    </BrowserRouter>
  );
};
