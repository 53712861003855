import { api } from "./api";

export const getAllLists = (type?: string) => {
  if (type) {
    return api.get(`/lists?type=${type}`);
  } else {
    return api.get(`/lists`);
  }
};

export const getAllListsAdmin = () => {
  return api.get(`/lists/admin`);
};

export const getList = (listId: string) => {
  return api.get(`/lists/${listId}`);
};

export const getAllListRegions = (listId: string) => {
  return api.get(`/lists/${listId}/regions`);
};
