import { FC, MouseEventHandler, CSSProperties } from "react";
import styled from "styled-components";

import { Loader } from "../Loader";

import { colors } from "src/theme/colors";

type ButtonVariant = "primary" | "outline" | "outline-error" | "secondary";
type VariantStyles = {
  color: NonNullable<CSSProperties["color"]>;
  backgroundColor: NonNullable<CSSProperties["backgroundColor"]>;
  borderColor: NonNullable<CSSProperties["borderColor"]>;
};

const mapVariantToStyle: Record<ButtonVariant, VariantStyles> = {
  primary: {
    color: colors.main.white,
    backgroundColor: colors.cta.green,
    borderColor: "rgba(34, 34, 34, 0.1)",
  },
  secondary: {
    color: colors.main.black,
    backgroundColor: colors.main.white,
    borderColor: "rgba(34, 34, 34, 0.1)",
  },
  outline: {
    color: colors.cta.green,
    backgroundColor: colors.main.white,
    borderColor: "rgba(34, 34, 34, 0.1)",
  },
  "outline-error": {
    color: colors.error[300],
    backgroundColor: colors.main.white,
    borderColor: "rgba(34, 34, 34, 0.1)",
  },
};

const disabledStyle: VariantStyles = {
  color: "rgba(34, 34, 34, 0.4)",
  backgroundColor: colors.grey[100],
  borderColor: "rgba(34, 34, 34, 0.1)",
};

const mapVariantToLoaderColor: Record<ButtonVariant, string> = {
  primary: colors.main.white,
  secondary: colors.main.black,
  outline: colors.main.black,
  "outline-error": colors.main.black,
};

interface ButtonProps {
  variant?: ButtonVariant;
  disabled?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  type?: "button" | "submit";
  className?: string;
  loading?: boolean;
}
export const Button: FC<ButtonProps> = props => {
  const {
    children,
    disabled = false,
    variant = "primary",
    type = "button",
    loading = false,
    className,
    onClick,
  } = props;

  const variantStyles: VariantStyles = disabled
    ? disabledStyle
    : mapVariantToStyle[variant];
  return (
    <StyledButton
      onClick={onClick}
      disabled={disabled}
      className={className}
      type={type}
      {...variantStyles}
    >
      {loading ? (
        <Loader
          size="16px"
          borderSize="2px"
          color={mapVariantToLoaderColor[variant]}
        />
      ) : (
        children
      )}
    </StyledButton>
  );
};

type StyledButtonProps = VariantStyles & {
  disabled: boolean;
};
export const StyledButton = styled.button<StyledButtonProps>`
  width: 100%;
  cursor: pointer;
  user-select: none;
  text-align: center;
  padding: 10px 16px;
  border-radius: 4px;
  white-space: nowrap;
  font-size: 14px;
  font-weight: 600;
  transition: all 250ms ease;

  pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};
  color: ${({ color }) => color};
  background-color: ${({ backgroundColor }) => backgroundColor};
  border: 1px solid ${({ borderColor }) => borderColor};

  &:hover {
    opacity: 0.75;
  }
`;
